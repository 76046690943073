import React, { useState } from "react";
import Modal from "../UI/Modal";
import sucess from "../../assets/images/new-images/sucess.png";
import dltIcon from "../../assets/images/new-images/dlt.png";
import axios from "axios";
import { fetchAllApps } from "../../actions/action-grouprule"; 
import { useDispatch } from "react-redux";
import { fetchAllUserApps } from "../../actions/actions-users";
import {resetSharepointState} from "../../actions/actions-sharepoint";
const { REACT_APP_BACKEND_API_APP } = process.env;

const DeleteApps = (props) => {

  const { deleteId, setDeleteSuccess } = props;
  const [appsDelete, setAppsDelete] = useState(false);
  const dispatch = useDispatch();
  const deleteHandler = (deleteId) => {
    
    let token = sessionStorage.getItem('accessToken2')
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    };

    axios
      .post(`${REACT_APP_BACKEND_API_APP}/api/DeleteApplicationByAppId?appId=${deleteId}`,{}, config)
      .then(async (res) => {
        setDeleteSuccess(true);
        setAppsDelete(!appsDelete);
        dispatch(fetchAllUserApps());
      });
  };

  const appsDeleteHandler = () => {
    setAppsDelete(!appsDelete);
  };

  return (
    <Modal modalId={"deleteAppsModal"} modalLabel={"deleteAppsModalLabel"} className={"dlt-modal"}>
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content">
          
          <div className="modal-body text-center pt-0">
            <img src={!appsDelete ? dltIcon : sucess} alt="icon" className="m-3" />

            {!appsDelete && <p className="dlt-hed">Are you sure ?</p>}

            <p className="dlt-body">
              {!appsDelete
                ? "Do you want to delete this record? Once deleted, this action cannot be undone"
                : "App Deleted Successfully"}
            </p>
          </div>
          <div className="modal-footer">
            {!appsDelete && (
              <button
                type="button"
                className="btn btn-secondary cancel--btn"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            )}

            {!appsDelete ? (
              <button
                type="button"
                className="btn btn-genmeb ms-3 submit--btn"
                onClick={() => deleteHandler(deleteId)}
              >
                Delete
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-dismiss="modal"
                onClick={appsDeleteHandler}
              >
                Ok
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteApps