import React, { useEffect } from "react";
import SearchIcon from "../../../assets/images/search.svg";
import up from "../../../assets/images/new-images/up.png";
import * as actions from "../../../actions/action-master";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
const AddGroupsModal = ({
  userGroups,
  setUserGroups,
  groupsSearchInput,
  setGroupsSearchInput,
  selectGroupChecked,
  setSelectGroupChecked,
  selectedGroups,
  setSelectedGroups,
  editmode,
  fetchAllUserGroups,
  groupsList = [],
  filterRule,
  handleGroupFocus,
  conflictEdit,
  handleClickGroup,
  isShownGroup,
  test,
  userApps,
}) => {
  const init = () => {
    fetchAllUserGroups();
  };

  useEffect(() => {
    init();
  }, []);
  const modifyRuleList = useSelector(
    (state) => state?.groupsRule?.modifyRuleList
  );
  const groupSearchHandler = (e) => {
    setGroupsSearchInput(e.target.value);
  };

  let groupSearchFilter =
    userGroups &&
    userGroups?.length &&
    userGroups
      ?.filter((a) => a.status === true)
      ?.filter((item) => {
        if (groupsSearchInput === "") {
          return item;
        } else if (
          item.value.toLowerCase().includes(groupsSearchInput.toLowerCase())
        ) {
          return item;
        }
        return null;
      });

  const groupSelect = (e) => {
    setSelectGroupChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedGroups(
        userGroups.map((i) => {
          return {
            ...i,
            ischecked: e.target.checked,
          };
        })
      );
    } else if (e.target.checked === false) {
      setSelectedGroups([]);
    }
  };

  const groupHandler = (e, val) => {
    if (e.target.checked) {
      setSelectedGroups([...selectedGroups, val]);
    } else if (e.target.checked === false) {
      setSelectGroupChecked(false);
      setSelectedGroups(selectedGroups.filter((i) => i.id !== val.id));
    }
  };

  const submitGroups = (values) => {
    setUserGroups(
      userGroups.map((obj) => {
        if (values.find((ol) => ol.id === obj.id)) {
          return {
            ...obj,
            ischecked: true,
          };
        } else {
          return { ...obj, ischecked: false };
        }
      })
    );

    setGroupsSearchInput("");
    handleClickGroup();
  };
  // useEffect(() => {
  //   let data = {
  //     groupIds: userGroups
  //       .filter((i) => i.ischecked === true)
  //       .map((item) => item.id),
  //     applicationIds: userApps
  //       .filter((i) => i.ischecked === true)
  //       .map((item) => item.appId),
  //     conditions: test,
  //   };
  //   !test?.map((i) => i.conditionOn).includes("") &&
  //     !test?.map((i) => i.conditionOperator).includes("") &&
  //     test?.map((i) => i.selectedValues)[0]?.length > 0 &&
  //     userGroups.filter((i) => i.ischecked === true).map((item) => item.id)
  //       ?.length > 0 &&
  //     console.info(data);
  // }, [userGroups]);

  useEffect(() => {
    if (editmode) {
      let newVal = {};
      newVal = groupsList?.map((item) => {
        if (
          [...new Set(modifyRuleList?.groupIds)].map((i) => i).includes(item.id)
        ) {
          return { ...item, ischecked: true };
        }
        return item;
      });

      setUserGroups(newVal);
      setSelectedGroups(
        groupsList?.filter((i) =>
        modifyRuleList?.groupIds.map((i) => i).includes(i.id)
        )
      );
      if (
        newVal.filter((i) => i.ischecked === true).length === groupsList?.length
      ) {
        setSelectGroupChecked(true);
      }
    } else {
      groupsList?.length > 0 &&
        setUserGroups(
          groupsList.map((item) => {
            return {
              ...item,
              ischecked: false,
            };
          })
        );
    }
  }, [groupsList, editmode, conflictEdit]);

  const cancelGroups = () => {
    if (userGroups.filter((i) => i.ischecked === true)?.length > 0) {
      setSelectedGroups(userGroups.filter((i) => i.ischecked === true));
    } else {
      setSelectedGroups([]);
    }
  };

  return (
    <>
      {isShownGroup && (
        <div className="select-data-option">
          <div className="relative w-100">
            <img src={SearchIcon} alt="icon" className="pop-search top" />
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              onChange={groupSearchHandler}
              value={groupsSearchInput}
            />
            {/* <img data-bs-dismiss="modal" src={up} alt="icon" className="up-icon" /> */}
          </div>
          <div className="scroll-height">
            {!groupsSearchInput && (
              <div className="s-all">
                <p>Select All</p>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={
                    selectGroupChecked ||
                    selectedGroups.length === userGroups.length
                  }
                  onChange={(e) => groupSelect(e)}
                />
              </div>
            )}
            {userGroups && userGroups.length === 0 && (
              <div className="all-check">No Group Found</div>
            )}
            {userGroups.length > 0 && groupSearchFilter.length === 0 ? (
              <div className="all-check">No Match Found</div>
            ) : (
              groupSearchFilter?.length > 0 &&
              groupSearchFilter?.map((r, index) => (
                <div className="all-check" key={r.id}>
                  <p>{r.value}</p>
                  <input
                    type="checkbox"
                    name="groupIds"
                    className="form-check-input"
                    value={r.id}
                    checked={selectedGroups.map((ir) => ir.id).includes(r.id)}
                    onChange={(e) => groupHandler(e, r)}
                  />
                </div>
              ))
            )}
          </div>
          <div className="select-fotter-section">
            <Button
              className="btn-cancel"
              variant="text"
              onClick={(e) => {
                cancelGroups();
                setGroupsSearchInput("");
                setSelectGroupChecked(false);
                handleClickGroup();
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={() => submitGroups(selectedGroups)}
              className="genmeb-btn"
              variant="contained"
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = ({ master, groupsRule }) => ({
  groupsList: master.userGroups,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllUserGroups: () => dispatch(actions.fetchAllUserGroups()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddGroupsModal);
