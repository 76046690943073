import { notificationActions } from "../actions/constants";
import { updateState } from "../middlewares/utils";

const defaultState = {
  openDeleteModal: false,
  loading: true,
  response: {},
  alerts: [],
  error: false,
  pageKeys: [],
  pageNo: 1,
  totalRecords: 0,
  currentPage: null,
  recordsPerPage: 10,
  displayLimit: 10,
  paginationOptions: {
    pageNo: 1,
    displayLimit: 10,
  },
  dismissLoading: false,
  dismissError: "",
  dismissResponse: "",
  newAppOrder: [],
  notificationStart: "",
  notificationEnd: "",
  dashNotificationStart: "",
  dashNotificationEnd: "",
  dashAlerts: [],
  dashLoading: true,
  dashPopAlerts: [],
  dashPopLoading: true,
};

const alerts = (state = defaultState, actions) => {
  switch (actions.type) {
    case notificationActions.CREATE_NEW_ALERT_START:
      return updateState(state, { loading: true, response: {} });
    case notificationActions.CREATE_NEW_ALERT_SUCCESS:
      return updateState(state, { response: actions.payload, loading: false });
    case notificationActions.CREATE_NEW_ALERT_FAILED:
      return updateState(state, { response: actions.payload, loading: false, error: true });
    case notificationActions.FETCH_ALERTS_BY_LOGIN_ID_START:
      return updateState(state, { loading: true });
    case notificationActions.FETCH_ALERTS_BY_LOGIN_ID_SUCCESS:
      return updateState(state, {
        alerts: actions.payload.payload.data,
        loading: actions.payload.loading,
      });
    case notificationActions.FETCH_ALERTS_BY_LOGIN_ID_FAILED:
      return updateState(state, { ...actions.payload });
    case notificationActions.DELETE_ALERT_START:
      return updateState(state, { loading: true });
    case notificationActions.DELETE_ALERT_SUCCESS:
      return updateState(state, {
        deletedMsg: actions.payload,
        loading: false,
      });
    case notificationActions.DELETE_ALERT_FAILED:
      return updateState(state, { error: actions.payload });
    case notificationActions.OPEN_DELETE_MODAL:
      return updateState(state, { openDeleteModal: actions.payload.openModal });

    case notificationActions.UPDATE_ALERT_START:
      return updateState(state, { loading: true });
    case notificationActions.UPDATE_ALERT_SUCCESS:
      return updateState(state, { response: actions.payload});
    case notificationActions.UPDATE_ALERT_FAILED:
      return updateState(state, { response: actions.payload });
    case notificationActions.SET_ALERTS_RECORDS_LENGTH:
      return updateState(state, { totalRecords: actions.payload.records });
    case notificationActions.FETCH_PAGINATION_DATA_SUCCESS:
      return updateState(state, { paginationOptions: { ...actions.payload } });
    case notificationActions.FETCH_SEARCH_INPUT:
      return updateState(state, {
        search: actions.payload.search,
        paginationOptions: { ...actions.payload.paginationOptions },
      });

    case notificationActions.DISMISS_ALERT_START:
      return updateState(state, { ...actions.payload });
    case notificationActions.DISMISS_ALERT_SUCCESS:
      return updateState(state, { ...actions.payload });
    case notificationActions.DISMISS_ALERT_FAILED:
      return updateState(state, { dismissError: { ...actions.payload } });

    case notificationActions.DISMISS_ALERT_START:
      return updateState(state, { ...actions.payload });
    case notificationActions.DISMISS_ALERT_SUCCESS:
      return updateState(state, { ...actions.payload });
    case notificationActions.DISMISS_ALERT_FAILED:
      return updateState(state, { ...actions.payload });

    case notificationActions.FETCH_NOTIFICATION_FITLER_DATES:
      return updateState(state, {
        notificationStart: actions.payload.dates.startDate,
        notificationEnd: actions.payload.dates.endDate,
        paginationOptions: { ...actions.payload.paginationOptions },
      });

      case notificationActions.FETCH_DASH_ALERTS_BY_LOGIN_ID_START:
      return updateState(state, { dashLoading: true });
    case notificationActions.FETCH_DASH_ALERTS_BY_LOGIN_ID_SUCCESS:
      return updateState(state, {
        dashAlerts: actions.payload.payload,
        dashLoading: actions.payload.loading,
      });
    case notificationActions.FETCH_DASH_ALERTS_BY_LOGIN_ID_FAILED:
      return updateState(state, { ...actions.payload });

      case notificationActions.FETCH_DASH_POPUP_ALERTS_BY_LOGIN_ID_START:
        return updateState(state, { dashPopLoading: true });
      case notificationActions.FETCH_DASH_POPUP_ALERTS_BY_LOGIN_ID_SUCCESS:
        return updateState(state, {
          dashPopAlerts: actions.payload.payload,
          dashPopLoading: actions.payload.loading,
        });
      case notificationActions.FETCH_DASH_POPUP_ALERTS_BY_LOGIN_ID_FAILED:
        return updateState(state, { ...actions.payload });


      case notificationActions.FETCH_DASH_NOTIFICATION_FITLER_DATES:
        return updateState(state, {
          dashNotificationStart: actions.payload.dates.startDate,
          dashNotificationEnd: actions.payload.dates.endDate,
        });
    default:
      return state;
  }
};

export default alerts;
