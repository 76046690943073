import React from "react";
import { DashboardLayout } from "../components/dashboard-layout";
import "../assets/css/myApp.css";
import "../assets/css/new-dashboard.css";
import brdCum from "../assets/images/new-images/brd-cum-icon.svg";
import support from "../assets/images/new-images/supportB.png";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

function Support() {
  const emailSend = () => {
    window.open(
      "mailto:BIS@genmab.com?subject=Need help with Launchpad&body=Enter your message"
    );
  };
  return (
    <>
      <AuthenticatedTemplate>
        <DashboardLayout>
          <section>
            <div className="breadcrumb">
              <img src={brdCum} alt="logo" />
              <p> |&nbsp;&nbsp; Support </p>
            </div>
            <div className="support-wrapper">
              <div className="app-header">
                <p className="app-heding">Support</p>
              </div>
              <div className="center-box">
                <div className="text-center">
                  <img src={support} alt="image" className="center-img" />
                  <p className="sup-mainText">Support</p>
                  <p className="sup-bodytext">
                    Select the “Contact Now” button to
                    <br /> request for assistance or to provide feedback on this
                    application.
                  </p>
                  <p className="supLinktext">BIS@genmab.com</p>
                  <button
                    className="btn btn-genmeb"
                    onClick={() => emailSend("BIS@genmab.com")}
                  >
                    Contact Now
                  </button>
                </div>
              </div>
            </div>
          </section>
        </DashboardLayout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <center className="center-content">
          You do not have access to the Launchpad application. To request access
          to Launchpad, or believe you should already have access to Launchpad,
          please reach out to the Launchpad support team through the following
          email: BIS@genmab.com.
        </center>
      </UnauthenticatedTemplate>
    </>
  );
}

export default Support;
