import React, { useEffect } from "react";
import SearchIcon from "../../../assets/images/search.svg";
import up from "../../../assets/images/new-images/up.png";
import * as actions from "../../../actions/action-master";
import { connect } from "react-redux";
import { fetchUsersListByDepartment } from "../../../actions/action-grouprule";
import Modal from "../../UI/Modal";
import { Loader } from "rsuite";

const AddDepartmentModal = ({
  depts,
  setDepts,
  deptSearchInput,
  setDeptSearchInput,
  selectdeptChecked,
  setSelectDeptChecked,
  selectedDepts,
  setSelectedDepts,
  editmode,
  filterRule,
  setUsersList,
  setLocationsList,
  fetchAllDepartments,
  departments = [],
  fetchUsersListByDepartment,
  fetchAllLocations,
  setSelectChecked,
  handleDeptFocus,
  setSelectLocationChecked,
  selectedLocations,
  fetchedLocations = [],
  setSelectedLocations,
  setSelectedUsers,
  departmentsLoading,
  conflictEdit,
}) => {
  const init = () => {
    fetchAllDepartments();
  };

  useEffect(() => {
    init();
  }, []);

  const deptSearchHandler = (e) => {
    setDeptSearchInput(e.target.value);
  };

  let deptSearchFilter =
    depts?.length > 0 &&
    depts?.filter((search) => {
      if (deptSearchInput === "") {
        return search;
      }
      if (search.value.toLowerCase().includes(deptSearchInput?.toLowerCase())) {
        return search;
      }
      return null;
    });
  const deptSelect = (e) => {
    setSelectDeptChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedDepts(
        depts.map((i) => {
          return {
            ...i,
            ischecked: e.target.checked,
          };
        })
      );
    } else if (e.target.checked === false) {
      setSelectedDepts([]);
    }
  };

  const deptHandler = (e, val) => {
    if (e.target.checked) {
      setSelectedDepts([...selectedDepts, val]);
    } else if (e.target.checked === false) {
      setSelectDeptChecked(false);
      setSelectedDepts(selectedDepts.filter((i) => i.id !== val.id));
    }
  };

  const submitDeptForUsers = (values) => {
    setDepts(
      depts.map((obj) => {
        if (values.find((ol) => ol.id === obj.id)) {
          return {
            ...obj,
            ischecked: true,
          };
        } else {
          return { ...obj, ischecked: false };
        }
      })
    );

    const payload = {
      deptNames: values.map((i) => i.value),
      locations:
        selectedLocations?.length > 0
          ? selectedLocations?.map((i) => i.cityState)
          : [],
    };

    fetchUsersListByDepartment(payload);
    fetchAllLocations(payload);
    setDeptSearchInput("");
  };

  useEffect(() => {
    if (editmode) {
      let newVal = [];
      newVal = departments.map((item) => {
        if ([...new Set(filterRule.deptNames)].includes(item.value)) {
          return { ...item, ischecked: true };
        }
        return item;
      });

      const newDepts = newVal
        .filter((i) => i.ischecked === true)
        .map((a) => a.value);
      const newLocations = [...new Set(filterRule.locations)].map(
        (i) => i.value
      );

      const payload = {
        deptNames: newDepts,
        locations: newLocations,
      };

      fetchUsersListByDepartment(payload);
      fetchAllLocations(payload);
      setDepts(newVal);
      setSelectedDepts(
        departments.filter((i) => filterRule.deptNames.includes(i.value))
      );
      if (
        newVal.filter((i) => i.ischecked === true).length === departments.length
      ) {
        setSelectDeptChecked(true);
      }
    } else {
      departments?.length > 0 &&
        setDepts(
          departments.map((item) => {
            return {
              ...item,
              ischecked: false,
            };
          })
        );
    }
  }, [departments, editmode, conflictEdit]);

  useEffect(() => {
    if (editmode && filterRule) {
      const newDepts = [...new Set(filterRule?.deptNames)];
      const newLocations = [...new Set(filterRule.locations)].map(
        (i) => i.value
      );
      const payload = {
        deptNames: newDepts,
        locations: filterRule.locations.length > 0 ? newLocations : [],
      };

      fetchUsersListByDepartment(payload);

      fetchAllLocations(payload);
    } else {
      setUsersList([]);
      setDepts(departments);
      setSelectedDepts([]);
      setSelectDeptChecked(false);
      setSelectChecked(false);
      // setSelectLocationChecked(false);
      // setLocationsList([]);
    }
  }, [editmode]);

  const cancelDepartments = () => {
    if (depts.filter((i) => i.ischecked === true)?.length > 0) {
      setSelectedDepts(depts.filter((i) => i.ischecked === true));
    } else {
      setSelectedDepts([]);
    }
  };

  useEffect(() => {
   if (depts.filter((a) => a.ischecked === true).length === 0) {
      setUsersList([]);
      setLocationsList([]);
      setSelectedLocations([]);
      setSelectChecked(false);
      setSelectLocationChecked(false);
      setSelectedUsers([]);
    }
  }, [depts, editmode]);
  return (
    <Modal
      modalId={"departmentOpen"}
      modalLabel={"exampledepartmentOpen"}
      className="modal fade"
    >
      <div
        className="modal-dialog modal-dialog-centered me-widht"
        onBlur={handleDeptFocus}
        name="deptNames"
      >
        <div className="modal-content department-side">
          <div className="modal-body p-0">

            {departmentsLoading ? <Loader/> : <div>
              <div className="relative w-100">
                <img src={SearchIcon} alt="icon" className="pop-search" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={deptSearchHandler}
                  value={deptSearchInput}
                />
                <img
                  data-bs-dismiss="modal"
                  src={up}
                  alt="icon"
                  className="up-icon"
                />
              </div>
              <div className="scroll-height">
                {depts?.length > 0  && !deptSearchInput && (
                  <div className="s-all">
                    <p>Select All</p>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={
                        selectdeptChecked ||
                        selectedDepts.length === depts.length
                      }
                      onChange={(e) => deptSelect(e)}
                    />
                  </div>
                )}
                {depts && depts.length === 0 && (
                  <div className="all-check">No Departments Found</div>
                )}
                {deptSearchFilter.length === 0 ? (
                  <div className="all-check">No Match Found</div>
                ) : (
                  deptSearchFilter.length > 0 &&
                  deptSearchFilter.map((r) => (
                    <div className="all-check" key={r.id}>
                      <p>{r.value}</p>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="deptNames"
                        value={r.id}
                        checked={selectedDepts
                          .map((ir) => ir.id)
                          .includes(r.id)}
                        onChange={(e) => deptHandler(e, r)}
                      />
                    </div>
                  ))
                )}
              </div>
              
            </div>}
            <div className="drop-btn-set">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    cancelDepartments();
                    setDeptSearchInput("");
                    setSelectDeptChecked(false);
                  }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="btn btn-genmeb ms-3"
                  onClick={() => submitDeptForUsers(selectedDepts)}
                >
                  Save
                </button>
              </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
const mapStateToProps = ({ master, groupsRule }) => ({
  departments: master.departments,
  fetchedLocations: master?.locations,
  departmentsLoading: master?.departmentsLoading
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllDepartments: () => dispatch(actions.fetchAllDepartments()),

  fetchUsersListByDepartment: (payload) =>
    dispatch(fetchUsersListByDepartment(payload)),

  fetchAllLocations: (payload) => dispatch(actions.fetchAllLocations(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDepartmentModal);
