import React from "react";
import { Alert as MuiAlert, AlertTitle, Avatar, Button, Typography } from "@mui/material";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { ArrowRight } from "../App/icons";

export const CustomAlert = (props) => {
  const {
    severity = 'success',
    title,
    mt = 3,
    mb = 0,
    secondaryText,
    action,
    actionText,
    onClick = () => null,
  } = props;
  const AlertSuccess = severity === 'success'
  return (
    <MuiAlert
      sx={ {
        mt: mt,
        mb: mb,
        borderRadius: '0.5rem',
        padding: '1rem',
        gap: '0.75rem',
        border: `0.0625rem solid ${AlertSuccess ? '#6CE9A6' : '#FDA29B'}`,
        background: AlertSuccess ? '#F6FEF9' : '#FFFBFA',

        '& .MuiAlert-icon': {
          padding: 0,
          margin: 0,
          color: AlertSuccess ? '#027A48' : '#B42318',

          '& svg': {
            fontSize: '1.25rem'
          }
        },

        '& .MuiAlert-message': {
          padding: 0,

          '& .MuiTypography-root': {
            marginTop: '0.25rem',
            fontFamily: 'Manrope',
            fontSize: '0.875rem',
            fontWeight: 400,
            color: AlertSuccess ? '#027A48' : '#B42318',
            lineHeight: '142.857%',
          },

          '& .MuiAlertTitle-root': {
            fontFamily: "Manrope SemiBold",
            margin: 0,
            color: AlertSuccess ? '#027A48' : '#B42318',
            fontWeight: 600,
          }
        }
      }}
      severity={ severity }
      icon={ AlertSuccess ? <CheckCircleOutlineOutlinedIcon /> : <ErrorOutlineOutlinedIcon />}
    >
      <AlertTitle>{ title }</AlertTitle>
      { secondaryText && <Typography>{ secondaryText }</Typography>}
      { action && (
        <Button
          disableRipple
          onClick={onClick}
          sx={ {
            mt: '0.75rem',
            p: 0,
            fontFamily: 'Manrope',
            fontSize: '0.875rem',
            fontWeight: 600,
            lineHeight: '142.857%',
            color: '#039855',
            textTransform: 'none',
            gap: '0.5rem',

            '&:hover': {
              background: 'transparent'
            }

          }}
        >
          { actionText }
          <ArrowRight />
        </Button>
      )}
    </MuiAlert>
  )
}