import axios from "axios";
import {fetchGraphToken} from "../utils/auth";

const axiosApiInstance = axios.create();

const BASE_GRAPH_URL = "https://graph.microsoft.com/v1.0/"

//Interceptors to handle MS Graph token authentication
axiosApiInstance.interceptors.request.use(
    async config => {
        let access_token = sessionStorage.getItem("graphAccessToken");

        if(access_token == null){
            access_token = await fetchGraphToken();
        }

        config.headers = {
            'Authorization': `Bearer ${access_token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Prefer': 'redeemSharingLinkIfNecessary',
        }

        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosApiInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const access_token = await fetchGraphToken();
        axiosApiInstance.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        return axiosApiInstance(originalRequest);
    }

    return Promise.reject(error);
});

export async function GET(params) {
    try {
        const {endpoint, queryString} = params;

        const q = queryString ? `?${queryString}` : "";

        return await axiosApiInstance.get(`${BASE_GRAPH_URL}${endpoint}${q}`)
            .then((response) => response.data);
    } catch (error) {
        return error;
    }
}

export default {
    GET
};