/**
 * Convert a sharepoint url to a shared url.
 * @param sharingUrl
 * @returns {string}
 */
export const convertToEncodedSharesUrl = (sharingUrl) => {
    const url = decodeUrl(sharingUrl);
    const base64Value = btoa(encodeURIComponent(url).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode('0x' + p1)));
    const encodedUrl = "u!" + base64Value.replace(/=+$/, '').replace(/\//g, '_').replace(/\+/g, '-');

    return encodedUrl;
}

export const decodeUrl = (sharingUrl) => {
    const isEncoded = uri => uri !== decodeURIComponent(uri || '')
    return isEncoded ? decodeURIComponent(sharingUrl) : sharingUrl;
}

/**
 * Determine the type of sharepoint sharing url based on the url patterns, as follows:
 *
 *  “:f” means Folder sharing
 *  “:w” means Word document sharing
 *  “:x” means Excel document sharing
 *  “:p” means PowerPoint document sharing
 *  “:b” means PDF document sharing
 *  ":l" means List sharing
 *  ":li" means ListItem sharing
 *
 * @param sharingUrl
 * @returns {string|string}
 */
export const getQueryStringType = (sharingUrl, linkType) => {

    let itemType;

    switch (linkType) {
        case 'f':
                itemType = 'children';
            break;
        default:
                itemType = 'unknown'; // Fallback case if none of the expected segments are matched
    }

    return itemType !== 'unknown' ? `$expand=${itemType}` : '';
}

export const getSharingLinkType = (sharingUrl) => {

    const url = decodeUrl(sharingUrl);

    // Regular expression to match the specific segment after the TLD
    const pattern = /https:\/\/.*\.sharepoint\.com\/:([l,w,x,f,p,b,li]):/i;
    const match = url.match(pattern);

    if (match && match[1]) {
        return match[1].toLowerCase()
    }

    return 'unknown';
}

export const getSharingURI = (encodedShareUrl, linkType) => {

    let slug;

    switch(linkType){
        case 'l':
                slug = 'list';
            break;
        case 'li':
                slug = 'listItem';
            break;
        default:
            slug = 'driveItem'
    }

    return `shares/${encodedShareUrl}/${slug}`
}

/**
 * Filters out objects from an array that contain a specific value in any of their properties.
 *
 * @param {Object[]} arr - The array of objects to filter.
 * @param {*} value - The value to check against each object's property values.
 * @returns {Object[]} A new array of objects, each of which does not contain the specified property value.
 */
export const removeObjectsWithKeyValue = (arr, value) => {
    return arr.filter(obj => {
        return !Object.values(obj).includes(value);
    });
}




