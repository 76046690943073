import React from "react";
import { useState } from "react";
import "../assets/css/myApp.css";
import "../assets/css/new-dashboard.css";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import GroupRule from "../components/Assignments/GroupRule";
import AddNewGroupRule from "../components/Assignments/AddNewGroupRule";

const Assignment = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <GroupRule />
      </AuthenticatedTemplate>
    </>
  );
};

export default Assignment;
