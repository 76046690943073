import React, {useEffect, useRef, useState} from "react";
import Modal from "../UI/Modal";
import axios from "axios";
import up from "../../assets/images/new-images/up.png";
import SearchIcon from "../../assets/images/search.svg";
import sucess from "../../assets/images/new-images/sucess.png";
import { useSelector } from "react-redux";
import { customStyles } from "./styles";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
const { REACT_APP_BACKEND_API_APP } = process.env;

const UpdateUser = (props) => {
  const { modalId, modalLabel, editUser, setUpdateSuccess } = props;

  const [userEditSuccess, setUserEditSuccess] = useState(false);
  const groupsList = useSelector((state) => state.master.userGroups);
  const userRole = useSelector((state) => state?.master?.userRoles);
  const [searchUserGroup, setSearchUserGroup] = useState([]);
  const [adminId, setAdminId] = useState([]);
  const [teamAdminId, setTeamAdminId] = useState([]);
  const [userId, setUserId] = useState([]);
  const [userGroupList, setUserGroupList] = useState([]);
  const modalRef = useRef(null);

  useEffect(() => {
    setAdminId(userRole?.filter((a) => a.value === "Admin")[0].id);
    setTeamAdminId(userRole?.filter((a) => a.value === "Team Lead")[0].id);
    setUserId(userRole?.filter((a) => a.value === "User")[0].id);
  }, [setAdminId, userRole, setTeamAdminId, setUserId]);

  useEffect(() => {
    groupsList?.length > 0 &&
      setUserGroupList(
        groupsList?.map((item) => {
          return {
            ...item,
          };
        })
      );
  }, [groupsList]);
  useEffect(() => {
    var index = userGroupList.findIndex((x) => x.id === 0);
    index === -1
      ? userGroupList.splice(0, 0, { value: "Select All", id: 0, status: true })
      : console.log();
  });

  let userGroupSearchFilter =
    userGroupList &&
    userGroupList
      ?.filter((i) => i.status === true)
      ?.filter((item) => {
        if (searchUserGroup === "") {
          return item;
        } else if (item.value.toLowerCase().includes(searchUserGroup)) {
          return item;
        }
      });

  const [editUserData, setEditUserData] = useState({
    userId: "",
    userName: "",
    email: "",
    userRole: "",
    group: [],
    employeeId: "",
    createdBy: "",
    updatedBy: "",
    userRoleId: "",
    deptName: "",
    city: "",
    state: "",
    isEmailSend: true,
  });

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [tempGroup, setTempgroup] = useState([]);
  const [tempUserRole, setTempUserRole] = useState([]);

  const [welcomeEmail, setWelcomeEmail] = useState(true);
  const handleChange = () => {
    setWelcomeEmail(!welcomeEmail);
  };

  useEffect(() => {
    setEditUserData({
      userId: editUser.userId,
      userName: editUser.userName,
      email: editUser.email,
      userRole: editUser.userRole,
      group: editUser.group || [],
      employeeId: editUser.employeeId,
      createdBy: editUser.createdBy,
      updatedBy: editUser.updatedBy,
      userRoleId: editUser.userRoleId,
      deptName: editUser.deptName,
      city: editUser.city,
      state: editUser.state,
      isEmailSend: editUser.isEmailSend,
    });

    setSelectedGroups(editUser?.group ? editUser.group : []);
    setSelectedUserRole(editUser?.userRoleId ? editUser.userRoleId : "");
    setTempgroup(editUser?.group ? editUser.group : []);
    setTempUserRole(editUser?.userRoleId ? editUser.userRoleId : "");
    setWelcomeEmail(editUser?.isEmailSend ? editUser?.isEmailSend : true);
  }, [editUser]);

  const groupChange = (e) => {
    var value = e.target.value;
    if (value === "Select All" && e.target.checked)
      setSelectedGroups(
        userGroupList
          ?.filter((i) => i.status === true)
          .filter((item) => item.value !== value)
          .map((a) => a.value)
      );
    else if (value === "Select All" && !e.target.checked) setSelectedGroups([]);
    else if (e.target.checked && value !== "Select All")
      setSelectedGroups([...selectedGroups, value]);
    else if (!e.target.checked && value !== "Select All")
      setSelectedGroups(selectedGroups.filter((item) => item !== value));
  };

  const [editTypeTouched, setEditTypeTouched] = useState(false);
  const editypeIsValid = editUserData.userRole !== "";
  const edittypeInputBlurHandler = () => {
    setEditTypeTouched(true);
  };

  const edittypeInputIsInvalid = !editypeIsValid && editTypeTouched;
  const [errorMsg, setErrorMsg] = useState(false);
  const [errorReason, setErrorReason] = useState({title: '', description: ''});
  const saveUpdate = () => () => {
    setEditTypeTouched(true);
    if (editUserData.userRole === "") {
      return;
    }

    let data = {
      userId: editUserData.userId,
      userName: editUserData.userName,
      email: editUserData.email,
      signal: editUserData.signal,
      employeeId: editUserData.employeeId,
      userGroupId: userGroupList
        .filter((a) => editUserData.group.some((o2) => a.value === o2))
        .map((a) => a.id)
        .filter((a) => a !== 0),
      userRoleId: parseInt(editUserData.userRoleId),
      createdBy: editUser.createdBy,
      updatedBy: editUser.createdBy,
      deptName: editUser.deptName,
      city: editUser.city,
      state: editUser.state,
      managerName: editUser.managerName,
      managerEmail: editUser.managerEmail,
      managerEmployeeId: editUser.managerEmployeeId,
      jobTitle: editUser.jobTitle,
      companyName: editUser.companyName,
      isEmailSend: welcomeEmail,
    };

    let token = sessionStorage.getItem("accessToken2");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    };

    axios
      .post(
        `${REACT_APP_BACKEND_API_APP}/api/UpdateUserDetailsByUserId`,
        data,
        config
      )
      .then(async (res) => {
        if (res.data.statusCode === 400 || res.data.statusCode === 500) {
          setErrorMsg(true);
          setErrorReason(res.data.statusCode === 500 ? {title: 'User email invalid', description: 'Add valid email'} : {title: 'User could not be updated.', description: ''})
        }else {
          setErrorMsg(false);
          setUpdateSuccess(true);
          setUserEditSuccess(true);
        }
      });

    setEditTypeTouched(false);
  };

  const userEditHandler = () => {
    setUserEditSuccess(false);
    setErrorMsg(false);
  };

  useEffect(() => {
    const modalElement = modalRef.current;

    const handleModalHidden = () => {
      userEditHandler();
    };

    if (modalElement) {
      modalElement.addEventListener('hidden.bs.modal', handleModalHidden);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
      }
    };
  }, []);

  return (
    <>
      <Modal
        sx={customStyles}
        modalId={modalId}
        modalLabel={modalLabel}
        modalRef={modalRef}
      >
        <div
          className={
            "modal-dialog modal-dialog-centered modal-l"
          }
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Edit User
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => {
                  setEditTypeTouched(false);
                  setEditUserData({
                    ...editUserData,
                    group: tempGroup,
                    userRoleId: tempUserRole,
                    isEmailSend: welcomeEmail,
                  });
                  setSelectedGroups(tempGroup);
                  setSelectedUserRole(tempUserRole);
                  setSearchUserGroup("");
                  setWelcomeEmail(true);
                }}
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>
                    Full Name <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type your full Name"
                    value={editUserData.userName || ""}
                    onChange={() => {
                      console.log("");
                    }}
                    name="fullName"
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label>
                    Email address<sup>*</sup>
                  </label>
                  <div className="input-with-icon">
                    <EmailOutlinedIcon />
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      onChange={() => {
                        console.log("");
                      }}
                      value={editUserData.email || ""}
                      name="email"
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Select Group
                  </label>
                  <div className="input-with-icon">
                    <GroupsOutlinedIcon />
                    <input
                      style={{ caretColor: "transparent" }}
                      type="text"
                      className="form-control"
                      data-bs-target="#exampleModalToggle2"
                      data-bs-toggle="modal"
                      placeholder="Select Group"
                      onChange={() => {
                        console.log("");
                      }}
                      value={editUserData.group
                        .filter((a) => a !== "Select All")
                        .join(", ")}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Select Role<sup>*</sup>
                  </label>

                  <div className="wrp-check">
                    <div className="all-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="flexRadioDefault1"
                        name="userRole"
                        key={selectedUserRole}
                        onChange={(e) => {
                          setEditUserData({
                            ...editUserData,
                            userRoleId: e.target.value,
                          });
                        }}
                        value={adminId}
                        checked={
                          parseInt(editUserData.userRoleId) === adminId
                            ? true
                            : false
                        }
                      />
                      <p>Admin</p>
                    </div>
                    <div className="all-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="flexRadioDefault2"
                        name="userRole"
                        key={selectedUserRole}
                        onChange={(e) => {
                          setEditUserData({
                            ...editUserData,
                            userRoleId: e.target.value,
                          });
                        }}
                        value={teamAdminId}
                        checked={
                          parseInt(editUserData.userRoleId) === teamAdminId
                            ? true
                            : false
                        }
                      />
                      <p>Team Lead</p>
                    </div>
                    <div className="all-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="flexRadioDefault2"
                        name="userRole"
                        key={selectedUserRole}
                        onChange={(e) => {
                          setEditUserData({
                            ...editUserData,
                            userRoleId: e.target.value,
                          });
                        }}
                        value={userId}
                        checked={
                          parseInt(editUserData.userRoleId) === userId
                            ? true
                            : false
                        }
                      />
                      <p>User</p>
                    </div>
                  </div>
                </div>
                {!editUser.isEmailSend ? (
                  <div className="form-check full-pop form-group">
                    <span>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={handleChange}
                        checked={welcomeEmail}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Send the welcome email
                      </label>
                    </span>
                    <p className="note-text">
                      If you uncheck this, their account will be created but
                      they won't receive the welcome email
                    </p>
                  </div>
                ) : (
                  <div className="form-check full-pop form-group">
                    <span>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        checked
                        disabled
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Welcome Email already sent
                      </label>
                    </span>
                  </div>
                )}
              </form>
              { userEditSuccess && 
              <Alert 
                sx={ {
                borderRadius: '8px',
                mb: 2,
                mt: 3,
                padding: '1rem',
                gap: '12px',
                border: '1px solid #6CE9A6',
                background: '#F6FEF9',

                '& .MuiAlert-icon': {
                  padding: 0,
                  margin: 0,
                  color: '#027A48',

                  '& svg': {
                    fontSize: '20px'
                  }
                },

                '& .MuiAlert-message': {
                  padding: 0,

                  '& .MuiTypography-root': {
                    marginTop: '0.25rem',
                    fontFamily: 'Manrope',
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#027A48',
                    lineHeight: '142.857%',
                  },

                  '& .MuiAlertTitle-root': {
                    fontFamily: "Manrope SemiBold",
                    margin: 0,
                    color: '#027A48',
                    fontWeight: 600,
                  }
                }
              }} icon={<CheckCircleOutlineOutlinedIcon />} variant="outlined" severity="success">
              <AlertTitle>{editUserData?.userName} successfully updated.</AlertTitle>
              </Alert> }
              { errorMsg && <Alert 
                sx={{
                  borderRadius: '8px',
                  mb: 2,
                  mt: 3,
                  padding: '1rem',
                  gap: '12px',
                  border: '1px solid #FDA29B',
                  background: '#FFFBFA',

                  '& .MuiAlert-icon': {
                    padding: 0,
                    margin: 0,
                    color: '#B42318',

                    '& svg': {
                      fontSize: '20px'
                    }
                  },

                  '& .MuiAlert-message': {
                    padding: 0,

                    '& .MuiTypography-root': {
                      marginTop: '0.25rem',
                      fontFamily: 'Manrope',
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#B42318',
                      lineHeight: '142.857%',
                    },

                    '& .MuiAlertTitle-root': {
                      margin: 0,
                      fontFamily: "Manrope SemiBold",
                      color: '#B42318',
                      fontWeight: 600,
                    }
                  }
                }} icon={<ErrorOutlineOutlinedIcon />} variant="outlined" severity="error">
                <AlertTitle>{errorReason?.title}</AlertTitle>
                <Typography>{errorReason?.description}</Typography>
              </Alert> }
            </div>
            <div className="modal-footer secondary">
              { !userEditSuccess &&
                  <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setEditTypeTouched(false);
                  setEditUserData({
                    ...editUserData,
                    group: tempGroup,
                    userRoleId: tempUserRole,
                    isEmailSend: welcomeEmail,
                  });
                  setSelectedGroups(tempGroup);
                  setSelectedUserRole(tempUserRole);
                  setSearchUserGroup("");
                  setWelcomeEmail(true);
                }}
              >
                Cancel
              </button>}
              { !userEditSuccess ? <button
                type="button"
                onClick={saveUpdate()}
                className="btn btn-genmeb ms-3"
              >
                Update
              </button> : <button
                type="button"
                className="btn btn-genmeb"
                data-bs-dismiss="modal"
              >
                Done
              </button>
              }
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        modalId={"exampleModalToggle2"}
        modalLabel={"exampleModalToggleLabel2"}
      >
        <div
          className="modal-dialog modal-dialog-centered me-widht"
        >
          <div className="modal-content for-align4">
            <div className="modal-body p-0">
              <div className="relative w-100">
                <img src={SearchIcon} alt="icon" className="pop-search" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={(evnt) =>
                    setSearchUserGroup(evnt.target.value.toLowerCase())
                  }
                  value={searchUserGroup}
                />
                <img
                  src={up}
                  alt="icon"
                  className="up-icon"
                  data-bs-target="#exampleModalToggle"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                />
              </div>
              {userGroupList && userGroupList?.length === 0 ? (
                <p className="events-error">No Groups available </p>
              ) : userGroupSearchFilter.length === 0 ? (
                <p className="events-error">No Match Found </p>
              ) : (
                userGroupSearchFilter &&
                userGroupSearchFilter.map((item, index) => {
                  if (
                    selectedGroups.length ===
                      userGroupList?.filter((i) => i.status === true).length -
                        1 &&
                    selectedGroups.includes("Select All")
                  )
                    setSelectedGroups(
                      selectedGroups.filter((a) => a !== "Select All")
                    );
                  if (
                    selectedGroups.length ===
                      userGroupList?.filter((i) => i.status === true).length -
                        1 &&
                    !selectedGroups.includes("Select All")
                  )
                    setSelectedGroups([...selectedGroups, "Select All"]);
                  return (
                    <div
                      key={index}
                      className={index === 0 ? "s-all" : "all-check"}
                    >
                      {item.status === true && (
                        <p
                          style={{
                            textDecoration:
                              item.status === false ? "line-through" : "",
                          }}
                        >
                          {item.value}
                        </p>
                      )}
                      {item.status === true && (
                        <input
                          type="checkbox"
                          checked={
                            selectedGroups.filter((a) => a === item.value)
                              .length === 1
                              ? true
                              : false
                          }
                          value={item.value}
                          onChange={groupChange}
                          name="group"
                          className="form-check-input"
                        />
                      )}
                    </div>
                  );
                })
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  setSelectedGroups(editUserData.group);
                  setSearchUserGroup("");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-target="#exampleModalToggle"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  setEditUserData({
                    ...editUserData,
                    group: selectedGroups,
                  });
                  setSearchUserGroup("");
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateUser;
