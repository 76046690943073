import {
  fetchAllGroupRules,
  triggerGroupRules,
  triggerInActiveGroupRuleByRuleId,
} from "../../actions/action-grouprule";
import React, { useState } from "react";
import sucess from "../../assets/images/new-images/sucess.png";
import axios from "axios";
import active from "../../assets/images/new-images/active.png";
import inactive from "../../assets/images/new-images/inactive.png";
import { connect, useDispatch } from "react-redux";
import Modal from "../UI/Modal";
import { fetchAllUserGroups } from "../../actions/action-master";
import failure from "../../assets/images/new-images/error.png";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
const { REACT_APP_BACKEND_API_APP } = process.env;

const ActivateGroupRule = ({
  modalId,
  modalLabel,
  inactiveItem,
  activeStatus,
  ruleId,
  fetchAllGroupRules,
  isReady,
  setIsReady,
  ...props
}) => {
  const [ruleInactive, setRuleInactive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const dispatch = useDispatch();
  const inactiveHandler = (inactiveItem, status) => {
    let isActive = activeStatus === true ? false : true;

    let token = sessionStorage.getItem("accessToken2");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    };
    setLoading(true);
    axios
      .post(
        `${REACT_APP_BACKEND_API_APP}/api/UpdateGroupRuleStatus?groupRuleId=${ruleId}&status=${isActive}`,
        {},
        config
      )
      .then(async (res) => {
        if (res.data.isSuccess === true) {
          setRuleInactive(!ruleInactive);
          setResponse(res.data.statusCode);
        }
        fetchAllGroupRules();
        fetchAllUserGroups();
      });
    setLoading(false);
    isReady === true &&
      (isActive === true
        ? dispatch(triggerGroupRules(ruleId))
        : dispatch(triggerInActiveGroupRuleByRuleId(ruleId)));
  };

  const ruleInactiveHandler = () => {
    setRuleInactive(!ruleInactive);
    setIsReady(false);
  };

  return (
    <Modal modalId={modalId} modalLabel={modalLabel} className={"addUserModal"}>
      <div className="modal-dialog modal-dialog-centered modal-sm ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {response && response === 404
                ? "Failure"
                : activeStatus === true
                ? !ruleInactive
                  ? "Deactivate Group Rule"
                  : "Success"
                : !ruleInactive
                ? "Activate Group Rule"
                : "Success"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setRuleInactive(false);
                setResponse(null);
                setIsReady(false);
              }}
            ></button>
          </div>

          <div className="modal-body text-center">
            {!ruleInactive ? (
              <>
                {activeStatus === true ? (
                  <img
                    src={!ruleInactive ? inactive : sucess}
                    alt="icon"
                    className="m-3"
                  />
                ) : (
                  <img
                    src={!ruleInactive ? active : sucess}
                    alt="icon"
                    className="m-3"
                  />
                )}
                <p className="d-text">
                  {activeStatus === true
                    ? !ruleInactive
                      ? "Are you sure you want to deactivate the Group Rule?"
                      : "Group Rule deactivated successfully"
                    : !ruleInactive
                    ? "Are you sure you want to Activate the Group Rule?"
                    : "Group Rule Activated successfully"}
                </p>
              </>
            ) : loading ? (
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  top: "30%",
                  width: "100%",
                  height: "300PX",
                  justifyContent: "center",
                  alignItems: "start",
                  // background: "#000",
                  zIndex: 99,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <img
                  src={response && (response === 404 ? failure : sucess)}
                  alt="icon"
                  className="m-3"
                />

                <p className="d-text">
                  {response &&
                    (response === 404
                      ? "You cannot delete/deactivate the group rule until you reassign the user(s) associated with this group to another group"
                      : activeStatus === true
                      ? "Group Rule deactivated Successfully"
                      : "Group Rule Activated Successfully")}
                </p>
              </>
            )}
          </div>
          <div className="modal-footer">
            {!ruleInactive && (
              <button
                type="button"
                className="btn btn-secondary ms-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            )}

            {activeStatus === true ? (
              !ruleInactive ? (
                <button
                  type="button"
                  className="btn btn-genmeb ms-3"
                  onClick={() => inactiveHandler(inactiveItem, activeStatus)}
                >
                  Deactivate
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-genmeb ms-3"
                  data-bs-dismiss="modal"
                  onClick={ruleInactiveHandler}
                >
                  Ok
                </button>
              )
            ) : !ruleInactive ? (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                onClick={() => inactiveHandler(inactiveItem, activeStatus)}
              >
                Activate
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-dismiss="modal"
                onClick={ruleInactiveHandler}
              >
                Ok
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchAllGroupRules: () => dispatch(fetchAllGroupRules()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivateGroupRule);
