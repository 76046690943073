import React, { useState } from "react";
import axios from "axios";
import Modal from "../UI/Modal";
import sucess from "../../assets/images/new-images/sucess.png";
import dltIcon from "../../assets/images/new-images/dlt.png";
const { REACT_APP_BACKEND_API_APP } = process.env;

const DeleteUser1 = (props) => {
  const { modalId, modalLabel, deleteId, setDeleteSuccess } = props;
  const [userDelete, setUserDelete] = useState(false);

  const deleteHandler = (deleteId) => {
    let token = sessionStorage.getItem("accessToken2");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    };

    axios
      .post(
        `${REACT_APP_BACKEND_API_APP}/api/DeleteUserByUserId?userId=${deleteId}`,
        {},
        config
      )
      .then(async (res) => {
        setDeleteSuccess(true);
        setUserDelete(!userDelete);
      });
  };

  const userDeleteHandler = () => {
    setUserDelete(!userDelete);
  };

  return (
    <Modal modalId={modalId} modalLabel={modalLabel} className={"dlt-modal"}>
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          {/* <div className="modal-header">
            <h5 className="modal-title">
              {" "}
              {!userDelete ? "Delete User" : "Success"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div> */}
          <div className="modal-body text-center pt-0 ">
          <img
              src={!userDelete ? dltIcon : sucess}
              alt="icon"
              className="m-3"
            />

            {!userDelete && <p className="dlt-hed">Are you sure ?</p>}

            {/* {userDelete && <img src={sucess} alt="icon" className="m-3" />} */}

            <p className="dlt-body">
              {!userDelete
                ? "Do you want to delete this record? Once deleted, this action cannot be undone"
                : "User Deleted Successfully"}
            </p>
            
          </div>
          <div className="modal-footer">
            {!userDelete && (
              <button
                type="button"
                className="btn btn-secondary ms-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            )}

            {!userDelete ? (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                onClick={() => deleteHandler(deleteId)}
              >
                Delete
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-dismiss="modal"
                onClick={userDeleteHandler}
              >
                Ok
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteUser1;
