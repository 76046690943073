import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_BACKEND_API_CLIENTID,
        authority: `${process.env.REACT_APP_BACKEND_API_AUTHORITY}/${process.env.REACT_APP_BACKEND_API_TENANT}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: window.location.origin,
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
 };

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const apiScopes = {
    scopes: [process.env.REACT_APP_BACKEND_API_SCOPES]
};

export const sharepointApiScopes = {
    scopes: ["User.Read", "Sites.Read.All"]
};
