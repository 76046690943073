import React from "react";

export const UserIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0002 4.99967C10.9168 4.99967 11.6668 5.74967 11.6668 6.66634C11.6668 7.58301 10.9168 8.33301 10.0002 8.33301C9.0835 8.33301 8.3335 7.58301 8.3335 6.66634C8.3335 5.74967 9.0835 4.99967 10.0002 4.99967ZM10.0002 12.4997C12.2502 12.4997 14.8335 13.5747 15.0002 14.1663V14.9997H5.00016V14.1747C5.16683 13.5747 7.75016 12.4997 10.0002 12.4997ZM10.0002 3.33301C8.1585 3.33301 6.66683 4.82467 6.66683 6.66634C6.66683 8.50801 8.1585 9.99967 10.0002 9.99967C11.8418 9.99967 13.3335 8.50801 13.3335 6.66634C13.3335 4.82467 11.8418 3.33301 10.0002 3.33301ZM10.0002 10.833C7.77516 10.833 3.3335 11.9497 3.3335 14.1663V16.6663H16.6668V14.1663C16.6668 11.9497 12.2252 10.833 10.0002 10.833Z" fill="#626D8A"/>
  </svg>
);

export const CloseIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 3.205L8.795 2.5L6 5.295L3.205 2.5L2.5 3.205L5.295 6L2.5 8.795L3.205 9.5L6 6.705L8.795 9.5L9.5 8.795L6.705 6L9.5 3.205Z" fill="#98A2B3"/>
  </svg>
);

export const ContentIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.3335 6.66634H6.66683V3.33301H3.3335V6.66634ZM8.3335 16.6663H11.6668V13.333H8.3335V16.6663ZM3.3335 16.6663H6.66683V13.333H3.3335V16.6663ZM3.3335 11.6663H6.66683V8.33301H3.3335V11.6663ZM8.3335 11.6663H11.6668V8.33301H8.3335V11.6663ZM13.3335 3.33301V6.66634H16.6668V3.33301H13.3335ZM8.3335 6.66634H11.6668V3.33301H8.3335V6.66634ZM13.3335 11.6663H16.6668V8.33301H13.3335V11.6663ZM13.3335 16.6663H16.6668V13.333H13.3335V16.6663Z" fill="#626D8A"/>
  </svg>
);

export const GroupIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.33333 10.8333C4.25 10.8333 5 10.0833 5 9.16667C5 8.25 4.25 7.5 3.33333 7.5C2.41667 7.5 1.66667 8.25 1.66667 9.16667C1.66667 10.0833 2.41667 10.8333 3.33333 10.8333ZM4.275 11.75C3.96667 11.7 3.65833 11.6667 3.33333 11.6667C2.50833 11.6667 1.725 11.8417 1.01667 12.15C0.4 12.4167 0 13.0167 0 13.6917V15H3.75V13.6583C3.75 12.9667 3.94167 12.3167 4.275 11.75ZM16.6667 10.8333C17.5833 10.8333 18.3333 10.0833 18.3333 9.16667C18.3333 8.25 17.5833 7.5 16.6667 7.5C15.75 7.5 15 8.25 15 9.16667C15 10.0833 15.75 10.8333 16.6667 10.8333ZM20 13.6917C20 13.0167 19.6 12.4167 18.9833 12.15C18.275 11.8417 17.4917 11.6667 16.6667 11.6667C16.3417 11.6667 16.0333 11.7 15.725 11.75C16.0583 12.3167 16.25 12.9667 16.25 13.6583V15H20V13.6917ZM13.5333 11.375C12.5583 10.9417 11.3583 10.625 10 10.625C8.64167 10.625 7.44167 10.95 6.46667 11.375C5.56667 11.775 5 12.675 5 13.6583V15H15V13.6583C15 12.675 14.4333 11.775 13.5333 11.375ZM6.725 13.3333C6.8 13.1417 6.83333 13.0083 7.48333 12.7583C8.29167 12.4417 9.14167 12.2917 10 12.2917C10.8583 12.2917 11.7083 12.4417 12.5167 12.7583C13.1583 13.0083 13.1917 13.1417 13.275 13.3333H6.725ZM10 6.66667C10.4583 6.66667 10.8333 7.04167 10.8333 7.5C10.8333 7.95833 10.4583 8.33333 10 8.33333C9.54167 8.33333 9.16667 7.95833 9.16667 7.5C9.16667 7.04167 9.54167 6.66667 10 6.66667ZM10 5C8.61667 5 7.5 6.11667 7.5 7.5C7.5 8.88333 8.61667 10 10 10C11.3833 10 12.5 8.88333 12.5 7.5C12.5 6.11667 11.3833 5 10 5Z" fill="#626D8A"/>
  </svg>
);

export const ArrowRight = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0002 3.33301L8.82516 4.50801L13.4752 9.16634H3.3335V10.833H13.4752L8.82516 15.4913L10.0002 16.6663L16.6668 9.99967L10.0002 3.33301Z" fill="#038146"/>
  </svg>
);

export const CheckIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.32911 13.2288L3.85411 9.75378L2.67078 10.9288L7.32911 15.5871L17.3291 5.58711L16.1541 4.41211L7.32911 13.2288Z" fill="#038177"/>
  </svg>
);

export const ErrorIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.33325 10.0002H8.66659V11.3335H7.33325V10.0002ZM7.33325 4.66683H8.66659V8.66683H7.33325V4.66683ZM7.99325 1.3335C4.31325 1.3335 1.33325 4.32016 1.33325 8.00016C1.33325 11.6802 4.31325 14.6668 7.99325 14.6668C11.6799 14.6668 14.6666 11.6802 14.6666 8.00016C14.6666 4.32016 11.6799 1.3335 7.99325 1.3335ZM7.99992 13.3335C5.05325 13.3335 2.66659 10.9468 2.66659 8.00016C2.66659 5.0535 5.05325 2.66683 7.99992 2.66683C10.9466 2.66683 13.3333 5.0535 13.3333 8.00016C13.3333 10.9468 10.9466 13.3335 7.99992 13.3335Z" fill="#F04438"/>
  </svg>
)