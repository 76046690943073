import React, { useState, useEffect } from "react";
import "../../assets/css/group-rule.css";
import arrow from "../../assets/images/new-images/arrow-left.svg";
import Box from "@mui/material/Box";
import AddGroups from "./AddRule/AddGroups";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddApp from "./AddRule/AddApp";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../UI/Modal";
import {
  createUserPreview,
  createNewGroupRule,
  conditionsData,
  triggerGroupRules,
  updateRule,
  resetData,
} from "../../actions/action-grouprule";
import CircularProgress from "@mui/material/CircularProgress";
import sucess from "../../assets/images/new-images/sucess.png";
import failure from "../../assets/images/new-images/error.png";
import RulePreview from "./AddRule/RulePreview";
import { Loader } from "rsuite";

const AddNewGroupRule = ({
  setAddRuleView,
  editmode,
  filterRule,
  setEditMode,
}) => {
  const dispatch = useDispatch();

  const allDepartments = useSelector((state) => state?.master?.departments);
  const departmentsLoading = useSelector(
    (state) => state?.master?.departmentsLoading
  );
  const allLocations = useSelector((state) => state?.master?.locations);
  const locationsLoading = useSelector(
    (state) => state?.master?.locationsLoading
  );
  const conditionsLoading = departmentsLoading && locationsLoading;
  const groupRules = useSelector((state) => state?.groupsRule?.groupRules);
  const allUMLPUsers = useSelector((state) => state?.groupsRule?.allUMLPUsers);
  // const allUMLPUsersLoading = useSelector(
  //   (state) => state?.groupsRule?.allUMLPUsers
  // );
  const allTitles = useSelector((state) => state?.groupsRule?.allTitles);
  // const allTitlesLoading = useSelector((state) => state?.groupsRule?.allTitles);
  const createdRuleId = useSelector(
    (state) => state?.groupsRule?.createdRuleId
  );
  const statusCode = useSelector(
    (state) => state?.groupsRule?.rulesResponseStatusCode
  );
  const loading = useSelector(
    (state) => state?.groupsRule?.rulesResponseLoading
  );
  const message = useSelector((state) => state?.groupsRule?.rulesResponse);
  // const groupsRules = useSelector((state) => state?.groupsRule?.groupRules);
  const modifyRuleList = useSelector(
    (state) => state?.groupsRule?.modifyRuleList
  );
  const modifyRuleListLoading = useSelector(
    (state) => state?.groupsRule?.modifyRuleListLoading
  );
  const conditionGroups = useSelector((state) => state?.master?.userGroups);

  const userPreview = useSelector((state) => state?.groupsRule?.userPreview);

  const initialState = {
    ruleName: "",
    groupIds: [],
    applicationIds: [],
    conditions: [
      {
        conditionId: 0,
        displayOperator: "",
        conditionOn: "",
        conditionOperator: "",
        operator: 1,
        activeCondition: false,
        selectedValues: [],
      },
    ],
  };

  const [rule, setRule] = useState(initialState);
  const [test, setTest] = useState(rule.conditions);
  let data = {
    conditions: test,
  };

  const resetValues = () => {
    setAddRuleView(false);
    dispatch(createUserPreview(data));
    setEditMode(false);
    setRule(initialState);
    setTest([
      {
        conditionId: 0,
        displayOperator: "",
        conditionOn: "",
        conditionOperator: "",
        operator: 1,
        activeCondition: false,
        selectedValues: [],
      },
    ]);

    dispatch(
      resetData({
        ruleName: "",
        groupIds: [],
        applicationIds: [],
        conditions: [
          {
            conditionId: 0,
            displayOperator: "",
            conditionOn: "",
            conditionOperator: "",
            operator: 1,
            activeCondition: false,
            selectedValues: [],
          },
        ],
      })
    );
  };

  useEffect(() => {
    editmode
      ? modifyRuleList && setRule(modifyRuleList)
      : setRule(initialState);
    editmode
      ? modifyRuleList && setTest(modifyRuleList.conditions)
      : setTest(rule.conditions);
  }, [editmode, setRule, modifyRuleList, setTest]);

  //Groups Start
  const [userGroups, setUserGroups] = useState([]);
  const [groupsSearchInput, setGroupsSearchInput] = useState("");

  const [selectGroupChecked, setSelectGroupChecked] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const [isShownGroup, setIsShownGroup] = useState(false);

  // Apps Start
  const [userApps, setUserApps] = useState([]);
  const [appsSearchInput, setAppsSearchInput] = useState("");

  const [selectAppChecked, setSelectAppChecked] = useState(false);
  const [selectedApps, setSelectedApps] = useState([]);

  const [isShownApp, setIsShownApp] = useState(false);

  const handleChange = (event) => {
    const result = event.target.value.replace(/[^0-9a-zA-Z\s]+/gi, "");
    setRule({ ...rule, ruleName: result });
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleAddFields = (index, event) => {
    event.preventDefault();
    const values = [...test];
    // if (event.target.name === "And") {
    //   values[index].operator = 1;
    // } else if (event.target.name === "OR") {
    //   values[index].operator = 2;
    // }

    values.push({
      conditionId: index + 1,
      displayOperator:
        event.target.name === "And"
          ? "1"
          : event.target.name === "OR"
          ? "2"
          : "",
      conditionOn: "",
      conditionOperator: "",
      operator:
        event.target.name === "And" ? 1 : event.target.name === "OR" ? 2 : "1",
      activeCondition:
        event.target.name === "OR"
          ? true
          : event.target.name === "And" &&
            values[index].activeCondition === true
          ? true
          : false,

      selectedValues: [],
    });
    setTest(values);
    setCurrentIndex(index);
  };

  const handleRemoveFields = (index) => {
    const values = [...test];
    values.splice(index, 1);
    setTest(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...test];
    if (event.target.name === "conditionOn") {
      values[index].conditionOn = event.target.value;
      values[index].selectedValues = [];
    } else if (event.target.name === "conditionOperator") {
      values[index].conditionOperator = event.target.value;
    } else {
      values[index].selectedValues = event.target.value;
    }
    setTest(values);
  };

  useEffect(() => {
    !test?.map((i) => i.conditionOn).includes("") &&
      !test?.map((i) => i.conditionOperator).includes("") &&
      dispatch(createUserPreview(data));
    dispatch(conditionsData(data));
  }, [test]);

  //Form Validation

  const defaultState = {
    ruleName: false,
  };
  const [touched, setTouched] = useState(defaultState);

  const handleFocus = (e) => {
    let { name } = e.target;
    setTouched({ ...touched, [name]: true });
  };

  const enteredTitleIsValid = rule.ruleName.trim() !== "";
  const [submitted, setSubmitted] = useState(false);
  const titleInputIsInvalid = !enteredTitleIsValid && touched.ruleName;
  const titleAlreadyExists = !submitted
    ? !editmode
      ? groupRules
          ?.map((g) => g.ruleName.toLowerCase())
          .includes(rule.ruleName.toLowerCase())
      : groupRules
          ?.filter((a) => a.ruleId !== rule.ruleId)
          .map((g) => g.ruleName.toLowerCase())
          .includes(rule.ruleName.toLowerCase())
    : false;

  const forSaveRun = test?.map((i) => i.conditionOn).includes(5)
    ? enteredTitleIsValid &&
      !test?.map((i) => i.conditionOn).includes("") &&
      !test?.map((i) => i.conditionOperator).includes("") &&
      !test?.map((i) => i.selectedValues.length > 0).includes(false) &&
      userApps.filter((i) => i.ischecked === true).map((item) => item.appId)
        ?.length > 0 &&
      userPreview?.length > 0
    : enteredTitleIsValid &&
      !test?.map((i) => i.conditionOn).includes("") &&
      !test?.map((i) => i.conditionOperator).includes("") &&
      !test?.map((i) => i.selectedValues.length > 0).includes(false) &&
      userGroups.filter((i) => i.ischecked === true).map((item) => item.id)
        ?.length > 0 &&
      userPreview?.length > 0;

  const forSave =
    enteredTitleIsValid &&
    !test?.map((i) => i.conditionOn).includes("") &&
    !test?.map((i) => i.conditionOperator).includes("") &&
    !test?.map((i) => i.selectedValues.length > 0).includes(false);

  const formIsValid =
    test?.map((i) => i.conditionOn).includes(5) ||
    userGroups.filter((i) => i.ischecked === true).map((item) => item.id)
      ?.length > 0
      ? forSaveRun
      : forSave;

  const successHandler = () => {
    statusCode && statusCode === 200 && setAddRuleView(false);
    setSubmitted(!submitted);
    statusCode && statusCode === 200 && setEditMode(false);

    let editPayloadId = modifyRuleList.ruleId;
    let payloadId = createdRuleId;
    statusCode &&
      statusCode === 200 &&
      (!editmode
        ? dispatch(triggerGroupRules(payloadId))
        : dispatch(triggerGroupRules(editPayloadId)));

    statusCode && statusCode === 200 && setRule(initialState);
    statusCode && statusCode === 200 && setTouched(defaultState);

    statusCode &&
      statusCode === 200 &&
      setTest([
        {
          conditionId: 0,
          displayOperator: "",
          conditionOn: "",
          conditionOperator: "",
          operator: 1,
          activeCondition: false,
          selectedValues: [],
        },
      ]);

    statusCode && statusCode === 200 && dispatch(createUserPreview(data));

    statusCode &&
      statusCode === 200 &&
      setUserGroups(
        userGroups.map((item) => {
          return {
            ...item,
            ischecked: false,
          };
        })
      );

    statusCode &&
      statusCode === 200 &&
      setUserApps(
        userApps.map((item) => {
          return {
            ...item,
            ischecked: false,
          };
        })
      );

    statusCode &&
      statusCode === 200 &&
      dispatch(
        resetData({
          ruleName: "",
          groupIds: [],
          applicationIds: [],
          conditions: [
            {
              conditionId: 0,
              displayOperator: "",
              conditionOn: "",
              conditionOperator: "",
              operator: 1,
              activeCondition: false,
              selectedValues: [],
            },
          ],
        })
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTouched({
      ruleName: true,
    });

    if (!enteredTitleIsValid) {
      return;
    }

    if (editmode) {
      let data = {
        ruleId: rule.ruleId,
        ruleName: rule.ruleName.trim(),
        createdBy: 0,
        groupIds: test?.map((i) => i.conditionOn).includes(5)
          ? []
          : userGroups
              .filter((i) => i.ischecked === true)
              .map((item) => item.id),
        applicationIds: userApps
          .filter((i) => i.ischecked === true)
          .map((item) => item.appId),
        conditions: test,
        isReadytoRun:
          userGroups.filter((i) => i.ischecked === true).map((item) => item.id)
            ?.length > 0 || test?.map((i) => i.conditionOn).includes(5)
            ? true
            : false,
      };
      dispatch(updateRule(data));
    } else {
      let data = {
        ruleId: 0,
        ruleName: rule.ruleName.trim(),
        createdBy: 0,
        groupIds: test?.map((i) => i.conditionOn).includes(5)
          ? []
          : userGroups
              .filter((i) => i.ischecked === true)
              .map((item) => item.id),
        applicationIds: userApps
          .filter((i) => i.ischecked === true)
          .map((item) => item.appId),
        conditions: test,
        isReadytoRun:
          userGroups.filter((i) => i.ischecked === true).map((item) => item.id)
            ?.length > 0 || test?.map((i) => i.conditionOn).includes(5)
            ? true
            : false,
      };

      dispatch(createNewGroupRule(data));
    }
    setSubmitted(true);
  };
  useEffect(() => {
    !editmode && dispatch(createUserPreview(data));
  }, []);
  return (
    <>
      {editmode && modifyRuleListLoading ? (
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            top: "50%",
            left: "0%",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "start",
            // background: "#000",
            zIndex: 99,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {" "}
          <section className="group-rule">
            <div className="group-rule-header">
              <Button className="backBtn" onClick={resetValues}>
                <img src={arrow} alt="arrow-icon" />
              </Button>
              <p className="app-heding">
                {editmode ? "Update  Rule" : " Add  Rule"}
              </p>
            </div>
            <div className="ruleName">
              <label className="form-label">
                Rule Name<sup>*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                id="ruleName"
                name="ruleName"
                value={rule.ruleName || ""}
                onChange={handleChange}
                onBlur={handleFocus}
                maxLength={30}
              />
              {titleInputIsInvalid && (
                <p className="e-message">Please enter a Rule Name</p>
              )}
              {titleAlreadyExists && (
                <p className="e-message">Rule Name Already Exists</p>
              )}
            </div>

            <div className="gry-container">
              <h5 className="main-title">Following conditions are met</h5>

              <div>
                {conditionsLoading ? (
                  <>
                    <Loader />
                  </>
                ) : (
                  test?.map((inputField, index) => (
                    <div
                      className={`${
                        index === 0
                          ? "primery-row"
                          : inputField.displayOperator === "1"
                          ? inputField.activeCondition === true
                            ? "green-container"
                            : "white-container"
                          : "green-container"
                      }`}
                      style={{
                        marginTop:
                          inputField.displayOperator === "2" ? "15px" : "",
                        paddingTop:
                          inputField.displayOperator === "2" ? "18px" : "",
                      }}
                      key={`${inputField}~${index}`}
                    >
                      {index !== 0 && (
                        <Box className="setOr">
                          {inputField.displayOperator === "2" && (
                            <Button className="grybtn" variant="contained">
                              OR
                            </Button>
                          )}
                          {inputField.displayOperator === "1" && (
                            <Button className="grybtn" variant="contained">
                              And
                            </Button>
                          )}
                        </Box>
                      )}
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth size="small">
                          {index === 0 || inputField.displayOperator === "2" ? (
                            <Select
                              labelId="conditionOn-label"
                              id="conditionOn"
                              value={inputField.conditionOn}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                              name="conditionOn"
                              inputProps={{ "aria-label": "Without label" }}
                              displayEmpty
                            >
                              <MenuItem value="" disabled>
                                <span className="placeholder">
                                  Choose Field
                                </span>
                              </MenuItem>
                              <MenuItem value={1}>Department</MenuItem>
                              <MenuItem value={2}>Location</MenuItem>
                              <MenuItem value={5}>Groups</MenuItem>
                            </Select>
                          ) : (
                            <Select
                              labelId="conditionOn-label"
                              id="conditionOn"
                              value={inputField.conditionOn}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                              name="conditionOn"
                              inputProps={{ "aria-label": "Without label" }}
                              displayEmpty
                            >
                              <MenuItem value="" disabled>
                                <span className="placeholder">
                                  Choose Field
                                </span>
                              </MenuItem>
                              <MenuItem value={1}>Department</MenuItem>
                              <MenuItem value={2}>Location</MenuItem>
                              <MenuItem value={3}>
                                Except the following Users
                              </MenuItem>
                              <MenuItem value={4}>
                                Except the following Title
                              </MenuItem>
                              <MenuItem value={5}>Groups</MenuItem>
                            </Select>
                          )}
                        </FormControl>
                      </Box>

                      {inputField.conditionOn && (
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth size="small">
                            <Select
                              labelId="conditionOperatorLabel"
                              id="conditionOperator"
                              value={inputField.conditionOperator}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                              name="conditionOperator"
                              inputProps={{ "aria-label": "Without label" }}
                              displayEmpty
                            >
                              <MenuItem value="" disabled>
                                <span className="placeholder">
                                  Choose Field
                                </span>
                              </MenuItem>
                              <MenuItem value={3}>Contains</MenuItem>
                              <MenuItem value={4}>Does not Contains</MenuItem>
                              <MenuItem value={1}>Equal</MenuItem>
                              <MenuItem value={2}>Does not Equal</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      )}

                      {inputField.conditionOn &&
                        inputField.conditionOperator && (
                          <Box className="autocomplete-box">
                            <Stack>
                              <Autocomplete
                                ListboxProps={{
                                  style: {
                                    maxHeight: "200px",
                                  },
                                }}
                                multiple
                                id="tags-filled"
                                name="selectedValues"
                                value={inputField.selectedValues}
                                // loading={
                                //   inputField.conditionOn === 1
                                //     ? !departmentsLoading
                                //     : inputField.conditionOn === 2
                                //     ? locationsLoading
                                //     : inputField.conditionOn === 3
                                //     ? allUMLPUsersLoading
                                //     : inputField.conditionOn === 5
                                //     ? allTitlesLoading
                                //     : true
                                // }
                                loading
                                loadingText="Not Found"
                                noOptionsText={"Not Found"}
                                options={
                                  inputField.conditionOn === 1
                                    ? allDepartments
                                        ?.filter((a) => a.value !== null)
                                        ?.map((a) => a.value)
                                    : inputField.conditionOn === 2
                                    ? allLocations
                                        ?.filter((a) => a.cityState !== null)
                                        ?.map((a) => a.cityState)
                                    : inputField.conditionOn === 3
                                    ? allUMLPUsers?.map((a) => a.userName)
                                    : inputField.conditionOn === 4
                                    ? allTitles
                                        ?.filter((a) => a.value !== null)
                                        ?.map((a) => a.value)
                                    : inputField.conditionOn === 5
                                    ? conditionGroups
                                        ?.filter((a) => a.status === true)
                                        ?.map((a) => a.value)
                                    : []
                                }
                                freeSolo
                                // getOptionSelected={
                                //   inputField.conditionOn === 1
                                //     ? (option, value) => option.value === value.value
                                //     : inputField.conditionOn === 2
                                //     ? (option, value) =>
                                //         option.cityState === value.cityState
                                //     : inputField.conditionOn === 3
                                //     ? (option, value) => option.userId === value.userId
                                //     : inputField.conditionOn === 4
                                //     ? (option, value) => option.value === value.value
                                //     : []
                                // }
                                // getOptionLabel={
                                //   inputField.conditionOn === 1
                                //     ? (option) => option.value
                                //     : inputField.conditionOn === 2
                                //     ? (option) => option.cityState
                                //     : inputField.conditionOn === 3
                                //     ? (option) => option.userName
                                //     : inputField.conditionOn === 4
                                //     ? (option) => option.value
                                //     : []
                                // }
                                onChange={(event, newValue) => {
                                  const values = [...test];
                                  values[index].selectedValues =
                                    inputField.conditionOn === 1
                                      ? newValue
                                      : inputField.conditionOn === 2
                                      ? newValue
                                      : inputField.conditionOn === 3
                                      ? newValue
                                      : inputField.conditionOn === 4
                                      ? newValue
                                      : inputField.conditionOn === 5
                                      ? newValue
                                      : [];

                                  setTest(values);
                                  // if (index === 0 && newValue.length === 0) {
                                  //   // Call your function here
                                  //   dispatch(createUserPreview(data))
                                  // }
                                }}
                                // renderOption={(props, option) => (
                                //   <Box
                                //     component="li"
                                //     sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                //     {...props}
                                //   >
                                //     {inputField.conditionOn === 1
                                //       ? option.value
                                //       : inputField.conditionOn === 2
                                //       ? option.cityState
                                //       : inputField.conditionOn === 3
                                //       ? option.userName
                                //       : inputField.conditionOn === 4
                                //       ? option.value
                                //       : ""}

                                //     <br />
                                //     {inputField.conditionOn === 3 && option.email}
                                //   </Box>
                                // )}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      {...getTagProps({ index })}
                                      size="small"
                                      deleteIcon={<CloseIcon />}
                                      key={option}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Please Enter Text"
                                    variant="outlined"
                                    // inputProps={{
                                    //   ...params.inputProps,
                                    //   onKeyDown: (e) => {
                                    //     if (e.key === "Enter") {
                                    //       e.stopPropagation();
                                    //     }
                                    //   },
                                    // }}
                                  />
                                )}
                              />
                            </Stack>
                          </Box>
                        )}

                      <div className="btn-set">
                        {index === 0
                          ? inputField.conditionOn &&
                            inputField.conditionOperator &&
                            inputField.selectedValues?.length > 0 &&
                            test?.length - 1 === index && (
                              <>
                                <Button
                                  className="grybtn"
                                  variant="contained"
                                  onClick={(event) =>
                                    handleAddFields(index, event)
                                  }
                                  name="And"
                                >
                                  And
                                </Button>
                                <Button
                                  className="grybtn"
                                  variant="contained"
                                  onClick={(event) =>
                                    handleAddFields(index, event)
                                  }
                                  name="OR"
                                >
                                  OR
                                </Button>{" "}
                              </>
                            )
                          : inputField.conditionOn &&
                            inputField.conditionOperator &&
                            inputField.selectedValues?.length > 0 &&
                            test?.length - 1 === index && (
                              <>
                                <Button
                                  className="grybtn"
                                  variant="contained"
                                  onClick={(event) =>
                                    handleAddFields(index, event)
                                  }
                                  name="And"
                                >
                                  And
                                </Button>
                                <Button
                                  className="grybtn"
                                  variant="contained"
                                  onClick={(event) =>
                                    handleAddFields(index, event)
                                  }
                                  name="OR"
                                >
                                  OR
                                </Button>{" "}
                              </>
                            )}
                        {index !== 0 && (
                          <IconButton
                            className="close-btn"
                            onClick={() => handleRemoveFields(index)}
                            disabled={index === 0}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="perform-action">
              <h5 className="main-title">Perform the following actions</h5>
              {!test?.map((i) => i.conditionOn).includes(5) && (
                <AddGroups
                  isShownGroup={isShownGroup}
                  setIsShownGroup={setIsShownGroup}
                  isShownApp={isShownApp}
                  setIsShownApp={setIsShownApp}
                  userGroups={userGroups}
                  setUserGroups={setUserGroups}
                  groupsSearchInput={groupsSearchInput}
                  setGroupsSearchInput={setGroupsSearchInput}
                  selectGroupChecked={selectGroupChecked}
                  setSelectGroupChecked={setSelectGroupChecked}
                  selectedGroups={selectedGroups}
                  setSelectedGroups={setSelectedGroups}
                  test={test}
                  userApps={userApps}
                  editmode={editmode}
                  filterRule={rule}
                />
              )}
              <AddApp
                isShownApp={isShownApp}
                setIsShownApp={setIsShownApp}
                isShownGroup={isShownGroup}
                setIsShownGroup={setIsShownGroup}
                userApps={userApps}
                setUserApps={setUserApps}
                appsSearchInput={appsSearchInput}
                setAppsSearchInput={setAppsSearchInput}
                selectAppChecked={selectAppChecked}
                setSelectAppChecked={setSelectAppChecked}
                selectedApps={selectedApps}
                setSelectedApps={setSelectedApps}
                test={test}
                userGroups={userGroups}
                editmode={editmode}
                filterRule={rule}
              />
            </div>
            {/* ::::::::::::Add Table Start here::::::::::: */}
            <RulePreview
              userGroups={userGroups}
              userApps={userApps}
              test={test}
            />
            <div className="button-set-box">
              <button className="cancel-btn btn" onClick={resetValues}>
                Cancel
              </button>
              <button
                className="save-btn btn"
                type="button"
                onClick={handleSubmit}
                disabled={!formIsValid}
                data-bs-toggle="modal"
                data-bs-target="#errormodal"
              >
                {userGroups
                  .filter((i) => i.ischecked === true)
                  .map((item) => item.id)?.length > 0 ||
                test?.map((i) => i.conditionOn).includes(5)
                  ? editmode
                    ? "Update & Run"
                    : "Save & Run"
                  : editmode
                  ? "Update"
                  : "Save"}
              </button>
            </div>
          </section>
          <section>
            <Modal
              modalId="errormodal"
              modalLabel="exampleModalToggleLabel"
              className="modal fade"
            >
              <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content edit-success">
                  <div className="modal-body text-center pt-0 type-loader">
                    {submitted === true && loading ? (
                      <Box
                        sx={{
                          display: "flex",
                          position: "absolute",
                          top: "30%",
                          width: "100%",
                          height: "300PX",
                          justifyContent: "center",
                          alignItems: "start",
                          // background: "#000",
                          zIndex: 99,
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <img
                          src={
                            statusCode &&
                            (statusCode === 400 ? failure : sucess)
                          }
                          alt="icon"
                          className="m-3"
                        />
                        <p
                          className={`${
                            statusCode &&
                            (statusCode === 400 ? "red-text" : "green-text")
                          }`}
                        >
                          {statusCode &&
                            (statusCode === 400 ? "Failure" : "Success")}
                        </p>
                        <p>
                          {statusCode &&
                            (statusCode === 400
                              ? message
                              : editmode
                              ? "Group Rule Updated Successfully"
                              : "New Group Rule Created Successfully")}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-genmeb ms-3"
                      data-bs-dismiss="modal"
                      onClick={successHandler}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </section>
        </>
      )}
    </>
  );
};

export default AddNewGroupRule;
