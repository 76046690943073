// QUICK LINK
export const SET_QUICK_LINKS = "SET_QUICK_LINKS";

// USER_DATA
export const SET_USERS_DATA = "SET_USERS_DATA";
export const SET_APPS_DATA = "SET_APPS_DATA";
export const SET_LOGIN_DATA = "SET_LOGIN_DATA";

//New Alerts
const CREATE_NEW_ALERT_START = "CREATE_NEW_ALERT_START";
const CREATE_NEW_ALERT_SUCCESS = "CREATE_NEW_ALERT_SUCCESS";
const CREATE_NEW_ALERT_FAILED = "CREATE_NEW_ALERT_FAILED";
const FETCH_ALERTS_BY_LOGIN_ID_START = "FETCH_ALERTS_BY_LOGIN_ID_START";
const FETCH_ALERTS_BY_LOGIN_ID_SUCCESS = "FETCH_ALERTS_BY_LOGIN_ID_SUCCESS";
const FETCH_ALERTS_BY_LOGIN_ID_FAILED = "FETCH_ALERTS_BY_LOGIN_ID_FAILED";
const DELETE_ALERT_START = "DELETE_ALERT_START";
const DELETE_ALERT_SUCCESS = "DELETE_ALERT_SUCCESS";
const DELETE_ALERT_FAILED = "DELETE_ALERT_FAILED";
const OPEN_DELETE_MODAL = "OPEN_DELETE_MODAL";
const UPDATE_ALERT_START = "UPDATE_ALERT_START";
const UPDATE_ALERT_SUCCESS = "UPDATE_ALERT_SUCCESS";
const UPDATE_ALERT_FAILED = "UPDATE_ALERT_FAILED";
const SET_ALERTS_RECORDS_LENGTH = "SET_ALERTS_RECORDS_LENGTH";
const FETCH_PAGINATION_DATA_SUCCESS = "FETCH_PAGINATION_DATA_SUCCESS";
const FETCH_SEARCH_INPUT = "FETCH_SEARCH_INPUT";
const DISMISS_ALERT_START = "DISMISS_ALERT_START";
const DISMISS_ALERT_SUCCESS = "DISMISS_ALERT_SUCCESS";
const DISMISS_ALERT_FAILED = "DISMISS_ALERT_FAILED";
const MOVE_APP_START = "MOVE_APP_START";
const MOVE_APP_SUCCESS = "MOVE_APP_SUCCESS";
const MOVE_APP_FAILED = "MOVE_APP_FAILED";
const FETCH_NOTIFICATION_FITLER_DATES = "FETCH_NOTIFICATION_FITLER_DATES";
const FETCH_DASH_ALERTS_BY_LOGIN_ID_START =
  "FETCH_DASH_ALERTS_BY_LOGIN_ID_START";
const FETCH_DASH_ALERTS_BY_LOGIN_ID_SUCCESS =
  "FETCH_DASH_ALERTS_BY_LOGIN_ID_SUCCESS";
const FETCH_DASH_ALERTS_BY_LOGIN_ID_FAILED =
  "FETCH_DASH_ALERTS_BY_LOGIN_ID_FAILED";
const FETCH_DASH_NOTIFICATION_FITLER_DATES =
  "FETCH_DASH_NOTIFICATION_FITLER_DATES";
const FETCH_DASH_POPUP_ALERTS_BY_LOGIN_ID_START =
  "FETCH_DASH_POPUP_ALERTS_BY_LOGIN_ID_START";
const FETCH_DASH_POPUP_ALERTS_BY_LOGIN_ID_SUCCESS =
  "FETCH_DASH_POPUP_ALERTS_BY_LOGIN_ID_SUCCESS";
const FETCH_DASH_POPUP_ALERTS_BY_LOGIN_ID_FAILED =
  "FETCH_DASH_POPUP_ALERTS_BY_LOGIN_ID_FAILED";

export const SET_APP_EXISTS = "SET_APP_EXISTS";

export const notificationActions = {
  CREATE_NEW_ALERT_START,
  CREATE_NEW_ALERT_SUCCESS,
  CREATE_NEW_ALERT_FAILED,
  FETCH_ALERTS_BY_LOGIN_ID_START,
  FETCH_ALERTS_BY_LOGIN_ID_SUCCESS,
  FETCH_ALERTS_BY_LOGIN_ID_FAILED,
  DELETE_ALERT_START,
  DELETE_ALERT_SUCCESS,
  DELETE_ALERT_FAILED,
  OPEN_DELETE_MODAL,
  UPDATE_ALERT_START,
  UPDATE_ALERT_SUCCESS,
  UPDATE_ALERT_FAILED,
  FETCH_PAGINATION_DATA_SUCCESS,
  SET_ALERTS_RECORDS_LENGTH,
  FETCH_SEARCH_INPUT,
  DISMISS_ALERT_START,
  DISMISS_ALERT_SUCCESS,
  DISMISS_ALERT_FAILED,
  MOVE_APP_START,
  MOVE_APP_SUCCESS,
  MOVE_APP_FAILED,
  FETCH_NOTIFICATION_FITLER_DATES,
  FETCH_DASH_ALERTS_BY_LOGIN_ID_START,
  FETCH_DASH_ALERTS_BY_LOGIN_ID_SUCCESS,
  FETCH_DASH_ALERTS_BY_LOGIN_ID_FAILED,
  FETCH_DASH_NOTIFICATION_FITLER_DATES,
  FETCH_DASH_POPUP_ALERTS_BY_LOGIN_ID_START,
  FETCH_DASH_POPUP_ALERTS_BY_LOGIN_ID_SUCCESS,
  FETCH_DASH_POPUP_ALERTS_BY_LOGIN_ID_FAILED,
};

//Master

const FETCH_ALL_DEPARTMENTS_START = "FETCH_ALL_DEPARTMENTS_START";
const FETCH_ALL_DEPARTMENTS_SUCCESS = "FETCH_ALL_DEPARTMENTS_SUCCESS";
const FETCH_ALL_DEPARTMENTS_FAILED = "FETCH_ALL_DEPARTMENTS_FAILED";

const FETCH_ALL_LOCATIONS_START = "FETCH_ALL_LOCATIONS_START";
const FETCH_ALL_LOCATIONS_SUCCESS = "FETCH_ALL_LOCATIONS_SUCCESS";
const FETCH_ALL_LOCATIONS_FAILED = "FETCH_ALL_LOCATIONS_FAILED";

const FETCH_ALL_USER_GROUPS_START = "FETCH_ALL_USER_GROUPS_START";
const FETCH_ALL_USER_GROUPS_SUCCESS = "FETCH_ALL_USER_GROUPS_SUCCESS";
const FETCH_ALL_USER_GROUPS_FAILED = "FETCH_ALL_USER_GROUPS_FAILED";

const CREATE_USER_GROUPS_START = "CREATE_USER_GROUPS_START";
const CREATE_USER_GROUPS_SUCCESS = "CREATE_USER_GROUPS_SUCCESS";
const CREATE_USER_GROUPS_FAILED = "CREATE_USER_GROUPS_FAILED";
const UPDATE_USER_GROUPS_START = "UPDATE_USER_GROUPS_START";
const UPDATE_USER_GROUPS_SUCCESS = "UPDATE_USER_GROUPS_SUCCESS";
const UPDATE_USER_GROUPS_FAILED = "UPDATE_USER_GROUPS_FAILED";
const DELETE_USER_GROUPS_START = "DELETE_USER_GROUPS_START";
const DELETE_USER_GROUPS_SUCCESS = "DELETE_USER_GROUPS_SUCCESS";
const DELETE_USER_GROUPS_FAILED = "DELETE_USER_GROUPS_FAILED";

const FETCH_ALL_NOTIFICATION_TYPE_START = "FETCH_ALL_NOTIFICATION_TYPE_START";
const FETCH_ALL_NOTIFICATION_TYPE_SUCCESS =
  "FETCH_ALL_NOTIFICATION_TYPE_SUCCESS";
const FETCH_ALL_NOTIFICATION_TYPE_FAILED = "FETCH_ALL_NOTIFICATION_TYPE_FAILED";

const CREATE_NOTIFICATION_TYPE_START = "CREATE_NOTIFICATION_TYPE_START";
const CREATE_NOTIFICATION_TYPE_SUCCESS = "CREATE_NOTIFICATION_TYPE_SUCCESS";
const CREATE_NOTIFICATION_TYPE_FAILED = "CREATE_NOTIFICATION_TYPE_FAILED";
const UPDATE_NOTIFICATION_TYPE_START = "UPDATE_NOTIFICATION_TYPE_START";
const UPDATE_NOTIFICATION_TYPE_SUCCESS = "UPDATE_NOTIFICATION_TYPE_SUCCESS";
const UPDATE_NOTIFICATION_TYPE_FAILED = "UPDATE_NOTIFICATION_TYPE_FAILED";
const DELETE_NOTIFICATION_TYPE_START = "DELETE_NOTIFICATION_TYPE_START";
const DELETE_NOTIFICATION_TYPE_SUCCESS = "DELETE_NOTIFICATION_TYPE_SUCCESS";
const DELETE_NOTIFICATION_TYPE_FAILED = "DELETE_NOTIFICATION_TYPE_FAILED";

const UPDATE_ALERT_READ_STATUS_START = "UPDATE_ALERT_READ_STATUS_START";
const UPDATE_ALERT_READ_STATUS_SUCCESS = "UPDATE_ALERT_READ_STATUS_SUCCESS";
const UPDATE_ALERT_READ_STATUS_FAILED = "UPDATE_ALERT_READ_STATUS_FAILED";
const FETCH_ALL_USER_ROLES_START = "FETCH_ALL_USER_ROLES_START";
const FETCH_ALL_USER_ROLES_SUCCESS = "FETCH_ALL_USER_ROLES_SUCCESS";
const FETCH_ALL_USER_ROLES_FAILED = "FETCH_ALL_USER_ROLES_FAILED";
const UPDATE_EVENT_READ_STATUS_START = "UPDATE_EVENT_READ_STATUS_START";
const UPDATE_EVENT_READ_STATUS_SUCCESS = "UPDATE_EVENT_READ_STATUS_SUCCESS";
const UPDATE_EVENT_READ_STATUS_FAILED = "UPDATE_EVENT_READ_STATUS_FAILED";

const FETCH_IMAGES_START = "FETCH_IMAGES_START";
const FETCH_IMAGES_SUCCESS = "FETCH_IMAGES_SUCCESS";
const FETCH_IMAGES_FAILED = "FETCH_IMAGES_FAILED";

export const masterActions = {
  FETCH_ALL_LOCATIONS_START,
  FETCH_ALL_LOCATIONS_SUCCESS,
  FETCH_ALL_LOCATIONS_FAILED,
  FETCH_ALL_DEPARTMENTS_START,
  FETCH_ALL_DEPARTMENTS_SUCCESS,
  FETCH_ALL_DEPARTMENTS_FAILED,
  FETCH_ALL_USER_GROUPS_START,
  FETCH_ALL_USER_GROUPS_SUCCESS,
  FETCH_ALL_USER_GROUPS_FAILED,
  CREATE_USER_GROUPS_START,
  CREATE_USER_GROUPS_SUCCESS,
  CREATE_USER_GROUPS_FAILED,
  UPDATE_USER_GROUPS_START,
  UPDATE_USER_GROUPS_SUCCESS,
  UPDATE_USER_GROUPS_FAILED,
  DELETE_USER_GROUPS_START,
  DELETE_USER_GROUPS_SUCCESS,
  DELETE_USER_GROUPS_FAILED,
  FETCH_ALL_NOTIFICATION_TYPE_START,
  FETCH_ALL_NOTIFICATION_TYPE_SUCCESS,
  FETCH_ALL_NOTIFICATION_TYPE_FAILED,
  CREATE_NOTIFICATION_TYPE_START,
  CREATE_NOTIFICATION_TYPE_SUCCESS,
  CREATE_NOTIFICATION_TYPE_FAILED,
  UPDATE_NOTIFICATION_TYPE_START,
  UPDATE_NOTIFICATION_TYPE_SUCCESS,
  UPDATE_NOTIFICATION_TYPE_FAILED,
  DELETE_NOTIFICATION_TYPE_START,
  DELETE_NOTIFICATION_TYPE_SUCCESS,
  DELETE_NOTIFICATION_TYPE_FAILED,
  UPDATE_ALERT_READ_STATUS_START,
  UPDATE_ALERT_READ_STATUS_SUCCESS,
  UPDATE_ALERT_READ_STATUS_FAILED,
  FETCH_ALL_USER_ROLES_START,
  FETCH_ALL_USER_ROLES_SUCCESS,
  FETCH_ALL_USER_ROLES_FAILED,
  SET_USERS_DATA,
  UPDATE_EVENT_READ_STATUS_START,
  UPDATE_EVENT_READ_STATUS_SUCCESS,
  UPDATE_EVENT_READ_STATUS_FAILED,
  FETCH_IMAGES_START,
  FETCH_IMAGES_SUCCESS,
  FETCH_IMAGES_FAILED,
};

//Users
const FETCH_DASH_SUMMARY_START = "FETCH_DASH_SUMMARY_START";
const FETCH_DASH_SUMMARY_SUCCESS = "FETCH_DASH_SUMMARY_SUCCESS";
const FETCH_DASH_SUMMARY_FAILED = "FETCH_DASH_SUMMARY_FAILED";

const FETCH_ALL_USER_LIST_BY_ROLE_START = "FETCH_ALL_USER_LIST_BY_ROLE_START";
const FETCH_ALL_USER_LIST_BY_ROLE_SUCCESS =
  "FETCH_ALL_USER_LIST_BY_ROLE_SUCCESS";
const FETCH_ALL_USER_LIST_BY_ROLE_FAILED = "FETCH_ALL_USER_LIST_BY_ROLE_FAILED";
const FETCH_USER_NOTIFICATION_FITLER_DATES =
  "FETCH_USER_NOTIFICATION_FITLER_DATES";
const FETCH_USERS_POPUP_PROFILE_START = "FETCH_USERS_POPUP_PROFILE_START";
const FETCH_USERS_POPUP_PROFILE_SUCCESS = "FETCH_USERS_POPUP_PROFILE_SUCCESS";
const FETCH_USERS_POPUP_PROFILE_FAILED = "FETCH_USERS_POPUP_PROFILE_FAILED";
const FETCH_USERS_PHOTO_START = "FETCH_USERS_PHOTO_START";
const FETCH_USERS_PHOTO_SUCCESS = "FETCH_USERS_PHOTO_SUCCESS";
const FETCH_USERS_PHOTO_FAILED = "FETCH_USERS_PHOTO_FAILED";

const FETCH_HEADER_USERS_POPUP_PROFILE_START =
  "FETCH_HEADER_USERS_POPUP_PROFILE_START";
const FETCH_HEADER_USERS_POPUP_PROFILE_SUCCESS =
  "FETCH_HEADER_USERS_POPUP_PROFILE_SUCCESS";
const FETCH_HEADER_USERS_POPUP_PROFILE_FAILED =
  "FETCH_HEADER_USERS_POPUP_PROFILE_FAILED";
const FETCH_HEADER_USERS_PHOTO_START = "FETCH_HEADER_USERS_PHOTO_START";
const FETCH_HEADER_USERS_PHOTO_SUCCESS = "FETCH_HEADER_USERS_PHOTO_SUCCESS";
const FETCH_HEADER_USERS_PHOTO_FAILED = "FETCH_HEADER_USERS_PHOTO_FAILED";
const FETCH_DASH_APPS_START = "FETCH_DASH_APPS_START";
const FETCH_DASH_APPS_SUCCESS = "FETCH_DASH_APPS_SUCCESS";
const FETCH_DASH_APPS_FAILED = "FETCH_DASH_APPS_FAILED";

const FETCH_ALL_USERS_START = "FETCH_ALL_USERS_START";
const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
const FETCH_ALL_USERS_FAILED = "FETCH_ALL_USERS_FAILED";

const FETCH_ALL_APPS_BY_USERID_START = "FETCH_ALL_APPS_BY_USERID_START";
const FETCH_ALL_APPS_BY_USERID_SUCCESS = "FETCH_ALL_APPS_BY_USERID_SUCCESS";
const FETCH_ALL_APPS_BY_USERID_FAILED = "FETCH_ALL_APPS_BY_USERID_FAILED";

const FETCH_ALL_USER_APPS_START = "FETCH_ALL_USER_APPS_START";
const FETCH_ALL_USER_APPS_SUCCESS = "FETCH_ALL_USER_APPS_SUCCESS";
const FETCH_ALL_USER_APPS_FAILED = "FETCH_ALL_USER_APPS_FAILED";

const TRIGGER_GROUP_RULES_BYUSERID_START = "TRIGGER_GROUP_RULES_BYUSERID_START";
const TRIGGER_GROUP_RULES_BYUSERID_SUCCESS =
  "TRIGGER_GROUP_RULES_BYUSERID_SUCCESS";
const TRIGGER_GROUP_RULES_BYUSERID_FAILED =
  "TRIGGER_GROUP_RULES_BYUSERID_FAILED";

const FETCH_EMAIL_USERS_START = "FETCH_EMAIL_USERS_START";
const FETCH_EMAIL_USERS_SUCCESS = "FETCH_EMAIL_USERS_SUCCESS";
const FETCH_EMAIL_USERS_FAILED = "FETCH_EMAIL_USERS_FAILED";

const SEND_BULK_EMAIL_START = "SEND_BULK_EMAIL_START";
const SEND_BULK_EMAIL_SUCCESS = "SEND_BULK_EMAIL_SUCCESS";
const SEND_BULK_EMAIL_FAILED = "SEND_BULK_EMAIL_FAILED";

const ADD_BULK_USERS_START = "ADD_BULK_USERS_START";
const ADD_BULK_USERS_SUCCESS = "ADD_BULK_USERS_SUCCESS";
const ADD_BULK_USERS_FAILED = "ADD_BULK_USERS_FAILED";

const VALIDATE_BULK_USERS_START = "VALIDATE_BULK_USERS_START";
const VALIDATE_BULK_USERS_SUCCESS = "VALIDATE_BULK_USERS_SUCCESS";
const VALIDATE_BULK_USERS_FAILED = "VALIDATE_BULK_USERS_FAILED";
const BULK_USER_UPLOAD_RESET = "BULK_USER_UPLOAD_RESET";


export const usersActions = {
  FETCH_DASH_SUMMARY_START,
  FETCH_DASH_SUMMARY_SUCCESS,
  FETCH_DASH_SUMMARY_FAILED,
  FETCH_DASH_APPS_START,
  FETCH_DASH_APPS_SUCCESS,
  FETCH_DASH_APPS_FAILED,
  FETCH_ALL_USER_LIST_BY_ROLE_START,
  FETCH_ALL_USER_LIST_BY_ROLE_SUCCESS,
  FETCH_ALL_USER_LIST_BY_ROLE_FAILED,
  FETCH_USER_NOTIFICATION_FITLER_DATES,
  FETCH_USERS_POPUP_PROFILE_START,
  FETCH_USERS_POPUP_PROFILE_SUCCESS,
  FETCH_USERS_POPUP_PROFILE_FAILED,
  FETCH_USERS_PHOTO_START,
  FETCH_USERS_PHOTO_SUCCESS,
  FETCH_USERS_PHOTO_FAILED,

  FETCH_HEADER_USERS_POPUP_PROFILE_START,
  FETCH_HEADER_USERS_POPUP_PROFILE_SUCCESS,
  FETCH_HEADER_USERS_POPUP_PROFILE_FAILED,
  FETCH_HEADER_USERS_PHOTO_START,
  FETCH_HEADER_USERS_PHOTO_SUCCESS,
  FETCH_HEADER_USERS_PHOTO_FAILED,
  FETCH_ALL_USERS_START,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAILED,
  FETCH_ALL_APPS_BY_USERID_START,
  FETCH_ALL_APPS_BY_USERID_SUCCESS,
  FETCH_ALL_APPS_BY_USERID_FAILED,

  FETCH_ALL_USER_APPS_START,
  FETCH_ALL_USER_APPS_SUCCESS,
  FETCH_ALL_USER_APPS_FAILED,

  TRIGGER_GROUP_RULES_BYUSERID_START,
  TRIGGER_GROUP_RULES_BYUSERID_SUCCESS,
  TRIGGER_GROUP_RULES_BYUSERID_FAILED,

  FETCH_EMAIL_USERS_START,
  FETCH_EMAIL_USERS_SUCCESS,
  FETCH_EMAIL_USERS_FAILED,

  SEND_BULK_EMAIL_START,
  SEND_BULK_EMAIL_SUCCESS,
  SEND_BULK_EMAIL_FAILED,

  ADD_BULK_USERS_START,
  ADD_BULK_USERS_SUCCESS,
  ADD_BULK_USERS_FAILED,
  VALIDATE_BULK_USERS_START,
  VALIDATE_BULK_USERS_SUCCESS,
  VALIDATE_BULK_USERS_FAILED,
  BULK_USER_UPLOAD_RESET,

};

// Event

const FETCH_EVENT_MODAL_DATA = "FETCH_EVENT_MODAL_DATA";
const FETCH_ALL_EVENT_START = "FETCH_ALL_EVENT_START";
const FETCH_ALL_EVENT_SUCCESS = "FETCH_ALL_EVENT_SUCCESS";
const FETCH_ALL_EVENT_FAILED = "FETCH_ALL_EVENT_FAILED";
const CREATE_NEW_EVENT_START = "CREATE_NEW_EVENT_START";
const CREATE_NEW_EVENT_SUCCESS = "CREATE_NEW_EVENT_SUCCESS";
const CREATE_NEW_EVENT_FAILED = "CREATE_NEW_ALERT_FAILED";
const UPDATE_EVENT_START = "UPDATE_EVENT_START";
const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
const UPDATE_EVENT_FAILED = "UPDATE_EVENT_FAILED";
const DELETE_EVENT_START = "DELETE_EVENT_START";
const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
const DELETE_EVENT_FAILED = "DELETE_EVENT_FAILED";
const SET_EVENT_RECORDS_LENGTH = "SET_EVENT_RECORDS_LENGTH";
const FETCH_EVENT_FITLER_DATES = "FETCH_EVENT_FITLER_DATES";
// const FETCH_PAGINATION_DATA_SUCCESS = 'FETCH_PAGINATION_DATA_SUCCESS';
const FETCH_DASH_EVENTS_BY_LOGIN_ID_START =
  "FETCH_DASH_EVENTS_BY_LOGIN_ID_START";
const FETCH_DASH_EVENTS_BY_LOGIN_ID_SUCCESS =
  "FETCH_DASH_EVENTS_BY_LOGIN_ID_SUCCESS";
const FETCH_DASH_EVENTS_BY_LOGIN_ID_FAILED =
  "FETCH_DASH_EVENTS_BY_LOGIN_ID_FAILED";

const FETCH_DASH_POPUP_EVENTS_BY_LOGIN_ID_START =
  "FETCH_DASH_POPUP_EVENTS_BY_LOGIN_ID_START";
const FETCH_DASH_POPUP_EVENTS_BY_LOGIN_ID_SUCCESS =
  "FETCH_DASH_POPUP_EVENTS_BY_LOGIN_ID_SUCCESS";
const FETCH_DASH_POPUP_EVENTS_BY_LOGIN_ID_FAILED =
  "FETCH_DASH_POPUP_EVENTS_BY_LOGIN_ID_FAILED";

const FETCH_DASH_EVENTS_FITLER_DATES = "FETCH_DASH_EVENTS_FITLER_DATES";

const FETCH_DASH_POPUP_EVENTS_FITLER_DATES =
  "FETCH_DASH_POPUP_EVENTS_FITLER_DATES";

const FETCH_DATES_EVENTS_BY_LOGIN_ID_START =
  "FETCH_DATES_EVENTS_BY_LOGIN_ID_START";
const FETCH_DATES_EVENTS_BY_LOGIN_ID_SUCCESS =
  "FETCH_DATES_EVENTS_BY_LOGIN_ID_SUCCESS";
const FETCH_DATES_EVENTS_BY_LOGIN_ID_FAILED =
  "FETCH_DATES_EVENTS_BY_LOGIN_ID_FAILED";

const FETCH_DATES_POPUP_EVENTS_BY_LOGIN_ID_START =
  "FETCH_DATES_POPUP_EVENTS_BY_LOGIN_ID_START";
const FETCH_DATES_POPUP_EVENTS_BY_LOGIN_ID_SUCCESS =
  "FETCH_DATES_POPUP_EVENTS_BY_LOGIN_ID_SUCCESS";
const FETCH_DATES_POPUP_EVENTS_BY_LOGIN_ID_FAILED =
  "FETCH_DATES_POPUP_EVENTS_BY_LOGIN_ID_FAILED";

export const eventActions = {
  FETCH_EVENT_MODAL_DATA,
  FETCH_ALL_EVENT_START,
  FETCH_ALL_EVENT_SUCCESS,
  FETCH_ALL_EVENT_FAILED,
  CREATE_NEW_EVENT_START,
  CREATE_NEW_EVENT_SUCCESS,
  CREATE_NEW_EVENT_FAILED,
  UPDATE_EVENT_START,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILED,
  DELETE_EVENT_START,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILED,
  OPEN_DELETE_MODAL,
  SET_EVENT_RECORDS_LENGTH,
  FETCH_PAGINATION_DATA_SUCCESS,
  FETCH_SEARCH_INPUT,
  FETCH_EVENT_FITLER_DATES,
  FETCH_DASH_EVENTS_BY_LOGIN_ID_START,
  FETCH_DASH_EVENTS_BY_LOGIN_ID_SUCCESS,
  FETCH_DASH_EVENTS_BY_LOGIN_ID_FAILED,
  FETCH_DASH_POPUP_EVENTS_BY_LOGIN_ID_START,
  FETCH_DASH_POPUP_EVENTS_BY_LOGIN_ID_SUCCESS,
  FETCH_DASH_POPUP_EVENTS_BY_LOGIN_ID_FAILED,
  FETCH_DASH_EVENTS_FITLER_DATES,
  FETCH_DASH_POPUP_EVENTS_FITLER_DATES,
  FETCH_DATES_EVENTS_BY_LOGIN_ID_START,
  FETCH_DATES_EVENTS_BY_LOGIN_ID_SUCCESS,
  FETCH_DATES_EVENTS_BY_LOGIN_ID_FAILED,
  FETCH_DATES_POPUP_EVENTS_BY_LOGIN_ID_START,
  FETCH_DATES_POPUP_EVENTS_BY_LOGIN_ID_SUCCESS,
  FETCH_DATES_POPUP_EVENTS_BY_LOGIN_ID_FAILED,
};

// Rule

const FETCH_ALL_GROUP_RULES_START = "FETCH_ALL_GROUP_RULES_START";
const FETCH_ALL_GROUP_RULES_SUCCESS = "FETCH_ALL_GROUP_RULES_SUCCESS";
const FETCH_ALL_GROUP_RULES_FAILED = "FETCH_ALL_GROUP_RULES_FAILED";

const CREATE_GROUP_RULES_START = "CREATE_GROUP_RULES_START";
const CREATE_GROUP_RULES_SUCCESS = "CREATE_GROUP_RULES_SUCCESS";
const CREATE_GROUP_RULES_FAILED = "CREATE_GROUP_RULES_FAILED";

const UPDATE_GROUP_RULES_START = "UPDATE_GROUP_RULES_START";
const UPDATE_GROUP_RULES_SUCCESS = "UPDATE_GROUP_RULES_SUCCESS";
const UPDATE_GROUP_RULES_FAILED = "UPDATE_GROUP_RULES_FAILED";

const FETCH_ALL_USER_LIST_BY_DEPARTMENT_START =
  "FETCH_ALL_USER_LIST_BY_DEPARTMENT_START";
const FETCH_ALL_USER_LIST_BY_DEPARTMENT_SUCCESS =
  "FETCH_ALL_USER_LIST_BY_ROLE_SUCCESS";
const FETCH_ALL_USER_LIST_BY_DEPARTMENT_FAILED =
  "FETCH_ALL_USER_LIST_BY_DEPARTMENT_FAILED";

const FETCH_ALL_APPS_START = "FETCH_ALL_APPS_START";
const FETCH_ALL_APPS_SUCCESS = "FETCH_ALL_APPS_SUCCESS";
const FETCH_ALL_APPS_FAILED = "FETCH_ALL_APPS_FAILED";

const DELETE_GROUP_RULES_START = "DELETE_GROUP_RULES_START";
const DELETE_GROUP_RULES_SUCCESS = "DELETE_GROUP_RULES_SUCCESS";
const DELETE_GROUP_RULES_FAILED = "DELETE_GROUP_RULES_FAILED";

const TRIGGER_GROUP_RULES_START = "TRIGGER_GROUP_RULES_START";
const TRIGGER_GROUP_RULES_SUCCESS = "TRIGGER_GROUP_RULES_SUCCESS";
const TRIGGER_GROUP_RULES_FAILED = "TRIGGER_GROUP_RULES_FAILED";

const TRIGGER_INACTIVE_GROUP_RULES_START = "TRIGGER_INACTIVE_GROUP_RULES_START";
const TRIGGER_INACTIVE_GROUP_RULES_SUCCESS =
  "TRIGGER_INACTIVE_GROUP_RULES_SUCCESS";
const TRIGGER_INACTIVE_GROUP_RULES_FAILED =
  "TRIGGER_INACTIVE_GROUP_RULES_FAILED";

const MODIFY_TRIGGER_GROUP_RULES_START = " MODIFY_TRIGGER_GROUP_RULES_START";
const MODIFY_TRIGGER_GROUP_RULES_SUCCESS =
  " MODIFY_TRIGGER_GROUP_RULES_SUCCESS";
const MODIFY_TRIGGER_GROUP_RULES_FAILED = " MODIFY_TRIGGER_GROUP_RULES_FAILED";

const FETCH_ALL_UMLP_USERS_START = "FETCH_ALL_UMLP_USERS_START";
const FETCH_ALL_UMLP_USERS_SUCCESS = "FETCH_ALL_UMLP_USERS_SUCCESS";
const FETCH_ALL_UMLP_USERS_FAILED = "FETCH_ALL_UMLP_USERS_FAILED";

const FETCH_ALL_TITLES_START = "FETCH_ALL_TITLES_START";
const FETCH_ALL_TITLES_SUCCESS = "FETCH_ALL_TITLES_SUCCESS";
const FETCH_ALL_TITLES_FAILED = "FETCH_ALL_TITLES_FAILED";

const CREATE_USER_PREVIEW_START = "CREATE_USER_PREVIEW_START";
const CREATE_USER_PREVIEW_SUCCESS = "CREATE_USER_PREVIEW_SUCCESS";
const CREATE_USER_PREVIEW_FAILED = "CREATE_USER_PREVIEW_FAILED";

const FETCH_RULE_PAGINATION_DATA_SUCCESS = "FETCH_RULE_PAGINATION_DATA_SUCCESS";

const FETCH_RULE_SEARCH_INPUT = "FETCH_RULE_SEARCH_INPUT";

const SET_PREVIEW_RECORDS_LENGTH = "SET_PREVIEW_RECORDS_LENGTH";

const FETCH_CONDITIONS_DATA = "FETCH_CONDITIONS_DATA";

const FETCH_ALL_GROUP_RULES_BYID_START = "FETCH_ALL_GROUP_RULES_BYID_START";
const FETCH_ALL_GROUP_RULES_BYID_SUCCESS = "FETCH_ALL_GROUP_RULES_BYID_SUCCESS";
const FETCH_ALL_GROUP_RULES_BYID_FAILED = "FETCH_ALL_GROUP_RULES_BYID_FAILED";

const RESET_MODIFYRULE_SUCCESS = "RESET_MODIFYRULE_SUCCESS";
export const groupRuleActions = {
  FETCH_ALL_APPS_START,
  FETCH_ALL_APPS_SUCCESS,
  FETCH_ALL_APPS_FAILED,

  UPDATE_GROUP_RULES_START,
  UPDATE_GROUP_RULES_SUCCESS,
  UPDATE_GROUP_RULES_FAILED,

  FETCH_ALL_GROUP_RULES_START,
  FETCH_ALL_GROUP_RULES_SUCCESS,
  FETCH_ALL_GROUP_RULES_FAILED,

  CREATE_GROUP_RULES_START,
  CREATE_GROUP_RULES_SUCCESS,
  CREATE_GROUP_RULES_FAILED,

  TRIGGER_GROUP_RULES_START,
  TRIGGER_GROUP_RULES_SUCCESS,
  TRIGGER_GROUP_RULES_FAILED,

  TRIGGER_INACTIVE_GROUP_RULES_START,
  TRIGGER_INACTIVE_GROUP_RULES_SUCCESS,
  TRIGGER_INACTIVE_GROUP_RULES_FAILED,

  MODIFY_TRIGGER_GROUP_RULES_START,
  MODIFY_TRIGGER_GROUP_RULES_SUCCESS,
  MODIFY_TRIGGER_GROUP_RULES_FAILED,

  DELETE_GROUP_RULES_START,
  DELETE_GROUP_RULES_SUCCESS,
  DELETE_GROUP_RULES_FAILED,

  FETCH_ALL_USER_LIST_BY_DEPARTMENT_START,
  FETCH_ALL_USER_LIST_BY_DEPARTMENT_SUCCESS,
  FETCH_ALL_USER_LIST_BY_DEPARTMENT_FAILED,

  FETCH_ALL_UMLP_USERS_START,
  FETCH_ALL_UMLP_USERS_SUCCESS,
  FETCH_ALL_UMLP_USERS_FAILED,

  FETCH_ALL_TITLES_START,
  FETCH_ALL_TITLES_SUCCESS,
  FETCH_ALL_TITLES_FAILED,

  CREATE_USER_PREVIEW_START,
  CREATE_USER_PREVIEW_SUCCESS,
  CREATE_USER_PREVIEW_FAILED,

  FETCH_RULE_PAGINATION_DATA_SUCCESS,
  FETCH_RULE_SEARCH_INPUT,
  SET_PREVIEW_RECORDS_LENGTH,
  FETCH_CONDITIONS_DATA,

  FETCH_ALL_GROUP_RULES_BYID_START,
  FETCH_ALL_GROUP_RULES_BYID_SUCCESS,
  FETCH_ALL_GROUP_RULES_BYID_FAILED,

  RESET_MODIFYRULE_SUCCESS,
};

export const FETCH_SHAREPOINT_BEGIN = 'FETCH_SHAREPOINT_BEGIN';
export const FETCH_SHAREPOINT_SUCCESS = 'FETCH_SHAREPOINT_SUCCESS';
export const FETCH_SHAREPOINT_FAILURE = 'FETCH_SHAREPOINT_FAILURE';
export const FETCH_SHAREPOINT_RESET = 'FETCH_SHAREPOINT_RESET';