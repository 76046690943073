import React from "react";
import { useState, useEffect } from "react";
import Circle from "../../assets/images/new-images/add.png";
import deleteIcon from "../../assets/images/new-images/delete.png";
import edit from "../../assets/images/new-images/edit.png";
import AddGroupRule from "./AddGroupRule";
import { connect } from "react-redux";
import * as actions from "../../actions/action-grouprule";
import { fetchAllGroupRules } from "../../actions/action-grouprule";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import DeleteRule from "./DeleteRule";
import ActivateGroupRule from "./ActivateGroupRule";
import AddNewGroupRule from "./AddNewGroupRule";
import {
  fetchAllDepartments,
  fetchAllLocations,
} from "../../actions/action-master";
import {
  fetchAllApps,
  fetchAllUMLPUsers,
  fetchAllTitles,
  modifyGroupRuleById,
} from "../../actions/action-grouprule";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUserGroups } from "../../actions/action-master";

const GroupRule = ({ fetchAllGroupRules, groupsRules, loading, ...props }) => {
  const dispatch = useDispatch();

  const init = () => {
    fetchAllGroupRules();
    dispatch(fetchAllApps());
    dispatch(fetchAllDepartments());
    dispatch(fetchAllLocations());
    dispatch(fetchAllUMLPUsers(false));
    dispatch(fetchAllTitles());
    dispatch(fetchAllUserGroups());
  };

  useEffect(() => {
    init();
  }, []);

  const [groupRules, setGroupRules] = useState([]);

  const createGroupRule = (newItem) => {
    setGroupRules([...groupRules, newItem]);
  };

  const [deleteId, setDeleteId] = useState(null);

  const removeType = (id) => {
    setDeleteId(id);
  };
  const modifyRuleList = useSelector(
    (state) => state?.groupsRule?.modifyRuleList
  );
  const [editmode, setEditMode] = useState(false);
  const [filterRuleIndex, setFilterRuleIndex] = useState("");
  const [filterRule, setFilterRule] = useState({});
  const editType = (id) => {
    setEditMode(true);
    dispatch(modifyGroupRuleById(id));
    // setFilterRuleIndex(
    //   modifyRuleList?.findIndex((item, index) => item.ruleId === id)
    // );
    // setFilterRule(modifyRuleList?.find((item, index) => item.ruleId === id));
  };

  const [showAll, setShowAll] = useState(false);
  const [inactiveItem, setInactiveItem] = useState(null);
  const [activeStatus, setActiveStatus] = useState("Active");
  const [ruleId, setRuleId] = useState(false);
  const [isReady, setIsReady] = useState(false)
  const inActiveTest = (item, status, id, readyToRun) => {

    setInactiveItem(item);
    setActiveStatus(status);
    setRuleId(id);
    setIsReady(readyToRun)
  };

  const [addRuleView, setAddRuleView] = useState(false);
  useEffect(() => {
    dispatch(
      actions.resetData({
        ruleName: "",
        groupIds: [],
        applicationIds: [],
        conditions: [
          {
            conditionId: 0,
            displayOperator: "",
            conditionOn: "",
            conditionOperator: "",
            operator: 1,
            activeCondition: false,
            selectedValues: [],
          },
        ],
      })
    );
  }, []);

  return (
    <>
      {/* <AddGroupRule
        createGroupRule={createGroupRule}
        groupRules={groupRules}
        setGroupRules={setGroupRules}
        editmode={editmode}
        setEditMode={setEditMode}
        setFilterRuleIndex={setFilterRuleIndex}
        filterRule={filterRule}
        filterRuleIndex={filterRuleIndex}
        setFilterRule={setFilterRule}
        groupsRules={groupsRules}
        editType={editType}
      /> */}
      <DeleteRule
        deleteId={deleteId}
        setGroupRules={setGroupRules}
        groupsRules={groupsRules}
      />
      <ActivateGroupRule
        modalId={"activateRuleStatus"}
        modalLabel={"activateRuleStatusLabel12"}
        inactiveItem={inactiveItem}
        activeStatus={activeStatus}
        ruleId={ruleId}
        isReady={isReady}
        setIsReady={setIsReady}
      />
      {addRuleView ? (
        <AddNewGroupRule
          setAddRuleView={setAddRuleView}
          editmode={editmode}
          setEditMode={setEditMode}
          setFilterRuleIndex={setFilterRuleIndex}
          // filterRule={filterRule}
          // filterRuleIndex={filterRuleIndex}
          // setFilterRule={setFilterRule}
        />
      ) : (
        <section>
          <div>
            <div className="setting-header">
              <p className="app-heding">Group Rules</p>
              <div className="applist-right-headbar">
                <div className="d-flex">
                  <p className="text-w">Hide Inactive</p>
                  <div className="togl-btn">
                    <label className="switch me-3 ms-3">
                      <input
                        type="checkbox"
                        checked={showAll}
                        onChange={() => setShowAll(!showAll)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <button
                  className="btn btn-genmeb  min-w72"
                  // data-bs-toggle="modal"
                  // data-bs-target="#AddGroupRule"
                  onClick={() => setAddRuleView(true)}
                >
                  <img src={Circle} alt="icon" className="me-2" /> Add New Rule
                </button>
              </div>
            </div>

            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  top: "50%",
                  left: "0%",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "start",
                  // background: "#000",
                  zIndex: 99,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <div className="table-responsive assignTable">
                <table className="table table-hover">
                  <thead className="table-head">
                    <tr>
                      <th>Action</th>
                      <th>#</th>
                      <th>Rule Name</th>
                      <th>Group Name</th>
                      <th>App Name</th>
                      <th>Except the Following Users</th>
                      <th>Status</th>
                      <th>Date Added</th>
                      <th>Last Modified Date</th>
                      <th>Last Modified By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupRules && groupsRules?.length === 0 ? (
                      <tr>
                        <td colSpan="6">No Group Rules available</td>
                      </tr>
                    ) : (
                      groupsRules
                        ?.filter((i) => (!showAll ? i : i.isActive === true))
                        .map((d, index) => (
                          <tr key={d.ruleId}>
                            <td>
                              <span className="d-flex">
                                {d.isActive === true && (
                                  <img
                                    src={edit}
                                    alt="icon"
                                    className="me-3"
                                    onClick={() => {
                                      setAddRuleView(true);
                                      editType(d.ruleId);
                                    }}
                                  />
                                )}

                                <img
                                  src={deleteIcon}
                                  alt="icon"
                                  onClick={() => removeType(d.ruleId)}
                                  data-bs-target="#deleteGroupRulesModal"
                                  data-bs-toggle="modal"
                                />
                              </span>
                            </td>
                            <td>{index + 1}</td>

                            <td>{d.ruleName}</td>
                            <td>
                              {d.groups?.length > 0
                                ? d.groups?.map((a) => a.value).join(", ")
                                : "None"}
                            </td>

                            <td>
                              {d.applications?.length > 0
                                ? d.applications?.map((a) => a.value).join(", ")
                                : "None"}
                            </td>
                            <td>
                              {d.exceptUsers?.length > 0
                                ? d.exceptUsers
                                    ?.map((a) => a.userName)
                                    .join(", ")
                                : "None"}
                            </td>
                            <td>
                              <div className="togl-btn">
                                <span
                                  className={`w33 ${
                                    d.isActive === true ? "green" : "red"
                                  }`}
                                >
                                  {d.isActive ? "Active" : "Inactive"}
                                </span>
                                <label
                                  className="switch me-3 ms-3"
                                  data-bs-toggle="modal"
                                  data-bs-target="#activateRuleStatus"
                                >
                                  <input
                                    type="checkbox"
                                    checked={d.isActive === true ? true : false}
                                    onChange={() => {
                                      console.log("");
                                    }}
                                    onClick={() => {
                                      inActiveTest(d, d.isActive, d.ruleId, d.isReadytoRun);
                                    }}
                                  />

                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              {moment
                                .utc(d.createdAt)
                                .local()
                                .format("MM/DD/YYYY")}
                            </td>
                            <td>
                              {d.updatedAt ? moment(d.updatedAt).local().format("MM/DD/YYYY") : "N/A"}
                            </td>
                            <td>
                              {d.updatedBy ? d.updatedBy : "N/A"}
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};
const mapStateToProps = ({ groupsRule }) => ({
  groupsRules: groupsRule?.groupRules,
  loading: groupsRule?.ruleLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllGroupRules: () => dispatch(fetchAllGroupRules()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupRule);
