import Modal from "../UI/Modal";
import React, { useState } from "react";
import sucess from "../../assets/images/new-images/sucess.png";
import * as actions from "../../actions/action-master";
import axios from "axios";
import active from "../../assets/images/new-images/active.png";
import inactive from "../../assets/images/new-images/inactive.png";
import { connect } from "react-redux";
import failure from "../../assets/images/new-images/error.png";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import { fetchAllGroupRules } from "../../actions/action-grouprule";
const { REACT_APP_BACKEND_API_APP } = process.env;

const ActivateUserGroup = ({
  modalId,
  modalLabel,
  inactiveItem,
  activeStatus,
  groupId,
  fetchAllUserGroups,
  ...props
}) => {
  const [userInactive, setUserInactive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const inactiveHandler = (inactiveItem, status) => {
    let isActive = activeStatus === true ? false : true;

    let token = sessionStorage.getItem("accessToken2");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    };
    setLoading(true);
    axios
      .post(
        `${REACT_APP_BACKEND_API_APP}/api/UpdateUserGroupStatus?groupId=${groupId}&status=${isActive}`,
        {},
        config
      )
      .then(async (res) => {
        if (res.data.isSuccess === true) {
          setUserInactive(!userInactive);
          setResponse(res.data.statusCode);
        }
        fetchAllUserGroups();
        fetchAllGroupRules();
      });
    setLoading(false);
  };
  const userInactiveHandler = () => {
    setUserInactive(!userInactive);
    setResponse(null);
  };

  return (
    <Modal modalId={modalId} modalLabel={modalLabel} className={"addUserModal"}>
      <div className="modal-dialog modal-dialog-centered modal-sm ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {response &&
                response === 404
                  ? "Failure"
                  : activeStatus === true
                  ? !userInactive
                    ? "Deactivate User Group"
                    : "Success"
                  : !userInactive
                  ? "Activate User Group"
                  : "Success"}
            </h5>
            <button
              type="button"
              className="btn-close close--btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {setUserInactive(false); setResponse(null)}}
            ></button>
          </div>

          <div className="modal-body text-center">
            {!userInactive ? (
              <>
                {activeStatus === true ? (
                  <img
                    src={!userInactive ? inactive : sucess}
                    alt="icon"
                    className="m-3"
                  />
                ) : (
                  <img
                    src={!userInactive ? active : sucess}
                    alt="icon"
                    className="m-3"
                  />
                )}
                <p className="d-text">
                  {activeStatus === true
                    ? !userInactive
                      ? "Are you sure you want to deactivate the User Group?"
                      : "User Group deactivated Successfully"
                    : !userInactive
                    ? "Are you sure you want to Activate the User Group?"
                    : "User Group Activated Successfully"}
                </p>
              </>
            ) : loading ? (
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  top: "30%",
                  width: "100%",
                  height: "300PX",
                  justifyContent: "center",
                  alignItems: "start",
                  // background: "#000",
                  zIndex: 99,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <img
                  src={response && (response === 404 ? failure : sucess)}
                  alt="icon"
                  className="m-3"
                />

                <p className="d-text">
                  {response &&
                    (response === 404
                      ? "You cannot deactivate the group until you reassign the user(s) associated with this group to another group"
                      : activeStatus === true
                      ? "User Group deactivated Successfully"
                      : "User Group Activated Successfully")}
                </p>
              </>
            )}
          </div>
          <div className="modal-footer">
            {!userInactive && (
              <button
                type="button"
                className="btn btn-secondary ms-3 cancel--btn"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            )}

            {activeStatus === true ? (
              !userInactive ? (
                <button
                  type="button"
                  className="btn btn-genmeb ms-3 delete--btn"
                  onClick={() => inactiveHandler(inactiveItem, activeStatus)}
                >
                  Deactivate
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-genmeb ms-3"
                  data-bs-dismiss="modal"
                  onClick={userInactiveHandler}
                >
                  Ok
                </button>
              )
            ) : !userInactive ? (
              <button
                type="button"
                className="btn btn-genmeb ms-3 submit--btn"
                onClick={() => inactiveHandler(inactiveItem, activeStatus)}
              >
                Activate
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-genmeb ms-3 close--btn"
                data-bs-dismiss="modal"
                onClick={userInactiveHandler}
              >
                Ok
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
const mapStateToProps = ({ master }) => ({
  userGroups: master.userGroups,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllUserGroups: () => dispatch(actions.fetchAllUserGroups()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivateUserGroup);
