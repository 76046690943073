import { usersActions, SET_USERS_DATA } from "../actions/constants";
import { updateState } from "../middlewares/utils";

const defaultState = {
  loading: true,
  usersList: [],
  usersListLoading: true,
  error: false,
  message: "",
  notificationStartDate: "",
  notificationEndDate: "",
  usersInfo: [],
  infoLoading: true,
  infoMessage: "",
  photoInfo: [],
  photoLoading: true,
  photoMessage: "",
  headerusersInfo: [],
  headerinfoLoading: true,
  headerinfoMessage: "",
  headerphotoInfo: [],
  headerphotoLoading: true,
  headerphotoMessage: "",
  dashApps: [],
  dashAppsLoading: true,
  dashLoading: true,
  dashData: {},
  dashError: "",
  allUsers: [],
  allUsersLoading: true,
  allUserApps: [],
  allUserAppsLoading: true,
  allUserAppsMessage: "",
  allAppsLoading: true,
  allApps: [],
  triggerRulesLoading: true,
  emailUsersLoading: true,
  emailUsers: [],
  emailResponse: null,
  emailResponseStatusCode: null,
  emailResponseLoading: true,
};

export const usersList = (state = defaultState, actions) => {
  switch (actions.type) {
    case usersActions.FETCH_DASH_SUMMARY_START:
      return updateState(state, { dashLoading: true });
    case usersActions.FETCH_DASH_SUMMARY_SUCCESS:
      return updateState(state, {
        dashData: actions.payload.payload,
        dashLoading: actions.payload.loading,
      });
    case usersActions.FETCH_DASH_SUMMARY_FAILED:
      return updateState(state, {
        dashError: actions.payload.error,
        dashLoading: actions.payload.loading,
      });

    case usersActions.FETCH_ALL_USER_LIST_BY_ROLE_START:
      return updateState(state, { usersListLoading: true });
    case usersActions.FETCH_ALL_USER_LIST_BY_ROLE_SUCCESS:
      return updateState(state, {
        usersList: actions.payload.test,
        usersListLoading: actions.payload.loading,
        message: actions.payload.message,
      });
    case usersActions.FETCH_ALL_USER_LIST_BY_ROLE_FAILED:
      return updateState(state, { error: actions.payload });

    case usersActions.FETCH_USER_NOTIFICATION_FITLER_DATES:
      return updateState(state, {
        notificationStartDate: actions.payload.startDate,
        notificationEndDate: actions.payload.endDate,
      });

    case usersActions.FETCH_USERS_POPUP_PROFILE_START:
      return updateState(state, { infoLoading: true });
    case usersActions.FETCH_USERS_POPUP_PROFILE_SUCCESS:
      return updateState(state, {
        usersInfo: actions.payload.profile,
        infoLoading: actions.payload.loading,
        infoMessage: actions.payload.message,
      });
    case usersActions.FETCH_USERS_POPUP_PROFILE_FAILED:
      return updateState(state, { ...actions.payload });

    case usersActions.FETCH_USERS_PHOTO_START:
      return updateState(state, { photoLoading: true });
    case usersActions.FETCH_USERS_PHOTO_SUCCESS:
      return updateState(state, {
        photoInfo: actions.payload.photo,
        photoLoading: actions.payload.loading,
        photoMessage: actions.payload.message,
      });
    case usersActions.FETCH_USERS_PHOTO_FAILED:
      return updateState(state, { ...actions.payload });

    case usersActions.FETCH_HEADER_USERS_POPUP_PROFILE_START:
      return updateState(state, { headerinfoLoading: true });
    case usersActions.FETCH_HEADER_USERS_POPUP_PROFILE_SUCCESS:
      return updateState(state, {
        headerusersInfo: actions.payload.profile,
        headerinfoLoading: actions.payload.loading,
        headerinfoMessage: actions.payload.message,
      });
    case usersActions.FETCH_HEADER_USERS_POPUP_PROFILE_FAILED:
      return updateState(state, { ...actions.payload });

    case usersActions.FETCH_HEADER_USERS_PHOTO_START:
      return updateState(state, { headerphotoLoading: true });
    case usersActions.FETCH_HEADER_USERS_PHOTO_SUCCESS:
      return updateState(state, {
        headerphotoInfo: actions.payload.photo,
        headerphotoLoading: actions.payload.loading,
        headerphotoMessage: actions.payload.message,
      });
    case usersActions.FETCH_HEADER_USERS_PHOTO_FAILED:
      return updateState(state, { ...actions.payload });

    case usersActions.FETCH_DASH_APPS_START:
      return updateState(state, { dashAppsLoading: true });
    case usersActions.FETCH_DASH_APPS_SUCCESS:
      return updateState(state, {
        dashApps: actions.payload.payload,
        dashAppsLoading: actions.payload.loading,
      });
    case usersActions.FETCH_DASH_APPS_FAILED:
      return updateState(state, { ...actions.payload });

    case usersActions.FETCH_ALL_USERS_START:
      return updateState(state, { allUsersLoading: true });

    case usersActions.FETCH_ALL_USERS_SUCCESS:
      var index = actions.payload.payload.findIndex((x) => x.userId === 0);
      index === -1
        ? actions.payload.payload.splice(0, 0, {
            userName: "Select All",
            userId: 0,
          })
        : console.log();

      return updateState(state, {
        allUsers: actions.payload.payload,
        allUsersLoading: actions.payload.loading,
      });
    case usersActions.FETCH_ALL_USERS_FAILED:
      return updateState(state, { ...actions.payload });

    case usersActions.FETCH_ALL_APPS_BY_USERID_START:
      return updateState(state, { allUserAppsLoading: true });
    case usersActions.FETCH_ALL_APPS_BY_USERID_SUCCESS:
      return updateState(state, {
        allUserApps: actions.payload.test,
        allUserAppsLoading: actions.payload.loading,
        allUserAppsMessage: actions.payload.message,
      });
    case usersActions.FETCH_ALL_APPS_BY_USERID_FAILED:
      return updateState(state, { error: actions.payload });

    case usersActions.FETCH_ALL_USER_APPS_START:
      return updateState(state, { allAppsLoading: true });
    case usersActions.FETCH_ALL_USER_APPS_SUCCESS:
      var index = actions.payload.payload.findIndex((x) => x.appId === 0);
      index === -1
        ? actions.payload.payload.splice(0, 0, {
            appName: "Select All",
            appId: 0,
            isActive: true,
          })
        : console.log();
      return updateState(state, {
        allApps: actions.payload.payload,
        allAppsLoading: actions.payload.loading,
      });
    case usersActions.FETCH_ALL_USER_APPS_FAILED:
      return updateState(state, { error: actions.payload });

    case usersActions.TRIGGER_GROUP_RULES_BYUSERID_START:
      return updateState(state, { triggerRulesLoading: true });
    case usersActions.TRIGGER_GROUP_RULES_BYUSERID_SUCCESS:
      return updateState(state, {
        triggerRulesLoading: actions.payload.loading,
      });
    case usersActions.TRIGGER_GROUP_RULES_BYUSERID_FAILED:
      return updateState(state, { error: actions.payload });

    case usersActions.FETCH_EMAIL_USERS_START:
      return updateState(state, { emailUsersLoading: true });
    case usersActions.FETCH_EMAIL_USERS_SUCCESS:
      return updateState(state, {
        emailUsers: actions.payload.payload,
        emailUsersLoading: actions.payload.loading,
      });
    case usersActions.FETCH_EMAIL_USERS_FAILED:
      return updateState(state, { ...actions.payload });

    case usersActions.SEND_BULK_EMAIL_START:
      return updateState(state, {
        emailResponseLoading: true,
        emailResponse: null,
        emailResponseStatusCode: null,
      });
    case usersActions.SEND_BULK_EMAIL_SUCCESS:
      return updateState(state, {
        emailResponseLoading: false,
        emailResponse: actions.payload.displayMessage,
        emailResponseStatusCode: actions.payload.statusCode,
      });
    case usersActions.SEND_BULK_EMAIL_FAILED:
      return updateState(state, { error: actions.payload });
    case usersActions.ADD_BULK_USERS_START:
      return updateState(state, {
        bulkUploadLoading: true,
        bulkUploadResponse: null,
        bulkUploadStatusCode: null,
      });
    case usersActions.ADD_BULK_USERS_SUCCESS:
      return updateState(state, {
        bulkUploadLoading: false,
        bulkUploadResponse: actions.payload.displayMessage,
        bulkUploadStatusCode: actions.payload.statusCode,
        bulkUploadResponseData: actions.payload.responseData
      });
    case usersActions.ADD_BULK_USERS_FAILED:
      return updateState(state, { error: actions.payload });
    case usersActions.VALIDATE_BULK_USERS_START:
      return updateState(state, {
        bulkValidateLoading: true,
        bulkValidateResponse: null,
        bulkValidateStatusCode: null,
      });
    case usersActions.VALIDATE_BULK_USERS_SUCCESS:
      return updateState(state, {
        bulkValidateLoading: false,
        bulkValidateResponse: actions.payload.displayMessage,
        bulkValidateStatusCode: actions.payload.statusCode,
        bulkValidateResponseData: actions.payload.responseData
      });
    case usersActions.BULK_USER_UPLOAD_RESET:
      return updateState(state, {
        bulkValidateLoading: false,
        bulkValidateResponse: null,
        bulkValidateStatusCode: null,
        bulkUploadLoading: false,
        bulkUploadResponse: null,
        bulkUploadStatusCode: null,
      });
    case usersActions.VALIDATE_BULK_USERS_FAILED:
      return updateState(state, { error: actions.payload });
    default:
      return state;
  }
};

const initialstate = {
  usersData: [],
};

export const users = (state = initialstate, action) => {
  switch (action.type) {
    case SET_USERS_DATA:
      return { ...state, usersData: action.payload };
    default:
      return state;
  }
};
