export const Bell = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.99967 18.125C10.9163 18.125 11.6663 17.375 11.6663 16.4583H8.33301C8.33301 17.375 9.08301 18.125 9.99967 18.125ZM14.9997 13.125V8.95833C14.9997 6.4 13.6413 4.25833 11.2497 3.69167V3.125C11.2497 2.43333 10.6913 1.875 9.99967 1.875C9.30801 1.875 8.74967 2.43333 8.74967 3.125V3.69167C6.36634 4.25833 4.99967 6.39167 4.99967 8.95833V13.125L3.33301 14.7917V15.625H16.6663V14.7917L14.9997 13.125ZM13.333 13.9583H6.66634V8.95833C6.66634 6.89167 7.92467 5.20833 9.99967 5.20833C12.0747 5.20833 13.333 6.89167 13.333 8.95833V13.9583Z" fill="#626D8A"/>
</svg>
);

export const Close = (props) => (
  <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.170705 13.8293C0.280023 13.9386 0.428384 14 0.582959 14C0.737535 14 0.88578 13.9386 0.995088 13.8293L6.99634 7.82806L12.9976 13.8293C13.1457 13.9723 13.3582 14.0266 13.5567 13.9722C13.7554 13.9178 13.9104 13.7627 13.9648 13.5641C14.0193 13.3656 13.965 13.153 13.822 13.0049L7.82072 7.00368L13.822 1.00243C13.9345 0.893844 13.9985 0.744509 14 0.588084C14.0013 0.431794 13.9397 0.281359 13.8292 0.170833C13.7186 0.0602932 13.5682 -0.00131852 13.4119 2.14073e-05C13.2555 0.00148549 13.1062 0.0655404 12.9976 0.178029L6.99632 6.17928L0.995064 0.178029C0.846947 0.0350375 0.634407 -0.0192561 0.435917 0.0351594C0.237289 0.0895748 0.082226 0.244644 0.0278168 0.443259C-0.0265986 0.641766 0.027695 0.854296 0.170686 1.00241L6.17194 7.00366L0.170686 13.0049C0.0613682 13.1142 0 13.2625 0 13.417C0 13.5716 0.0613868 13.72 0.170705 13.8293Z" fill="#667085"/>
</svg>
);

export const Search = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.1296 11.8796H12.4712L12.2379 11.6546C13.0546 10.7046 13.5462 9.47122 13.5462 8.12956C13.5462 5.13789 11.1212 2.71289 8.12956 2.71289C5.13789 2.71289 2.71289 5.13789 2.71289 8.12956C2.71289 11.1212 5.13789 13.5462 8.12956 13.5462C9.47122 13.5462 10.7046 13.0546 11.6546 12.2379L11.8796 12.4712V13.1296L16.0462 17.2879L17.2879 16.0462L13.1296 11.8796ZM8.12956 11.8796C6.05456 11.8796 4.37956 10.2046 4.37956 8.12956C4.37956 6.05456 6.05456 4.37956 8.12956 4.37956C10.2046 4.37956 11.8796 6.05456 11.8796 8.12956C11.8796 10.2046 10.2046 11.8796 8.12956 11.8796Z" fill="#626D8A"/>
</svg>
);

export const Filter = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.33333 15H11.6667V13.3333H8.33333V15ZM2.5 5V6.66667H17.5V5H2.5ZM5 10.8333H15V9.16667H5V10.8333Z" fill="#344054"/>
</svg>
);

export const More = (props) => (
  <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.99967 6.66634C10.9163 6.66634 11.6663 5.91634 11.6663 4.99967C11.6663 4.08301 10.9163 3.33301 9.99967 3.33301C9.08301 3.33301 8.33301 4.08301 8.33301 4.99967C8.33301 5.91634 9.08301 6.66634 9.99967 6.66634ZM9.99967 8.33301C9.08301 8.33301 8.33301 9.08301 8.33301 9.99967C8.33301 10.9163 9.08301 11.6663 9.99967 11.6663C10.9163 11.6663 11.6663 10.9163 11.6663 9.99967C11.6663 9.08301 10.9163 8.33301 9.99967 8.33301ZM9.99967 13.333C9.08301 13.333 8.33301 14.083 8.33301 14.9997C8.33301 15.9163 9.08301 16.6663 9.99967 16.6663C10.9163 16.6663 11.6663 15.9163 11.6663 14.9997C11.6663 14.083 10.9163 13.333 9.99967 13.333Z" fill="#98A2B3"/>
</svg>
);

export const Visit = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.91667 3.75V5.41667H13.4083L3.75 15.075L4.925 16.25L14.5833 6.59167V12.0833H16.25V3.75H7.91667Z" fill="white"/>
</svg>
);

export const Down = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.825 6.91211L10 10.7288L6.175 6.91211L5 8.08711L10 13.0871L15 8.08711L13.825 6.91211Z" fill="#026460"/>
  </svg>
);

export const Up = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 6.91211L5 11.9121L6.175 13.0871L10 9.27044L13.825 13.0871L15 11.9121L10 6.91211Z" fill="#026460"/>
  </svg>
);

export const Edit = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.99902 14.0007H4.49902L11.8724 6.62736L9.37236 4.12736L1.99902 11.5007V14.0007ZM3.33236 12.054L9.37236 6.01402L9.98569 6.62736L3.94569 12.6674H3.33236V12.054Z" fill="#344054"/>
  <path d="M12.2457 2.19402C11.9857 1.93402 11.5657 1.93402 11.3057 2.19402L10.0857 3.41402L12.5857 5.91402L13.8057 4.69402C14.0657 4.43402 14.0657 4.01402 13.8057 3.75402L12.2457 2.19402Z" fill="#344054"/>
</svg>
);

export const Delete = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.6663 6V12.6667H5.33301V6H10.6663ZM9.66634 2H6.33301L5.66634 2.66667H3.33301V4H12.6663V2.66667H10.333L9.66634 2ZM11.9997 4.66667H3.99967V12.6667C3.99967 13.4 4.59967 14 5.33301 14H10.6663C11.3997 14 11.9997 13.4 11.9997 12.6667V4.66667Z" fill="#344054"/>
</svg>
);

export const CheckboxDefault = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white"/>
  <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#D0D5DD"/>
  </svg>
)

export const CheckboxSelected = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#E6F6F4"/>
<rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#038177"/>
<path d="M6.39754 9.93746L4.31254 7.85246L3.60254 8.55746L6.39754 11.3525L12.3975 5.35246L11.6925 4.64746L6.39754 9.93746Z" fill="#038177"/>
</svg>
);


export const HighPriority = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8 6.03027L4 10.0303L4.94 10.9703L8 7.91694L11.06 10.9703L12 10.0303L8 6.03027Z" fill="#640802"/>
  </svg>
);

export const Minimize = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 7.83301H12V9.16634H4V7.83301Z" fill="#643A02"/>
  </svg>
);

export const LowPriority = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.06 6.03027L8 9.08361L4.94 6.03027L4 6.97027L8 10.9703L12 6.97027L11.06 6.03027Z" fill="#023978"/>
  </svg>
)

export const ErrorOutlineIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.33398 10.0002H8.66732V11.3335H7.33398V10.0002ZM7.33398 4.66683H8.66732V8.66683H7.33398V4.66683ZM7.99398 1.3335C4.31398 1.3335 1.33398 4.32016 1.33398 8.00016C1.33398 11.6802 4.31398 14.6668 7.99398 14.6668C11.6807 14.6668 14.6673 11.6802 14.6673 8.00016C14.6673 4.32016 11.6807 1.3335 7.99398 1.3335ZM8.00065 13.3335C5.05398 13.3335 2.66732 10.9468 2.66732 8.00016C2.66732 5.0535 5.05398 2.66683 8.00065 2.66683C10.9473 2.66683 13.334 5.0535 13.334 8.00016C13.334 10.9468 10.9473 13.3335 8.00065 13.3335Z" fill="#F04438"/>
  </svg>
);

export const CalendarIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.66667 7.33301H6V8.66634H4.66667V7.33301ZM14 3.99967V13.333C14 14.0663 13.4 14.6663 12.6667 14.6663H3.33333C2.59333 14.6663 2 14.0663 2 13.333L2.00667 3.99967C2.00667 3.26634 2.59333 2.66634 3.33333 2.66634H4V1.33301H5.33333V2.66634H10.6667V1.33301H12V2.66634H12.6667C13.4 2.66634 14 3.26634 14 3.99967ZM3.33333 5.33301H12.6667V3.99967H3.33333V5.33301ZM12.6667 13.333V6.66634H3.33333V13.333H12.6667ZM10 8.66634H11.3333V7.33301H10V8.66634ZM7.33333 8.66634H8.66667V7.33301H7.33333V8.66634Z" fill="#667085"/>
</svg>
)