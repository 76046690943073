import { SET_APPS_DATA, SET_APP_EXISTS } from "./constants";

export const setAppsData = (value) => ({
  type: SET_APPS_DATA,
  payload: value,
});

export const setAppsExist = (value) => ({
  type: SET_APP_EXISTS,
  payload: value,
});
