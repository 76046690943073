import axios from "axios";
const { REACT_APP_BACKEND_API_APP } = process.env;

const BASE_URL = `${REACT_APP_BACKEND_API_APP}/api`;

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.delete["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";


async function GET(params) {
  const { name, queryString } = params;
  const q = queryString ? `?${queryString}` : "";
  try {
    return await axios({
      method: "get",
      url: `${BASE_URL}/${name}/${q}`,
    }).then((response) => response.data);
  } catch (error) {
    return error;
  }
}

async function POST(params) {
  const { name, queryString, payload, headers } = params;
  const q = queryString ? `?${queryString}` : "";
  try {
    return await axios({
      method: "post",
      url: `${BASE_URL}/${name}/${q}`,
      data: payload,
      withCredentials: true,
      headers: headers ? headers : {}
    }).then((response) => response.data);
  } catch (error) {
    return error;
  }
}

async function PUT(params) {
  const { name, queryString, payload } = params;
  const q = queryString ? `?${queryString}` : "";
  try {
    return await axios({
      method: "put",
      url: `${BASE_URL}/${name}/${q}`,
      data: payload,
    }).then((response) => response.data);
  } catch (error) {
    return error;
  }
}

async function DELETE(params) {
  const { name, queryString, payload } = params;
  const q = queryString ? `?${queryString}` : "";
  try {
    return await axios({
      method: "delete",
      url: `${BASE_URL}/${name}/${q}`,
      data: payload,
    }).then((response) => response.data);
  } catch (error) {
    return error;
  }
}

export default {
  GET,
  POST,
  PUT,
  DELETE,
};
