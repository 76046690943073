import React, {useState, useEffect} from "react";

import Modal from "../UI/Modal";
import sucess from "../../assets/images/new-images/sucess.png";
import failure from "../../assets/images/new-images/error.png";
import * as actions from "../../actions/action-master";
import { connect } from "react-redux";
import { createUserGroup, updateUserGroup } from "../../actions/action-master";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import { Alert, AlertTitle, Avatar, Button, Typography } from "@mui/material";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { ArrowRight } from "../App/icons";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { TextField } from "../UI/TextField";
import { CustomAlert } from "../UI/Alert";

const AddUserGroup = ({
  userGroups,
  setGroups,
  editmode,
  setEditMode,
  filterGroupIndex,
  filterGroup,
  setFilterGroup,
  updateUserGroup,
  message,
  loading,
  createUserGroup,
  activeStatus,
  displayErrorMessage,
  ...props
}) => {
  const initialState = {
    value: "",
  };

  const [userInput, setUserInput] = useState(initialState);

  useEffect(() => {
    {
      editmode ? setUserInput(filterGroup) : setUserInput(initialState);
    }
  }, [editmode, filterGroup]);

  const handleChange = (evt) => {
    if (!editmode) {
      setUserInput({ ...userInput, [evt.target.name]: evt.target.value });
    } else {
      setFilterGroup({
        ...filterGroup,
        [evt.target.name]: evt.target.value,
      });
    }
  };

  const [submitted, setSubmitted] = useState(false);

  const successHandler = () => {
    setSubmitted(false);
    setEditMode(false);
    setUserInput(initialState);
    setTouched(defaultState);
  };

  const defaultState = {
    value: false,
  };
  const [touched, setTouched] = useState(defaultState);

  const handleFocus = (e) => {
    let { name } = e.target;
    setTouched({ ...touched, [name]: true });
  };
  const enteredGroupIsValid = userInput.value.trim() !== "";
  const groupInputIsInvalid = !enteredGroupIsValid && touched.value;

  const handleSubmit = (e) => {
    e.preventDefault() ;
        setTouched({
      value: true,
      colour: true,
    });

    if (!enteredGroupIsValid) {
      return;
    }

    if (editmode) {
      let payload = {
        groupId: filterGroup.id,
        groupName: filterGroup.value.trim(),
        createdBy: 0,
      };
      updateUserGroup(payload);
    } else {
      let payload = {
        groupId: 0,
        groupName: userInput.value.trim(),
      };
      createUserGroup(payload);
    }

    // setUserInput(initialState);
    setSubmitted(true);
    // setTouched(defaultState);
  };

  const modalClose = () => {
    setTouched(defaultState);
    setSubmitted(false);
    // props?.setShowAssign(false)
    if (!editmode) {
      setUserInput(initialState);
    } else {
      setEditMode(false);
      setFilterGroup({
        filterGroup,
      });
    }
  }

  return (
    <Modal
      modalId={"groupName"}
      modalLabel={"exampleModalLabel"}
      className="secondary-modal"
    >
      <div className="modal-dialog modal-dialog-centered modal-l">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {editmode
                  ? "Edit Group"
                  : "Create a new Group"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={modalClose}
            ></button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <TextField
                label="Group Name"
                required
                placeholder="Enter Group Name(max characters length - 30)"
                onChange={handleChange}
                value={
                  !editmode ? userInput.value || "" : filterGroup.value || ""
                }
                name="value"
                maxLength="30"
                onBlur={ handleFocus }
                error={ groupInputIsInvalid }
                errorMessage="Please enter a User Group Name"
                disabled={submitted &&  message === 200 }
              />

              { submitted && ( message === 200 ? (
                <CustomAlert
                  title={ `${ !editmode ? userInput.value || "" : filterGroup.value || "" } group successfully ${ !editmode ? 'created' : 'updated' }.` }
                />
              ) : message === 404 && (
                  <CustomAlert
                    severity='error'
                    title={ displayErrorMessage }
                    secondaryText="Try to change the Group name."
                  />
              ) )
            }
          </div>
          <div className="modal-footer">
            { message === 200 && submitted ?
              <button
                className="btn btn-genmeb ms-3 submit--btn"
                data-bs-dismiss="modal"
                onClick={successHandler}
              >
                Done
              </button> : (
              <>
              <button
                type="button"
                className="btn btn-secondary cancel--btn"
                data-bs-dismiss="modal"
                onClick={modalClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-genmeb ms-3 submit--btn"
                onClick={handleSubmit}
                disabled={userInput.value === '' || groupInputIsInvalid}
              >
                {editmode ? "Update" : "Create"}
              </button>
              </>
              )
          }
            {/* <button
              type="button"
              className="btn btn-secondary cancel--btn"
              data-bs-dismiss="modal"
              onClick={() => {
                setTouched(defaultState);
                if (!editmode) {
                  setUserInput(initialState);
                } else {
                  setEditMode(false);
                  setFilterGroup({
                    filterGroup,
                  });
                }
              }}
            >
              Cancel
            </button>
            <button className="btn btn-genmeb ms-3 submit--btn">
            {editmode ? "Update" : "Add"}
            </button> */}
          </div>
          </form>
        </div>
      </div>
    {/* ) : (
      <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content edit-success">
            <div className="modal-body text-center pt-0 type-loader">
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    position: "absolute",
                    top: "30%",
                    width: "100%",
                    height: "300PX",
                    justifyContent: "center",
                    alignItems: "start",
                    // background: "#000",
                    zIndex: 99,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <img
                    src={message && (message === 404 ? failure : sucess)}
                    alt="icon"
                    className="m-3"
                  />
                  <p className={`${message && (message === 404 ? "red-text" : "green-text")}`}>
                    {message && (message === 404 ? "Failure" : "Success")}
                  </p>

                  <p>
                    {message &&
                      ( message === 404
                        ?  "User Group Already Exists"
                        : editmode
                        ? "User Group Updated Successfully"
                        : "New User Group Created Successfully")}

                  </p>

                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-genmeb ms-3 close--btn"
                data-bs-dismiss="modal"
                onClick={successHandler}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )} */}


    </Modal>
  );
};
const mapStateToProps = ({ master }) => ({
  userGroups: master.userGroups,
  message: master.groupsResponse,
  loading: master.groupsResponseLoading,
  displayErrorMessage: master.groupPaylod?.displayMessage
});

const mapDispatchToProps = (dispatch) => ({
  createUserGroup: (payload) => dispatch(createUserGroup(payload)),
  updateUserGroup: (payload) => dispatch(updateUserGroup(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserGroup);
