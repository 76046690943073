import {FETCH_SHAREPOINT_BEGIN, FETCH_SHAREPOINT_FAILURE, FETCH_SHAREPOINT_SUCCESS, FETCH_SHAREPOINT_RESET} from '../actions/constants';
import {removeObjectsWithKeyValue} from "../utils/sharepoint";

const initialState = {
    notifications: [],
    loading: false,
    error: null
};

export default function sharepointNotifications(state = initialState, action) {
    switch(action.type) {
        case FETCH_SHAREPOINT_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_SHAREPOINT_SUCCESS:

            const filteredNotifications = removeObjectsWithKeyValue(state.notifications, action.payload.appId);

            return {
                ...state,
                loading: false,
                notifications: [...filteredNotifications, {...action.payload.data, appId: action.payload.appId}]
            };
        case FETCH_SHAREPOINT_RESET:
            return initialState;
        case FETCH_SHAREPOINT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                notifications: [...state.notifications]
            };
        default:
            return state;
    }
}