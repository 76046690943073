import Modal from "../UI/Modal";
import React, { useState } from "react";
import sucess from "../../assets/images/new-images/sucess.png";
import * as actions from "../../actions/action-master";
import axios from "axios";
import active from "../../assets/images/new-images/active.png";
import inactive from "../../assets/images/new-images/inactive.png";
import { connect } from "react-redux";
const { REACT_APP_BACKEND_API_APP } = process.env;

const ActivateNotificationType = ({
  modalId,
  modalLabel,
  inactiveItem,
  activeStatus,
  notifyId,
  setStatusSuccess,
  fetchNotificationTypes,
  ...props
}) => {
  const [userInactive, setUserInactive] = useState(false);

  const inactiveHandler = (inactiveItem, status) => {
    let isActive = activeStatus === true ? false : true;

    let token = sessionStorage.getItem("accessToken2");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    };

    axios
      .post(
        `${REACT_APP_BACKEND_API_APP}/api/UpdateNotificationTypeStatus?notificationTypeId=${notifyId}&status=${isActive}`,
        {},
        config
      )
      .then(async (res) => {
        if (res.data.isSuccess === true) {
          setStatusSuccess(true);
          setUserInactive(!userInactive);
        }
        fetchNotificationTypes()
      });
  };

  const userInactiveHandler = () => {
    setUserInactive(!userInactive);
  };

  return (
    <Modal modalId={modalId} modalLabel={modalLabel} className={"addUserModal"}>
      <div className="modal-dialog modal-dialog-centered modal-sm ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              
              {activeStatus === true
                ? !userInactive
                  ? "Deactivate Notification Type"
                  : "Success"
                : !userInactive
                ? "Activate Notification Type"
                : "Success"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() =>setUserInactive(false)}
            ></button>
          </div>

          <div className="modal-body text-center">
            {activeStatus === true ? (
              <img
                src={!userInactive ? inactive : sucess}
                alt="icon"
                className="m-3"
              />
            ) : (
              <img
                src={!userInactive ? active : sucess}
                alt="icon"
                className="m-3"
              />
            )}
            <p className="d-text">
              {activeStatus === true
                ? !userInactive
                  ? "Are you sure you want to deactivate the Notification Type?"
                  : "Notification Type deactivated Successfully"
                : !userInactive
                ? "Are you sure you want to Activate the Notification Type?"
                : "Notification Type Activated Successfully"}
            </p>
          </div>
          <div className="modal-footer">
            {!userInactive && (
              <button
                type="button"
                className="btn btn-secondary ms-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            )}

            {activeStatus === true ? (
              !userInactive ? (
                <button
                  type="button"
                  className="btn btn-genmeb ms-3"
                  onClick={() => inactiveHandler(inactiveItem, activeStatus)}
                >
                  Deactivate
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-genmeb ms-3"
                  data-bs-dismiss="modal"
                  onClick={userInactiveHandler}
                >
                  Ok
                </button>
              )
            ) : !userInactive ? (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                onClick={() => inactiveHandler(inactiveItem, activeStatus)}
              >
                Activate
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-dismiss="modal"
                onClick={userInactiveHandler}
              >
                Ok
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ master }) => ({
  notificationTypes: master.notificationTypes,
});

const mapDispatchToProps = (dispatch) => ({
  fetchNotificationTypes: () => dispatch(actions.fetchNotificationTypes()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateNotificationType);
