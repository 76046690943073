import React, { useEffect, useState } from "react";
import "../assets/css/new-dashboard.css";
import logo from "../assets/images/new-images/logo.png";
import downIcon from "../assets/images/new-images/down.svg";
import emailUs from "../assets/images/new-images/email-us.svg";
import signOut from "../assets/images/new-images/logoff.svg";
import menu from "../assets/images/new-images/menu.png";
import close from "../assets/images/new-images/close.png";
import camera from "../assets/images/new-images/camera.png";
import { Link, NavLink } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import profileLogout from "../assets/images/new-images/profile-logout.svg";
import copy from "../assets/images/new-images/copy.svg";
import { connect } from "react-redux";
import {
  fetchHeaderUserProfiePhoto,
  fetchHeaderUsersPopUpInfo,
} from "../actions/actions-users";
import { Loader } from "rsuite";
import { renderImageDataUrl } from "../middlewares/utils";
import { Bell, Close } from './Icons'

const Navbar = ({}) => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const loginData = useSelector((state) => state?.usersList?.dashData);
  const headerphotoInfo = useSelector(
    (state) => state?.usersList?.headerphotoInfo
  );
  const headerphotoLoading = useSelector(
    (state) => state?.usersList?.headerphotoLoading
  );
  const headerusersInfo = useSelector(
    (state) => state?.usersList?.headerusersInfo
  );
  const headerinfoLoading = useSelector(
    (state) => state?.usersList?.headerinfoLoading
  );

  let empId = sessionStorage.getItem("userEmpId");

  const mobileMenuHolder = () => {
    setMobileMenu(!mobileMenu);
  };

  const { instance, inProgress } = useMsal();
  const [name, setName] = useState(null);

  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    if (activeAccount && activeAccount.name) {
      setName(activeAccount.name.split(" ")[0]);
    } else {
      setName(null);
    }
  }, [activeAccount, inProgress]);

  const handleSignOut = () => {
    if (activeAccount && activeAccount.name) {
      instance.logoutRedirect();
    }
  };

  const navigate = useNavigate();
  const loginAs = () => {
    navigate("/login-as");
  };
  const emailSend = () => {
    window.open(
      "mailto:BIS@genmab.com?subject=Need help with Launchpad&body=Enter your message"
    );
  };

  const profileLogoutHandler = () => {
    // setEmpId("");
    sessionStorage.removeItem("dataLoad");
    sessionStorage.removeItem("photoLoad");
    sessionStorage.removeItem("userEmpId");
    navigate("/my-apps");
  };

  var userIntials = loginData?.userName;
  var matches = userIntials?.match(/\b(\w)/g);
  let firstLetter = matches && matches[0];
  let lastLetter = matches && matches[matches.length - 1];
  const intials = firstLetter + lastLetter;

  const [ showNotification, setShowNotification ] = useState( false );

  const imageLink = 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Microsoft_Office_SharePoint_%282019%E2%80%93present%29.svg/512px-Microsoft_Office_SharePoint_%282019%E2%80%93present%29.svg.png'

  const data = {
    today: [
      {
        icon: imageLink,
        title: 'A new HCP has been initiated in your library',
        date: 'Jan 6',
        read: false
      },
      {
        icon: imageLink,
        title: 'A new HCP has been initiated in your library',
        date: 'Jan 6',
        read: false
      }
    ],
    yesterday: [
      {
        icon: imageLink,
        title: 'A new HCP has been initiated in your library',
        date: 'Jan 5',
        read: true
      },
      {
        icon: imageLink,
        title: 'A new HCP has been initiated in your library',
        date: 'Jan 5',
        read: true
      },
      {
        icon: imageLink,
        title: 'A new HCP has been initiated in your library',
        date: 'Jan 5',
        read: true
      }
    ],

    this_week: [
      {
        icon: imageLink,
        title: 'A new HCP has been initiated in your library',
        date: 'Jan 4',
        read: true
      },
      {
        icon: imageLink,
        title: 'A new HCP has been initiated in your library',
        date: 'Jan 4',
        read: true
      },
      {
        icon: imageLink,
        title: 'A new HCP has been initiated in your library',
        date: 'Jan 4',
        read: true
      }
    ],
  }

    function countReadTrue(arr) {
      return arr.filter(item => item.read === false).length;
    }

    function getTotalReadCount(data) {
      let totalCount = 0;

      Object.keys(data).forEach(key => {
        totalCount += countReadTrue(data[key]);
      });

      return totalCount;
    }

  return (
    <>
      <header>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" className="img-fluid" />
          </Link>
        </div>
        <div className="user-detail">
          <p>
            Welcome back ! <span>{loginData.userName}</span>
          </p>
        </div>
        <div className="admin-profile d-flex">
          <div className="phone-menu">
            <img src={menu} alt="user-pic" onClick={mobileMenuHolder} />
          </div>
          <div className="m-view">
            {headerphotoLoading && sessionStorage.getItem("photoLoad") === null ? (
              <Loader />
            ) : headerphotoInfo && headerphotoInfo?.length > 0 ? (
              <img
                className="user-pic"
                src={renderImageDataUrl(headerphotoInfo?.map(
                  (a) => a.profilePhoto
                ))}
                alt="icon"
              />
            ) : (
              <div className="p-img">
                <div className="p-box">
                  <span>{loginData.userName && intials}</span>
                </div>
              </div>
            )}
            <div>
              <p>{loginData.userRoleName}</p>
              {empId && empId !== "" && empId !== undefined && (
                <p className="logout-profile" onClick={profileLogoutHandler}>
                  Sign Out
                  <img className="logIcon" src={profileLogout} alt="icon" />
                </p>
              )}
            </div>

            <div className="dropdown">
              <button
                type="button"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <img src={downIcon} alt="logo" />
              </button>
              <ul className="dropdown-menu">
                {headerinfoLoading ? (
                  <Loader />
                ) : (
                  <>
                    <img src={close} alt="icon" className="close-icon-b" />
                    <li className="dropdown-item">
                      <span className="position-user">
                        {headerphotoLoading && sessionStorage.getItem("photoLoad") === null ? (
                          <Loader />
                        ) : headerphotoInfo && headerphotoInfo?.length > 0 ? (
                          <div>
                            <img
                              className="user-pic"
                              src={renderImageDataUrl(headerphotoInfo?.map(
                                (a) => a.profilePhoto
                              ))}
                              alt="icon"
                            />
                          </div>
                        ) : (
                          <div className="p-img">
                            <div className="p-box">
                              <span>{loginData.userName && intials}</span>
                            </div>
                          </div>
                        )}

                        <img src={camera} alt="icon" className="camera" />
                      </span>
                    </li>
                    <li>
                      <span className="text-position">
                        <p className="user-n mb-2">{loginData.userName}</p>
                        <p className="user-d mb-1">
                          <b>Role:</b> {loginData.userRoleName}
                        </p>
                        <p className="user-d mb-1">
                          <b className="me-1">
                            {headerusersInfo?.[0]?.userGroups?.length > 1
                              ? "Groups:"
                              : "Group:"}
                          </b>
                          {headerusersInfo &&
                          headerusersInfo[0]?.userGroups?.length !== 0
                            ? headerusersInfo[0]?.userGroups
                                ?.map((a) => a.value)
                                .join(", ")
                            : "N/A"}
                        </p>

                        <p className="user-d mb-1">
                          <b>Business Title:</b>{" "}
                          {headerusersInfo &&
                          headerusersInfo[0]?.jobTitle !== null
                            ? headerusersInfo[0]?.jobTitle
                            : "N/A"}
                        </p>
                        <p className="user-d mb-1">
                          <b>Organization:</b>{" "}
                          {headerusersInfo &&
                          headerusersInfo[0]?.companyName !== null
                            ? headerusersInfo[0]?.companyName
                            : "N/A"}
                        </p>
                        <p className="user-d mb-1">
                          <b>Reporting Manager:</b>{" "}
                          {headerusersInfo &&
                          headerusersInfo[0]?.manager !== null
                            ? headerusersInfo[0]?.manager
                            : "N/A"}
                        </p>
                        <div className="bdr-top zmargin">
                          <p className="user-d mb-1">
                            <b>Location :</b>{" "}
                            {headerusersInfo &&
                            headerusersInfo[0]?.location !== null
                              ? headerusersInfo[0]?.location
                              : "N/A"}
                          </p>
                          <p className="user-d mb-2 dflex">
                            <span>
                              <b>Email :</b>{" "}
                              {headerusersInfo &&
                              headerusersInfo[0]?.email !== null
                                ? headerusersInfo[0]?.email
                                : "N/A"}
                            </span>
                            <img
                              src={copy}
                              alt="icon"
                              className="copyIcon g-bg"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  headerusersInfo[0]?.email
                                );
                              }}
                            />
                          </p>
                        </div>
                      </span>
                    </li>
                    <li>
                      <span className="email-bar">
                        <img src={emailUs} alt="user-pic" />
                        <span>
                          <span className="font-14">Support :</span>
                          <span>
                            For support or to provide feedback on this app
                            select the following email:
                          </span>
                          <span
                            className="url-link"
                            onClick={() => emailSend("BIS@genmab.com")}
                          >
                            BIS@genmab.com
                          </span>
                        </span>
                      </span>
                    </li>
                    {loginData.userRoleName === "Admin" ? (
                      <li className="logout" onClick={loginAs}>
                        <img src={signOut} alt="out" />
                        <span>Login As</span>
                      </li>
                    ) : empId && empId !== "" && empId !== undefined ? (
                      <></>
                    ) : (
                      <li className="logout" onClick={handleSignOut}>
                        <img src={signOut} alt="out" />
                        <span>Sign Out</span>
                      </li>
                    )}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </header>
      <aside className={`side-nav-bar ${!mobileMenu ? "mobile-menu" : ""}`} id='nav-bar'>
        <ul>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "active-sidebar" : "")}
          >
            <li className="dash-icon">
              <span>Dashboard</span>
            </li>
          </NavLink>
          {loginData.userRoleName === "Admin" && (
            <NavLink
              to="/AdminCenter"
              className={({ isActive }) => (isActive ? "active-sidebar" : "")}
            >
              <li className="admin-center-icon">
                <span>Admin Center</span>
              </li>
            </NavLink>
          )}
          {/* {loginData.userRoleName === "Admin" && (
              <NavLink
                to="/my-apps"
                className={({ isActive }) => (isActive ? "active-sidebar" : "")}
              >
                <li className="app-icon">
                  <span>Apps Management</span>
                </li>
              </NavLink>
            )}
            {loginData.userRoleName === "Admin" && (
              <NavLink
                to="/users"
                className={({ isActive }) => (isActive ? "active-sidebar" : "")}
              >
                <li className="user-icon">
                  <span>User Management</span>
                </li>
              </NavLink>
            )} */}
          {loginData.userRoleName === "Admin" && (
            <NavLink
              to="/events"
              className={({ isActive }) => (isActive ? "active-sidebar" : "")}
            >
              <li className="calendar-icon">
                <span>Events Management</span>
              </li>
            </NavLink>
          )}
          {loginData.userRoleName === "Team Lead" && (
            <NavLink
              to="/events"
              className={({ isActive }) => (isActive ? "active-sidebar" : "")}
            >
              <li className="calendar-icon">
                <span>Events Management</span>
              </li>
            </NavLink>
          )}
          {loginData.userRoleName === "Admin" && (
            <NavLink
              to="/notifications"
              className={({ isActive }) => (isActive ? "active-sidebar" : "")}
            >
              <li className="alerts-icon">
                <span>Notifications Management</span>
              </li>
            </NavLink>
          )}
          {loginData.userRoleName === "Team Lead" && (
            <NavLink
              to="/notifications"
              className={({ isActive }) => (isActive ? "active-sidebar" : "")}
            >
              <li className="alerts-icon">
                <span>Notifications Management</span>
              </li>
            </NavLink>
          )}
          {/* {loginData.userRoleName === "Admin" && (
              <NavLink
                to="/groups"
                className={({ isActive }) => (isActive ? "active-sidebar" : "")}
              >
                <li className="setting-icon">
                  <span className="mt-1">Groups</span>
                </li>
              </NavLink>
            )} */}
          {/* {loginData.userRoleName === "Admin" && (
              <NavLink
                to="/Assignment"
                className={({ isActive }) => (isActive ? "active-sidebar" : "")}
              >
                <li className="assignment-icon">
                  <span>Assignments</span>
                </li>
              </NavLink>
            )} */}
          <NavLink
            to="/support"
            className={({ isActive }) => (isActive ? "active-sidebar" : "")}
          >
            <li className="support-icon">
              <span>Support</span>
            </li>
          </NavLink>
        </ul>
      </aside>
    </>
  );
};

const mapStateToProps = ({ usersList }) => ({
  headerusersInfo: usersList?.headerusersInfo,
  headerinfoLoading: usersList?.headerinfoLoading,
  headerphotoInfo: usersList?.headerphotoInfo,
  headerphotoLoading: usersList?.headerphotoLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchHeaderUsersPopUpInfo: (payload) =>
    dispatch(fetchHeaderUsersPopUpInfo(payload)),
  fetchHeaderUserProfiePhoto: (payload) =>
    dispatch(fetchHeaderUserProfiePhoto(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
