import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import sucess from "../../assets/images/new-images/sucess.png";
import {
  sendWelcomeEmails,
  fetchUsersForWelcomeEmail,
} from "../../actions/actions-users";

import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

const SendWelcomeEmailModal = ({ setBulkEmail }) => {
  const emailUsersLoading = useSelector(
    (state) => state?.usersList?.emailUsersLoading
  );
  const emailUsers = useSelector((state) => state?.usersList?.emailUsers);

  const [userEmail, setUserEmails] = useState([]);

  const [selectUserChecked, setSelectUserChecked] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const userSelect = (e) => {
    setSelectUserChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedUsers(
        userEmail.map((i) => {
          return {
            ...i,
            ischecked: e.target.checked,
          };
        })
      );
    } else if (e.target.checked === false) {
      setSelectedUsers([]);
    }
  };

  const userHandler = (e, val) => {
    if (e.target.checked) {
      setSelectedUsers([...selectedUsers, val]);
    } else if (e.target.checked === false) {
      setSelectUserChecked(false);
      setSelectedUsers(selectedUsers.filter((i) => i.userId !== val.userId));
    }
  };
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();
  const submitUsers = () => {
    dispatch(sendWelcomeEmails(selectedUsers?.map((u) => u.empId)));
    setSubmitted(!submitted);
    setSelectedUsers([]);
    setSelectUserChecked(false);
  };

  const successHandler = () => {
    setSubmitted(!submitted);
    setBulkEmail(false);
  };

  useEffect(() => {
    emailUsers?.length > 0 &&
      setUserEmails(
        emailUsers.map((item) => {
          return {
            ...item,
            ischecked: false,
          };
        })
      );
  }, [emailUsers]);

  const cancelUsers = () => {
    setSelectedUsers([]);
    setSelectUserChecked(false);
    setBulkEmail(false);
  };

  return (
    <div
      className="modal fade"
      id="bulkEmail"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      {!submitted ? (
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-l">
          <div className="modal-content sendMailBody">
            <div className="modal-header">
              <h5 className="modal-title fs-5">
                Send Bulk Email
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => cancelUsers()}
              ></button>
            </div>
            {emailUsersLoading ? (
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  top: "30%",
                  width: "100%",
                  height: "300PX",
                  justifyContent: "center",
                  alignItems: "start",
                  // background: "#000",
                  zIndex: 99,
                }}
              >
                <CircularProgress />
              </Box>
            ) : emailUsers && !emailUsers?.length > 0 ? (
              <p className="user-msg">No Users Found</p>
            ) : (
              <>
                <div className="modal-body sendMailBody">
                  <div className="s-all">
                    <p>
                      <strong>Select All</strong>
                    </p>

                    <input
                      type="checkbox"
                      name="userArr"
                      className="form-check-input"
                      checked={
                        selectUserChecked ||
                        selectedUsers.length === userEmail.length
                      }
                      onChange={(e) => userSelect(e)}
                    />
                  </div>
                  {emailUsers &&
                    emailUsers?.length > 0 &&
                    emailUsers.map((u) => (
                      <div className="all-check" key={u.userId}>
                        <p>
                          {u.userName}
                          <span> {u.email}</span>
                        </p>

                        <input
                          type="checkbox"
                          name="userArr"
                          className="form-check-input"
                          value={u.userId}
                          checked={selectedUsers
                            .map((ir) => ir.userId)
                            .includes(u.userId)}
                          onChange={(e) => userHandler(e, u)}
                        />
                      </div>
                    ))}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => cancelUsers()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    disabled={selectedUsers?.length === 0}
                    className="btn btn-genmeb ms-3"
                    onClick={() => submitUsers()}
                  >
                    Send
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="modal-dialog modal-dialog-centered modal-l">
          <div className="modal-content edit-success">
            <div className="modal-body text-center pt-0">
              {<img src={sucess} alt="icon" className="m-3" />}

              <p className="green-text">Success</p>

              <p>Request Submitted Sucessfully</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className={"btn btn-genmeb ms-3"}
                data-bs-dismiss="modal"
                onClick={successHandler}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SendWelcomeEmailModal;
