import React, { useState } from "react";
import Modal from "../UI/Modal";
import sucess from "../../assets/images/new-images/sucess.png";
import dltIcon from "../../assets/images/new-images/dlt.png";
import { connect } from "react-redux";
import * as actions from "../../actions/action-notifications";

const DeleteAlerts = ({
  fetchAlertByLoginId,
  deleteAlert,
  alertsDelete,
  deleteModal,
  ...props
}) => {
  const { alerts, setAlerts, deleteId, setDeleteSuccess } = props;

  const alertsDeleteHandler = () => {
    deleteModal(!alertsDelete);
  };

  return (
    <Modal
      modalId={"deleteAlertsModal"}
      modalLabel={"deleteAlertsModalLabel"}
      className={"dlt-modal"}
    >
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content">
          <div className="modal-body text-center pt-0">
            <img
              src={alertsDelete ? dltIcon : sucess}
              alt="icon"
              className="m-3"
            />

            {alertsDelete && <p className="dlt-hed">Are you sure ?</p>}

            <p className="dlt-body">
              {alertsDelete
                ? "Do you want to delete this record? Once deleted, this action cannot be undone"
                : "Notification Deleted Successfully"}
            </p>
          </div>
          <div className="modal-footer">
            {alertsDelete && (
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            )}

            {alertsDelete ? (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                onClick={() => deleteAlert(deleteId)}
              >
                Delete
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-dismiss="modal"
                onClick={alertsDeleteHandler}
              >
                Ok
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ alerts }) => ({
  alertsDelete: alerts.openDeleteModal,
});

const mapDispatchToProps = (dispatch) => ({
  deleteAlert: (id) => dispatch(actions.deleteAlert(id)),
  deleteModal: (val) => dispatch(actions.deleteModal(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAlerts);
