import React, { useEffect, useState } from "react";
import "../assets/css/myApp.css";
import "../assets/css/new-dashboard.css";
import SearchIcon from "../assets/images/search.svg";
import Circle from "../assets/images/new-images/add.png";
import deleteIcon from "../assets/images/new-images/delete.png";
import edit from "../assets/images/new-images/edit.png";
import Pagination from "../components/Pagination";
import DeleteUser from "../components/Users/DeleteUser";
import ActivateUsers from "../components/Users/ActivateUsers";
import AddUser from "../components/Users/AddUser";
import UpdateUser from "../components/Users/UpdateUser";
import axios from "axios";
import mmit from "../assets/images/new-images/t1.png";
import copy from "../assets/images/new-images/copy.svg";
import sortIcon from "../assets/images/new-images/sortIcon.svg";
import emailUs from "../assets/images/new-images/email-us.svg";
import locationIcon from "../assets/images/new-images/location-icon.svg";
import n1 from "../assets/images/new-images/placeholder.png";
import { connect } from "react-redux";
import {
  fetchUsersForWelcomeEmail,
  fetchUsersPopUpInfo,
  fetchUsersProfiePhoto,
} from "../actions/actions-users";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { setUsersData } from "../actions/actions-users";
import { useDebounce } from "use-debounce";
import sortIcon2 from "../assets/images/new-images/sortIcon3.png";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import { Loader } from "rsuite";
import { fetchAllApps } from "../actions/action-grouprule";
import { fetchAllUserGroups, fetchImages } from "../actions/action-master";
import SendWelcomeEmailModal from "../components/App/SendWelcomeEmailModal";
import AddBulkUser from "../components/Users/AddBulkUsers";
import {renderImageDataUrl} from "../middlewares/utils";
import moment from "moment/moment";
const { REACT_APP_BACKEND_API_APP } = process.env;

const Users = ({
  fetchUsersPopUpInfo,
  usersInfo = [],
  infoLoading,
  fetchUsersProfiePhoto,
  photoInfo = [],
  photoLoading,
}) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.usersData);
  const userGroupList = useSelector((state) => state.master.userGroups);
  const userRole = useSelector((state) => state?.master?.userRoles);
  const triggerRulesLoading = useSelector(
    (state) => state?.usersList?.triggerRulesLoading
  );

  const [usersArr, setUsersArr] = useState([]);
  useEffect(() => {
    dispatch(fetchAllApps());
    dispatch(fetchAllUserGroups());
  }, []);

  const [showAll, setShowAll] = useState(false);
  const [bulkEmail, setBulkEmail] = useState(false);
  const [inactiveItem, setInactiveItem] = useState(null);
  const [activeStatus, setActiveStatus] = useState("Active");
  const [searchValue, setSearchValue] = useState("");
  const [statusUserId, setStatusUserId] = useState(false);
  const [statusSuccess, setStatusSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [bulkAddSuccess, setBulkAddSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const appsData = useSelector((state) => state.groupsRule.apps);
  const groupRules = useSelector((state) => state.groupsRule.groupRules);
  const [offset, setOffset] = useState(1);
  const [data, setData] = useState(0);
  const [searchFilter, setSearchFilter] = useState(false);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [serachInput] = useDebounce(searchValue, 1000);
  const [searchPageNum, setSearchPageNum] = useState(1);
  const [loading, setLoading] = useState(false);
  let LoginUserId = sessionStorage.getItem("userId");
  LoginUserId = +LoginUserId;
  const emailResponseLoading = useSelector((state) => state?.usersList?.emailResponseLoading);

  useEffect(() => {
    let token = sessionStorage.getItem("accessToken2");
    let hideInactive = false;

    if (showAll) {
      hideInactive = true;
    }
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let pNum = "";

    if (serachInput !== "" && serachInput !== undefined) {
      setSearchFilter(true);
      pNum = searchPageNum;
      if (deleteSuccess && data % 10 === 1 && pageCount === searchPageNum) {
        pNum = searchPageNum - 1;
        setSearchPageNum(pNum);
      }
    } else {
      setSearchFilter(false);
      pNum = offset;
      if (deleteSuccess && data % 10 === 1 && pageCount === offset) {
        pNum = offset - 1;
        setOffset(pNum);
      }
    }
    if (hideInactive && pageCount < offset) {
      pNum = offset - 1;
      setOffset(pNum);
    }
    if (hideInactive && data % 10 === 1 && pageCount === offset) {
      pNum = offset - 1;
      setOffset(pNum);
    }
    setLoading(true);
    axios
      .get(
        `${REACT_APP_BACKEND_API_APP}/api/GetAllInvitedUserListByLoginUserId?PageNumber=${pNum}&PageSize=${perPage}&keyword=${encodeURIComponent(
          serachInput
        )}&includeInActive=${!showAll}`,
        config
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          let userList = response.data.responseData.data;
          setData(response.data.responseData.recordCount);
          setPageCount(
            Math.ceil(response.data.responseData.recordCount / perPage)
          );
          setAddSuccess(false);
          setUpdateSuccess(false);
          setStatusSuccess(false);
          setDeleteSuccess(false);
          setBulkAddSuccess(false);

          userList = userList.map((item) => {
            return {
              userRole:
                userRole?.length > 0
                  ? userRole?.filter(
                      (a) => a.id === parseInt(item.userRoleId)
                    )[0].value
                  : "",
              group: userGroupList
                .filter((a) => item.userGroupId.some((o2) => a.id === o2))
                .map((a) => a.value),
              groupSort: userGroupList
                .filter((a) => item.userGroupId.some((o2) => a.id === o2))
                .map((a) => a.value)
                .join(", "),
              date: new Date(item.createdAt),
              // profile: <Person userId={item.employeeId} />,
              ...item,
            };
          });
          dispatch(setUsersData({ userList: userList, pageCount: pageCount }));
          setUsersArr(userList ? userList : []);
        } else {
          dispatch(setUsersData({ userList: [], pageCount: 0 }));
          setUsersArr([]);
          setData(0);
          setPageCount(Math.ceil(0));
        }
        setLoading(false);
      });
  }, [
    // userGroupList,
    // groupRules,
    // pageCount,
    userRole,
    dispatch,
    serachInput,
    statusSuccess,
    deleteSuccess,
    updateSuccess,
    addSuccess,
    offset,
    perPage,
    REACT_APP_BACKEND_API_APP,
    searchPageNum,
    showAll,
    triggerRulesLoading,
    emailResponseLoading,
    bulkAddSuccess
  ]);

  const inActiveTest = (item, status, id) => {
    setInactiveItem(item);
    setActiveStatus(status);
    setStatusUserId(id);
  };

  const [deleteId, setDeleteId] = useState(null);

  const deleteClick = (userId) => {
    setDeleteId(userId);
  };

  const [editUserData, setEditUserData] = useState("");
  const editClick = (item) => () => {
    setEditUserData(item);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    if (serachInput !== "" && serachInput !== undefined) {
      setSearchPageNum(selectedPage + 1);
    } else {
      setOffset(selectedPage + 1);
    }
  };

  useEffect(() => {
    if (pageCount > data / 10) {
      setSearchPageNum(1);
    }
  }, [serachInput]);

  const [order, setOrder] = useState("ASC");
  const [ColName, setColName] = useState("");

  const sorting = (col) => {
    setColName(col);

    if (order === "ASC") {
      const sorted = [...usersArr].sort((a, b) => (a[col] > b[col] ? 1 : -1));

      setUsersArr(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...usersArr].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setUsersArr(sorted);
      setOrder("ASC");
    }
  };

  function copyToClip(str) {
    function listener(e) {
      e.clipboardData.setData("text/html", str);
      e.preventDefault();
    }
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
  }

  useEffect(() => {
    let empIds = usersArr?.map((e) => e.employeeId);
    usersArr?.length > 0 && fetchUsersPopUpInfo(empIds);
    usersArr?.length > 0 && fetchUsersProfiePhoto(empIds);
    // update user email list if anything changes in user array
    dispatch(fetchUsersForWelcomeEmail());
  }, [usersArr]);

  useEffect(() => {
    let imageNames = appsData?.map((a) => a.imageName);
    appsData?.length > 0 && dispatch(fetchImages(imageNames));
  }, [appsData]);

  const appPhotoInfo = useSelector((state) => state?.master.photoInfo);
  const appPhotoLoading = useSelector((state) => state?.master.photoLoading);

  const searchLoading = !loading && !infoLoading && !photoLoading;

  return (
    <>
      <AuthenticatedTemplate>
        <DeleteUser
          modalId={"DeleteeModalToggle7"}
          modalLabel={"exampleModalToggleLabel7"}
          deleteId={deleteId}
          setDeleteSuccess={setDeleteSuccess}
        />
        <ActivateUsers
          modalId={"exampleModalToggle12"}
          modalLabel={"exampleModalToggleLabel12"}
          inactiveItem={inactiveItem}
          activeStatus={activeStatus}
          setStatusSuccess={setStatusSuccess}
          userId={statusUserId}
        />
        <AddUser
          modalId={"addUser"}
          modalLabel={"exampleModalToggleLabel7"}
          setAddSuccess={setAddSuccess}
          addSuccess={addSuccess}
        />
        <AddBulkUser
          modalId={"bulkUser"}
          modalLabel={"exampleModalToggleLabel8"}
          setBulkAddSuccess={setBulkAddSuccess}
        />
        <UpdateUser
          modalId={"editUser"}
          modalLabel={"exampleModalLabel"}
          editUser={editUserData}
          setUpdateSuccess={setUpdateSuccess}
        />
        <SendWelcomeEmailModal  setBulkEmail={setBulkEmail}/>

        <div>
          <div className="app-header userheaderSet">
            <p className="app-heding">Users List</p>
            <div className="app-searching">
              {searchLoading && (
                <div className="relative w-100">
                  <img src={SearchIcon} alt="icon" />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(evnt) =>
                      setSearchValue(evnt.target.value.toLowerCase())
                    }
                  />
                </div>
              )}
            </div>
            <div className="applist-right-headbar">
              <div className="tg-btn">
                <p>Hide Inactive</p>
                <div className="togl-btn">
                  <label className="switch me-3 ms-3">
                    <input
                      type="checkbox"
                      checked={showAll}
                      onChange={() => setShowAll(!showAll)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="tg-btn">
                <p>Send Bulk Email</p>
                <div className="togl-btn">
                  <label
                    className="switch me-3 ms-3"
                    data-bs-toggle="modal"
                    data-bs-target="#bulkEmail"
                  >
                    <input
                      type="checkbox"
                      checked={bulkEmail}
                      onChange={() => setBulkEmail(true)}
                    />

                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <button
                className="btn btn-genmeb  min-w72"
                data-bs-toggle="modal"
                data-bs-target="#addUser"
              >
                <img src={Circle} alt="icon" className="me-2" /> Add New User
              </button>
              <button
                className="btn btn-genmeb"
                data-bs-toggle="modal"
                data-bs-target="#bulkUser"
              >
                <img src={Circle} alt="icon" className="me-2" /> Add Bulk Users
              </button>
            </div>
          </div>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                top: "50%",
                left: "0%",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "start",
                // background: "#000",
                zIndex: 99,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <div className="table-responsive scroll-show">
                <table className="table table-hover user-table">
                  <thead className="table-head">
                    <tr>
                      <th>Action</th>
                      <th>#</th>
                      <th
                        className={`${
                          ColName === "userName" ? "sort-highlight" : ""
                        }`}
                      >
                        Full Name
                        <span className="ms-2">
                          <img
                            src={
                              ColName === "userName" && order === "ASC"
                                ? sortIcon2
                                : sortIcon
                            }
                            alt="icon"
                            onClick={() => {
                              sorting("userName");
                            }}
                          />
                        </span>
                      </th>
                      <th
                      // className={`${
                      //   ColName === "email" ? "sort-highlight" : ""
                      // }`}
                      >
                        Apps
                        {/* <span className="ms-2">
                          <img
                            src={
                              ColName === "email" && order === "ASC"
                                ? sortIcon2
                                : sortIcon
                            }
                            alt="icon"
                            onClick={() => {
                              sorting("email");
                            }}
                          />
                        </span> */}
                      </th>
                      <th
                        className={`${
                          ColName === "userRole" ? "sort-highlight" : ""
                        }`}
                      >
                        Role
                        <span className="ms-2">
                          <img
                            src={
                              ColName === "userRole" && order === "ASC"
                                ? sortIcon2
                                : sortIcon
                            }
                            alt="icon"
                            onClick={() => {
                              sorting("userRole");
                            }}
                          />
                        </span>
                      </th>
                      <th
                        className={`${
                          ColName === "groupSort" ? "sort-highlight" : ""
                        }`}
                      >
                        Groups
                        <span className="ms-2">
                          <img
                            src={
                              ColName === "groupSort" && order === "ASC"
                                ? sortIcon2
                                : sortIcon
                            }
                            alt="icon"
                            onClick={() => {
                              sorting("groupSort");
                            }}
                          />
                        </span>
                      </th>
                      <th>Status</th>
                      <th
                        className={`${
                          ColName === "createdAt" ? "sort-highlight" : ""
                        }`}
                      >
                        Date Added
                        <span className="ms-2">
                          <img
                            src={
                              ColName === "createdAt" && order === "ASC"
                                ? sortIcon2
                                : sortIcon
                            }
                            alt="icon"
                            onClick={() => {
                              sorting("createdAt");
                            }}
                          />
                        </span>{" "}
                      </th>
                      <th
                          className={`${
                              ColName === "updatedAt" ? "sort-highlight" : ""
                          }`}
                      >
                        Last Modified Date
                        <span className="ms-2">
                          <img
                              src={
                                ColName === "updatedAt" && order === "ASC"
                                    ? sortIcon2
                                    : sortIcon
                              }
                              alt="icon"
                              onClick={() => {
                                sorting("updatedAt");
                              }}
                          />
                        </span>{" "}
                      </th>
                      <th
                          className={`${
                              ColName === "updatedBy" ? "sort-highlight" : ""
                          }`}
                      >
                        Last Modified By
                        <span className="ms-2">
                          <img
                              src={
                                ColName === "updatedBy" && order === "ASC"
                                    ? sortIcon2
                                    : sortIcon
                              }
                              alt="icon"
                              onClick={() => {
                                sorting("updatedBy");
                              }}
                          />
                        </span>{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersArr.length === 0 && !searchFilter ? (
                      <tr>
                        <td colSpan="8">No User Added</td>
                      </tr>
                    ) : searchFilter && usersArr.length === 0 ? (
                      <tr>
                        <td colSpan="8">No Match Found</td>
                      </tr>
                    ) : (
                      usersArr.map((item, index) => {
                        return (
                          <tr key={item.userId}>
                            <td>
                              <span className="d-flex">
                                <img
                                  src={edit}
                                  alt="icon"
                                  className="me-3"
                                  data-bs-toggle="modal"
                                  onClick={editClick(item)}
                                  data-bs-target="#editUser"
                                />
                                {LoginUserId !== item.userId && (
                                  <img
                                    src={deleteIcon}
                                    alt="icon"
                                    data-bs-toggle="modal"
                                    data-bs-target="#DeleteeModalToggle7"
                                    onClick={() => {
                                      deleteClick(item.userId);
                                    }}
                                  />
                                )}
                              </span>
                            </td>
                            <td>
                              {searchFilter
                                ? (searchPageNum > 0
                                    ? searchPageNum - 1
                                    : searchPageNum) *
                                    perPage +
                                  index +
                                  1
                                : (offset > 0 ? offset - 1 : offset) * perPage +
                                  index +
                                  1}
                            </td>
                            <td className="mainRelative">
                              <div className="d-flex align-items-center min-h-48">
                                {/* <img
                                  src={u1}
                                  alt="icon"
                                  className="me-2 customIcon"
                                /> */}
                                {photoLoading ? (
                                  <Loader className="loader-w" />
                                ) : photoInfo?.find(
                                    (b) => item.employeeId === b.employeeId
                                  )?.profilePhoto ? (
                                  <img
                                    className="user-pic  me-2"
                                    src={renderImageDataUrl(
                                      photoInfo?.find(
                                        (b) => item.employeeId === b.employeeId
                                      )?.profilePhoto
                                    )}
                                    alt="icon"
                                  />
                                ) : (
                                  <div className="up-img me-2">
                                    <span>
                                      {item.userName
                                        .match(/(^\S\S?|\b\S)?/g)
                                        .join("")
                                        .match(/(^\S|\S$)?/g)
                                        .join("")
                                        .toUpperCase()}
                                    </span>
                                  </div>
                                )}
                                <span>{item.userName}</span>
                              </div>

                              <div className="apphover w-300">
                                {infoLoading ? (
                                  <Loader />
                                ) : (
                                  <>
                                    <div className="userHeader">
                                      {photoInfo?.find(
                                        (b) => item.employeeId === b.employeeId
                                      )?.profilePhoto ? (
                                        <img
                                          className="user-pic usershowimg"
                                          src={renderImageDataUrl(
                                            photoInfo?.find(
                                              (b) =>
                                                item.employeeId === b.employeeId
                                            )?.profilePhoto
                                          )}
                                          alt="icon"
                                        />
                                      ) : (
                                        <div className="up-img usershowimg">
                                          <span>
                                            {usersInfo
                                              ?.find(
                                                (b) =>
                                                  item.employeeId ===
                                                  b.employeeId
                                              )
                                              ?.userName.match(
                                                /(^\S\S?|\b\S)?/g
                                              )
                                              .join("")
                                              .match(/(^\S|\S$)?/g)
                                              .join("")
                                              .toUpperCase()}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    <div className="userbodybox">
                                      <p className="user-n font-9">
                                        {
                                          usersInfo?.find(
                                            (b) =>
                                              item.employeeId === b.employeeId
                                          )?.userName
                                        }
                                      </p>
                                      <p className="user-d">
                                        <b>Role: </b>
                                        {
                                          usersInfo?.find(
                                            (b) =>
                                              item.employeeId === b.employeeId
                                          )?.userRole
                                        }
                                      </p>
                                      <p className="user-d">
                                        <b>Groups: </b>
                                        {item?.group?.length > 0
                                          ? usersInfo
                                              ?.find(
                                                (b) =>
                                                  item.employeeId ===
                                                  b.employeeId
                                              )
                                              ?.userGroups?.map((a) => a.value)
                                              .join(", ")
                                          : "N/A"}
                                      </p>
                                      <p className="user-d">
                                        <b>Business Title: </b>
                                        {usersInfo?.find(
                                          (b) =>
                                            item.employeeId === b.employeeId
                                        )?.jobTitle !== null
                                          ? usersInfo?.find(
                                              (b) =>
                                                item.employeeId === b.employeeId
                                            )?.jobTitle
                                          : "N/A"}
                                      </p>

                                      <p className="user-d">
                                        <b>Organization: </b>
                                        {usersInfo?.find(
                                          (b) =>
                                            item.employeeId === b.employeeId
                                        )?.companyName !== null
                                          ? usersInfo?.find(
                                              (b) =>
                                                item.employeeId === b.employeeId
                                            )?.companyName
                                          : "N/A"}
                                      </p>

                                      <p className="user-d">
                                        <b>Reporting Manager: </b>
                                        {usersInfo?.find(
                                          (b) =>
                                            item.employeeId === b.employeeId
                                        )?.manager !== null
                                          ? usersInfo?.find(
                                              (b) =>
                                                item.employeeId === b.employeeId
                                            )?.manager
                                          : "N/A"}
                                      </p>
                                    </div>
                                    <div className="bdr-top">
                                      <span className="useremail-bar pb-0">
                                        <img
                                          src={locationIcon}
                                          alt="user-pic"
                                          className="locationIcon"
                                        />
                                        <span className="ms-2">
                                          {usersInfo?.find(
                                            (b) =>
                                              item.employeeId === b.employeeId
                                          )?.location !== null
                                            ? usersInfo?.find(
                                                (b) =>
                                                  item.employeeId ===
                                                  b.employeeId
                                              )?.location
                                            : "N/A"}
                                        </span>
                                      </span>

                                      <span className="useremail-bar mb-2">
                                        <img
                                          src={emailUs}
                                          alt="user-pic"
                                          className="emailicon"
                                        />
                                        <span className="ms-2">
                                          {" "}
                                          {
                                            usersInfo?.find(
                                              (b) =>
                                                item.employeeId === b.employeeId
                                            )?.email
                                          }
                                        </span>
                                        <img
                                          src={copy}
                                          alt="icon"
                                          className="copyIcon g-bg"
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              usersInfo?.find(
                                                (b) =>
                                                  item.employeeId ===
                                                  b.employeeId
                                              )?.email
                                            );
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            </td>
                            <td className="mainRelative">
                              {item?.applications?.length > 0
                                ? item.applications
                                    ?.map((a) => a.value)
                                    .join(", ")
                                    .substr(0, 20) + "..."
                                : "None"}
                              <div className="apphover">
                                <div className="appheader">
                                  <h5>
                                    Apps
                                    {item.applications.length > 0 && (
                                      <img
                                        src={copy}
                                        alt="icon"
                                        className="copyIcon"
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            `${
                                              item.applications.length > 0 &&
                                              item.applications.map(
                                                (a) => `<li>${a.value}`
                                              )
                                            }`.replace(/(<([^>]+)>)/gi, "\n")
                                          );
                                        }}
                                      />
                                    )}
                                  </h5>
                                </div>
                                <div className="app-body">
                                  <div id="foo" style={{ display: "none" }}>
                                    <ul>
                                      {item.applications.length > 0 &&
                                        item.applications.map((a) => (
                                          <li key={a.id}>{a.value}</li>
                                        ))}
                                    </ul>
                                  </div>
                                  <ul>
                                    {photoLoading ? (
                                      <Loader />
                                    ) : appPhotoInfo?.length > 0 &&
                                      appsData?.length &&
                                      item.applications.length > 0 ? (
                                      item.applications.map((a) => (
                                        <li key={a.id}>
                                          <></>

                                          {appPhotoInfo?.find(
                                            (c) =>
                                              c.imageName ===
                                              appsData?.find(
                                                (b) => a.id === b.appId
                                              )?.imageName
                                          ) ? (
                                            appPhotoInfo?.find(
                                              (c) =>
                                                c.imageName ===
                                                appsData?.find(
                                                  (b) => a.id === b.appId
                                                )?.imageName
                                            )?.imageBase64String === null ||
                                            appPhotoInfo?.find(
                                              (c) =>
                                                c.imageName ===
                                                appsData?.find(
                                                  (b) => a.id === b.appId
                                                )?.imageName
                                            )?.imageBase64String === "" ? (
                                              <img
                                                src={n1}
                                                alt="icon"
                                                className="popicon"
                                              />
                                            ) : (
                                              <img
                                                src={renderImageDataUrl(
                                                  appPhotoInfo?.find(
                                                    (c) =>
                                                      c.imageName ===
                                                      appsData?.find(
                                                        (b) => a.id === b.appId
                                                      )?.imageName
                                                  )?.imageBase64String
                                                )}
                                                alt="icon"
                                                className="popicon"
                                              />
                                            )
                                          ) : (
                                            <img
                                              src={n1}
                                              alt="icon"
                                              className="popicon"
                                            />
                                          )}

                                          {a.value}
                                        </li>
                                      ))
                                    ) : (
                                      <li>None</li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </td>
                            <td>{item.userRole}</td>
                            <td>
                              {item?.group?.length > 0 ? item.groupSort : "N/A"}
                            </td>
                            <td className="togl-btn">
                              {LoginUserId !== item.userId && (
                                <>
                                  <span
                                    className={`w33 ${
                                      item.isActive === true ? "green" : "red"
                                    }`}
                                  >
                                    {item.isActive ? "Active" : "Inactive"}
                                  </span>
                                  <label
                                    className="switch me-3 ms-3"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModalToggle12"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={
                                        item.isActive === true ? true : false
                                      }
                                      onChange={() => {
                                        console.log("");
                                      }}
                                      onClick={() => {
                                        inActiveTest(
                                          item,
                                          item.isActive,
                                          item.userId
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </>
                              )}
                            </td>
                            <td>
                              {item.date
                                ? item.date.getMonth() +
                                  1 +
                                  "/" +
                                  item.date.getDate() +
                                  "/" +
                                  item.date.getFullYear()
                                : ""}
                            </td>
                            <td>
                              {item.updatedAt ? moment(item.updatedAt).local().format("MM/DD/YYYY") : "N/A"}
                            </td>
                            <td>
                              {item.updatedBy ? item.updatedBy : "N/A"}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              {!infoLoading && !photoLoading && (
                <Pagination
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  setOffset={searchFilter ? setSearchPageNum : setOffset}
                  pageNum={searchFilter ? searchPageNum : offset}
                  count={data}
                  perPage={perPage}
                />
              )}
            </>
          )}
        </div>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <center className="center-content">
          You do not have access to the Launchpad application. To request access
          to Launchpad, or believe you should already have access to Launchpad,
          please reach out to the Launchpad support team through the following
          email: BIS@genmab.com.
        </center>
      </UnauthenticatedTemplate>
    </>
  );
};

const mapStateToProps = ({ usersList }) => ({
  usersInfo: usersList?.usersInfo,
  infoLoading: usersList?.infoLoading,
  photoInfo: usersList?.photoInfo,
  photoLoading: usersList?.photoLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersPopUpInfo: (payload) => dispatch(fetchUsersPopUpInfo(payload)),
  fetchUsersProfiePhoto: (payload) => dispatch(fetchUsersProfiePhoto(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
