import React, { useState } from "react";
import {
  Calendar as BigCalendar,
  Views,
  momentLocalizer,
} from "react-big-calendar";
import _ from "lodash";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
const localizer = momentLocalizer(moment);

const CustomToolbar = (props) => {
  const [viewState, setViewState] = useState("day");

  function addMonths(date, months) {
    const d = date.getDate();
    date.setMonth(date.getMonth() + months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }

  function addWeeks(date, weeks) {
    date.setDate(date.getDate() + 7 * weeks);
    return date;
  }

  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  const goToDayView = () => {
    props.onView("day");
    setViewState("day");
  };
  const goToWeekView = () => {
    props.onView("week");
    setViewState("week");
  };
  const goToMonthView = () => {
    props.onView("month");
    setViewState("month");
  };

  const goToBack = () => {
    if (viewState === "month") {
      props.onNavigate("prev", addMonths(props.date, -1));
    } else if (viewState === "week") {
      props.onNavigate("prev", addWeeks(props.date, -1));
    } else {
      props.onNavigate("prev", addDays(props.date, -1));
    }
  };

  const goToNext = () => {
    if (viewState === "month") {
      props.onNavigate("next", addMonths(props.date, +1));
    } else if (viewState === "week") {
      props.onNavigate("next", addWeeks(props.date, +1));
    } else {
      props.onNavigate("next", addDays(props.date, +1));
    }
  };

  const goToToday = () => {
    const now = new Date();
    props.date.setMonth(now.getMonth());
    props.date.setYear(now.getFullYear());
    props.date.setDate(now.getDate());
    props.onNavigate("current");
  };

  var startDate = moment(props.date).startOf("week");
  var endDate = moment(props.date).endOf("week");

  return (
    <div className="calendarHeader">
      <div className="calendar-LeftBtngroup">
        
        <button onClick={goToToday}>Today</button>
       
      </div>
      <div className="calendar-LeftBtngroup">
      <button onClick={goToBack}>&#8249;</button>
        <label>
          {viewState === "month" && moment(props.date).format("MMMM - YYYY")}
          {viewState === "day" && moment(props.date).format("MMMM Do YYYY")}
          {viewState === "week" &&
            moment(props.date).format("MMMM") +
              " " +
              startDate.format("DD") +
              "-" +
              endDate.format("DD")}
        </label>
        <button onClick={goToNext}>&#8250;</button>
      </div>
      <div className="calendar-RightBtngroup">
        <button className={`${viewState === "day"  ? "calActive" : ""}`} onClick={goToDayView}>
          Day
        </button>

        <button className={`${viewState === "week"  ? "calActive" : ""}`} onClick={goToWeekView}>Week</button>
        <button className={`${viewState === "month"  ? "calActive" : ""}`} onClick={goToMonthView}>Month</button>
      </div>
    </div>
  );
};

export default CustomToolbar;
