import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/new-dashboard.css";
import brdCum from "../assets/images/new-images/brd-cum-icon.svg";
import tHide from "../assets/images/new-images/cal-toggle.png";
import tShow from "../assets/images/new-images/cal-toggle-b.png";
import QuickLinks from "../components/Dashboard/QuickLinks";
import { DashboardLayout } from "../components/dashboard-layout";
import AlertsDashboard from "../components/Dashboard/Alerts/AlertsDashboard";
import DashboardCalender from "../components/Dashboard/DashboardCalender";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import CircularProgress from "@mui/material/CircularProgress";
import {Badge, Box} from "@mui/material";
import { fetchDashboarData } from "../actions/actions-users";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchHeaderUserProfiePhoto,
  fetchHeaderUsersPopUpInfo,
} from "../actions/actions-users";
import useMediaQuery from "../components/Calendar/useMediaQuery";
import {fetchDashboardEvents} from "../actions/action-event";

function NewDashboard({
  empId,
  fetchDashboarData,
  dashLoading,
  dashData = {},
  tokenAcquired,
  dashError,
  fetchHeaderUserProfiePhoto,
  fetchHeaderUsersPopUpInfo, fetchDashboardEvents,
}) {
  const isDesktop = useMediaQuery("(min-width: 1023px)");
  const { instance, accounts, inProgress } = useMsal();
  const dashAppsLoading = useSelector(
    (state) => state.usersList.dashAppsLoading
  );
  const unreadCalendarEvents = useSelector((state) => state.event.dashEvents.filter(event => !event.isRead))
  const activeAccount = instance?.getActiveAccount();
  const navigate = useNavigate();

  const [isAdd, setIsAdd] = useState(false);
  const [activateCalendar, setActivateCalendar] = useState(false);
  const handleClick = (event) => {
    setIsAdd((current) => !current);
    let value = name;

    if (value === tHide) {
      setName(tShow);
    } else {
      setName(tHide);
    }
    setActivateCalendar(!activateCalendar);
  };
  const [name, setName] = useState(tHide);

  useEffect(() => {
    window.onunload = function () {
      sessionStorage.removeItem("dataLoad");
      sessionStorage.removeItem("photoLoad");
    };

    if (tokenAcquired && activeAccount) {
      fetchDashboarData();
      fetchDashboardEvents();
    }
    if (dashError) {
      navigate("/error-page");
    }
  }, [tokenAcquired, dashError]);

  const loginData = useSelector((state) => state?.usersList?.dashData);
  let dataLoad = sessionStorage?.getItem("dataLoad");
  const userEmpId = sessionStorage.getItem("userEmpId");

  useEffect(() => {
    let empIds =
      userEmpId && userEmpId !== null ? userEmpId : loginData?.employeeId;
    dataLoad &&
      loginData &&
      Object.keys(loginData).length > 0 &&
      fetchHeaderUserProfiePhoto([empIds]);
    dataLoad &&
      loginData &&
      Object.keys(loginData).length > 0 &&
      fetchHeaderUsersPopUpInfo([empIds]);
  }, [dataLoad]);

  return (
    <>
      <AuthenticatedTemplate>
        {dashLoading && sessionStorage.getItem("dataLoad") === null ? (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              background: "#fff",
              zIndex: 999999,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          ""
        )}
        {tokenAcquired && (
          <DashboardLayout empId={empId}>
            <>
              <div className="breadcrumb dashbord-togl">
                <div className="algin-center">
                  <img src={brdCum} alt="logo" />
                  <p> |&nbsp;&nbsp; Dashboard </p>
                </div>
                <button className="toggle-button" onClick={handleClick}>
                  <Badge sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: '#06a894',
                      color: '#fff'
                    }
                  }} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }} badgeContent={unreadCalendarEvents.length} invisible={unreadCalendarEvents.length === 0}>
                  </Badge>
                  <img src={name} alt="toggle-icon"/>
                </button>
              </div>
              <div className="main-box">
                <div className={isAdd ? "grid-70" : "w-100"}>
                  {dashAppsLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        position: "absolute",
                        top: `${isDesktop ? "0%" : "-5%"}`,
                        width: "100%",
                        height: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                        // background: "#fff",
                        zIndex: 99999,
                      }}
                    >
                      <CircularProgress size="2rem" />
                    </Box>
                  ) : (
                    ""
                  )}
                  <QuickLinks onAdd={isAdd} />
                  <AlertsDashboard onAdd={isAdd} empId={empId} />
                </div>
                <div className={isAdd ? "grid-2" : "d-none"}></div>
                <div className={isAdd ? "grid-30 " : "none-box"}>
                  {(isDesktop ? activateCalendar : <></>) && (
                    <DashboardCalender />
                  )}
                </div>
              </div>
            </>
          </DashboardLayout>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <center className="center-content">loading...</center>
      </UnauthenticatedTemplate>
    </>
  );
}

const mapStateToProps = ({ usersList }) => ({
  dashData: usersList?.dashData,
  dashLoading: usersList?.dashLoading,
  dashError: usersList?.dashError,
});
const mapDispatchToProps = (dispatch) => ({
  fetchDashboardEvents: () => dispatch(fetchDashboardEvents()),
  fetchDashboarData: () => dispatch(fetchDashboarData()),
  fetchHeaderUsersPopUpInfo: (payload) =>
    dispatch(fetchHeaderUsersPopUpInfo(payload)),
  fetchHeaderUserProfiePhoto: (payload) =>
    dispatch(fetchHeaderUserProfiePhoto(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NewDashboard);
