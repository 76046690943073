import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDropzone } from 'react-dropzone';
import Modal from "../UI/Modal";
import Download from "../../assets/images/circle-down.svg"
import Check from "../../assets/images/check.svg"
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "rsuite";
import Upload from "../../assets/images/document-upload.svg"
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {resetBulkUsersState, uploadBulkUsers, uploadBulkUsersValidate} from "../../actions/actions-users";
import { Alert, AlertTitle } from "@mui/material";
import UploadErrorTable from "./UploadErrorTable";
import ExcelJS from 'exceljs';
import {
  BULK_USER_CREATE_ERROR,
  BULK_USER_VALIDATION_ERRORS,
  INVALID_BULK_UPLOAD_FILE_FORMAT,
  USER_ROLES
} from "../../constants";
import {joinArrayByComma, renderStringsFromArray} from "../../middlewares/utils";


const AddBulkUser = (props) => {
  const { modalId, modalLabel, setBulkAddSuccess } = props;
  //Validation
  const loadingValidation = useSelector((state) => state.usersList.bulkValidateLoading);
  const validateStatusCodeRedux = useSelector((state) => state.usersList.bulkValidateStatusCode);
  const validateResponseMessage = useSelector((state) => state.usersList.bulkValidateResponse);
  const validateResponseData = useSelector((state) => state.usersList.bulkValidateResponseData);
  //Uploading
  const loadingUserUpload = useSelector((state) => state.usersList.bulkUploadLoading);
  const uploadStatusCodeRedux = useSelector((state) => state.usersList.bulkUploadStatusCode);
  const uploadResponseMessage = useSelector((state) => state.usersList.bulkUploadResponse);
  const uploadResponseData = useSelector((state) => state.usersList.bulkUploadResponseData);

  const [uploadedExcelFileContent, setUploadedExcelFileContent] = useState(0);
  const [exceptionWithExcelfile, setExceptionWithExcelfile] = useState('');
  const [userUploadStep, setUserUploadStep] = useState(1)
  const [ acceptedFiles, setAcceptedFiles ] = useState([]);
  const dispatch = useDispatch();
  const modalRef = useRef(null);


  useEffect(() => {
    if(uploadStatusCodeRedux === 200) {
      setBulkAddSuccess(true)
    }
  }, [uploadStatusCodeRedux]);

  useEffect(() => {
    if(uploadedExcelFileContent.length > 0) {
      setDataforValidationRequest()
    }
  }, [uploadedExcelFileContent]);

  const setDataforValidationRequest = () => {
    const req = {
      users: []
    };
    uploadedExcelFileContent.map((user, i) => {
      req.users.push({
        email: user?.[0],
        userName: user?.[1],
        groups: parseUserGroups(user?.[2]),
        roleName: user?.[3],
        rowNumber: i
      })
    })
    dispatch(uploadBulkUsersValidate(req));
  }

  const parseExcelFile = (file) => {
    const reader = new FileReader()

    reader.onload = async (e) => {
      try {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(e.target.result);
        const worksheet = workbook.getWorksheet(1);
        const userData = [];

        worksheet.eachRow((row, rowNumber) => {
          if(rowNumber === 1) return;
          const rowData = [];
          row.eachCell({includeEmpty: true},(cell, colNumber) => {
            //Read the best possible representation as text

            let plainText = cell.text;
            if (plainText.richText) {
              // Convert rich text to plain text
              plainText = plainText.richText.map(segment => segment.text).join('');
            }

            rowData.push(plainText);
          });
          userData.push(rowData);
        });

        setUploadedExcelFileContent(userData);
        setExceptionWithExcelfile('')
      } catch (error) {
        setAcceptedFiles([]);
        setExceptionWithExcelfile(INVALID_BULK_UPLOAD_FILE_FORMAT)
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const onDrop = useCallback(acceptedFiles => {
    setAcceptedFiles(acceptedFiles)
    parseExcelFile(acceptedFiles[0])
  }, [])

  const submitUsers = () => {
    dispatch(uploadBulkUsers(acceptedFiles[0]));
    setAcceptedFiles([]);
  };

  useEffect(() => {
    const modalElement = modalRef.current;

    const handleModalHidden = () => {
      dispatch(resetBulkUsersState());
      setUserUploadStep(1);
      setExceptionWithExcelfile('');
      setAcceptedFiles([]);
    };

    if (modalElement) {
      modalElement.addEventListener('hidden.bs.modal', handleModalHidden);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
      }
    };
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':  ['.xlsx']
    }
  });

  const continueToNextStep = () => {
    if(userUploadStep === 1) { // if user is on first step simply show the second page
      setUserUploadStep(2)
    } else if(userUploadStep === 2) { // if user is on second step move to step 3 and submit the file for upload
      submitUsers();
      setUserUploadStep(3)
    }
  }

  const getInvalidUsers = () => {
    return validateResponseData.users.filter(user => user.validationErrors.length > 0).length;
  }

  const getValidUsers = () => {
    return validateResponseData.users.filter(user => user.validationErrors.length === 0).length;
  }

  const replaceFile = () => {
    setAcceptedFiles([]);
  }

  const parseUserGroups = (groups) => {
    return groups.split(/\s*,\s*/).map(s => s.trim())
  };

  const handleGenerateExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    worksheet.addRow(['Full Name', 'Email', 'Groups', 'Role'], 'Issues found');
    validateResponseData?.users.map((user) => {
      if(user?.validationErrors.length > 0){
        worksheet.addRow([user?.userName, user?.email, joinArrayByComma(user?.groups), USER_ROLES[user?.userRoleId - 1], renderStringsFromArray(BULK_USER_VALIDATION_ERRORS, user?.validationErrors)]);
      }
    })

    const blob = await workbook.xlsx.writeBuffer();

    const url = window.URL.createObjectURL(new Blob([blob]));
    const a = document.createElement('a');
    a.href = url;
    a.download = "invalid-bulk-upload-users.xlsx";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <Modal
        modalId={modalId}
        modalLabel={modalLabel}
        className={"addUserModal"}
        modalRef={modalRef}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-l"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Add Bulk Users
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body" style={ { paddingBottom: '1.88rem', minHeight: '35.75rem' } }>

              <>
              {/* Step 1 Header */}
              {(userUploadStep === 1) && (
              <div className="d-flex align-items-center justify-content-between">
                <Typography sx={ {
                  color: '#626D8A',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '171.429%',
                  letterSpacing: '0.28px',
                }}>Import file</Typography>
                <a href="./test-sheet.xlsx" download={'test-sheet.xlsx'} className="download-template">
                  <img src={Download} alt="Download" />
                  Download template
                </a>
              </div>)}
              {/* Step 1 Header Ends */}

              {/* Step 1 Sceanario: Show dropzone, no file selected yet */}
              { userUploadStep === 1 && !loadingValidation && acceptedFiles.length === 0 && (<Box
                  sx={{
                    cursor: 'pointer',
                    marginTop: '1.25rem',
                    borderRadius: '8px',
                    height: '28.875rem',
                    border: '1px solid #D9D9D9',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',

                    '& .MuiTypography-root': {
                      fontSize: '1rem',
                      letterSpacing: 'normal',
                      lineHeight: ' normal',
                      fontWeight: 400,
                      '& span': {
                        color: '#06A894',
                        fontFamily: "Manrope",
                      }
                    },
                    ...(isDragActive && { backgroundColor: '#F8FAFF' })
                  }}
                  {...getRootProps()}
              >
                <input {...getInputProps()} />
                <img src={Upload} alt="" />
                <Typography sx={{marginTop: '1rem'}}>Drag and drop XLS / XLSX file</Typography>
                <Typography sx={{ marginTop: '0.25rem' }}>
                  or <Typography component='span'>Browse from computer</Typography>
                </Typography>
              </Box>)}

              {/* Step 1 Sceanario: Show dropzone, no file selected yet ends */}

              {/* Step 1 Sceanario: File is being validated */}
              {(userUploadStep === 1 && loadingValidation) && (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1.25rem',
                  borderRadius: '8px',
                  border: '1px solid #D9D9D9',
                  height: '28.875rem',
                }}>
                  <Loader className="primary-loader" />
                  <Typography sx={{fontSize: '1rem', color: '#626D8A', mt: 2}}>
                    Importing file
                  </Typography>
                </Box>
              )}
              {/* Step 1 Sceanario: File is being validated ends*/}

              {/* Step 1 Sceanario: File has been successfully validated, option to replace here */}
              { userUploadStep === 1 && !loadingValidation && acceptedFiles.length === 1 && (
                  <ul className="uploaded-files secondary">
                    <li key={acceptedFiles[0]?.path}>
                      <Box flexGrow={1}>
                        <p title={acceptedFiles[0]?.path}>{ acceptedFiles[ 0 ]?.name }</p>
                        <button onClick={replaceFile}>Replace file</button>
                      </Box>
                      <img src={Check} alt="" />
                    </li>
                  </ul>
              )}
              </>
              {/* Step 1 Scenario: File has been successfully validated ends */}

              { userUploadStep === 2 && (
                  <>
                    { getValidUsers() > 0 && (
                    <Alert sx={ {
                      borderRadius: '8px',
                      mb: 2,
                      padding: '1rem',
                      gap: '12px',
                      border: '1px solid #6CE9A6',
                      background: '#F6FEF9',

                      '& .MuiAlert-icon': {
                        padding: 0,
                        margin: 0,
                        color: '#027A48',

                        '& svg': {
                          fontSize: '20px'
                        }
                      },

                      '& .MuiAlert-message': {
                        padding: 0,

                        '& .MuiTypography-root': {
                          marginTop: '0.25rem',
                          fontFamily: 'Manrope',
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#027A48',
                          lineHeight: '142.857%',
                        },

                        '& .MuiAlertTitle-root': {
                          fontFamily: "Manrope SemiBold",
                          margin: 0,
                          color: '#027A48',
                          fontWeight: 600,
                        }
                      }
                    }} icon={<CheckCircleOutlineOutlinedIcon />} variant="outlined" severity="success">
                      <AlertTitle>{getValidUsers()}/{validateResponseData.users.length} users will be added.</AlertTitle>
                      <Typography sx={ {
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '171.429%',
                        letterSpacing: '0.28px',
                      }}>Validation was successful for {getInvalidUsers() > 0 ? "some of" : "all"} the users in the uploaded file. Click Continue to import them.</Typography>
                    </Alert>)}

                    { getInvalidUsers() > 0 && (
                    <>
                    <Alert sx={ {
                      borderRadius: '8px',
                      mb: 2,
                      padding: '1rem',
                      gap: '12px',
                      border: '1px solid #FDA29B',
                      background: '#FFFBFA',

                      '& .MuiAlert-icon': {
                        padding: 0,
                        margin: 0,
                        color: '#B42318',

                        '& svg': {
                          fontSize: '20px'
                        }
                      },

                      '& .MuiAlert-message': {
                        padding: 0,

                        '& .MuiTypography-root': {
                          marginTop: '0.25rem',
                          fontFamily: 'Manrope',
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#B42318',
                          lineHeight: '142.857%',
                        },

                        '& .MuiAlertTitle-root': {
                          margin: 0,
                          fontFamily: "Manrope SemiBold",
                          color: '#B42318',
                          fontWeight: 600,
                        }
                      }
                    }} icon={<ErrorOutlineOutlinedIcon />} variant="outlined" severity="error">
                      <AlertTitle>{getInvalidUsers()}/{validateResponseData.users.length} users cannot be imported.</AlertTitle>
                      <Typography>These users encountered errors when importing. Try importing these users again.</Typography>
                    </Alert>
                    <div className="d-flex align-items-center justify-content-between">
                      <Typography sx={ {
                        color: '#626D8A',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '171.429%',
                        letterSpacing: '0.28px',
                      }}>Users that can’t be imported</Typography>
                      <button className="download-template" onClick={handleGenerateExcel}>
                        <img src={Download} alt="Download" />
                        Download users that can’t be imported (.xlsx)
                      </button>
                    </div>
                    <UploadErrorTable users={validateResponseData.users} />
                    </>)}

                    { validateResponseData.newUserGroups.length > 0 && (
                    <Alert sx={ {
                      borderRadius: '8px',
                      mt: 2,
                      padding: '1rem',
                      gap: '12px',
                      border: '1px solid #6CE9A6',
                      background: '#F6FEF9',

                      '& .MuiAlert-icon': {
                        padding: 0,
                        margin: 0,
                        color: '#027A48',

                        '& svg': {
                          fontSize: '20px'
                        }
                      },

                      '& .MuiAlert-message': {
                        padding: 0,

                        '& .MuiTypography-root': {
                          marginTop: '0.25rem',
                          fontFamily: 'Manrope',
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#027A48',
                          lineHeight: '142.857%',
                        },

                        '& .MuiAlertTitle-root': {
                          margin: 0,
                          fontFamily: "Manrope SemiBold",
                          color: '#027A48',
                          fontWeight: 600,
                        }
                      }
                    }} icon={<CheckCircleOutlineOutlinedIcon />} variant="outlined" severity="success">
                      <AlertTitle>The following groups will be created:</AlertTitle>
                      <Typography>{joinArrayByComma(validateResponseData.newUserGroups)}</Typography>
                    </Alert>)}

                  </>)}

              {(userUploadStep === 3 && loadingUserUpload) && (
                  <>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '1.25rem',
                    height: '28.875rem',
                  }}>
                    <Loader className="primary-loader" />
                    <Typography sx={{fontSize: '1rem', color: '#626D8A', mt: 2}}>
                      Creating users
                    </Typography>
                  </Box>
                  </>
              )}

              { userUploadStep === 3 && uploadStatusCodeRedux === 200 && (
                  <>
                    <Alert sx={ {
                      borderRadius: '8px',
                      mb: 2,
                      padding: '1rem',
                      gap: '12px',
                      border: '1px solid #6CE9A6',
                      background: '#F6FEF9',

                      '& .MuiAlert-icon': {
                        padding: 0,
                        margin: 0,
                        color: '#027A48',

                        '& svg': {
                          fontSize: '20px'
                        }
                      },

                      '& .MuiAlert-message': {
                        padding: 0,

                        '& .MuiTypography-root': {
                          marginTop: '0.25rem',
                          fontFamily: 'Manrope',
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#027A48',
                          lineHeight: '142.857%',
                        },

                        '& .MuiAlertTitle-root': {
                          fontFamily: "Manrope SemiBold",
                          margin: 0,
                          color: '#027A48',
                          fontWeight: 600,
                        }
                      }
                    }} icon={<CheckCircleOutlineOutlinedIcon />} variant="outlined" severity="success">
                      <AlertTitle>{uploadResponseData.users.length}/{getValidUsers()} users have been created successfully.</AlertTitle>
                    </Alert>
                    { uploadResponseData.newUserGroups.length > 0 && (
                    <Alert sx={ {
                      borderRadius: '8px',
                      mt: 2,
                      padding: '1rem',
                      gap: '12px',
                      border: '1px solid #6CE9A6',
                      background: '#F6FEF9',

                      '& .MuiAlert-icon': {
                        padding: 0,
                        margin: 0,
                        color: '#027A48',

                        '& svg': {
                          fontSize: '20px'
                        }
                      },

                      '& .MuiAlert-message': {
                        padding: 0,

                        '& .MuiTypography-root': {
                          marginTop: '0.25rem',
                          fontFamily: 'Manrope',
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#027A48',
                          lineHeight: '142.857%',
                        },

                        '& .MuiAlertTitle-root': {
                          margin: 0,
                          fontFamily: "Manrope SemiBold",
                          color: '#027A48',
                          fontWeight: 600,
                        }
                      }
                    }} icon={<CheckCircleOutlineOutlinedIcon />} variant="outlined" severity="success">
                      <AlertTitle>The following groups have been created:</AlertTitle>
                      <Typography>{joinArrayByComma(uploadResponseData.newUserGroups)}</Typography>
                    </Alert>)}

                    { getInvalidUsers() > 0 && (
                    <div className="d-flex align-items-center justify-content-end">
                      <button className="download-template" onClick={handleGenerateExcel}>
                        <img src={Download} alt="Download" />
                        Download users that can’t be imported (.xlsx)
                      </button>
                    </div>)}
                  </>)}

            </div>

            <div className="modal-footer">
              <div className="flex-grow-1 d-flex align-items-center">
                <ul className="d-flex align-items-center">
                  <li className={userUploadStep === 1 && "active"}></li>
                  <li className={userUploadStep === 2 && "active"}></li>
                  <li className={userUploadStep === 3 && "active"}></li>
                </ul>
                {/* Step 1 Sceanario: File could not be validated error message */}
                {(userUploadStep === 1 && validateStatusCodeRedux !== 200 && !loadingValidation) && <p>{validateResponseMessage}</p>}
                {/* Step 1 Sceanario: File could be send to validate error */}
                {(userUploadStep === 1 && exceptionWithExcelfile && !loadingValidation) && <p>{exceptionWithExcelfile}</p>}
                {/* Step 1 Sceanario: File could not be validated error message */}
                {(userUploadStep === 3 && uploadStatusCodeRedux !== 200 && !loadingUserUpload) && <p>{BULK_USER_CREATE_ERROR}</p>}
              </div>
             { userUploadStep === 3 && uploadStatusCodeRedux === 200 ?
              <button
              type="button"
              className={`btn btn-genmeb ms-3`}
              data-bs-dismiss="modal"
            >
              Done
            </button> :
            <>
             <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className={`btn btn-genmeb ms-3 ${acceptedFiles.length === 0 || ( acceptedFiles.length === 1 && validateStatusCodeRedux !== 200) || ( userUploadStep === 2 && validateStatusCodeRedux === 200 && getValidUsers() == 0)? 'disabled' : ''}`}
                onClick={continueToNextStep}
              >
                Continue
              </button>
              </>
            }
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AddBulkUser;