import React, { useEffect } from "react";

const AddDepartment = ({ depts, deptsInputIsInvalid }) => {
  return (
    <div className="mb-3 relative-box">
      <label className="form-label">
        Department<sup>*</sup>
      </label>
      <input
        data-bs-target="#departmentOpen"
        data-bs-toggle="modal"
        style={{ caretColor: "transparent" }}
        type="text"
        className="form-control arow dropdown-toggle"
        placeholder="Select Department"
        value={
          depts &&
          depts
            .filter((a) => a.ischecked === true)
            .map((item) => item.value)
            .join(", ")
        }
        onChange={() => {
          console.log();
        }}
        name="deptNames"
      />

      {deptsInputIsInvalid && (
        <p className="e-message">Please select a Department</p>
      )}
    </div>
  );
};

export default AddDepartment;
