import Modal from "../UI/Modal";
import React, { useState } from "react";
import sucess from "../../assets/images/new-images/sucess.png";
import active from "../../assets/images/new-images/active.png";
import inactive from "../../assets/images/new-images/inactive.png";
import axios from "axios";
import { fetchAllUserApps } from "../../actions/actions-users";
import { useDispatch } from "react-redux";

const { REACT_APP_BACKEND_API_APP } = process.env;

const ActivateApps = (props) => {
  const {
    modalId,
    modalLabel,
    inactiveItem,
    activeStatus,
    setStatusSuccess,
    appId,
  } = props;
  const dispatch = useDispatch();
  const [appsInactive, setAppsInactive] = useState(false);
  const inactiveHandler = (inactiveItem, status) => {
    let isActive = activeStatus === true ? false : true
    let token = sessionStorage.getItem('accessToken2')
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    };

    axios
      .post(`${REACT_APP_BACKEND_API_APP}/api/UpdateApplicationStatusByAppId?appId=${appId}&status=${isActive}`,{}, config)
      .then(async (res) => {
        if (res.data.isSuccess === true) {
          setStatusSuccess(true);
          setAppsInactive(!appsInactive);
          dispatch(fetchAllUserApps())
        }
      });

  };

  const appsInactiveHandler = () => {
    setAppsInactive(!appsInactive);
  };

  return (
    <Modal modalId={modalId} modalLabel={modalLabel} className={"addUserModal"}>
      <div className="modal-dialog modal-dialog-centered modal-sm ">
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title"> {activeStatus === true
              ? !appsInactive
                ? "Deactivate App"
                : "Success"
              : !appsInactive
                ? "Activate App"
                : "Success"}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-center pt-0">
            {activeStatus === true ? (
              <img
                src={!appsInactive ? inactive : sucess}
                alt="icon"
                className="m-3"
              />
            ) : (
              <img
                src={!appsInactive ? active : sucess}
                alt="icon"
                className="m-3"
              />
            )}
            {/* {!appsInactive && <p className="dlt-hed">Are you sure ?</p>} */}
            <p className="d-text">
              {activeStatus === true
                ? !appsInactive
                  ? "Are you sure you want to deactivate the App?"
                  : "App Deactivated Successfully"
                : !appsInactive
                  ? "Are you sure you want to Activate the  App?"
                  : "App Activated Successfully"}
            </p>


          </div>
          <div className="modal-footer">
            {!appsInactive && (
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            )}

            {activeStatus === true ? (
              !appsInactive ? (
                <button
                  type="button"
                  className="btn btn-genmeb ms-3"
                  onClick={() => inactiveHandler(inactiveItem, activeStatus)}
                >
                  Deactivate
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-genmeb ms-3"
                  data-bs-dismiss="modal"
                  onClick={appsInactiveHandler}
                >
                  Ok
                </button>
              )
            ) : !appsInactive ? (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                onClick={() => inactiveHandler(inactiveItem, activeStatus)}
              >
                Activate
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-dismiss="modal"
                onClick={appsInactiveHandler}
              >
                Ok
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ActivateApps;
