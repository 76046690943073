import queryString from "query-string";
import Service from "../lib/Service";
import { groupRuleActions } from "./constants";

export const fetchAllGroupRules = () => async (dispatch) => {
  const userRoleId = sessionStorage.getItem("userRoleId");

  try {
    dispatch({
      type: groupRuleActions.FETCH_ALL_GROUP_RULES_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetAllGroupRules",
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: groupRuleActions.FETCH_ALL_GROUP_RULES_SUCCESS,
      payload: {
        payload: data.responseData !== null ? data.responseData : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: groupRuleActions.FETCH_ALL_GROUP_RULES_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const fetchAllApps = () => async (dispatch) => {
  const userId = sessionStorage.getItem("userId");
  const payload = {
    keyword: "",
    groupIds: [],
    includeInActive: false,
  };
  try {
    dispatch({
      type: groupRuleActions.FETCH_ALL_APPS_START,
      loading: true,
    });

    const data = await Service.POST({
      name: "GetApplicationsListByLoginUserId",
      queryString: `userId=${userId}&PageNumber=1&PageSize=50`,
      payload: payload
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: groupRuleActions.FETCH_ALL_APPS_SUCCESS,
      payload: {
        payload: data.responseData !== null ? data.responseData.data : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: groupRuleActions.FETCH_ALL_APPS_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const createNewGroupRule = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: groupRuleActions.CREATE_GROUP_RULES_START,
    });

    const data = await Service.POST({
      name: "CreateNewGroupRule",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      } else {
        return {
          displayMessage: res.displayMessage,
          statusCode: res.statusCode,
        };
      }
    });
    dispatch({
      type: groupRuleActions.CREATE_GROUP_RULES_SUCCESS,
      payload: data,
    });
    dispatch(fetchAllGroupRules());
  } catch (error) {
    dispatch({
      type: groupRuleActions.CREATE_GROUP_RULES_FAILED,
      payload: error.message,
    });
  }
};

export const fetchUsersListByDepartment =
  (value) => async (dispatch, getState) => {
    const payload = {
      deptNames: value.deptNames,
      locations: value.locations,
    };

    try {
      dispatch({
        type: groupRuleActions.FETCH_ALL_USER_LIST_BY_DEPARTMENT_START,
        loading: true,
      });

      const data = await Service.POST({
        name: "GetAdDatabyDepartments",
        queryString: "",
        payload: payload,
      }).then((res) => {
        if (res.isSuccess) {
          return res;
        }
      });
      dispatch({
        type: groupRuleActions.FETCH_ALL_USER_LIST_BY_DEPARTMENT_SUCCESS,
        payload: {
          test: data.responseData !== null ? data.responseData : [],
          message: data.responseData === null ? "" : "",
          loading: false,
        },
      });
    } catch (error) {
      dispatch({
        type: groupRuleActions.FETCH_ALL_USER_LIST_BY_DEPARTMENT_FAILED,
        payload: {
          loading: false,
          error: error.message,
        },
      });
    }
  };

export const deleteGroupRule = (id) => async (dispatch) => {
  try {
    dispatch({
      type: groupRuleActions.DELETE_GROUP_RULES_START,
    });

    const data = await Service.POST({
      name: "/DeleteGroupRule",
      queryString: `groupRuleId=${id}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: groupRuleActions.DELETE_GROUP_RULES_SUCCESS,
      payload: data,
    });
    dispatch(fetchAllGroupRules());
  } catch (error) {
    dispatch({
      type: groupRuleActions.DELETE_GROUP_RULES_FAILED,
      payload: error.message,
    });
  }
};

export const updateRule = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: groupRuleActions.UPDATE_GROUP_RULES_START,
    });

    const data = await Service.POST({
      name: "UpdateGroupRule",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      } else {
        return {
          displayMessage: res.displayMessage,
          statusCode: res.statusCode,
        };
      }
    });
    dispatch({
      type: groupRuleActions.UPDATE_GROUP_RULES_SUCCESS,
      payload: data,
    });
    dispatch(fetchAllGroupRules());
  } catch (error) {
    dispatch({
      type: groupRuleActions.UPDATE_GROUP_RULES_FAILED,
      payload: error.message,
    });
  }
};

export const triggerGroupRules = (payload) => async (dispatch) => {
  const userRoleId = sessionStorage.getItem("userRoleId");

  try {
    dispatch({
      type: groupRuleActions.TRIGGER_GROUP_RULES_START,
    });

    const data = await Service.GET({
      name: "TriggerGroupRulesByRuleId",
      queryString: `id=${payload}`,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: groupRuleActions.TRIGGER_GROUP_RULES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: groupRuleActions.TRIGGER_GROUP_RULES_FAILED,
      payload: error.message,
    });
  }
};

export const modifyTriggerGroupRules = (payload) => async (dispatch) => {
  const userRoleId = sessionStorage.getItem("userRoleId");

  try {
    dispatch({
      type: groupRuleActions.MODIFY_TRIGGER_GROUP_RULES_START,
    });

    const data = await Service.GET({
      name: "ModifyTriggerGroupRulesByRuleId",
      queryString: `id=${payload}`,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: groupRuleActions.MODIFY_TRIGGER_GROUP_RULES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: groupRuleActions.MODIFY_TRIGGER_GROUP_RULES_FAILED,
      payload: error.message,
    });
  }
};

export const fetchAllUMLPUsers = (value) => async (dispatch, getState) => {
  const userEmpId = sessionStorage.getItem("userEmpId")
    ? sessionStorage.getItem("userEmpId")
    : "";
  try {
    dispatch({
      type: groupRuleActions.FETCH_ALL_UMLP_USERS_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetAllUsers",
      queryString: `IsAdminInclude=${value}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: groupRuleActions.FETCH_ALL_UMLP_USERS_SUCCESS,
      payload: {
        payload: data.responseData !== null ? data.responseData : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: groupRuleActions.FETCH_ALL_UMLP_USERS_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const fetchAllTitles = () => async (dispatch, getState) => {
  const userEmpId = sessionStorage.getItem("userEmpId")
    ? sessionStorage.getItem("userEmpId")
    : "";
  try {
    dispatch({
      type: groupRuleActions.FETCH_ALL_TITLES_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetTitlesByKeyword",
      queryString: `keyword=${""}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: groupRuleActions.FETCH_ALL_TITLES_SUCCESS,
      payload: {
        payload: data.responseData !== null ? data.responseData : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: groupRuleActions.FETCH_ALL_TITLES_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const setRecordLength =
  (data, records) => async (dispatch, getState) => {
    dispatch({
      type: groupRuleActions.SET_PREVIEW_RECORDS_LENGTH,
      payload: {
        records: data.recordCount ? data.recordCount : 0,
      },
    });
  };

export const createUserPreview = (payload) => async (dispatch, getState) => {
  const { paginationOptions, search } = getState().groupsRule;
  const { pageNo, displayLimit } = paginationOptions;
  const searchKeyword = search ? encodeURIComponent(search) : "";
  try {
    dispatch({
      type: groupRuleActions.CREATE_USER_PREVIEW_START,
      loading: true,
    });

    const data = await Service.POST({
      name: "GetUsersPreview",
      payload: payload,
      queryString: `PageNumber=${pageNo}&PageSize=${displayLimit}&keyword=${
        searchKeyword || ""
      }`,
    }).then((res) => {
      if (res) {
        return res;
      }
    });
    dispatch({
      type: groupRuleActions.CREATE_USER_PREVIEW_SUCCESS,
      payload: {
        payload: data.responseData !== null ? data.responseData.data : [],
        loading: false,
        success: data.isSuccess,
      },
    });
    dispatch(
      setRecordLength(
        data.responseData !== null ? data.responseData : [],
        data.recordCount
      )
    );
  } catch (error) {
    dispatch({
      type: groupRuleActions.CREATE_USER_PREVIEW_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const conditionsData = (data) => async (dispatch, getState) => {
  dispatch({
    type: groupRuleActions.FETCH_CONDITIONS_DATA,
    payload: data,
  });
};

export const getPaginationData = (data) => async (dispatch, getState) => {
  const { conditionsData } = getState().groupsRule;
  dispatch({
    type: groupRuleActions.FETCH_RULE_PAGINATION_DATA_SUCCESS,
    payload: {
      pageNo: data.pageNo,
      displayLimit: data.displayLimit,
    },
  });

  dispatch(createUserPreview(conditionsData));
};

export const fetchSearch = (values) => async (dispatch, getState) => {
  const { conditionsData } = getState().groupsRule;
  dispatch({
    type: groupRuleActions.FETCH_RULE_SEARCH_INPUT,
    payload: {
      search: values,
      paginationOptions: {
        pageNo: 1,
        displayLimit: 10,
      },
    },
  });
  dispatch(createUserPreview(conditionsData));
};

export const modifyGroupRuleById = (value) => async (dispatch, getState) => {
  const ruleId = value;

  try {
    dispatch({
      type: groupRuleActions.FETCH_ALL_GROUP_RULES_BYID_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetGroupRulesbyId",
      queryString: `ruleId=${ruleId}`,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: groupRuleActions.FETCH_ALL_GROUP_RULES_BYID_SUCCESS,
      payload: {
        test: data.responseData !== null ? data.responseData : [],
        message: data.responseData === null ? "" : "",
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: groupRuleActions.FETCH_ALL_GROUP_RULES_BYID_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};



export const resetData = (data) => async (dispatch, getState) => {
  dispatch({
    type: groupRuleActions.RESET_MODIFYRULE_SUCCESS,
    payload: data,
  });
};


export const triggerInActiveGroupRuleByRuleId = (payload) => async (dispatch) => {
  const userRoleId = sessionStorage.getItem("userRoleId");

  try {
    dispatch({
      type: groupRuleActions.TRIGGER_INACTIVE_GROUP_RULES_START,
    });

    const data = await Service.GET({
      name: "TriggerInActiveGroupRuleByRuleId",
      queryString: `id=${payload}`,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: groupRuleActions.TRIGGER_INACTIVE_GROUP_RULES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: groupRuleActions.TRIGGER_INACTIVE_GROUP_RULES_FAILED,
      payload: error.message,
    });
  }
};
