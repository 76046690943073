import { SET_QUICK_LINKS } from "./../actions/constants";
import t1 from "../assets/images/new-images/t1.png";
import t2 from "../assets/images/new-images/t2.png";
import t3 from "../assets/images/new-images/t3.png";
import t4 from "../assets/images/new-images/t4.png";
import t5 from "../assets/images/new-images/t5.png";

const initialstate = {
  quickLinks: [
    {
      id: 1,
      title: "MMIT Pulse",
      subtitle: "Health Options",
      tags: "MMIT Pulse ",
      img: t1,
      url: "https://login.pulse-tools.com/",
      role: ["Market Access, MSLT"],
      user: ["Robert, Nate Buchholz"],
      dateAdded: "09/12/22",
      status: "Active",
      color: "#4f86be",
    },
    {
      id: 2,
      title: "Beacon",
      subtitle: "Health Options",
      tags: "Beacon",
      img: t2,
      url: "www.mmitpluse.com",
      role: ["MSLT"],
      user: [" Lauren Becker"],
      dateAdded: "09/12/22",
      status: "Active",
    },
    {
      id: 3,
      title: "Eversana",
      subtitle: "Health Options",
      tags: "Eversana",
      img: t3,
      url: "www.mmitpluse.com",
      role: ["Operations"],
      user: [" Rama Shah"],
      dateAdded: "09/12/22",
      status: "Active",
    },
    {
      id: 4,
      title: "Concur",
      subtitle: "Health Options",
      tags: "Concur",
      img: t4,
      url: "www.mmitpluse.com",
      role: ["NAM"],
      user: ["Kanad Chatterjee"],
      dateAdded: "09/12/22",
      status: "Active",
    },
    {
      id: 5,
      title: "Breakaway Partners",
      subtitle: "Health Options",
      tags: "Komodo",
      img: t5,
      url: "www.mmitpluse.com",
      role: ["Training"],
      user: ["Michael Buchholz"],
      dateAdded: "09/12/22",
      status: "Inactive",
    },
  ],
};

function quickLinksReducer(state = initialstate, action) {
  switch (action.type) {
    case SET_QUICK_LINKS:
      return { ...state, quickLinks: action.payload };
    default:
      return state;
  }
}

export default quickLinksReducer;
