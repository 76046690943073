const parseUri = (uri) =>{
    if (!/^https?:\/\//i.test(uri)) {
        uri = 'http://' + uri;
    }
    return uri
}

export  const openInNewTab = (url, mobileUrl) => {
    url = parseUri(url)
    mobileUrl = parseUri(mobileUrl)
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      )
    ) {
        window.open(mobileUrl, "_blank")
    } else {
        window.open(url, "_blank")
    }
};

export const formatDate = (inputDate) => {
    // Parse the input date string into a Date object
    const date = new Date(inputDate);

    // Define arrays for month names and day names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Format the date
    const formattedDate = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

    return formattedDate;
};

export const sortByDateKey = (objects, dateKey, desc = true) => {
    return objects.sort((a, b) => {
        const dateA = new Date(a[dateKey]);
        const dateB = new Date(b[dateKey]);

        // Compare the dates to sort in the configured order
        return (desc) ? dateB - dateA : dateA - dateB;
    });
}