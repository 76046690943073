import React, { useRef, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import SearchIcon from "../../../assets/images/search.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddGroupsModal from "../Modals/AddGroupsModal";
import CloseIcon from "@mui/icons-material/Close";
const AddGroups = ({
  isShownGroup,
  setIsShownGroup,
  setIsShownApp,
  userGroups,
  setUserGroups,
  groupsSearchInput,
  setGroupsSearchInput,
  selectGroupChecked,
  setSelectGroupChecked,
  selectedGroups,
  setSelectedGroups,
  test,
  userApps,
  editmode,
  filterRule
}) => {
  const handleRemove = (e, id) => {
    e.preventDefault();
    setUserGroups(
      userGroups?.map((obj) => {
        if (id === obj.id) {
          return {
            ...obj,
            ischecked: false,
          };
        } else {
          return { ...obj };
        }
      })
    );
    setSelectedGroups(selectedGroups.filter((i) => i.id !== id));
    setSelectGroupChecked(false);
  };
  const handleClickGroup = (event) => {
    if (userGroups.filter((i) => i.ischecked === true)?.length > 0) {
      setSelectedGroups(userGroups.filter((i) => i.ischecked === true));
    } else {
      setSelectedGroups([]);
    }
    setGroupsSearchInput("");
    setSelectGroupChecked(false);
    setIsShownApp(false);
    setIsShownGroup((current) => !current);
  };

  const btnRefGroup = useRef();
  useEffect(() => {
    const closeDropdown = (e) => {
      console.log(e);
      // setIsShownGroup(true);

      if (!btnRefGroup.current.contains(e.target)) {
        setIsShownGroup(false);
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <>
      <div className="c-select" ref={btnRefGroup}>
        <div className="select-bar">
          {userGroups?.filter((a) => a.ischecked === true)?.map((item) => item)
            ?.length > 0 ? (
            userGroups &&
            userGroups
              ?.filter((a) => a.status === true)
              ?.filter((a) => a.ischecked === true)
              ?.map((item) => (
                <span
                  key={item.id}
                  className="placeholder placeholder_selected "
                >
                  {item.value}{" "}
                  <button
                    className="remove-button"
                    onClick={(e) => handleRemove(e, item.id)}
                  >
                    <CloseIcon className="close-icon"></CloseIcon>
                  </button>
                </span>
              ))
          ) : (
            <span className="placeholder">Assign Group</span>
          )}

          <ArrowDropDownIcon onClick={handleClickGroup} className="down-icon" />
        </div>
        <AddGroupsModal
          isShownGroup={isShownGroup}
          handleClickGroup={handleClickGroup}
          userGroups={userGroups}
          setUserGroups={setUserGroups}
          groupsSearchInput={groupsSearchInput}
          setGroupsSearchInput={setGroupsSearchInput}
          selectGroupChecked={selectGroupChecked}
          setSelectGroupChecked={setSelectGroupChecked}
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
          editmode={editmode}
          filterRule={filterRule}
          test={test}
          userApps={userApps}
        />
      </div>
    </>
  );
};

export default AddGroups;
