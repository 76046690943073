import React from "react";
import { DashboardLayout } from "../components/dashboard-layout";
import "../assets/css/myApp.css";
import "../assets/css/new-dashboard.css";
import brdCum from "../assets/images/new-images/brd-cum-icon.svg";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import Groups_navigation from "../components/Groups/Groups_navigation";
import UserGroup from "../components/Groups/UserGroup";

const Groups = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <section>
          <div>
            <UserGroup />
          </div>
        </section>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <center className="center-content">Content is not accessable</center>
      </UnauthenticatedTemplate>
    </>
  );
};

export default Groups;
