import React, { useEffect } from "react";
import SearchIcon from "../../../assets/images/search.svg";
import up from "../../../assets/images/new-images/up.png";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
const AddAppsModal = ({
  userApps,
  setUserApps,
  appsSearchInput,
  setAppsSearchInput,
  selectAppChecked,
  setSelectAppChecked,
  selectedApps,
  appsData = [],
  setSelectedApps,
  editmode,
  filterRule,
  fetchAllApps,
  handleAppFocus,
  conflictEdit,
  handleClickApp,
  isShownApp,
  test,
  userGroups,
}) => {

  const appSearchHandler = (e) => {
    setAppsSearchInput(e.target.value);
  };
  const modifyRuleList = useSelector(
    (state) => state?.groupsRule?.modifyRuleList
  );
  let appSearchFilter =
    userApps &&
    userApps?.length &&
    userApps
      ?.filter((a) => a.isActive === true)
      ?.filter((item) => {
        if (appsSearchInput === "") {
          return item;
        } else if (
          item.appName.toLowerCase().includes(appsSearchInput.toLowerCase())
        ) {
          return item;
        }
        return null;
      });

  const appSelect = (e) => {
    setSelectAppChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedApps(
        userApps.map((i) => {
          return {
            ...i,
            ischecked: e.target.checked,
          };
        })
      );
    } else if (e.target.checked === false) {
      setSelectedApps([]);
    }
  };

  const appHandler = (e, val) => {
    if (e.target.checked) {
      setSelectedApps([...selectedApps, val]);
    } else if (e.target.checked === false) {
      setSelectAppChecked(false);
      setSelectedApps(selectedApps.filter((i) => i.appId !== val.appId));
    }
  };

  const submitApps = (values) => {
    setUserApps(
      userApps.map((obj) => {
        if (values.find((ol) => ol.appId === obj.appId)) {
          return {
            ...obj,
            ischecked: true,
          };
        } else {
          return { ...obj, ischecked: false };
        }
      })
    );

    setAppsSearchInput("");
    handleClickApp();
  };

  // useEffect(() => {
  //   let data = {
  //     groupIds: userGroups
  //       .filter((i) => i.ischecked === true)
  //       .map((item) => item.id),
  //     applicationIds: userApps
  //       .filter((i) => i.ischecked === true)
  //       .map((item) => item.appId),
  //     conditions: test,
  //   };
  //   !test?.map((i) => i.conditionOn).includes("") &&
  //     !test?.map((i) => i.conditionOperator).includes("") &&
  //     test?.map((i) => i.selectedValues)[0]?.length > 0 &&
  //     userGroups.filter((i) => i.ischecked === true).map((item) => item.id)
  //       ?.length > 0 &&
  //     console.info(data);
  // }, [userApps]);

  useEffect(() => {
    if (editmode) {
      let newVal = {};
      newVal = appsData?.map((item) => {
        if (
          [...new Set(modifyRuleList?.applicationIds)]
            .map((i) => i)
            .includes(item.appId)
        ) {
          return { ...item, ischecked: true };
        }
        return item;
      });

      setUserApps(newVal);
      setSelectedApps(
        appsData?.filter((i) =>
        modifyRuleList?.applicationIds.map((i) => i).includes(i.appId)
        )
      );
      if (
        newVal.filter((i) => i.ischecked === true).length === appsData?.length
      ) {
        setSelectAppChecked(true);
      }
    } else {
      appsData?.length > 0 &&
        setUserApps(
          appsData.map((item) => {
            return {
              ...item,
              ischecked: false,
            };
          })
        );
    }
  }, [appsData, editmode, conflictEdit]);

  const cancelApps = () => {
    if (userApps.filter((i) => i.ischecked === true)?.length > 0) {
      setSelectedApps(userApps.filter((i) => i.ischecked === true));
    } else {
      setSelectedApps([]);
    }
  };
  return (
    <>
      {isShownApp && (
        <div className="select-data-option">
          <div className="relative w-100">
            <img src={SearchIcon} alt="icon" className="pop-search top" />
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              onChange={appSearchHandler}
              value={appsSearchInput}
            />
            {/* <img
              data-bs-dismiss="modal"
              src={up}
              alt="icon"
              className="up-icon"
            /> */}
          </div>
          <div className="scroll-height">
            {!appsSearchInput && (
              <div className="s-all">
                <p>Select All</p>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={
                    selectAppChecked || selectedApps.length === userApps.length
                  }
                  onChange={(e) => appSelect(e)}
                />
              </div>
            )}
            {userApps && userApps.length === 0 && (
              <div className="all-check">No Apps Found</div>
            )}
            {userApps.length > 0 && appSearchFilter.length === 0 ? (
              <div className="all-check">No Match Found</div>
            ) : (
              appSearchFilter?.length > 0 &&
              appSearchFilter?.map((r, index) => (
                <div className="all-check" key={r.appId}>
                  <p>{r.appName}</p>
                  <input
                    type="checkbox"
                    name="applicationIds"
                    className="form-check-input"
                    value={r.appId}
                    checked={selectedApps
                      .map((ir) => ir.appId)
                      .includes(r.appId)}
                    onChange={(e) => appHandler(e, r)}
                  />
                </div>
              ))
            )}
          </div>
          <div className="select-fotter-section">
            <Button
              className="btn-cancel"
              variant="text"
              onClick={(e) => {
                cancelApps();
                setAppsSearchInput("");
                setSelectAppChecked(false);
                handleClickApp();
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={() => submitApps(selectedApps)}
              className="genmeb-btn"
              variant="contained"
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ groupsRule }) => ({
  appsData: groupsRule.apps,
});

const mapDispatchToProps = (dispatch) => ({
 
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAppsModal);
