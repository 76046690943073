import GraphService from '../lib/SharepointService'
import {convertToEncodedSharesUrl, getQueryStringType, getSharingLinkType, getSharingURI} from '../utils/sharepoint'

import {
    FETCH_SHAREPOINT_BEGIN,
    FETCH_SHAREPOINT_SUCCESS,
    FETCH_SHAREPOINT_FAILURE,
    FETCH_SHAREPOINT_RESET
} from './constants';

export const fetchSharepointData = (appId, shareUrl) => {

    return dispatch => {
        dispatch(fetchSharepointDataBegin());
        const encodedShareUrl = convertToEncodedSharesUrl(shareUrl);
        const linkType = getSharingLinkType(shareUrl);

        const parameters = {
            endpoint: getSharingURI(encodedShareUrl, linkType),
            queryString: getQueryStringType(shareUrl, linkType)
        }

        if(linkType !== 'unknown'){
            GraphService.GET(parameters)
                .then(data => dispatch(fetchSharepointDataSuccess(appId, data)))
                .catch(error => dispatch(fetchSharepointDataFailure(error.message)));
        } else {
            dispatch(fetchSharepointDataFailure("Invalid or unknown Sharepoint url format."));
        }
    };
};

const fetchSharepointDataBegin = () => ({
    type: FETCH_SHAREPOINT_BEGIN
});

const fetchSharepointDataSuccess = (appId, data) => ({
    type: FETCH_SHAREPOINT_SUCCESS,
    payload: { appId, data }
});

const fetchSharepointDataFailure = error => ({
    type: FETCH_SHAREPOINT_FAILURE,
    payload: { error }
});

export const resetSharepointState = () => ({
    type: FETCH_SHAREPOINT_RESET
});