import { groupRuleActions } from "../actions/constants";
import { updateState } from "../middlewares/utils";

const defaultState = {
  ruleLoading: true,
  response: null,
  groupRules: [],
  usersList: [],
  error: false,
  message: "",
  apps: [],
  rulesResponse: null,
  rulesResponseStatusCode: null,
  rulesResponseLoading: true,
  createdRuleId: null,
  adUsersLoading: true,
  deleteResponse: null,
  deleteResponseLoading: true,
  allAppsLoading: true,
  allUMLPUsers: [],
  allUMLPUsersLoading: true,
  allTitles: [],
  allTitlesLoading: true,
  userPreviewLoading: false,
  userPreview: [],
  paginationOptions: {
    pageNo: 1,
    displayLimit: 10,
  },
  recordsPerPage: 10,
  displayLimit: 10,
  pageNo: 1,
  totalRecords: 0,
  currentPage: null,
  search: "",
  conditionsData: [],
  userPreviewResponse: false,
  modifyRuleListLoading: true,
  modifyRuleList: {
    ruleName: "",
    groupIds: [],
    applicationIds: [],
    conditions: [
      {
        conditionId: 0,
        displayOperator: "",
        conditionOn: "",
        conditionOperator: "",
        operator: 1,
        activeCondition: false,
        selectedValues: [],
      },
    ],
  },
};

const groupsRule = (state = defaultState, actions) => {
  switch (actions.type) {
    case groupRuleActions.TRIGGER_GROUP_RULES_START:
      return updateState(state, { loading: true });
    case groupRuleActions.TRIGGER_GROUP_RULES_SUCCESS:
      return updateState(state, {
        loading: false,
      });
    case groupRuleActions.TRIGGER_GROUP_RULES_FAILED:
      return updateState(state, { error: actions.payload });

      case groupRuleActions.TRIGGER_INACTIVE_GROUP_RULES_START:
        return updateState(state, { loading: true });
      case groupRuleActions.TRIGGER_INACTIVE_GROUP_RULES_SUCCESS:
        return updateState(state, {
          loading: false,
        });
      case groupRuleActions.TRIGGER_INACTIVE_GROUP_RULES_FAILED:
        return updateState(state, { error: actions.payload });

    case groupRuleActions.MODIFY_TRIGGER_GROUP_RULES_START:
      return updateState(state, { loading: true });
    case groupRuleActions.MODIFY_TRIGGER_GROUP_RULES_SUCCESS:
      return updateState(state, {
        loading: false,
      });
    case groupRuleActions.MODIFY_TRIGGER_GROUP_RULES_FAILED:
      return updateState(state, { error: actions.payload });

    case groupRuleActions.FETCH_ALL_APPS_START:
      return updateState(state, { allAppsLoading: true });
    case groupRuleActions.FETCH_ALL_APPS_SUCCESS:
      return updateState(state, {
        apps: actions.payload.payload,
        allAppsLoading: actions.payload.loading,
      });
    case groupRuleActions.FETCH_ALL_APPS_FAILED:
      return updateState(state, { error: actions.payload });

    case groupRuleActions.FETCH_ALL_GROUP_RULES_START:
      return updateState(state, { ruleLoading: true });
    case groupRuleActions.FETCH_ALL_GROUP_RULES_SUCCESS:
      return updateState(state, {
        groupRules: actions.payload.payload,
        ruleLoading: actions.payload.loading,
      });
    case groupRuleActions.FETCH_ALL_GROUP_RULES_FAILED:
      return updateState(state, { ...actions.payload });

    case groupRuleActions.CREATE_GROUP_RULES_START:
      return updateState(state, {
        rulesResponseLoading: true,
        rulesResponse: null,
        rulesResponseStatusCode: null,
        createdRuleId: null,
      });
    case groupRuleActions.CREATE_GROUP_RULES_SUCCESS:
      return updateState(state, {
        rulesResponseLoading: false,
        rulesResponse: actions.payload.displayMessage,
        rulesResponseStatusCode: actions.payload.statusCode,
        createdRuleId: actions.payload.responseData,
      });
    case groupRuleActions.CREATE_GROUP_RULES_FAILED:
      return updateState(state, { error: actions.payload });

    case groupRuleActions.UPDATE_GROUP_RULES_START:
      return updateState(state, {
        rulesResponseLoading: true,
        rulesResponse: null,
        rulesResponseStatusCode: null,
        createdRuleId: null,
      });
    case groupRuleActions.UPDATE_GROUP_RULES_SUCCESS:
      return updateState(state, {
        rulesResponseLoading: false,
        rulesResponse: actions.payload.displayMessage,
        rulesResponseStatusCode: actions.payload.statusCode,
        createdRuleId: actions.payload.responseData,
      });
    case groupRuleActions.UPDATE_GROUP_RULES_FAILED:
      return updateState(state, { error: actions.payload });

    case groupRuleActions.DELETE_GROUP_RULES_START:
      return updateState(state, {
        deleteResponse: null,
        deleteResponseLoading: true,
      });
    case groupRuleActions.DELETE_GROUP_RULES_SUCCESS:
      return updateState(state, {
        deleteResponse: actions.payload.statusCode,
        deleteResponseLoading: false,
      });
    case groupRuleActions.DELETE_GROUP_RULES_FAILED:
      return updateState(state, { error: actions.payload });

    case groupRuleActions.FETCH_ALL_USER_LIST_BY_DEPARTMENT_START:
      return updateState(state, { adUsersLoading: true });
    case groupRuleActions.FETCH_ALL_USER_LIST_BY_DEPARTMENT_SUCCESS:
      return updateState(state, {
        usersList: actions.payload.test,
        adUsersLoading: actions.payload.loading,
        message: actions.payload.message,
      });
    case groupRuleActions.FETCH_ALL_USER_LIST_BY_DEPARTMENT_FAILED:
      return updateState(state, { ...actions.payload });

    case groupRuleActions.FETCH_ALL_UMLP_USERS_START:
      return updateState(state, { allUMLPUsersLoading: true });

    case groupRuleActions.FETCH_ALL_UMLP_USERS_SUCCESS:
      return updateState(state, {
        allUMLPUsers: actions.payload.payload,
        allUMLPUsersLoading: actions.payload.loading,
      });
    case groupRuleActions.FETCH_ALL_UMLP_USERS_FAILED:
      return updateState(state, { ...actions.payload });

    case groupRuleActions.FETCH_ALL_TITLES_START:
      return updateState(state, { allTitlesLoading: true });

    case groupRuleActions.FETCH_ALL_TITLES_SUCCESS:
      return updateState(state, {
        allTitles: actions.payload.payload,
        allTitlesLoading: actions.payload.loading,
      });
    case groupRuleActions.FETCH_ALL_TITLES_FAILED:
      return updateState(state, { ...actions.payload });

    case groupRuleActions.CREATE_USER_PREVIEW_START:
      return updateState(state, { userPreviewLoading: true });
    case groupRuleActions.CREATE_USER_PREVIEW_SUCCESS:
      return updateState(state, {
        userPreview: actions.payload.payload,
        userPreviewLoading: actions.payload.loading,
        userPreviewResponse: actions.payload.success,
      });
    case groupRuleActions.CREATE_USER_PREVIEW_FAILED:
      return updateState(state, { ...actions.payload });

    case groupRuleActions.FETCH_CONDITIONS_DATA:
      return updateState(state, { conditionsData: actions.payload });

      case groupRuleActions.RESET_MODIFYRULE_SUCCESS:
        return updateState(state, { modifyRuleList: actions.payload });

    case groupRuleActions.FETCH_RULE_PAGINATION_DATA_SUCCESS:
      return updateState(state, { paginationOptions: { ...actions.payload } });
    case groupRuleActions.FETCH_RULE_SEARCH_INPUT:
      return updateState(state, {
        search: actions.payload.search,
        paginationOptions: { ...actions.payload.paginationOptions },
      });

    case groupRuleActions.SET_PREVIEW_RECORDS_LENGTH:
      return updateState(state, { totalRecords: actions.payload.records });

    case groupRuleActions.FETCH_ALL_GROUP_RULES_BYID_START:
      return updateState(state, { modifyRuleListLoading: true });
    case groupRuleActions.FETCH_ALL_GROUP_RULES_BYID_SUCCESS:
      return updateState(state, {
        modifyRuleList: actions.payload.test,
        modifyRuleListLoading: actions.payload.loading,
        message: actions.payload.message,
      });
    case groupRuleActions.FETCH_ALL_GROUP_RULES_BYID_FAILED:
      return updateState(state, { ...actions.payload });
    default:
      return state;
  }
};

export default groupsRule;
