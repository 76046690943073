import React from "react";

const AddDepartmentUsers = ({ usersList }) => {
  return (
    <div className="mb-3 relative-box">
      <label className="form-label">Except the Following Users</label>
      <input
        type="text"
        className="form-control arow"
        data-bs-target="#exceptUSerOpen"
        data-bs-toggle="modal"
        style={{ caretColor: "transparent" }}
        placeholder="Select User"
        name="exceptUsers"
        value={
          usersList.length > 0
            ? usersList
                .filter((a) => a.checked === true)
                .map((item) => item.displayName)
                .join(", ")
            : ""
        }
        onChange={() => {
          console.log();
        }}
      />
    </div>
  );
};


export default AddDepartmentUsers;
