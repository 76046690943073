import { SET_LOGIN_DATA } from "./../actions/constants";

const initialstate = {
  loginData: [],
};

function loginReducer(state = initialstate, action) {
  switch (action.type) {
    case SET_LOGIN_DATA:
      return { ...state, loginData: action.payload };
    default:
      return state;
  }
}

export default loginReducer;
