import React, { useState, useEffect } from "react";
import SearchIcon from "../../assets/images/search.svg";
import up from "../../assets/images/new-images/up.png";
import cross from "../../assets/images/new-images/tag.png";
import Modal from "../UI/Modal";
import sucess from "../../assets/images/new-images/sucess.png";
import error from "../../assets/images/new-images/error.png";
import moment from "moment";
import remove from "../../assets/images/new-images/remove2.png";
import * as actions from "../../actions/actions-users";
import {
  createNewAlert,
  updateAlertDetails,
} from "../../actions/action-notifications";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {renderImageDataUrl} from "../../middlewares/utils";

const AddAlerts = ({
  filterAlert = [],
  setFilterAlert,
  editmode,
  notifications,
  alerts,
  setAlerts,
  filterAlertIndex,
  setEditMode,
  notificationTypes,
  groupsList = [],
  fetchedUsers = [],
  updateNotification,
  message,
  loading,
  ...props
}) => {
  const [userGroups, setUserGroups] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [base64Img, setBase64Img] = useState("");
  const [imgDelete, setImgDelete] = useState("");

  const initialState = {
    title: "",
    description: "",
    flage: null,
    tagList: [],
    groups: [],
    users: [],
    localImage: "",
    imageName: "",
    dateAdded: "",
    isRead: false,
    isReadTime: "",
    publishOn: null,
    expireOn: null,
  };
  const todayDate = new Date();
  const todayDate2 = moment();

  const [publishValue, setPublishValue] = React.useState(null);
  const [expireValue, setexpireValue] = React.useState(null);
  const [errorPublishDate, setErrorPublishDate] = useState(false);
  const [errorExpireDate, setErrorExpireDate] = useState(false);
  const alertState = useSelector(state => state.alerts);

  const [alert, setAlert] = useState(initialState);
  const {
    tagList,
    notificationTypeID,
    localImage,
    imageName,
    title,
    description,
    userId,
    groups,
    publishOn,
    expireOn,
  } = alert;
  const [selectChecked, setSelectChecked] = useState(false);

  useEffect(() => {
    {
      editmode ? setAlert(filterAlert) : setAlert(initialState);
    }
  }, [editmode, filterAlert]);

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (!editmode) {
      setAlert({ ...alert, [name]: value });
    } else {
      setFilterAlert({
        ...filterAlert,
        [name]: value,
      });
    }
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      !editmode
        ? setAlert({
            ...alert,
            localImage: e.target.files[0],
            imageName: e.target.files[0].name.replace(/\.[^/.]+$/, ""),
          })
        : setFilterAlert({
            ...filterAlert,
            localImage: e.target.files[0],
            imageName: e.target.files[0].name.replace(/\.[^/.]+$/, ""),
          });

      if (e.target.files[0].size > 2000000) {
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onload = () => {
        setBase64Img(reader.result.split("base64,")[1]); //base64encoded string
      };
      reader.onerror = (error) => {
        console.log("Error: ", error);
      };
    }
    e.target.value = null;
  };
  const removeimageHandler = () => {
    if (!editmode) setAlert({ ...alert, localImage: "" });
    else {
      setFilterAlert({
        ...filterAlert,
        imageBase64String: "",
      });
      setImgDelete(alert.imageBase64String);
    }
  };
  const imageSizeIsValid = localImage && localImage.size < 1000000;

  function handleKeyDown(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setAlert({ ...alert, tagList: [...tagList, value] });
    if (editmode) {
      setFilterAlert({ ...filterAlert, tagList: [...tagList, value] });
    }
    e.target.value = "";
  }
  function removeTag(index) {
    setAlert({ ...alert, tagList: tagList.filter((el, i) => i !== index) });
    if (editmode) {
      setFilterAlert({
        ...filterAlert,
        tagList: tagList.filter((el, i) => i !== index),
      });
    }
  }

  const successHandler = () => {
    setSubmitted(!submitted);
    setEditMode(false);
  };

  const [userSearchInput, setUserSearchInput] = useState("");

  const userSearchHandler = (e) => {
    setUserSearchInput(e.target.value);
  };

  let userSearchFilter =
    fetchedUsers?.length > 0 &&
    usersList &&
    usersList?.length &&
    usersList?.filter((item) => {
      if (userSearchInput === "") {
        return item;
      } else if (
        item.userName?.toLowerCase().includes(userSearchInput.toLowerCase()) ||
        item.email?.toLowerCase().includes(userSearchInput.toLowerCase())
      ) {
        return item;
      }
      return null;
    });

  const [groupsSearchInput, setGroupsSearchInput] = useState("");

  const groupsSearchHandler = (e) => {
    setGroupsSearchInput(e.target.value);
  };

  let groupsSearchFilter =
    userGroups?.length > 0 &&
    userGroups
      ?.filter((i) => i.status === true)
      ?.filter((search) => {
        if (groupsSearchInput === "") {
          return search;
        }
        if (
          search.value.toLowerCase().includes(groupsSearchInput?.toLowerCase())
        ) {
          return search;
        }
        return null;
      });

  const usersSelect = (e) => {
    setSelectChecked(e.target.checked);

    if (e.target.checked) {
      setSelectedUsers(
        usersList.map((i) => {
          return {
            ...i,
            checked: e.target.checked,
          };
        })
      );
    } else if (e.target.checked === false) {
      setSelectedUsers([]);
    }
  };

  const [selectGroupChecked, setSelectGroupChecked] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectNotification, setSelectNotification] = useState("");

  const usersHandler = (e, val) => {
    if (e.target.checked) {
      setSelectedUsers([...selectedUsers, val]);
    } else if (e.target.checked === false) {
      setSelectChecked(false);
      setSelectedUsers(selectedUsers.filter((i) => i.userId !== val.userId));
    }
  };

  const groupSelect = (e) => {
    setSelectGroupChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedGroups(
        userGroups?.map((i) => {
          return {
            ...i,
            ischecked: e.target.checked,
          };
        })
      );
    } else if (e.target.checked === false) {
      setSelectedGroups([]);
    }
  };

  const groupHandler = (e, val) => {
    if (e.target.checked) {
      setSelectedGroups([...selectedGroups, val]);
    } else if (e.target.checked === false) {
      setSelectGroupChecked(false);
      setSelectedGroups(selectedGroups.filter((i) => i.id !== val.id));
    }
  };

  const defaultState = {
    title: false,
    description: false,
    flage: false,
    tags: false,
    groups: false,
    users: false,
    image: false,
    startDate: false,
    endDate: false,
  };
  const [touched, setTouched] = useState(defaultState);
  const handleFocus = (e) => {
    let { name } = e.target;
    setTouched({ ...touched, [name]: true });
  };
  const handleFocus2 = (e) => {
    setTouched({ ...touched, flage: true });
  };
  const handleFocus3 = (e) => {
    setTouched({ ...touched, groups: true });
  };
  const handleFocus4 = (e) => {
    setTouched({ ...touched, users: true });
  };
  const datehandleFocus = (e) => {
    setTouched({ ...touched, startDate: true });
  };
  const datehandleFocus2 = (e) => {
    setTouched({ ...touched, endDate: true });
  };

  const enteredTitleIsValid = title.trim() !== "";
  const enteredDescriptionIsValid = description.trim() !== "";
  const enteredTypeIsValid = editmode
    ? filterAlert.notificationTypeID !== undefined &&
      notificationTypes?.find(
        (item) => item.value === filterAlert.notificationTypeID
      )?.status === true
    : alert.flage !== null;
  let userValidFilter =
    selectedUsers && selectedUsers?.length > 0 && selectedUsers.map((i) => i);
  const enteredUserIsValid = userValidFilter?.length > 0;
  let groupValidFilter =
    userGroups && userGroups?.filter((group) => group.ischecked === true);
  const enteredGroupIsValid = groupValidFilter?.length > 0;
  const enteredStartDateIsValid =
    (!editmode ? publishValue !== null : filterAlert.publishOn !== null) &&
    errorPublishDate === false;
  const enteredEndDateIsValid =
    (!editmode ? expireValue !== null : filterAlert.expireOn !== null) &&
    errorExpireDate === false;

  const titleInputIsInvalid =
    (!enteredTitleIsValid && touched.title) ||
    (touched.flage && !enteredTitleIsValid) ||
    (touched.description && !enteredTitleIsValid) ||
    (touched.groups && !enteredTitleIsValid) ||
    (touched.users && !enteredTitleIsValid) ||
    (touched.startDate && !enteredTitleIsValid) ||
    (touched.endDate && !enteredTitleIsValid);

  const typeInputIsInvalid =
    (!enteredTypeIsValid && touched.flage) ||
    (touched.description && !enteredTypeIsValid) ||
    (touched.groups && !enteredTypeIsValid) ||
    (touched.users && !enteredTypeIsValid) ||
    (touched.startDate && !enteredTypeIsValid) ||
    (touched.endDate && !enteredTypeIsValid);

  const desInputIsInvalid =
    (!enteredDescriptionIsValid && touched.description) ||
    (touched.groups && !enteredDescriptionIsValid) ||
    (touched.users && !enteredDescriptionIsValid) ||
    (touched.startDate && !enteredDescriptionIsValid) ||
    (touched.endDate && !enteredDescriptionIsValid);

  const groupsInputIsInvalid =
    (!enteredGroupIsValid && touched.groups) ||
    (touched.users && !enteredGroupIsValid) ||
    (touched.startDate && !enteredGroupIsValid) ||
    (touched.endDate && !enteredGroupIsValid);

  const usersInputIsInvalid =
    (!enteredUserIsValid && touched.users) ||
    (touched.startDate && !enteredUserIsValid) ||
    (touched.endDate && !enteredUserIsValid);

  const startdateInputIsInvalid =
    (!enteredStartDateIsValid && touched.startDate) ||
    (touched.endDate && !enteredStartDateIsValid);

  const enddateInputIsInvalid = !enteredEndDateIsValid && touched.endDate;

  const submitHandler = (e) => {
    e.preventDefault();
    setTouched({
      title: true,
      description: true,
      flage: true,
      tags: true,
      groups: true,
      users: true,
      image: true,
      startDate: true,
      endDate: true,
    });
    if (
      !enteredTitleIsValid ||
      !enteredTypeIsValid ||
      !enteredUserIsValid ||
      !enteredGroupIsValid ||
      !enteredDescriptionIsValid ||
      (publishValue && !enteredStartDateIsValid) ||
      (expireValue && !enteredEndDateIsValid) ||
      (editmode && newPublishOn && !enteredStartDateIsValid) ||
      (editmode && newExpireOn && !enteredEndDateIsValid)
    ) {
      return;
    }

    if (editmode) {
      let updatedAlert = alert;
      let updatedAlerts = [...alerts];
      updatedAlerts[filterAlertIndex] = updatedAlert;
      setAlerts(updatedAlerts);
      let start = new Date(filterAlert.publishOn);
      let end = new Date(filterAlert.expireOn);
      let payload = {
        id: filterAlert.id,
        title: filterAlert.title,
        description: filterAlert.description,
        notificationTypeID: parseInt(
          notificationTypes.find(
            (item) => item.value === filterAlert.notificationTypeID
          )?.id
        ),
        imageName: imgDelete
          ? filterAlert.imageName
          : base64Img
          ? imageName
          : "",
        imagebase64String: base64Img
          ? base64Img
          : filterAlert.imageBase64String,
        priority: filterAlert.priority,
        createdBy: filterAlert.createdBy,
        isRead: filterAlert.isRead,
        readAt: filterAlert.readAt || null,
        groupIdList: userGroups
          .filter((i) => i.ischecked === true)
          .map((item) => item.id),
        userId: usersList
          .filter((i) => i.checked === true)
          .map((item) => item.userId),
        tagList: filterAlert.tagList,
        publishOn: moment.utc(filterAlert.publishOn).format(),
        expireOn: moment.utc(filterAlert.expireOn).format(),
      };
      updateNotification(payload);
    } else {
      setAlerts([...alerts, alert]);
      let date = new Date();
      let payload = {
        title: alert.title,
        description: alert.description,
        notificationTypeID: parseInt(
          notificationTypes.find((item) => item.value === alert.flage)?.id
        ),
        imageName: imageName,
        imagebase64String: base64Img,
        priority: 0,
        createdBy: 0,
        isRead: false,
        readAt: "",
        groupIdList: userGroups
          .filter((i) => i.ischecked === true)
          .map((item) => item.id),
        userId: usersList
          .filter((i) => i.checked === true)
          .map((item) => item.userId),
        tagList: alert.tagList,
        publishOn: publishValue?.$d
          ? moment.utc(publishValue?.$d).format()
          : moment.utc().format(),
        expireOn: expireValue?.$d ? moment.utc(expireValue?.$d).format() : null,
      };
      props.createNewAlert(payload);

      setUserGroups(
        userGroups.map((item) => {
          return {
            ...item,
            ischecked: false,
          };
        })
      );
      setUsersList(
        usersList.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
    }
    setBase64Img("");
    setAlert(initialState);
    setTouched(defaultState);
    setSubmitted(!submitted);
    setSelectChecked(false);
    setSelectGroupChecked(false);
    setSelectedGroups([]);
    setSelectedUsers([]);
    setSelectNotification("");
    setUsersList([]);
    setPublishValue(null);
    setexpireValue(null);
    setFilterAlert({
      ...filterAlert,
      imageBase64String: "",
      localImage: "",
    });
  };

  const submitGroupForUsers = (values) => {
    setUserGroups(
      userGroups?.map((obj) => {
        if (values.find((ol) => ol.id === obj.id)) {
          return {
            ...obj,
            ischecked: true,
          };
        } else {
          return { ...obj, ischecked: false };
        }
      })
    );
    const payload = values?.map((i) => i.id);

    props.fetchUsersListByRolesID(payload);
    setGroupsSearchInput("");
  };

  const submitUsers = (values) => {
    setUsersList(
      usersList?.map((obj) => {
        if (values.find((ol) => ol.userId === obj.userId)) {
          return {
            ...obj,
            checked: true,
          };
        } else {
          return { ...obj, checked: false };
        }
      })
    );

    setUserSearchInput("");
  };

  const submitNotificationType = (values) => {
    if (editmode) {
      setFilterAlert({ ...filterAlert, notificationTypeID: values });
    } else {
      setAlert({ ...alert, flage: values });
    }
  };

  useEffect(() => {
    if (editmode) {
      let newVal = {};
      newVal = groupsList?.map((item) => {
        if ([...new Set(filterAlert.groupIdList)].includes(item.id)) {
          return { ...item, ischecked: true };
        }
        return item;
      });
      props.fetchUsersListByRolesID(
        newVal.filter((i) => i.ischecked === true).map((a) => a.id)
      );
      setUserGroups(newVal);
      setSelectedGroups(
        groupsList.filter((i) => filterAlert.groupIdList.includes(i.id))
      );
      if (
        newVal.filter((i) => i.ischecked === true).length === groupsList.length
      ) {
        setSelectGroupChecked(true);
      }
    } else {
      groupsList?.length > 0 &&
        setUserGroups(
          groupsList?.map((item) => {
            return {
              ...item,
              ischecked: false,
            };
          })
        );
    }
  }, [groupsList, editmode]);

  useEffect(() => {
    if (editmode) {
      let newVal = {};
      newVal =
        fetchedUsers?.length > 0 &&
        fetchedUsers.map((item) => {
          if (filterAlert.userId.some((i) => i === parseInt(item.userId))) {
            return { ...item, checked: true };
          }
          return item;
        });
      setUsersList(newVal);
      let newArr = [];
      let test = fetchedUsers?.filter((item) =>
        filterAlert.userId.includes(item.userId)
      );
      for (let i = 0; i < test.length; i++) {
        if (selectedUsers.indexOf(test[i]) === -1) {
          newArr.push(test[i]);
        }
      }
      setSelectedUsers(newArr);
    } else if (selectedUsers.length > 0) {
      setUsersList(
        fetchedUsers?.map((obj) => {
          if (selectedUsers.find((ol) => ol.userId === obj.userId)) {
            return {
              ...obj,
              checked: true,
            };
          } else {
            return { ...obj, checked: false };
          }
        })
      );
    } else {
      fetchedUsers?.length > 0 &&
        setUsersList(
          fetchedUsers.length > 0 &&
            fetchedUsers.map((item) => {
              return { ...item, checked: false };
            })
        );
    }
  }, [fetchedUsers, editmode]);

  const resetAllValues = () => {
    setSelectChecked(false);
    setUserGroups(
      userGroups?.map((item) => {
        return {
          ...item,
          ischecked: false,
        };
      })
    );
    setUsersList([]);
    setSelectedUsers([]);
    setSelectedGroups([]);
    setSelectNotification("");
    setSelectGroupChecked(false);
    setGroupsSearchInput("");
    setUserSearchInput("");
  };

  useEffect(() => {
    if (editmode && filterAlert) {
      const newUsrGroups = [...new Set(filterAlert?.groupIdList)];
      props.fetchUsersListByRolesID(newUsrGroups);
    } else {
      setUsersList([]);
      setUserGroups(groupsList);
      setSelectedGroups([]);
      setSelectGroupChecked(false);
      setSelectChecked(false);
    }
  }, [editmode]);

  useEffect(() => {
    if (editmode && filterAlert) {
      const newAlert = {
        ...filterAlert,
        notificationTypeID: notificationTypes.find(
          (e) => e.id === filterAlert.notificationTypeID
        )?.value,
      };
      setSelectNotification(
        notificationTypes.find((e) => e.id === filterAlert.notificationTypeID)
          ?.value
      );
      setFilterAlert(newAlert);
    }
  }, [editmode]);

  const dummy = () => {
    if (userGroups?.filter((i) => i.ischecked === true)?.length > 0) {
      setSelectedGroups(userGroups?.filter((i) => i.ischecked === true));
    } else {
      setSelectedGroups([]);
    }
  };

  const dummy2 = () => {
    if (
      usersList.length > 0 &&
      usersList.filter((i) => i.checked === true)?.length > 0
    ) {
      setSelectedUsers(usersList.filter((i) => i.checked === true));
    } else {
      setSelectedUsers([]);
    }
  };

  const dummy3 = (mode) => {
    if (mode) {
      if (filterAlert.notificationTypeID) {
        setSelectNotification(
          notificationTypes.find(
            (i) => i.value === filterAlert.notificationTypeID
          )?.value
        );
      } else {
        setSelectNotification(selectNotification);
      }
    } else {
      if (alert.flage !== "") {
        setSelectNotification(alert.flage);
      } else {
        setSelectNotification("");
      }
    }
  };

  useEffect(() => {
    if (!editmode) {
      if (selectedGroups?.length === 0) {
        setUsersList([]);
      }
    }
  }, [selectedGroups, editmode]);

  let newPublishOn = moment.utc(filterAlert.publishOn).local().format();

  let newExpireOn = moment.utc(filterAlert.expireOn).local().format();

  return (
    <>
      <Modal
        modalId={"Alertsend"}
        modalLabel={"exampleModalLabel"}
        className={"addUserModal"}
      >
        {!submitted && (
          <div className="modal-dialog modal-dialog-centered m-widht">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" >
                  {editmode ? "Update Notification" : "Create Notification"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setTouched(defaultState);
                    if (!editmode) {
                      setBase64Img("");
                      setAlert({ ...initialState, localImage: "" });
                      resetAllValues();
                      setPublishValue(null);
                      setexpireValue(null);
                    } else {
                      setEditMode(false);
                      setSelectedUsers([]);
                      setUsersList([]);
                      setSelectNotification("");
                      setFilterAlert({
                        ...filterAlert,
                        imageBase64String: "",
                        localImage: "",
                      });
                    }
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <form className="user-lable">
                  <div className="form-group">
                    <label>
                      Title<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter a Title"
                      onChange={handleChange}
                      name="title"
                      value={!editmode ? alert.title : filterAlert.title || ""}
                      onBlur={handleFocus}
                    />
                    {titleInputIsInvalid && (
                      <p className="e-message">
                        Please enter a Notification Title
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Notification Type<sup>*</sup>
                    </label>
                    {!editmode ? (
                      <input
                        style={{ caretColor: "transparent" }}
                        type="text"
                        className="form-control arow"
                        placeholder="Select type"
                        data-bs-target="#exampleModalToggle3"
                        data-bs-toggle="modal"
                        name="flage"
                        value={alert.flage || ""}
                        onChange={() => {
                          console.log();
                        }}
                      />
                    ) : (
                      <input
                        style={{
                          caretColor: "transparent",
                          color:
                            notificationTypes.find(
                              (ii) =>
                                ii.value === filterAlert.notificationTypeID
                            )?.status === false
                              ? "#ccc"
                              : "",
                        }}
                        type="text"
                        className="form-control arow"
                        placeholder="Select type"
                        data-bs-target="#exampleModalToggle3"
                        data-bs-toggle="modal"
                        name="notificationTypeID"
                        value={
                          notificationTypes.find(
                            (ii) => ii.value === filterAlert.notificationTypeID
                          )?.value
                        }
                        onChange={() => {
                          console.log();
                        }}
                      />
                    )}
                    {typeInputIsInvalid && (
                      <p className="e-message">
                        Please select a Notification Type
                      </p>
                    )}
                  </div>
                  <div className="form-group ful-w fix-height">
                    <label>
                      Notification Details<sup>*</sup>
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Notification Details"
                      value={
                        !editmode
                          ? alert.description
                          : filterAlert.description || ""
                      }
                      onChange={handleChange}
                      name="description"
                      rows="3"
                      onBlur={handleFocus}
                    ></textarea>
                    {desInputIsInvalid && (
                      <p className="e-message">
                        Please enter Notification Details
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Group<sup>*</sup>
                    </label>

                    <input
                      style={{ caretColor: "transparent" }}
                      type="text"
                      className="form-control arow"
                      data-bs-target="#exampleModalToggle2"
                      data-bs-toggle="modal"
                      name="groups"
                      placeholder="Select Group"
                      value={
                        userGroups &&
                        userGroups.filter(a => a.status === true)
                          .filter((a) => a.ischecked === true)
                          .map((item) => item.value)
                          .join(", ")
                      }
                      onChange={() => {
                        console.log();
                      }}
                    />

                    {groupsInputIsInvalid && (
                      <p className="e-message">Please select a Group</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      User <sup>*</sup>
                    </label>
                    <input
                      style={{ caretColor: "transparent" }}
                      type="text"
                      className="form-control arow"
                      placeholder="Select User"
                      data-bs-target="#exampleModalToggle7"
                      data-bs-toggle="modal"
                      name="user"
                      value={
                        usersList.length > 0
                          ? usersList
                              .filter((a) => a.checked === true)
                              .map((item) => item.userName)
                              .join(", ")
                          : ""
                      }
                      onChange={() => {
                        console.log();
                      }}
                    />
                    {usersInputIsInvalid && (
                      <p className="e-message">Please select a User</p>
                    )}
                  </div>
                  <div className="form-group ">
                    <label>Upload Image</label>
                    <div className="file-upload">
                      <span className="file-name">
                        {filterAlert.imageBase64String || localImage ? (
                          <img
                            src={
                              localImage
                                ? URL.createObjectURL(localImage)
                                : renderImageDataUrl(filterAlert.imageBase64String)
                            }
                            alt="icon"
                            className="up-icon-alt"
                          />
                        ) : (
                          "Formats: .jpg , .png"
                        )}{" "}
                        {(filterAlert.imageBase64String || localImage) && (
                          <img
                            src={remove}
                            alt="close"
                            className="remove-icon"
                            onClick={() => removeimageHandler()}
                          />
                        )}
                      </span>
                      <span className="upload">Browse</span>
                      <input
                        type="file"
                        className="file"
                        onChange={(e) => imageChange(e)}
                        name="image"
                        accept="image/x-png,image/jpeg,image/jpg"
                      />
                    </div>
                  </div>
                  <div className="form-group ">
                    <label>Add tags</label>
                    <div className="tag-input-container">
                      {tagList &&
                        tagList.length > 0 &&
                        tagList.map((tag, index) => (
                          <div className="tag-item" key={index}>
                            <span className="text-show">{tag}</span>
                            <span
                              onClick={() => removeTag(index)}
                              className="close-btn"
                            >
                              <img src={cross} alt="close"></img>
                            </span>
                          </div>
                        ))}
                      <input
                        type="text"
                        className="tags-input"
                        placeholder="Type a Tag Name and Hit Enter..."
                        onKeyDown={(e) => handleKeyDown(e)}
                        name="tagList"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Publish On</label>

                    <div
                      className="relative-box input-setting"
                      onBlur={datehandleFocus}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={5}>
                          <DateTimePicker
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                error={errorPublishDate}
                              />
                            )}
                            value={
                              !editmode
                                ? publishValue
                                : moment.utc(filterAlert.publishOn).format() ||
                                  newPublishOn
                            }
                            minDate={todayDate}
                            minTime={
                              !editmode
                                ? publishValue &&
                                  publishValue?.$d.getMonth() +
                                    publishValue?.$d.getDate() +
                                    publishValue?.$d.getFullYear() ===
                                    todayDate.getMonth() +
                                      todayDate.getDate() +
                                      todayDate.getFullYear()
                                  ? todayDate2
                                      .hours(todayDate2.hour())
                                      .minutes(todayDate2.minutes())
                                  : null
                                : newPublishOn &&
                                  new Date(newPublishOn).getMonth() +
                                    new Date(newPublishOn).getDate() +
                                    new Date(newPublishOn).getFullYear() ===
                                    todayDate.getMonth() +
                                      todayDate.getDate() +
                                      todayDate.getFullYear()
                                ? todayDate2
                                    .hours(todayDate2.hour())
                                    .minutes(todayDate2.minutes())
                                : null
                            }
                            onChange={(newValue) => {
                              !editmode
                                ? setPublishValue(newValue)
                                : setFilterAlert({
                                    ...filterAlert,
                                    publishOn: newValue?.$d,
                                  });
                            }}
                            onError={(reason, value) => {
                              if (reason) {
                                setErrorPublishDate(true);
                              } else {
                                setErrorPublishDate(false);
                              }
                            }}
                          />
                        </Stack>
                      </LocalizationProvider>
                      {startdateInputIsInvalid && (
                        <p className="e-message">
                          Please enter a valid Publication Date
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Expire On</label>

                    <div
                      className="relative-box input-setting"
                      onBlur={datehandleFocus2}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={5}>
                          <DateTimePicker
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                error={errorExpireDate}
                              />
                            )}
                            minDate={
                              !editmode
                                ? publishValue?.$d || todayDate
                                : filterAlert.publishOn || todayDate
                            }
                            minTime={
                              !editmode
                                ? publishValue
                                  ? expireValue?.$d.getDate() +
                                      expireValue?.$d.getMonth() +
                                      expireValue?.$d.getFullYear() ===
                                    publishValue?.$d.getDate() +
                                      publishValue?.$d.getMonth() +
                                      publishValue?.$d.getFullYear()
                                    ? moment(publishValue?.$d)
                                        .hours(moment(publishValue?.$d).hour())
                                        .minutes(
                                          moment(publishValue?.$d).minutes() + 5
                                        )
                                    : null
                                  : expireValue &&
                                    expireValue?.$d.getDate() +
                                      expireValue?.$d.getMonth() +
                                      expireValue?.$d.getFullYear() ===
                                      todayDate.getDate() +
                                        todayDate.getMonth() +
                                        todayDate.getFullYear()
                                  ? todayDate2
                                      .hours(todayDate2.hour())
                                      .minutes(todayDate2.minutes() + 5)
                                  : null
                                : newPublishOn &&
                                  new Date(newPublishOn).getDate() +
                                    new Date(newPublishOn).getMonth() +
                                    new Date(newPublishOn).getFullYear() ===
                                    new Date(newExpireOn).getDate() +
                                      new Date(newExpireOn).getMonth() +
                                      new Date(newExpireOn).getFullYear()
                                ? moment(newPublishOn)
                                    .hours(moment(newPublishOn).hour())
                                    .minutes(moment(newPublishOn).minutes() + 5)
                                : null
                            }
                            value={
                              !editmode
                                ? expireValue
                                : moment.utc(filterAlert.expireOn).format() ||
                                  newExpireOn
                            }
                            onChange={(newValue) => {
                              !editmode
                                ? setexpireValue(newValue)
                                : setFilterAlert({
                                    ...filterAlert,
                                    expireOn: newValue?.$d,
                                  });
                            }}
                            onError={(reason, value) => {
                              if (reason) {
                                setErrorExpireDate(true);
                              } else {
                                setErrorExpireDate(false);
                              }
                            }}
                          />
                        </Stack>
                      </LocalizationProvider>

                      {enddateInputIsInvalid && (
                        <p className="e-message">
                          Please enter a valid Expiry Date
                        </p>
                      )}
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setTouched(defaultState);
                    if (!editmode) {
                      setBase64Img("");
                      setAlert({ ...initialState, localImage: "" });
                      resetAllValues();
                      setPublishValue(null);
                      setexpireValue(null);
                    } else {
                      setEditMode(false);
                      setSelectNotification("");
                      setSelectedUsers([]);
                      setFilterAlert({
                        ...filterAlert,
                        imageBase64String: "",
                        localImage: "",
                      });
                      setUsersList([]);
                    }
                  }}
                >
                  Cancel
                </button>
                <button className="btn btn-genmeb ms-3" onClick={submitHandler}>
                  {editmode ? "Update" : "Publish"}
                </button>
              </div>
            </div>
          </div>
        )}

        {(submitted && alertState.isLoading) && (
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content edit-success">
              <div className="modal-body text-center pt-0">
                <p>
                  Processing notification...
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-genmeb ms-3"
                  data-bs-dismiss="modal">
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}
        {(submitted && !alertState.isLoading && alertState.response.isSuccess) && (
            <div className="modal-dialog modal-dialog-centered ">
              <div className="modal-content edit-success">
                <div className="modal-body text-center pt-0">
                  {<img src={sucess} alt="icon" className="m-3" />}
                  <p className="green-text">Success</p>
                  <p>
                    {editmode
                        ? "Notification Updated Successfully"
                        : "New Notification Created Successfully"}
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                      type="button"
                      className="btn btn-genmeb ms-3"
                      data-bs-dismiss="modal"
                      onClick={successHandler}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
        )}
        {(submitted && !alertState.isLoading && alertState.error) && (
            <div className="modal-dialog modal-dialog-centered ">
              <div className="modal-content edit-success">
                <div className="modal-body text-center pt-0">
                  {<img src={error} alt="icon" className="m-3" />}
                  <p className="red-text">Failed</p>
                  <p>
                    {editmode
                        ? "Notification could not be updated"
                        : "Notification could not be created"}
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                      type="button"
                      className="btn btn-genmeb ms-3"
                      data-bs-dismiss="modal"
                      onClick={successHandler}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
        )}

      </Modal>
      {/* // Start Notification Type */}
      <Modal
        modalId={"exampleModalToggle3"}
        modalLabel={"exampleModalToggleLabel2"}
      >
        <div
          className="modal-dialog modal-dialog-centered me-widht"
          name="flage"
          onBlur={handleFocus2}
        >
          <div className="modal-content alt2">
            <div className="modal-body p-0">
              <div className="s-all">
                <p>Select Notification Type</p>
                <img
                  src={up}
                  alt="icon"
                  className="up-icon"
                  data-bs-dismiss="modal"
                />
              </div>
              {!editmode
                ? notificationTypes
                    ?.filter((i) => i.status === true)
                    ?.map((type, index) => (
                      <div className="all-check" key={type.id}>
                        <p
                          style={{
                            textDecoration:
                              type.status === false ? "line-through" : "",
                          }}
                        >
                          {type.value}
                        </p>
                        {type.status === true && (
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flage"
                            value={type.value}
                            key={alert.flage}
                            onChange={() => setSelectNotification(type.value)}
                            checked={selectNotification === type.value}
                            id={`flexRadioDefault${type.id}`}
                          />
                        )}
                      </div>
                    ))
                : notificationTypes.map((type, index) => (
                    <div className="all-check" key={type.id}>
                      <p
                        style={{
                          color: type.status === false ? "#cccccc" : "",
                        }}
                      >
                        {type.value}
                      </p>

                      {type.status === true && (
                        <input
                          className="form-check-input"
                          type="radio"
                          name="notificationTypeID"
                          value={type.value}
                          key={filterAlert.notificationTypeID}
                          onChange={() => setSelectNotification(type.value)}
                          checked={selectNotification === type.value}
                          id={`flexRadioDefault${type.id}`}
                        />
                      )}
                    </div>
                  ))}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => dummy3(editmode)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-target="#exampleModalToggle"
                data-bs-dismiss="modal"
                onClick={() => submitNotificationType(selectNotification)}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* // end Notification Type */}

      {/* User Start */}
      <Modal
        modalId={"exampleModalToggle7"}
        modalLabel={"exampleModalToggleLabel7"}
      >
        <div
          className="modal-dialog modal-dialog-centered me-widht"
          name="users"
          onBlur={handleFocus4}
        >
          <div className="modal-content alt3">
            <div className="modal-body p-0">
              <div className="relative w-100">
                <img src={SearchIcon} alt="icon" className="pop-search" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={userSearchHandler}
                  value={userSearchInput}
                />
                <img
                  src={up}
                  alt="icon"
                  className="up-icon"
                  data-bs-target="#exampleModalToggle"
                  data-bs-dismiss="modal"
                />
              </div>

              {fetchedUsers?.length > 0 &&
                usersList.length > 0 &&
                !userSearchInput && (
                  <div className="s-all">
                    <p>Select All</p>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedUsers.length === usersList.length}
                      onChange={(e) => usersSelect(e)}
                    />
                  </div>
                )}
              {message && <div className="all-check">message</div>}
              {fetchedUsers?.length === 0 && (
                <div className="all-check">No User(s) Found</div>
              )}
              {fetchedUsers?.length > 0 &&
              usersList.length > 0 &&
              userSearchFilter.length === 0 ? (
                <div className="all-check">No Match Found</div>
              ) : (
                userSearchFilter.length > 0 &&
                userSearchFilter.map((user, index) => (
                  <div key={index} className="all-check">
                    <p>
                      {user.userName}
                      <span>{user.email}</span>
                    </p>
                    <input
                      type="checkbox"
                      name="users"
                      className="form-check-input"
                      value={user.userId}
                      checked={selectedUsers
                        .map((ir) => ir.userId)
                        .includes(user.userId)}
                      onChange={(e) => usersHandler(e, user)}
                    />
                  </div>
                ))
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  dummy2();
                  setUserSearchInput("");
                  setSelectChecked(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-target="#exampleModalToggle"
                data-bs-dismiss="modal"
                onClick={() => submitUsers(selectedUsers)}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Group Start */}
      <Modal
        modalId={"exampleModalToggle2"}
        modalLabel={"exampleModalToggleLabel2"}
      >
        <div
          className="modal-dialog modal-dialog-centered me-widht"
          name="groups"
          onBlur={handleFocus3}
        >
          <div className="modal-content alt1">
            <div className="modal-body p-0">
              <div className="relative w-100">
                <img src={SearchIcon} alt="icon" className="pop-search" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={groupsSearchHandler}
                  value={groupsSearchInput}
                />
                <img
                  src={up}
                  alt="icon"
                  className="up-icon"
                  data-bs-target="#exampleModalToggle"
                  data-bs-dismiss="modal"
                />
              </div>
              {!groupsSearchInput && (
                <div className="s-all">
                  <p>Select All</p>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={
                      selectGroupChecked ||
                      selectedGroups?.length === userGroups?.length
                    }
                    onChange={(e) => groupSelect(e)}
                  />
                </div>
              )}

              {userGroups && userGroups.length === 0 && (
                <div className="all-check">No Group Found</div>
              )}

              {groupsSearchFilter.length === 0 ? (
                <div className="all-check">No Match Found</div>
              ) : (
                groupsSearchFilter.length > 0 &&
                groupsSearchFilter.map((r) => (
                  <div className="all-check" key={r.id}>
                    <p>{r.value}</p>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="group"
                      value={r.id}
                      checked={selectedGroups.map((ir) => ir.id).includes(r.id)}
                      onChange={(e) => groupHandler(e, r)}
                    />
                  </div>
                ))
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  dummy();
                  setGroupsSearchInput("");
                  setSelectGroupChecked(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => submitGroupForUsers(selectedGroups)}
                className="btn btn-genmeb ms-3"
                data-bs-target="#exampleModalToggle"
                data-bs-dismiss="modal"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ usersList, alerts }) => ({
  loading: alerts.loading,
  fetchedUsers: usersList.usersList,
  message: usersList.message,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersListByRolesID: (payload) =>
    dispatch(actions.fetchUsersListByRolesID(payload)),
  createNewAlert: (payload) => dispatch(createNewAlert(payload)),
  updateNotification: (payload) => dispatch(updateAlertDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAlerts);
