import React from "react";
import { useState, useEffect } from "react";
import { DashboardLayout } from "../components/dashboard-layout";
import "../assets/css/myApp.css";
import "../assets/css/new-dashboard.css";
import brdCum from "../assets/images/new-images/brd-cum-icon.svg";
import MyApp from "./MyApp";
import Users from "./Users";
import Assignment from "./Assignment";
import Groups from "./Groups";
import { fetchAllUserRoles } from "../actions/action-master";
import { connect } from "react-redux";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

function AdminCenter({  }) {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
 
  return (
    <div>
      <AuthenticatedTemplate>
        <DashboardLayout>
          <section>
            <div className="breadcrumb">
              <img src={brdCum} alt="logo" />
              <p> |&nbsp;&nbsp; Admin Center</p>
            </div>
            <div className="">
              <ul
                className="nav nav-tabs Admin-center"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="assignments-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#assignments"
                    type="button"
                    role="tab"
                    aria-controls="assignments"
                    aria-selected="false"
                    onClick={() => handleTabClick(0)}
                  >
                    <span className="assiconshow tabActiveIcon"></span>
                    <span className="m-text ass-mobile">Assignments</span>
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="usersmanagement-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#usersmanagement"
                    type="button"
                    role="tab"
                    aria-controls="usersmanagement"
                    aria-selected="false"
                    onClick={() => handleTabClick(1)}
                  >
                    <span className="usericonshow tabActiveIcon"></span>
                    <span className="m-text u-mobile">Users Management</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link "
                    id="appsmanagement-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#appsmanagement"
                    type="button"
                    role="tab"
                    aria-controls="appsmanagement"
                    aria-selected="true"
                    onClick={() => handleTabClick(2)}
                  >
                    <span className="appiconshow tabActiveIcon"></span>
                    <span className="m-text a-mobile">Apps Management</span>
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="groups-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#groupTab"
                    type="button"
                    role="tab"
                    aria-controls="groupTab"
                    aria-selected="true"
                    onClick={() => handleTabClick(3)}
                  >
                    <span className="groupiconshow tabActiveIcon"></span>
                    <span className="m-text g-mobile"> Groups</span>
                  </button>
                </li>
              </ul>
              <div className="tab-content app-wrapper" id="myTabContent">
                <div
                  className="tab-pane fade  "
                  id="appsmanagement"
                  role="tabpanel"
                  aria-labelledby="appsmanagement-tab"
                >
                  {activeTab === 2 && <MyApp />}
                </div>

                <div
                  className="tab-pane fade"
                  id="usersmanagement"
                  role="tabpanel"
                  aria-labelledby="usersmanagement-tab"
                >
                  {activeTab === 1 && <Users />}
                </div>

                <div
                  className="tab-pane fade show active"
                  id="assignments"
                  role="tabpanel"
                  aria-labelledby="assignments-tab"
                >
                  {activeTab === 0 && <Assignment />}
                </div>

                <div
                  className="tab-pane fade"
                  id="groupTab"
                  role="tabpanel"
                  aria-labelledby="groupTab"
                >
                  {activeTab === 3 && <Groups />}
                </div>
              </div>
            </div>
          </section>
        </DashboardLayout>
      </AuthenticatedTemplate>
    </div>
  );
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  // fetchAllUserRoles: () => dispatch(fetchAllUserRoles()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminCenter);
