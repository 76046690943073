import React from "react";
import { useSelector } from "react-redux";
import "../assets/css/myApp.css";

const ErrorPage = () => {
  const errorMessage = useSelector((state) => state.usersList.dashError);
  return (
    <div className="err-page">
      {errorMessage
        ? "Something went wrong. Please open the App in a new browser."
        : "You do not have access to the Launchpad application. To request access to Launchpad, or believe you should already have access to Launchpad, please reach out to the Launchpad support team through the following email:BIS@genmab.com."}
    </div>
  );
};

export default ErrorPage;
