import React, { useState, useEffect } from "react";
import edit from "../../assets/images/new-images/edit.png";
import SearchIcon from "../../assets/images/search.svg";
import up from "../../assets/images/new-images/up.png";
import Modal from "../UI/Modal";
import sucess from "../../assets/images/new-images/sucess.png";
import failure from "../../assets/images/new-images/error.png";
import { connect } from "react-redux";
import * as actions from "../../actions/action-master";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import {
  createNewGroupRule,
  updateRule,
  triggerGroupRules,
  modifyTriggerGroupRules,
} from "../../actions/action-grouprule";
import AddGroups from "./AddRule/AddGroups";
import AddDepartment from "./AddRule/AddDepartment";
import AddLocation from "./AddRule/AddLocation";
import AddDepartmentUsers from "./AddRule/AddDepartmentUsers";
import AddApp from "./AddRule/AddApp";
import AddDepartmentModal from "./Modals/AddDepartmentModal";
import AddLocationsModal from "./Modals/AddLocationsModal";
import AddGroupsModal from "./Modals/AddGroupsModal";
import AddAppsModal from "./Modals/AddAppsModal";
import AddUsersModal from "./Modals/AddUsersModal";

const AddGroupRule = ({
  editmode,
  setEditMode,
  setFilterRuleIndex,
  filterRule,
  setFilterRule,
  createGroupRule,
  filterRuleIndex,
  groupRules,
  setGroupRules,
  message,
  loading,
  statusCode,
  groupsRules,
  editType,
  fetchedUsers = [],
  updateRule,
  createdRuleId,
  triggerGroupRules,
  modifyTriggerGroupRules,
  groupsList,
  ...props
}) => {
  const initialState = {
    ruleName: "",
    deptNames: [],
    groupIds: [],
    exceptUsers: [],
    applicationIds: [],
    locations: [],
  };

  const [rule, setRule] = useState(initialState);

  useEffect(() => {
    {
      editmode ? setRule(filterRule) : setRule(initialState);
    }
  }, [editmode, setRule]);

  const handleChange = (evt) => {
    if (!editmode) {
      setRule({ ...rule, [evt.target.name]: evt.target.value });
    } else {
      setFilterRule({
        ...filterRule,
        [evt.target.name]: evt.target.value,
      });
    }
  };

  // Users Start

  const [usersList, setUsersList] = useState([]);

  const [userSearchInput, setUserSearchInput] = useState("");

  const [selectChecked, setSelectChecked] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Locations Start

  const [locationsList, setLocationsList] = useState([]);

  const [locationSearchInput, setLocationSearchInput] = useState("");

  const [selectLocationChecked, setSelectLocationChecked] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);

  // departments Start

  const [depts, setDepts] = useState([]);
  const [deptSearchInput, setDeptSearchInput] = useState("");

  const [selectdeptChecked, setSelectDeptChecked] = useState(false);
  const [selectedDepts, setSelectedDepts] = useState([]);

  //Groups Start

  const [userGroups, setUserGroups] = useState([]);
  const [groupsSearchInput, setGroupsSearchInput] = useState("");

  const [selectGroupChecked, setSelectGroupChecked] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);

  // Apps Start

  const [userApps, setUserApps] = useState([]);
  const [appsSearchInput, setAppsSearchInput] = useState("");

  const [selectAppChecked, setSelectAppChecked] = useState(false);
  const [selectedApps, setSelectedApps] = useState([]);

  //Form Validation

  const defaultState = {
    ruleName: false,
    deptNames: false,
    groupIds: false,
    exceptUsers: false,
    applicationIds: false,
  };
  const [touched, setTouched] = useState(defaultState);

  const handleFocus = (e) => {
    let { name } = e.target;
    setTouched({ ...touched, [name]: true });
  };
  const handleDeptFocus = (e) => {
    setTouched({ ...touched, deptNames: true });
  };
  const handleGroupFocus = (e) => {
    setTouched({ ...touched, groupIds: true });
  };
  const handleAppFocus = (e) => {
    setTouched({ ...touched, applicationIds: true });
  };

  const enteredTitleIsValid = editmode
    ? filterRule.ruleName.trim() !== ""
    : rule.ruleName.trim() !== "";
  const titleInputIsInvalid =
    (!enteredTitleIsValid && touched.ruleName) ||
    (touched.deptNames && !enteredTitleIsValid) ||
    (touched.groupIds && !enteredTitleIsValid) ||
    (touched.applicationIds && !enteredTitleIsValid);

  let deptValidFilter =
    depts && depts.filter((dept) => dept.ischecked === true);
  const enteredDeptIsValid = deptValidFilter.length > 0;
  const deptsInputIsInvalid =
    (!enteredDeptIsValid && touched.deptNames) ||
    (touched.groupIds && !enteredDeptIsValid) ||
    (touched.applicationIds && !enteredDeptIsValid);

  let groupValidFilter =
    userGroups && userGroups.filter((group) => group.ischecked === true);
  const enteredGroupIsValid = groupValidFilter.length > 0;
  const groupsInputIsInvalid =
    (!enteredGroupIsValid && touched.groupIds) ||
    (touched.applicationIds && !enteredGroupIsValid);

  let appValidFilter =
    userApps && userApps.filter((app) => app.ischecked === true);
  const enteredAppIsValid = appValidFilter.length > 0;
  const appsInputIsInvalid = !enteredAppIsValid && touched.applicationIds;

  // Form Submission

  const [submitted, setSubmitted] = useState(false);

  const successHandler = () => {
    setSubmitted(!submitted);
    setEditMode(false);

    let editPayloadId = filterRule.ruleId;
    let payloadId = createdRuleId;
    statusCode &&
      statusCode === 200 &&
      (!editmode
        ? triggerGroupRules(payloadId)
        : modifyTriggerGroupRules(editPayloadId));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTouched({
      ruleName: true,
      deptNames: true,
      groupIds: true,
      exceptUsers: true,
      applicationIds: true,
    });

    if (
      !enteredTitleIsValid ||
      !enteredDeptIsValid ||
      !enteredAppIsValid ||
      !enteredGroupIsValid
    ) {
      return;
    }
    if (editmode) {
      let data = {
        ruleId: filterRule.ruleId,
        ruleName: filterRule.ruleName.trim(),
        deptNames: depts
          .filter((i) => i.ischecked === true)
          .map((item) => item.value),
        locations:
          locationsList?.length === 0
            ? []
            : locationsList
                .filter((i) => i.checked === true)
                .map((item) => item.cityState),
        applicationIds: userApps
          .filter((i) => i.ischecked === true)
          .map((item) => item.appId),
        createdBy: 0,
        exceptUsers:
          usersList?.length === 0
            ? []
            : usersList.filter((i) => i.checked === true).map((item) => item),
        groupIds: userGroups
          .filter((i) => i.ischecked === true)
          .map((item) => item.id),
      };

      updateRule(data);
    } else {
      let data = {
        ruleId: 0,
        groupIds: userGroups
          .filter((i) => i.ischecked === true)
          .map((item) => item.id),
        ruleName: rule.ruleName.trim(),
        createdBy: 0,
        locations:
          locationsList?.length === 0
            ? []
            : locationsList
                .filter((i) => i.checked === true)
                .map((item) => item.cityState),
        deptNames: depts
          .filter((i) => i.ischecked === true)
          .map((item) => item.value),
        applicationIds: userApps
          .filter((i) => i.ischecked === true)
          .map((item) => item.appId),
        exceptUsers:
          usersList?.length === 0
            ? []
            : usersList.filter((i) => i.checked === true).map((item) => item),
      };

      props.createNewGroupRule(data);

      setDepts(
        depts.map((item) => {
          return {
            ...item,
            ischecked: false,
          };
        })
      );
      setUsersList(
        usersList.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setLocationsList(
        locationsList.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );

      setUserGroups(
        userGroups.map((item) => {
          return {
            ...item,
            ischecked: false,
          };
        })
      );

      setUserApps(
        userApps.map((item) => {
          return {
            ...item,
            ischecked: false,
          };
        })
      );
    }

    setRule(initialState);
    setTouched(defaultState);
    setSubmitted(true);
    setSelectChecked(false);
    setSelectLocationChecked(false);
    setSelectDeptChecked(false);
    setSelectGroupChecked(false);
    setSelectAppChecked(false);
    setSelectedDepts([]);
    setSelectedUsers([]);
    setSelectedLocations([]);
    setSelectedGroups([]);
    setSelectedApps([]);
    setUsersList([]);
    setLocationsList([]);
    setConflictEdit(false);
  };

  const resetAllValues = () => {
    setDepts(
      depts.map((item) => {
        return {
          ...item,
          ischecked: false,
        };
      })
    );
    setUserGroups(
      userGroups.map((item) => {
        return {
          ...item,
          ischecked: false,
        };
      })
    );
    setUserApps(
      userApps.map((item) => {
        return {
          ...item,
          ischecked: false,
        };
      })
    );
    setSelectChecked(false);
    setSelectLocationChecked(false);
    setSelectDeptChecked(false);
    setSelectGroupChecked(false);
    setSelectAppChecked(false);
    setSelectedDepts([]);
    setSelectedUsers([]);
    setSelectedLocations([]);
    setSelectedGroups([]);
    setSelectedApps([]);
    setUsersList([]);
    setLocationsList([]);

    setGroupsSearchInput("");
    setAppsSearchInput("");
    setDeptSearchInput("");
    setUserSearchInput("");
    setLocationSearchInput("");
  };

  const [conflictEdit, setConflictEdit] = useState(false);

  return (
    <>
      <section>
        <Modal
          modalId="AddGroupRule"
          modalLabel="exampleModalToggleLabel"
          className="modal fade"
        >
          <div className="modal-dialog modal-dialog-centered m-widht">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="AddGroupRuleLabel">
                  {editmode ? "Update Group Rule" : " Add Group Rule"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setTouched(defaultState);
                    if (!editmode) {
                      setRule(initialState);
                      resetAllValues();
                    } else {
                      setEditMode(false);

                      setFilterRule({
                        filterRule,
                      });
                      setSelectedGroups([]);
                      setUserGroups([]);
                      setUserApps([]);
                      setSelectGroupChecked(false);
                      setSelectAppChecked(false);
                      setSelectedApps([]);
                      setSelectedLocations([]);
                      setSelectedUsers([]);
                      setUsersList([]);
                      setLocationsList([]);
                      setConflictEdit(false);
                    }
                  }}
                ></button>
              </div>

              <form className="assignment-form" onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">
                      Rule Name<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Create a Rule Name"
                      onChange={handleChange}
                      onBlur={handleFocus}
                      name="ruleName"
                      maxLength="30"
                      value={
                        !editmode
                          ? rule.ruleName || ""
                          : filterRule.ruleName || ""
                      }
                    />
                    {titleInputIsInvalid && (
                      <p className="e-message">Please enter a Rule Name</p>
                    )}
                  </div>

                  <div className="grid-6">
                    <AddDepartment
                      depts={depts}
                      deptsInputIsInvalid={deptsInputIsInvalid}
                    />
                    <AddLocation locationsList={locationsList} />
                  </div>
                  <div className="grid-6">
                    <AddGroups
                      userGroups={userGroups}
                      groupsInputIsInvalid={groupsInputIsInvalid}
                      editmode={editmode}
                    />
                    <AddApp
                      userApps={userApps}
                      appsInputIsInvalid={appsInputIsInvalid}
                    />
                  </div>

                  <AddDepartmentUsers usersList={usersList} />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setTouched(defaultState);
                      if (!editmode) {
                        setRule(initialState);
                        resetAllValues();
                      } else {
                        setEditMode(false);

                        setFilterRule({
                          filterRule,
                        });

                        setSelectedGroups([]);
                        setUserGroups([]);
                        setUserApps([]);
                        setSelectAppChecked(false);
                        setSelectGroupChecked(false);
                        setSelectedApps([]);
                        setSelectedLocations([]);
                        setSelectedUsers([]);
                        setUsersList([]);
                        setLocationsList([]);
                        setConflictEdit(false);
                      }
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-genmeb ms-3"
                    data-bs-toggle="modal"
                    data-bs-target="#errormodal"
                    data-bs-dismiss="modal"
                    disabled={
                      !enteredTitleIsValid ||
                      !enteredAppIsValid ||
                      !enteredDeptIsValid ||
                      !enteredGroupIsValid
                    }
                  >
                    {editmode ? "Update and Run" : "Save and Run"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </section>
      <section>
        <Modal
          modalId="errormodal"
          modalLabel="exampleModalToggleLabel"
          className="modal fade"
        >
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content edit-success">
              <div className="modal-body text-center pt-0 type-loader">
                {submitted === true && loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      position: "absolute",
                      top: "30%",
                      width: "100%",
                      height: "300PX",
                      justifyContent: "center",
                      alignItems: "start",
                      // background: "#000",
                      zIndex: 99,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <img
                      src={
                        statusCode && (statusCode === 400 ? failure : sucess)
                      }
                      alt="icon"
                      className="m-3"
                    />
                    <p
                      className={`${
                        statusCode &&
                        (statusCode === 400 ? "red-text" : "green-text")
                      }`}
                    >
                      {statusCode &&
                        (statusCode === 400 ? "Failure" : "Success")}
                    </p>
                    <p>
                      {statusCode &&
                        (statusCode === 400
                          ? message
                          : editmode
                          ? "Group Rule Updated Successfully"
                          : "New Group Rule Created Successfully")}
                    </p>

                    <div>
                      {statusCode &&
                        statusCode === 400 &&
                        message ===
                          "Rule has been already created for the selected Groups" && (
                          <table className="table table-hover conflict-table">
                            <thead className="table-head">
                              <tr>
                                <th>Rule Name</th>
                                <th>Group Name</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {groupsRules
                                ?.filter((g) =>
                                  createdRuleId.includes(g.ruleId)
                                )
                                ?.map((d, index) => (
                                  <tr key={d.ruleId}>
                                    <td>{d.ruleName}</td>
                                    <td>
                                      {d.groups?.map((a) => a.value).join(", ")}
                                    </td>
                                    <td>
                                      <span className="d-flex">
                                        <img
                                          src={edit}
                                          alt="icon"
                                          className="me-3"
                                          data-bs-toggle="modal"
                                          data-bs-target="#AddGroupRule"
                                          data-bs-dismiss="modal"
                                          onClick={() => {
                                            editType(d.ruleId);
                                            editmode
                                              ? setConflictEdit(true)
                                              : setConflictEdit(false);
                                          }}
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        )}
                    </div>

                    <div>
                      {statusCode &&
                        statusCode === 400 &&
                        message === "Group Rule Name Already Exists" && (
                          <table className="table table-hover conflict-table">
                            <thead className="table-head">
                              <tr>
                                <th>Rule Name</th>
                                <th>Group Name</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {groupsRules
                                ?.filter((g) =>
                                  createdRuleId.includes(g.ruleId)
                                )
                                ?.map((d, index) => (
                                  <tr key={d.ruleId}>
                                    <td>{d.ruleName}</td>
                                    <td>
                                      {d.groups?.map((a) => a.value).join(", ")}
                                    </td>
                                    <td>
                                      <span className="d-flex">
                                        <img
                                          src={edit}
                                          alt="icon"
                                          className="me-3"
                                          data-bs-toggle="modal"
                                          data-bs-target="#AddGroupRule"
                                          data-bs-dismiss="modal"
                                          onClick={() => {
                                            editType(d.ruleId);
                                            editmode
                                              ? setConflictEdit(true)
                                              : setConflictEdit(false);
                                          }}
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        )}
                    </div>
                  </>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-genmeb ms-3"
                  data-bs-dismiss="modal"
                  onClick={successHandler}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </section>

      <AddDepartmentModal
        depts={depts}
        setDepts={setDepts}
        deptSearchInput={deptSearchInput}
        setDeptSearchInput={setDeptSearchInput}
        selectdeptChecked={selectdeptChecked}
        setSelectDeptChecked={setSelectDeptChecked}
        selectedDepts={selectedDepts}
        setSelectedDepts={setSelectedDepts}
        editmode={editmode}
        filterRule={filterRule}
        setUsersList={setUsersList}
        setSelectChecked={setSelectChecked}
        handleDeptFocus={handleDeptFocus}
        deptsInputIsInvalid={deptsInputIsInvalid}
        setLocationsList={setLocationsList}
        setSelectLocationChecked={setSelectLocationChecked}
        selectedLocations={selectedLocations}
        setSelectedLocations={setSelectedLocations}
        setSelectedUsers={setSelectedUsers}
        locationsList={locationsList}
        selectedUsers={selectedUsers}
        conflictEdit={conflictEdit}
      />
      <AddLocationsModal
        locationsList={locationsList}
        setLocationsList={setLocationsList}
        locationSearchInput={locationSearchInput}
        setLocationSearchInput={setLocationSearchInput}
        selectLocationChecked={selectLocationChecked}
        setSelectLocationChecked={setSelectLocationChecked}
        selectedLocations={selectedLocations}
        filterRule={filterRule}
        editmode={editmode}
        setSelectedLocations={setSelectedLocations}
        selectedDepts={selectedDepts}
        depts={depts}
        conflictEdit={conflictEdit}
      />
      <AddGroupsModal
        userGroups={userGroups}
        setUserGroups={setUserGroups}
        groupsSearchInput={groupsSearchInput}
        setGroupsSearchInput={setGroupsSearchInput}
        selectGroupChecked={selectGroupChecked}
        setSelectGroupChecked={setSelectGroupChecked}
        selectedGroups={selectedGroups}
        setSelectedGroups={setSelectedGroups}
        editmode={editmode}
        filterRule={filterRule}
        handleGroupFocus={handleGroupFocus}
        conflictEdit={conflictEdit}
      />
      <AddAppsModal
        userApps={userApps}
        setUserApps={setUserApps}
        appsSearchInput={appsSearchInput}
        setAppsSearchInput={setAppsSearchInput}
        selectAppChecked={selectAppChecked}
        setSelectAppChecked={setSelectAppChecked}
        selectedApps={selectedApps}
        setSelectedApps={setSelectedApps}
        editmode={editmode}
        filterRule={filterRule}
        handleAppFocus={handleAppFocus}
        appsInputIsInvalid={appsInputIsInvalid}
        conflictEdit={conflictEdit}
      />
      <AddUsersModal
        usersList={usersList}
        setUsersList={setUsersList}
        userSearchInput={userSearchInput}
        setUserSearchInput={setUserSearchInput}
        selectChecked={selectChecked}
        setSelectChecked={setSelectChecked}
        selectedUsers={selectedUsers}
        filterRule={filterRule}
        editmode={editmode}
        setSelectedUsers={setSelectedUsers}
        selectedDepts={selectedDepts}
        conflictEdit={conflictEdit}
      />
    </>
  );
};

const mapStateToProps = ({ master, groupsRule }) => ({
  message: groupsRule.rulesResponse,
  loading: groupsRule.rulesResponseLoading,
  statusCode: groupsRule.rulesResponseStatusCode,
  fetchedUsers: groupsRule?.usersList?.users,
  createdRuleId: groupsRule.createdRuleId,
  groupsList: master.userGroups,
});

const mapDispatchToProps = (dispatch) => ({
  createNewGroupRule: (payload) => dispatch(createNewGroupRule(payload)),
  updateRule: (payload) => dispatch(updateRule(payload)),
  triggerGroupRules: (payload) => dispatch(triggerGroupRules(payload)),
  modifyTriggerGroupRules: (payload) =>
    dispatch(modifyTriggerGroupRules(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddGroupRule);
