import React from "react";
import Modal from "../UI/Modal";
import { connect } from "react-redux";
import sucess from "../../assets/images/new-images/sucess.png";
import dltIcon from "../../assets/images/new-images/dlt.png";
import { useState } from "react";
import { deleteUserGroup } from "../../actions/action-master";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import failure from "../../assets/images/new-images/error.png";
const DeleteUserGroup = ({
  deleteId,
  deleteUserGroup,
  message,
  loading,
  ...props
}) => {
  const [typeDelete, setTypeDelete] = useState(true);

  const typesDeleteHandler = () => {
    setTypeDelete(!typeDelete);
  };

  const deleteType = (id) => {
    deleteUserGroup(id);
    setTypeDelete(!typeDelete);
  };
  return (
    <Modal
      modalId={"deleteGroupsModal"}
      modalLabel={"deleteGroupsModalLabel"}
      className={"dlt-modal"}
    >
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content">
          <div className="modal-body text-center pt-0 type-loader">
            {typeDelete ? (
              <>
                <img
                  src={typeDelete ? dltIcon : sucess}
                  alt="icon"
                  className="m-3"
                />

                {typeDelete && <p className="dlt-hed">Are you sure ?</p>}

                <p className="dlt-body">
                  {typeDelete
                    ? "Do you want to delete this record? Once deleted, this action cannot be undone"
                    : "User Group Deleted Successfully"}
                </p>
              </>
            ) : (
              loading ?
                <Box
                  sx={{
                    display: "flex",
                    position: "absolute",
                    top: "30%",
                    width: "100%",
                    height: "300PX",
                    justifyContent: "center",
                    alignItems: "start",
                    // background: "#000",
                    zIndex: 99,
                  }}
                >
                  <CircularProgress />
                </Box>
               : 
              <>
                <img src={message && (message === 404 ? failure : sucess)} alt="icon" className="m-3" />
                
                <p className={`${message && (message === 404 ? "red-text" : "green-text")}`}>
                    {message && (message === 404 ? "Failure" : "Success")}
                  </p>
                  
                  <p className="dlt-body">
                    {message &&
                      (message === 404
                        ? "You cannot delete the group until you reassign the user(s) associated with this group to another group"
                        
                        : "User Group Deleted Successfully"
                       )}
                  </p>

               
              </>
            )}
          </div>
          <div className="modal-footer">
            {typeDelete && (
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            )}

            {typeDelete ? (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                onClick={() => deleteType(deleteId)}
              >
                Delete
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-dismiss="modal"
                onClick={typesDeleteHandler}
              >
                Ok
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
const mapStateToProps = ({ master }) => ({
  message: master.deleteResponse,
  loading: master.deleteResponseLoading,
});

const mapDispatchToProps = (dispatch) => ({
  deleteUserGroup: (payload) => dispatch(deleteUserGroup(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserGroup);
