import React, { useEffect } from "react";
import { fetchUsersListByDepartment } from "../../../actions/action-grouprule";
import SearchIcon from "../../../assets/images/search.svg";
import up from "../../../assets/images/new-images/up.png";
import * as actions from "../../../actions/action-master";
import { connect } from "react-redux";
import Modal from "../../UI/Modal";
import { Loader } from "rsuite";

const AddLocationsModal = ({
  fetchedLocations = [],
  locationsList,
  setLocationsList,
  locationSearchInput,
  setLocationSearchInput,
  selectLocationChecked,
  setSelectLocationChecked,
  selectedLocations,
  setSelectedLocations,
  filterRule,
  editmode,
  message,
  selectedDepts,
  fetchUsersListByDepartment,
  locationsLoading,
  conflictEdit,
  ...props
}) => {
  const locationSearchHandler = (e) => {
    setLocationSearchInput(e.target.value);
  };

  let locationSearchFilter =
    locationsList &&
    locationsList?.length &&
    locationsList.filter((item) => {
      if (locationSearchInput === "") {
        return item;
      } else if (
        item.cityState.toLowerCase().includes(locationSearchInput.toLowerCase())
      ) {
        return item;
      }
      return null;
    });
  const locationsSelect = (e) => {
    setSelectLocationChecked(e.target.checked);

    if (e.target.checked) {
      setSelectedLocations(
        locationsList.map((i) => {
          return {
            ...i,
            checked: e.target.checked,
          };
        })
      );
    } else if (e.target.checked === false) {
      setSelectedLocations([]);
    }
  };

  const locationsHandler = (e, val) => {
    if (e.target.checked) {
      setSelectedLocations([...selectedLocations, val]);
    } else if (e.target.checked === false) {
      setSelectLocationChecked(false);
      setSelectedLocations(
        selectedLocations.filter((i) => i.cityState !== val.cityState)
      );
    }
  };

  const submitLocations = (values) => {
    setLocationsList(
      locationsList.map((obj) => {
        if (values.find((ol) => ol.cityState === obj.cityState)) {
          return {
            ...obj,
            checked: true,
          };
        } else {
          return { ...obj, checked: false };
        }
      })
    );

    const payload = {
      deptNames:
        selectedDepts?.length > 0 ? selectedDepts?.map((i) => i.value) : [],
      locations: values.map((i) => i.cityState),
    };

    fetchUsersListByDepartment(payload);
    setLocationSearchInput("");
  };

  useEffect(() => {
    if (editmode) {
      let newVal = {};
      newVal =
        fetchedLocations?.length > 0 &&
        fetchedLocations?.map((item) => {
          if (
            [...new Set(filterRule.locations)]
              .map((i) => i.value)
              .includes(item.cityState)
          ) {
            return { ...item, checked: true };
          }
          return { ...item, checked: false };
        });

      if (newVal?.length > 0) {
        setLocationsList(newVal);
      }
      let newArr = [];
      let test = fetchedLocations?.filter((item) =>
        filterRule.locations.map((i) => i.value).includes(item.cityState)
      );
      for (let i = 0; i < test.length; i++) {
        if (selectedLocations?.indexOf(test[i]) === -1) {
          newArr.push(test[i]);
        }
      }
      if (newArr?.length > 0) {
        setSelectedLocations(newArr);
      } else {
        fetchedLocations?.length > 0 && setSelectedLocations([]);
      }
      // if (newArr?.length > 0 ){ setSelectedLocations(newVal?.filter((i) => i.checked === true))}
      // else{setSelectedLocations([])};
      if (
        locationsList?.filter((i) => i.checked === true).length ===
        fetchedLocations?.length
      ) {
        setSelectLocationChecked(true);
      }
    } else if (selectedLocations.length > 0) {
      setLocationsList(
        fetchedLocations?.map((obj) => {
          if (selectedLocations?.find((ol) => ol.id === obj.id)) {
            return {
              ...obj,
              checked: true,
            };
          } else {
            return { ...obj, checked: false };
          }
        })
      );
    } else {
      fetchedLocations.length > 0 &&
        setLocationsList(
          fetchedLocations?.length > 0 &&
            fetchedLocations?.map((item) => {
              return { ...item, checked: false };
            })
        );
    }
  }, [fetchedLocations, editmode, conflictEdit]);

  const cancelLocations = () => {
    if (
      locationsList.length > 0 &&
      locationsList.filter((i) => i.checked === true)?.length > 0
    ) {
      setSelectedLocations(locationsList.filter((i) => i.checked === true));
    } else {
      setSelectedLocations([]);
    }
  };

  return (
    <Modal
      className="modal fade"
      modalId={"locationOpen"}
      modalLabel={"examplelocationOpen"}
    >
      <div className="modal-dialog modal-dialog-centered me-widht">
        <div className="modal-content location-side">
          <div className="modal-body p-0">
            <div>
              <div className="relative w-100">
                {locationsList.length > 0 && (
                  <>
                    <img
                      src={SearchIcon}
                      alt="icon"
                      className="pop-search top"
                    />{" "}
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      onChange={locationSearchHandler}
                      value={locationSearchInput}
                    />
                  </>
                )}
                <img
                  src={up}
                  alt="icon"
                  className="up-icon"
                  data-bs-dismiss="modal"
                />
              </div>
              {selectedDepts?.length === 0 ? (
                <div className="all-check height-full">
                  No Location(s) Found
                </div>
              ) : locationsLoading ? (
                <Loader />
              ) : (
                <div className="scroll-height">
                  {locationsList?.length > 0 && !locationSearchInput && (
                    <div className="s-all">
                      <p>Select All</p>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={
                          selectedLocations.length === locationsList.length
                        }
                        onChange={(e) => locationsSelect(e)}
                      />
                    </div>
                  )}
                  {/* <div className="all-check">{message}</div> */}
                  {locationsList?.length === 0 && (
                    <div className="all-check">No Location(s) Found</div>
                  )}
                  {locationsList?.length > 0 &&
                  locationSearchFilter?.length === 0 ? (
                    <div className="all-check">No Match Found</div>
                  ) : (
                    locationSearchFilter?.length > 0 &&
                    locationSearchFilter?.map((user, index) => (
                      <div key={index} className="all-check">
                        <p>{user.cityState}</p>
                        <input
                          type="checkbox"
                          name="locations"
                          className="form-check-input"
                          value={user?.cityState}
                          checked={selectedLocations
                            ?.map((ir) => ir.cityState)
                            ?.includes(user.cityState)}
                          onChange={(e) => locationsHandler(e, user)}
                        />
                      </div>
                    ))
                  )}
                </div>
              )}

              <div className="drop-btn-set">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    cancelLocations();
                    setLocationSearchInput("");
                    setSelectLocationChecked(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => submitLocations(selectedLocations)}
                  type="button"
                  className="btn btn-genmeb ms-3"
                  data-bs-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ master, groupsRule }) => ({
  fetchedLocations: master?.locations,
  locationsLoading: master?.locationsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersListByDepartment: (payload) =>
    dispatch(fetchUsersListByDepartment(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLocationsModal);
