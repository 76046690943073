import { SET_APPS_DATA, SET_APP_EXISTS } from "../actions/constants";

const initialstate = {
  appsData: [],
  apps: [],
};

export const appsData = (state = initialstate, action) => {
  switch (action.type) {
    case SET_APPS_DATA:
      return { ...state, appsData: action.payload };
    case SET_APP_EXISTS:
      return {...state, apps: action.payload }
    default:
      return state;
  }
};
