import queryString from "query-string";
import Service from "../lib/Service";
import { usersActions, SET_USERS_DATA } from "./constants";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {INVALID_BULK_UPLOAD_FILE_FORMAT} from "../constants";

export const fetchDashboarData = () => async (dispatch, getState) => {
  const offsetTime2 = encodeURIComponent(moment().local().format());
  const userEmpId = sessionStorage.getItem("userEmpId")
    ? sessionStorage.getItem("userEmpId")
    : "";
  try {
    dispatch({
      type: usersActions.FETCH_DASH_SUMMARY_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetUserSummaryByEmail",
      queryString: `&dateTimeOffset=${offsetTime2}&empId=${userEmpId}&PageNumber=${1}&PageSize=${10}`,
    }).then((res) => {
      sessionStorage.setItem("dataLoad", true);
      if (res.isSuccess) return res;
    });
    dispatch({
      type: usersActions.FETCH_DASH_SUMMARY_SUCCESS,
      payload: {
        payload: data.responseData !== null ? data.responseData : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: usersActions.FETCH_DASH_SUMMARY_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const fetchUsersListByRolesID = (ids) => async (dispatch, getState) => {
  const payload = {
    groupIdList: ids,
  };
  try {
    dispatch({
      type: usersActions.FETCH_ALL_USER_LIST_BY_ROLE_START,
      loading: true,
    });

    const data = await Service.POST({
      name: "GetAllUserListByGroupId",
      queryString: "",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: usersActions.FETCH_ALL_USER_LIST_BY_ROLE_SUCCESS,
      payload: {
        test: data.responseData !== null ? data.responseData : [],
        message: data.responseData === null ? "" : "",
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: usersActions.FETCH_ALL_USER_LIST_BY_ROLE_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const setUsersData = (value) => ({
  type: SET_USERS_DATA,
  payload: value,
});

export const dateFilter = (date) => async (dispatch) => {
  dispatch({
    type: usersActions.FETCH_USER_NOTIFICATION_FITLER_DATES,
    payload: date,
  });
};

export const fetchUsersPopUpInfo = (value) => async (dispatch, getState) => {
  const payload = {
    employeeId: value,
  };

  try {
    dispatch({
      type: usersActions.FETCH_USERS_POPUP_PROFILE_START,
      loading: true,
    });

    const data = await Service.POST({
      name: "GetUsersPopupProfile",
      queryString: "",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: usersActions.FETCH_USERS_POPUP_PROFILE_SUCCESS,
      payload: {
        profile: data.responseData !== null ? data.responseData : [],
        message: data.responseData === null ? "" : "",
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: usersActions.FETCH_USERS_POPUP_PROFILE_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const fetchUsersProfiePhoto = (value) => async (dispatch, getState) => {
  const payload = {
    employeeId: value,
  };

  try {
    dispatch({
      type: usersActions.FETCH_USERS_PHOTO_START,
      loading: true,
    });

    const data = await Service.POST({
      name: "GetUsersProfilePhoto",
      queryString: "",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: usersActions.FETCH_USERS_PHOTO_SUCCESS,
      payload: {
        photo: data.responseData !== null ? data.responseData : [],
        message: data.responseData === null ? "" : "",
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: usersActions.FETCH_USERS_PHOTO_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const fetchHeaderUsersPopUpInfo =
  (value) => async (dispatch, getState) => {
    const payload = {
      employeeId: value,
    };

    try {
      dispatch({
        type: usersActions.FETCH_HEADER_USERS_POPUP_PROFILE_START,
        loading: true,
      });

      const data = await Service.POST({
        name: "GetUsersPopupProfile",
        queryString: "",
        payload: payload,
      }).then((res) => {
        if (res.isSuccess) {
          return res;
        }
      });
      dispatch({
        type: usersActions.FETCH_HEADER_USERS_POPUP_PROFILE_SUCCESS,
        payload: {
          profile: data.responseData !== null ? data.responseData : [],
          message: data.responseData === null ? "" : "",
          loading: false,
        },
      });
    } catch (error) {
      dispatch({
        type: usersActions.FETCH_HEADER_USERS_POPUP_PROFILE_FAILED,
        payload: {
          loading: false,
          error: error.message,
        },
      });
    }
  };

export const fetchHeaderUserProfiePhoto =
  (value) => async (dispatch, getState) => {
    const payload = {
      employeeId: value,
    };

    try {
      dispatch({
        type: usersActions.FETCH_HEADER_USERS_PHOTO_START,
        loading: true,
      });

      const data = await Service.POST({
        name: "GetUsersProfilePhoto",
        queryString: "",
        payload: payload,
      }).then((res) => {
        sessionStorage.setItem("photoLoad", true);
        if (res.isSuccess) {
          return res;
        }
      });
      dispatch({
        type: usersActions.FETCH_HEADER_USERS_PHOTO_SUCCESS,
        payload: {
          photo: data.responseData !== null ? data.responseData : [],
          message: data.responseData === null ? "" : "",
          loading: false,
        },
      });
    } catch (error) {
      dispatch({
        type: usersActions.FETCH_HEADER_USERS_PHOTO_FAILED,
        payload: {
          loading: false,
          error: error.message,
        },
      });
    }
  };

export const fetchDashboardApps = () => async (dispatch, getState) => {
  const userEmpId = sessionStorage.getItem("userEmpId")
    ? sessionStorage.getItem("userEmpId")
    : "";
  try {
    dispatch({
      type: usersActions.FETCH_DASH_APPS_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetApplicationsforDashboard",
      queryString: `empId=${userEmpId}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: usersActions.FETCH_DASH_APPS_SUCCESS,
      payload: {
        payload:
          data.responseData !== null ? data.responseData.applications : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: usersActions.FETCH_DASH_APPS_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};


export const fetchAllUsers = (value) => async (dispatch, getState) => {
  const userEmpId = sessionStorage.getItem("userEmpId")
    ? sessionStorage.getItem("userEmpId")
    : "";
  try {
    dispatch({
      type: usersActions.FETCH_ALL_USERS_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetAllUsers",
      queryString: `IsAdminInclude=${value}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: usersActions.FETCH_ALL_USERS_SUCCESS,
      payload: {
        payload:
          data.responseData !== null ? data.responseData : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: usersActions.FETCH_ALL_USERS_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const fetchAllUserApps = () => async (dispatch) => {
  const userId = sessionStorage.getItem("userId");
  const payload = {
    keyword: "",
    groupIds: [],
    includeInActive: false,
  };
  try {
    dispatch({
      type: usersActions.FETCH_ALL_USER_APPS_START,
      loading: true,
    });

    const data = await Service.POST({
      name: "GetApplicationsListByLoginUserId",
      queryString: `userId=${userId}&PageNumber=1&PageSize=100`,
      payload: payload
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: usersActions.FETCH_ALL_USER_APPS_SUCCESS,
      payload: {
        payload: data.responseData !== null ? data.responseData.data : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: usersActions.FETCH_ALL_USER_APPS_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};


export const fetchAppsByUserId = (ids) => async (dispatch, getState) => {
  const payload = {
    userIds: ids,
  };
  try {
    dispatch({
      type: usersActions.FETCH_ALL_APPS_BY_USERID_START,
      loading: true,
    });

    const data = await Service.POST({
      name: "GetApplicationsByUserIds",
      queryString: "",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: usersActions.FETCH_ALL_APPS_BY_USERID_SUCCESS,
      payload: {
        test: data.responseData !== null ? data.responseData.applications : [],
        message: data.responseData === null ? "" : "",
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: usersActions.FETCH_ALL_APPS_BY_USERID_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const triggerGroupRulesByUserId = (payload) => async (dispatch) => {
  const userRoleId = sessionStorage.getItem("userRoleId");

  try {
    dispatch({
      type: usersActions.TRIGGER_GROUP_RULES_BYUSERID_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "TriggerGroupRulesByUserId",
      queryString: `id=${payload}`,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: usersActions.TRIGGER_GROUP_RULES_BYUSERID_SUCCESS,
      payload: {
        payload:data,
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: usersActions.TRIGGER_GROUP_RULES_BYUSERID_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};


export const fetchUsersForWelcomeEmail = () => async (dispatch, getState) => {
 
  try {
    dispatch({
      type: usersActions.FETCH_EMAIL_USERS_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetUserstoSendEmail",
     
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: usersActions.FETCH_EMAIL_USERS_SUCCESS,
      payload: {
        payload:
          data.responseData !== null ? data.responseData : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: usersActions.FETCH_EMAIL_USERS_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const sendWelcomeEmails = (value) => async (dispatch) => {
  const payload = {
    employeeId: value,
  };
  try {
    dispatch({
      type: usersActions.SEND_BULK_EMAIL_START,
    });

    const data = await Service.POST({
      name: "SendWelcomeEmail",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      } else {
        return {
          displayMessage: res.displayMessage,
          statusCode: res.statusCode,
        };
      }
    });
    dispatch({
      type: usersActions.SEND_BULK_EMAIL_SUCCESS,
      payload: data,
    });
    dispatch(fetchUsersForWelcomeEmail())
  } catch (error) {
    dispatch({
      type: usersActions.SEND_BULK_EMAIL_FAILED,
      payload: error.message,
    });
  }
};

export const uploadBulkUsers = (value) => async (dispatch) => {
  const form = new FormData();
  form.append('file', value);
  try {
    dispatch({
      type: usersActions.ADD_BULK_USERS_START,
    });

    const data = await Service.POST({
      name: "BulkUserCreate",
      payload: form,
      headers: { "Content-Type": "multipart/form-data" }
    }).then((res) => {
      if (res.isSuccess && res.statusCode === 200) {
        return res;
      } else {
        return {
          displayMessage: res.displayMessage,
          statusCode: res.statusCode,
        };
      }
    });
    dispatch({
      type: usersActions.ADD_BULK_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: usersActions.ADD_BULK_USERS_FAILED,
      payload: error.message,
    });
  }
};

export const uploadBulkUsersValidate = (value) => async (dispatch) => {
  try {
    dispatch({
      type: usersActions.VALIDATE_BULK_USERS_START,
    });

    const data = await Service.POST({
      name: "BulkUserValidate",
      payload: value,
    }).then((res) => {
      if (res.isSuccess && res.statusCode === 200) {
        return res;
      } else {
        return {
          displayMessage: INVALID_BULK_UPLOAD_FILE_FORMAT,
          statusCode: res.statusCode,
        };
      }
    });
    dispatch({
      type: usersActions.VALIDATE_BULK_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: usersActions.VALIDATE_BULK_USERS_FAILED,
      payload: error.message,
    });
  }
};

export const resetBulkUsersState = () => async (dispatch) => {
  dispatch({
    type: usersActions.BULK_USER_UPLOAD_RESET
  });
};
