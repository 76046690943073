import React, { useState } from "react";
import ReactPaginate from "react-paginate";

const Pagination = (props) => {
  const handlePageClick = (e) => {
    if (props.onPageChange){
      return props.onPageChange(e)
    }
    const selectedPage = e.selected;
    if (props.setOffset) {
      props.setOffset(selectedPage + 1);
    } else {
      props.setSearchPageNum(selectedPage + 1);
    }
  };
  return (
    <nav aria-label="Page navigation example">
      <p>
        Showing {props.count > 0 ? props.pageNum * 10 - 9 : 0} -{" "}
        {props.count < props.perPage * props.pageNum
          ? props.count
          : props.perPage * props.pageNum}{" "}
        of {props.count} Entries
      </p>
      <ReactPaginate
        forcePage={props.pageNum - 1}
        previousLabel={
          <button className="page-link nex-pev me-2" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </button>
        }
        nextLabel={
          <button className="page-link nex-pev ms-2" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </button>
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={props.pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </nav>
  );
};

export default Pagination;
