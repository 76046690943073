import React, { useState, useRef } from "react";
import Modal from "../UI/Modal";
import sucess from "../../assets/images/new-images/sucess.png";
import up from "../../assets/images/new-images/up.png";
import SearchIcon from "../../assets/images/search.svg";
import error from "../../assets/images/new-images/error.png";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { useEffect } from "react";
import { Loader } from "rsuite";
import { triggerGroupRulesByUserId } from "../../actions/actions-users";
import Checkbox from "@mui/material/Checkbox";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { customStyles } from "./styles";
import { ErrorOutlineIcon } from "../Icons";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const { REACT_APP_BACKEND_API_APP } = process.env;

const AddUser = (props) => {
  const { modalId, modalLabel, setAddSuccess, addSuccess } = props;

  const [user, setUser] = useState({
    userId: "",
    userName: "",
    email: "",
    userRole: 3,
    group: [],
    employeeId: "",
    createdBy: "",
    updatedBy: "",
    deptName: "",
    city: "",
    state: "",
    managerName: "",
    managerEmployeeId: "",
    managerEmail: "",
    jobTitle: "",
    companyName: "",
  });

  const loginData = useSelector((state) => state.loginReducer.dashData);

  const groupsList = useSelector((state) => state.master.userGroups);
  const userRole = useSelector((state) => state.master.userRoles);
  const [searchUserGroup, setSearchUserGroup] = useState("");
  const [adminId, setAdminId] = useState([]);
  const [teamAdminId, setTeamAdminId] = useState([]);
  const [userId, setUserId] = useState([]);

  const [userGroupList, setUserGroupList] = useState([]);
  const modalRef = useRef(null);

  useEffect(() => {
    setAdminId(userRole?.filter((a) => a.value === "Admin")[0].id);
    setTeamAdminId(userRole?.filter((a) => a.value === "Team Lead")[0].id);
    setUserId(userRole?.filter((a) => a.value === "User")[0].id);
  }, [setAdminId, userRole, setTeamAdminId, setUserId]);
  useEffect(() => {
    groupsList?.length > 0 &&
      setUserGroupList(
        groupsList?.map((item) => {
          return {
            ...item,
          };
        })
      );
  }, [groupsList]);

  useEffect(() => {
    var index = userGroupList?.findIndex((x) => x.id === 0);
    index === -1
      ? userGroupList?.splice(0, 0, {
          value: "Select All",
          id: 0,
          status: true,
        })
      : console.log();
  });

  let userGroupSearchFilter =
    userGroupList &&
    userGroupList
      ?.filter((i) => i.status === true)
      ?.filter((item, index) => {
        if (searchUserGroup === "") {
          return item;
        } else if (item.value.toLowerCase().includes(searchUserGroup)) {
          return item;
        }
      });

  const [suggestions, setSuggestions] = useState([]);

  const [active, setActive] = useState(0);
  const [filtered, setFiltered] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [input, setInput] = useState("");
  const [fullName, setFullName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [empId, setEmpId] = useState("");
  const [value] = useDebounce(input, 1000);
  const [dept, setDept] = useState(null);
  const [userCity, setUserCity] = useState(null);
  const [userState, setUserState] = useState(null);
  const [manName, setManName] = useState(null);
  const [manEmail, setManEmail] = useState(null);
  const [manId, setManId] = useState(null);
  const [userJob, setUserJob] = useState(null);
  const [userCompany, setUserCompany] = useState(null);

  const onChange = (e) => {
    setIsLoading(true);
    setActive(0);
    setIsShow(true);
    setInput(e.currentTarget.value);
  };
  const onClick = (e) => {
    setActive(0);
    setFiltered([]);
    setIsShow(false);
    setInput(e.currentTarget.innerText);

    let fullName = e.currentTarget.innerText;
    let email = suggestions
      .filter((item) => e.currentTarget.innerText === item.displayName)
      .map((item) => item.email)[0];
    let empId = suggestions
      .filter((item) => e.currentTarget.innerText === item.displayName)
      .map((item) => item.id)[0];
    let dept = suggestions
      .filter((item) => e.currentTarget.innerText === item.displayName)
      .map((item) => item.department)[0];
    let userCity = suggestions
      .filter((item) => e.currentTarget.innerText === item.displayName)
      .map((item) => item.city)[0];
    let userState = suggestions
      .filter((item) => e.currentTarget.innerText === item.displayName)
      .map((item) => item.state)[0];
    let manName = suggestions
      .filter((item) => e.currentTarget.innerText === item.displayName)
      .map((item) => (item?.manager ? item?.manager?.displayName : "N/A"))[0];
    let manEmail = suggestions
      .filter((item) => e.currentTarget.innerText === item.displayName)
      .map((item) => (item?.manager ? item?.manager?.email : "N/A"))[0];
    let manId = suggestions
      .filter((item) => e.currentTarget.innerText === item.displayName)
      .map((item) => (item?.manager ? item?.manager?.id : "N/A"))[0];
    setFullName(fullName);
    setUserEmail(email);
    setEmpId(empId);
    setDept(dept);
    setUserCity(userCity);
    setUserState(userState);
    setManName(manName);
    setManEmail(manEmail);
    setManId(manId);
    let userJob = suggestions
      .filter((item) => e.currentTarget.innerText === item.displayName)
      .map((item) => (item.jobTitle ? item.jobTitle : "N/A"))[0];
    let userCompany = suggestions
      .filter((item) => e.currentTarget.innerText === item.displayName)
      .map((item) => (item.organization ? item.organization : "N/A"))[0];
    setUserJob(userJob);
    setUserCompany(userCompany);
    setUserSave(false);
    setErrorMsg(false);
    setEnteredNameTouched(true);

  };
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    if (value !== "") {
      let token = sessionStorage.getItem("accessToken2");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      setIsLoading(true);
      axios
        .get(
          `${REACT_APP_BACKEND_API_APP}/api/SearchUserNamebyKeyword?keyword=${value}`,
          config
        )
        .then((response) => {
          setSuggestions(
            response.data.responseData ? response.data.responseData.users : []
          );
          setIsLoading(false);
        });
    }
  }, [value]);

  const [notFound, setNotFound] = useState(false);
  const onKeyDown = (e) => {
    setNotFound(false);
    if (e.key === "Enter") {
      setUser({
        userName: e.target.value,
        email: user.email,
        userRole: user.userRole,
        group: user.group,
      });
      setActive(0);
      setFiltered([]);
      setIsShow(false);
      setInput(e.target.value);

      let fullName = e.target.value;
      let email = suggestions
        .filter(
          (item) =>
            e.target.value.toLowerCase() === item.displayName.toLowerCase()
        )
        .map((item) => item.email)[0];
      let empId = suggestions
        .filter((item) => e.currentTarget.innerText === item.displayName)
        .map((item) => item.id)[0];
      setFullName(fullName);
      setUserEmail(email);
      setEmpId(empId);

      let dept = suggestions
        .filter((item) => e.currentTarget.innerText === item.displayName)
        .map((item) => item.department)[0];
      let userCity = suggestions
        .filter((item) => e.currentTarget.innerText === item.displayName)
        .map((item) => item.city)[0];
      let userState = suggestions
        .filter((item) => e.currentTarget.innerText === item.displayName)
        .map((item) => item.state)[0];
      setDept(dept);
      setUserCity(userCity);
      setUserState(userState);

      let manName = suggestions
        .filter((item) => e.currentTarget.innerText === item.displayName)
        .map((item) => (item?.manager ? item?.manager?.displayName : "N/A"))[0];
      let manEmail = suggestions
        .filter((item) => e.currentTarget.innerText === item.displayName)
        .map((item) => (item?.manager ? item?.manager?.email : "N/A"))[0];
      let manId = suggestions
        .filter((item) => e.currentTarget.innerText === item.displayName)
        .map((item) => (item?.manager ? item?.manager?.id : "N/A"))[0];
      setManName(manName);
      setManEmail(manEmail);
      setManId(manId);

      let userJob = suggestions
        .filter((item) => e.currentTarget.innerText === item.displayName)
        .map((item) => (item.jobTitle ? item.jobTitle : "N/A"))[0];
      let userCompany = suggestions
        .filter((item) => e.currentTarget.innerText === item.displayName)
        .map((item) => (item.organization ? item.organization : "N/A"))[0];
      setUserJob(userJob);
      setUserCompany(userCompany);
    } else {
      if (e.keyCode === 13) {
        setActive(0);
        setIsShow(false);
        setInput(filtered[active]);
      } else if (e.keyCode === 38) {
        return active === 0 ? null : setActive(active - 1);
      } else if (e.keyCode === 40) {
        return active - 1 === filtered.length ? null : setActive(active + 1);
      }
    }
  };

  useEffect(() => {
    setUser({
      ...user,
      userName: fullName,
      email: userEmail,
      employeeId: empId,
      deptName: dept,
      city: userCity,
      state: userState,
      managerName: manName,
      managerEmail: manEmail,
      managerEmployeeId: manId,
      jobTitle: userJob,
      companyName: userCompany,
    });
  }, [
    fullName,
    empId,
    userEmail,
    dept,
    userCity,
    userState,
    manName,
    manEmail,
    manId,
    userJob,
    userCompany,
  ]);

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedUserRole, setSelectedUserRole] = useState(3);

  const groupChange = (e) => {
    var value = e.target.value;
    if (value === "Select All" && e.target.checked)
      setSelectedGroups(
        userGroupList
          ?.filter((i) => i.status === true)
          .filter((item) => item.value !== value)
          .map((a) => a.value)
      );
    else if (value === "Select All" && !e.target.checked) setSelectedGroups([]);
    else if (e.target.checked && value !== "Select All")
      setSelectedGroups([...selectedGroups, value]);
    else if (!e.target.checked && value !== "Select All")
      setSelectedGroups(selectedGroups.filter((item) => item !== value));
  };

  const addUserChange = (e) => {
    var value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  const [enteredNameTouched, setEnteredNameTouched] = useState(false);
  const enteredNameIsValid = user.userName !== "";
  const nameInputBlurHandler = () => {
    setEnteredNameTouched(true);
  };

  const [enteredEmailTouched, setEnteredEmailTouched] = useState(false);
  const enteredEmailIsValid = user.email !== "" && user.email !== undefined;
  const emailInputBlurHandler = () => {
    setEnteredEmailTouched(true);
  };

  const [enteredTypeTouched, setEnteredTypeTouched] = useState(false);
  const enteredTypeIsValid = user.userRole !== "";

  const typeInputBlurHandler = () => {
    setEnteredTypeTouched(true);
  };

  const [enteredGroupTouched, setEnteredGroupTouched] = useState(false);

  const groupInputBlurHandler = () => {
    setEnteredGroupTouched(true);
  };

  const nameInputIsInvalid =
    (!enteredNameIsValid && enteredNameTouched) ||
    (enteredEmailTouched && !enteredNameIsValid) ||
    (enteredGroupTouched && !enteredNameIsValid) ||
    (enteredTypeTouched && !enteredNameIsValid);

  const emailInputIsInvalid =
    (!enteredEmailIsValid && enteredEmailTouched) ||
    (enteredGroupTouched && !enteredEmailIsValid) ||
    (enteredTypeTouched && !enteredEmailIsValid);

  const typeInputIsInvalid = !enteredTypeIsValid && enteredTypeTouched;

  const [userSave, setUserSave] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [errorReason, setErrorReason] = useState({title: '', description: ''});
  const buttonRef = useRef(null);

  const formIsValid = enteredNameIsValid && enteredEmailIsValid
  // enteredTypeIsValid;

  const [statusCode, setStatusCode] = useState(null);

  const [createdUserId, setCreatedUserId] = useState(null);
  const [welcomeEmail, setWelcomeEmail] = useState(true);
  const handleChange = () => {
    setWelcomeEmail(!welcomeEmail);
  };

  const dispatch = useDispatch();

  const saveAddUser = (e) => {
    e.preventDefault();
    buttonRef.current.disabled = formIsValid ? true : false;

    setEnteredNameTouched(true);
    setEnteredEmailTouched(true);
    setEnteredTypeTouched(true);
    setEnteredGroupTouched(true);
    if (
      user.userName === "" ||
      user.email === "") {
      return;
    }

    if (
      suggestions.filter((item) => item.displayName === user.userName)
        .length === 0
    ) {
      return;
    }

    let data = {
      userName: fullName,
      email: userEmail,
      signal: "string",
      employeeId: empId,
      userGroupId: userGroupList
        .filter((a) => user.group.some((o2) => a.value === o2))
        .map((a) => a.id)
        .filter((a) => a !== 0),
      userRoleId: parseInt(user.userRole),
      createdBy: 0,
      updatedBy: 0,
      deptName: dept,
      city: userCity,
      state: userState,
      managerName: manName,
      managerEmail: manEmail,
      managerEmployeeId: manId,
      jobTitle: userJob,
      companyName: userCompany,
      IsEmailSend: welcomeEmail,
    };

    if (!data.email && !data.userName && !data.userRoleId && !data.userRoleId) {
      setErrorMsg(!errorMsg);
      setUserSave(!userSave);
      return;
    }

    let token = sessionStorage.getItem("accessToken2");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    };

    axios
      .post(`${REACT_APP_BACKEND_API_APP}/api/SendInviteToUser`, data, config)
      .then(async (res) => {
        setStatusCode(res.data.statusCode);
        if (res.data.statusCode === 400 || res.data.statusCode === 500) {
          setErrorMsg(!errorMsg);
          setUserSave(!userSave);
          setErrorReason(res.data.statusCode === 500 ? {title: 'User email invalid', description: 'Add valid email'} : {title: 'User already exits.', description: 'Add another user'})
        } else {
          setUserSave(!userSave);
          setAddSuccess(true);
          setCreatedUserId(res.data.responseData);
        }
      });

    setEnteredNameTouched(false);
    setEnteredEmailTouched(false);
    setEnteredTypeTouched(false);
    setEnteredGroupTouched(false);
    setSelectedUserRole("");
    setSelectedGroups([]);
  };

  const addUserHandler = () => {
    statusCode &&
      statusCode === 200 &&
      dispatch(triggerGroupRulesByUserId(createdUserId));

    setUserSave(false);
    setErrorMsg(false);
    setUser({
      fullName: "",
      email: "",
      userRole: 3,
      group: [],
    });
    setInput("");
    setFullName("");
    setUserEmail("");
    setEmpId("");
    setDept("");
    setUserCity("");
    setUserState("");
    setManEmail("");
    setManName("");
    setManId("");
    setUserJob("");
    setUserCompany("");
    setSelectedUserRole(3);
    setWelcomeEmail(true);
  };

  useEffect(() => {
    const modalElement = modalRef.current;

    const handleModalHidden = () => {
      addUserHandler();
    };

    if (modalElement) {
      modalElement.addEventListener('hidden.bs.modal', handleModalHidden);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
      }
    };
  }, []);

  return (
    <>
      <Modal
        sx={customStyles}
        modalId={modalId}
        modalLabel={modalLabel}
        modalRef={modalRef}
      >
        <div
          className={
           "modal-dialog modal-dialog-centered modal-l"
          }
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Add User
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => {
                  setEnteredNameTouched(false);
                  setEnteredEmailTouched(false);
                  setEnteredTypeTouched(false);
                  setEnteredGroupTouched(false);
                  setUser({
                    userName: "",
                    email: "",
                    userRole: 3,
                    group: [],
                  });
                  setSelectedGroups([]);
                  setSelectedUserRole(3);
                  setInput("");
                  setNotFound(false);
                  setFullName("");
                  setUserEmail("");
                  setEmpId("");
                  setSearchUserGroup("");
                  setDept("");
                  setUserCity("");
                  setUserState("");
                  setManEmail("");
                  setManName("");
                  setManId("");
                  setUserJob("");
                  setUserCompany("");
                  setWelcomeEmail(true);
                }}
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>Full Name <sup>*</sup></label>
                  <div className="input-with-icon">
                    <input
                      className={nameInputIsInvalid ? 'form-control error' : 'form-control'}
                      placeholder="User’s Full Name"
                      type="text"
                      name="userName"
                      onChange={onChange}
                      onKeyDown={onKeyDown}
                      value={input}
                      onBlur={nameInputBlurHandler}
                    />
                    {nameInputIsInvalid && (
                      <ErrorOutlineIcon />
                    )}
                  </div>

                  {isShow && input && suggestions && (
                    <ul className="autocomplete">
                      {loading ? (
                        <Loader />
                      ) : suggestions?.length > 0 ? (
                        suggestions
                          .filter((suggestion) => {
                            const searchTerm = input.toLowerCase();
                            const fullName =
                              suggestion.displayName.toLowerCase();
                            return fullName.startsWith(searchTerm);
                          })
                          .map((item, index) => {
                            let className;
                            if (index === active) {
                              className = "active";
                            }
                            return (
                              <li
                                className={className}
                                key={index}
                                onClick={onClick}
                              >
                                {item.displayName}
                              </li>
                            );
                          })
                      ) : (
                        <li className="error-text">Not Found</li>
                      )}
                    </ul>
                  )}

                  {nameInputIsInvalid && (
                    <p className="error-text">Please enter Full Name</p>
                  )}
                  {notFound && (
                    <div className="no-autocomplete">
                      <p className="error-text">Not found</p>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Email address <sup>*</sup>
                  </label>
                  <div className="input-with-icon">
                    <EmailOutlinedIcon />
                    <input
                      type="email"
                      className={emailInputIsInvalid ? 'form-control error' : 'form-control'}
                      placeholder="Email address"
                      name="email"
                      value={user.email}
                      onChange={addUserChange}
                      onBlur={emailInputBlurHandler}
                      disabled
                    />
                    {emailInputIsInvalid && (
                      <ErrorOutlineIcon />
                    )}
                  </div>
                  {emailInputIsInvalid && (
                    <p className="error-text">Please enter a valid Email</p>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Select Group
                  </label>
                  <div className="input-with-icon">
                    <GroupsOutlinedIcon />
                    <input
                      style={{ caretColor: "transparent" }}
                      autoFocus=""
                      type="text"
                      className="form-control"
                      data-bs-target="#exampleModalToggle4"
                      data-bs-toggle="modal"
                      placeholder="Select Group"
                      onChange={addUserChange}
                      value={user.group
                        .filter((a) => a !== "Select All")
                        .join(", ")}
                    />
                  </div>

                </div>

                <div className="form-group">
                  <label>
                    Select Role <sup>*</sup>
                  </label>

                  <div className="wrp-check">
                    <div className="all-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="flexRadioDefault1"
                        name="userRole"
                        key={selectedUserRole}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            userRole: e.target.value,
                          });
                        }}
                        // onBlur={typeInputBlurHandler}
                        value={adminId}
                        checked={
                          parseInt(user.userRole) === adminId ? true : false
                        }
                      />
                      <p>Admin</p>
                    </div>
                    <div className="all-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="flexRadioDefault2"
                        name="userRole"
                        key={selectedUserRole}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            userRole: e.target.value,
                          });
                        }}
                        value={teamAdminId}
                        checked={
                          parseInt(user.userRole) === teamAdminId
                            ? true
                            : false
                        }
                      />
                      <p>Team Lead</p>
                    </div>
                    <div className="all-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="flexRadioDefault2"
                        name="userRole"
                        key={selectedUserRole}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            userRole: e.target.value,
                          });
                        }}
                        value={userId}
                        checked={
                          parseInt(user.userRole) === userId ? true : false
                        }
                      />
                      <p>User</p>
                    </div>
                  </div>
                  {/* {typeInputIsInvalid && (
                    <p className="error-text">Please select a Role</p>
                  )} */}
                </div>

                <div className="form-group form-check full-pop">
                  <span>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onChange={handleChange}
                      checked={welcomeEmail}
                    />
                    <label
                      className="form-check-label mb-0"
                      htmlFor="flexCheckDefault"
                    >
                      Send the welcome email
                      <p className="note-text">
                        If you uncheck this, their account will be created but
                        they won't receive the welcome email
                      </p>
                    </label>
                  </span>

                </div>
              </form>
             { (userSave && !errorMsg) && <Alert
                sx={ {
                borderRadius: '8px',
                mb: 2,
                mt: 3,
                padding: '1rem',
                gap: '12px',
                border: '1px solid #6CE9A6',
                background: '#F6FEF9',

                '& .MuiAlert-icon': {
                  padding: 0,
                  margin: 0,
                  color: '#027A48',

                  '& svg': {
                    fontSize: '20px'
                  }
                },

                '& .MuiAlert-message': {
                  padding: 0,

                  '& .MuiTypography-root': {
                    marginTop: '0.25rem',
                    fontFamily: 'Manrope',
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#027A48',
                    lineHeight: '142.857%',
                  },

                  '& .MuiAlertTitle-root': {
                    fontFamily: "Manrope SemiBold",
                    margin: 0,
                    color: '#027A48',
                    fontWeight: 600,
                  }
                }
              }} icon={<CheckCircleOutlineOutlinedIcon />} variant="outlined" severity="success">
              <AlertTitle>{user?.userName} successfully created.</AlertTitle>
              </Alert>
              }
              { (userSave && errorMsg) && <Alert
                sx={{
                  borderRadius: '8px',
                  mb: 2,
                  mt: 3,
                  padding: '1rem',
                  gap: '12px',
                  border: '1px solid #FDA29B',
                  background: '#FFFBFA',

                  '& .MuiAlert-icon': {
                    padding: 0,
                    margin: 0,
                    color: '#B42318',

                    '& svg': {
                      fontSize: '20px'
                    }
                  },

                  '& .MuiAlert-message': {
                    padding: 0,

                    '& .MuiTypography-root': {
                      marginTop: '0.25rem',
                      fontFamily: 'Manrope',
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#B42318',
                      lineHeight: '142.857%',
                    },

                    '& .MuiAlertTitle-root': {
                      margin: 0,
                      fontFamily: "Manrope SemiBold",
                      color: '#B42318',
                      fontWeight: 600,
                    }
                  }
                }} icon={<ErrorOutlineOutlinedIcon />} variant="outlined" severity="error">
                <AlertTitle>{errorReason?.title}</AlertTitle>
                <Typography>{errorReason?.description}</Typography>
              </Alert> }

            </div>
            <div className="modal-footer secondary">
              { (!userSave && !errorMsg) &&
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setEnteredNameTouched(false);
                  setEnteredEmailTouched(false);
                  setEnteredTypeTouched(false);
                  setEnteredGroupTouched(false);
                  setUser({
                    fullName: "",
                    email: "",
                    userRole: 3,
                    group: [],
                  });
                  setSelectedGroups([]);
                  setSelectedUserRole(3);
                  setInput("");
                  setNotFound(false);
                  setFullName("");
                  setUserEmail("");
                  setEmpId("");
                  setSearchUserGroup("");
                  setDept("");
                  setUserCity("");
                  setUserState("");
                  setManEmail("");
                  setManName("");
                  setManId("");
                  setUserCompany("");
                  setUserJob("");
                  setWelcomeEmail(true);
                  //userRole(3)
                }}
              >
                Cancel
              </button>}
              { !userSave ? <button
                type="button"
                className="btn btn-genmeb"
                ref={buttonRef}
                onClick={(e) => saveAddUser(e)}
                disabled={!enteredNameTouched || nameInputIsInvalid}
              >
                Add User
              </button> : <button
                type="button"
                className="btn btn-genmeb"
                data-bs-dismiss="modal"
              >
                Done
              </button>
              }

            </div>
          </div>

        </div>
      </Modal>

      <Modal
        modalId={"exampleModalToggle4"}
        modalLabel={"exampleModalToggleLabel4"}
      >
        <div
          className="modal-dialog modal-dialog-centered me-widht"
          onBlur={groupInputBlurHandler}
        >
          <div className="modal-content for-align4">
            <div className="modal-body p-0">
              <div className="relative w-100">
                <img src={SearchIcon} alt="icon" className="pop-search" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={searchUserGroup}
                  onChange={(evnt) =>
                    setSearchUserGroup(evnt.target.value.toLowerCase())
                  }
                />
                <img
                  src={up}
                  alt="icon"
                  className="up-icon"
                  data-bs-target="#exampleModalToggle"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                />
              </div>
              {userGroupList && userGroupList?.length === 0 ? (
                <p className="events-error">No Groups available </p>
              ) : userGroupSearchFilter.length === 0 ? (
                <p className="events-error">No Match Found </p>
              ) : (
                userGroupSearchFilter &&
                userGroupSearchFilter.map((item, index) => {
                  if (
                    selectedGroups?.length > 0 &&
                    selectedGroups?.length ===
                      userGroupList?.filter((i) => i.status === true).length -
                        1 &&
                    selectedGroups?.includes("Select All")
                  )
                    setSelectedGroups(
                      selectedGroups?.filter((a) => a !== "Select All")
                    );
                  if (
                    selectedGroups?.length > 0 &&
                    selectedGroups?.length ===
                      userGroupList?.filter((i) => i.status === true).length -
                        1 &&
                    !selectedGroups.includes("Select All")
                  )
                    setSelectedGroups([...selectedGroups, "Select All"]);
                  return (
                    <div
                      key={index}
                      className={
                        index === 0 && searchUserGroup === ""
                          ? "s-all"
                          : "all-check"
                      }
                    >
                      <p>{item.value}</p>

                      {item.status === true && (
                        <input
                          type="checkbox"
                          checked={
                            selectedGroups?.filter((a) => a === item.value)
                              .length === 1
                              ? true
                              : false
                          }
                          value={item.value}
                          onChange={groupChange}
                          name="group"
                          className="form-check-input"
                        />
                      )}
                    </div>
                  );
                })
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  setSelectedGroups(user.group);
                  setSearchUserGroup("");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-target="#exampleModalToggle"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  setUser({
                    ...user,
                    group: selectedGroups,
                  });
                  setSearchUserGroup("");
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </Modal>


    </>
  );
};

export default AddUser;
