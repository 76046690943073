import { Avatar } from "@mui/material";
import React from "react";

export const CustomAvatar = (props) => {
  const { title, sx } = props;
  return (
    <Avatar sx={ {
      width: '1rem',
      height: '1rem',
      lineHeight: 1,
      fontSize: '0.75em',
      color: '#038177',
      fontFamily: '"Manrope", Roboto, "Helvetica Neue", Arial, sans-serif',
      backgroundColor: '#e6f6f4 !important',
      borderRadius: '50%',
      ...sx,
    }}>
      { title }
    </Avatar>
  )
}