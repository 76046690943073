import React, { useState, useEffect } from "react";
import Modal from "../UI/Modal";
import sucess from "../../assets/images/new-images/sucess.png";
import isURL from "validator/lib/isURL";
import axios from "axios";
import remove from "../../assets/images/new-images/remove.png";
import error from "../../assets/images/new-images/error.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUserApps } from "../../actions/actions-users";
import { fetchImages } from "../../actions/action-master";
import { Loader } from "rsuite";
import {IMAGE_FILE_TYPES, MAX_LOGO_SIZE_BYTES} from "../../constants";
import { customStyles } from "../Users/styles";
import cross from "../../assets/images/close.svg";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { ErrorOutlineIcon } from "../Icons";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
const { REACT_APP_BACKEND_API_APP } = process.env;

const UpdateApp = (props) => {
  const { modalId, modalLabel, editAppData, setUpdateSuccess } = props;
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [display, setDisplay] = useState(false);
  const [base64Img, setBase64Img] = useState(false);
  const [imgDelete, setImgDelete] = useState("");

  const initialState = {
    appName: "",
    displayName: "",
    description: "",
    appUrl: "",
    mobileAppUrl: "",
    imagebase64String: "",
    imageName: "",
    tagList: [],
    localImage: "",
    image: "",
  };

  const [appData, setAppData] = useState(initialState);
  const [previousData, setPreviousData] = useState(false);

  useEffect(() => {
    let imageNames = [editAppData?.imageName];
    dispatch(fetchImages(imageNames));
  }, [editAppData]);
  const photoInfo = useSelector((state) => state?.master.photoInfo);
  const photoLoading = useSelector((state) => state?.master.photoLoading);

  useEffect(() => {
    setAppData({
      appId: editAppData?.appId,
      appName: editAppData.appName || "",
      displayName: editAppData.displayName || "",
      description: editAppData.description || "",
      appUrl: editAppData.appUrl || "",
      mobileAppUrl: editAppData.mobileAppUrl || "",
      imageName: editAppData.imageName ? editAppData.imageName : "",
      image: photoInfo[0]?.imageBase64String
        ? new File([appData.imagebase64String], "image")
        : "",
      imagebase64String: photoInfo[0]?.imageBase64String
        ? photoInfo[0]?.imageBase64String
        : "",
      tagList: editAppData.tagList || [],
    });

    if (editAppData?.appName === editAppData?.displayName) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
    setPreviousData(false);
    setNameAlreadyExists(false)
  }, [editAppData, appData.appName === '', photoInfo]);
  const [imageSize, setimageSize] = useState(null);
  const [imageType, setImageType] = useState(null);

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setAppData({
        ...appData,
        localImage: e.target.files[0],
        imageName: e.target.files[0].name.replace(/\.[^/.]+$/, ""),
      });
      //  setImgDelete("");

      setimageSize(e.target.files[0].size);
      setImageType(e.target.files[0].type);
      // if (e.target.files[0].size > 30000) {
      //   return;
      // }
    }
  };

  const logoSizeIsValid = imageSize < MAX_LOGO_SIZE_BYTES;
  const logoFormatIsValid = imageType ? (imageType === IMAGE_FILE_TYPES.JPEG || imageType === IMAGE_FILE_TYPES.PNG || imageType === IMAGE_FILE_TYPES.JPG || imageType === IMAGE_FILE_TYPES.SVG) : (photoInfo[0]?.imageBase64String && !appData.localImage) ? true : false

  const logoValidation = {
    logoIsValid: logoSizeIsValid && logoFormatIsValid,
    errorMsg:[!logoSizeIsValid && `Please upload a image with size less than ${MAX_LOGO_SIZE_BYTES/1000}kb`,
    !logoFormatIsValid && 'Please upload a image with a valid format: .jpg, .png and .svg']
  }
  const removeimageHandler = () => {
    setAppData({
      ...appData,
      imagebase64String: "",
      localImage: "",
      image: "",
    });
    // appData.imagebase64String
    //   ? setImgDelete(editAppData.imagebase64String)
    //   : setImgDelete("");
    setimageSize(null);
    setImageType(null);
    setBase64Img(true);
  };

  function handleKeyDown(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setAppData({ ...appData, tagList: [...appData.tagList, value] });

    e.target.value = "";
  }

  function removeTag(index) {
    setAppData({
      ...appData,
      tagList: appData.tagList.filter((el, i) => i !== index),
    });
  }

  const handleChange = (evt) => {
    setAppData({
      ...appData,
      [evt.target.name]: evt.target.value,
    });
    setSubmitted(false);
    setErrorMsg(null)
  };

  const defaultState = {
    appName: false,
    displayName: false,
    subtitle: false,
    url: false,
    logo: false,
  };

  const [touched, setTouched] = useState(defaultState);

  const handleFocus = (e) => {
    let { name } = e.target;
    setTouched({ ...touched, [name]: true });
  };

  const enteredNameIsValid = appData.appName.trim() !== "";
  const enteredDisplayIsValid = display
    ? appData.appName.trim() !== ""
    : appData.displayName.trim() !== "";
  const enteredSubIsValid = appData.subtitle !== "";
  const enteredUrlIsValid =
    appData.appUrl.trim() !== "" && isURL(decodeURIComponent(appData.appUrl));

  const enteredLogoIsValid = appData.logo !== "";

  const nameInputIsInvalid =
    (!enteredNameIsValid && touched.appName) ||
    (touched.displayName && !enteredNameIsValid) ||
    (touched.subtitle && !enteredNameIsValid) ||
    (touched.url && !enteredNameIsValid) ||
    (touched.logo && !enteredNameIsValid) ||
    (touched.tags && !enteredNameIsValid);

  const displayInputIsInvalid =
    (!enteredDisplayIsValid && touched.displayName) ||
    (touched.subtitle && !enteredDisplayIsValid) ||
    (touched.url && !enteredDisplayIsValid) ||
    (touched.logo && !enteredDisplayIsValid) ||
    (touched.tags && !enteredDisplayIsValid);

  const urlInputIsInvalid =
    (!enteredUrlIsValid && touched.url) ||
    (touched.logo && !enteredUrlIsValid) ||
    (touched.tags && !enteredUrlIsValid);

  const [errorMsg, setErrorMsg] = useState(null);
  const [displayErrorMsg, setDisplayErrorMsg] = useState('');

  const submitHandler = (e) => {
    e.preventDefault();
    setTouched({
      appName: true,
      displayName: true,
      subtitle: true,
      url: true,
      logo: true,
      tags: true,
    });

    if (
      !enteredDisplayIsValid ||
      !enteredLogoIsValid ||
      !enteredNameIsValid ||
      !enteredSubIsValid ||
      !enteredUrlIsValid ||
      !logoSizeIsValid
    ) {
      return;
    }

    const formData = new FormData();

    const userRoleId = sessionStorage.getItem("userRoleId");

    formData.append("appId", editAppData.appId);
    formData.append("appName", appData.appName.trim());
    formData.append(
      "displayName",
      display === true ? appData.appName.trim() : appData.displayName.trim()
    );
    formData.append("description", appData.description);
    formData.append("imagebase64String", "");
    formData.append("imageName", appData.imageName);
    formData.append(
      "appUrl",
      decodeURIComponent(appData.appUrl) === appData.appUrl
        ? encodeURIComponent(appData.appUrl)
        : appData.appUrl
    );
    formData.append(
      "mobileAppUrl",
      appData.mobileAppUrl.trim() !== ""
          ? decodeURIComponent(appData.mobileAppUrl.trim()) ===
            appData.mobileAppUrl.trim()
            ? encodeURIComponent(appData.mobileAppUrl.trim())
            : appData.mobileAppUrl.trim()
          : decodeURIComponent(appData.appUrl) === appData.appUrl
          ? encodeURIComponent(appData.appUrl)
          : appData.appUrl
    );
    formData.append("priorityIndex", 1);
    formData.append("createdBy", userRoleId);
    formData.append("isActive", editAppData.isActive);
    formData.append("isApproved", editAppData.isApproved);
    formData.append("tagList", appData.tagList);
    formData.append(
      "image",
      appData.localImage ? appData.localImage : appData.image
    );

    let token = sessionStorage.getItem("accessToken2");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    };

    axios
      .post(
        `${REACT_APP_BACKEND_API_APP}/api/UpdateApplicationDetailsByAppId`,
        formData,
        config
      )
      .then(async (res) => {
        setSubmitted(true);
        setErrorMsg(res.data.statusCode);
        if (res.data.statusCode === 200) {
          setUpdateSuccess(true);
          setDisplayErrorMsg('')
          // setDisplay(!display);
        } else {
          setUpdateSuccess(true);
          setDisplayErrorMsg(res.data.responseData)
          // setDisplay(!display);
        }
        dispatch(fetchAllUserApps());
      });
  };
  const [nameAlreadyExists, setNameAlreadyExists] = useState(false);

  const modalClosed = () => {
    setDisplay(false);
    setAppData(initialState);
    setTouched(defaultState);
    setErrorMsg(null);
    setSubmitted(false);
    setImgDelete("");
    setBase64Img(false);
    setimageSize(null);
    setImageType(null);
    setNameAlreadyExists(false);
    setDisplayErrorMsg('')
    props?.setShow(false);
    props?.setShowAssign(false);
  };

  let commonInputs = {
    onBlur: handleFocus,
  };

  const appsData = useSelector((state) => state.appsData.apps);

  React.useEffect(() => {
    setNameAlreadyExists(appsData.appsList?.length > 0 && appsData.appsList[0].appId !== appData.appId)
  }, [appsData]);

  React.useEffect(() => {
    if(appData.displayName === '') {
      setNameAlreadyExists(false)
    }
  }, [appData.displayName]);

  const changeSelectedApp = () => {
    props?.editClick(appsData.appsList[0])
  }

  const openAssignUserModal = () => {
    props?.setSelectedApps([parseInt(appData.appId)])
    modalClosed();
    props?.setShowAssign(true);
  }

  return (
    <Modal sx={customStyles} modalId={modalId} modalLabel={modalLabel} showModal={props?.show}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Edit App
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={modalClosed}
              ></button>
            </div>

            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label className="d-flex justify-content-between">
                    <span>
                      App Name<sup>*</sup>
                    </span>
                  </label>
                  <div className="input-with-icon">
                    <input
                      type="text"
                      className={nameInputIsInvalid ? 'form-control error' : 'form-control'}
                      placeholder="App Name"
                      onChange={handleChange}
                      name="appName"
                      value={appData.appName}
                      {...commonInputs}
                      readOnly
                    />
                    { nameInputIsInvalid && (
                      <ErrorOutlineIcon />
                    )}
                    {nameInputIsInvalid && (
                      <p className="error-text">Please enter an App Name</p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="d-flex justify-content-between">
                    <label>Display Name<sup>*</sup></label>
                    <label className="form-check-label">
                      <input
                        className="form-check-input me-2"
                        name="same-as-app-name"
                        type="checkbox"
                        checked={!display ? false : true}
                        onChange={() => {
                          setDisplay(!display);
                        }}
                      />

                      Same as App Name
                    </label>
                  </div>
                  <div className="input-with-icon">
                    <input
                      type="text"
                      className={displayInputIsInvalid ? 'form-control error' : 'form-control'}
                      placeholder="Display Name"
                      onChange={handleChange}
                      value={display ? appData.appName : appData.displayName}
                      name="displayName"
                      onKeyUp={(e) => e.target.value.trim().length > 1 && props?.searchApp(e.target.value.toLowerCase().trim())}
                      {...commonInputs}
                    />
                     { displayInputIsInvalid && (
                      <ErrorOutlineIcon />
                    )}
                  </div>

                  {displayInputIsInvalid && !display && (
                    <p className="error-text">Please enter a Display Name</p>
                  )}
                  {
                    nameAlreadyExists && (
                      <>
                      <p className="error-text">An app with the same name already exists.
                      <Button
                        disableRipple
                        onClick={changeSelectedApp}
                        sx={ {
                          color: '#D92D20',
                          fontWeight: 600,
                          marginLeft: '0.375rem',
                          padding: 0,
                          gap: '0.5rem',
                          height: 'auto',
                          textTransform: 'none',
                          fontSize: '0.875rem',
                          lineHeight: 1,
                          fontFamily: '"Manrope", Roboto, "Helvetica Neue", Arial, sans-serif !important',

                          '&:hover': {
                            background: 'transparent'
                          }
                      } }>Edit existing app <ArrowForwardOutlinedIcon sx={{color: '#D92D20',fontSize: '0.875rem'}} />
                      </Button>
                      </p>
                    </>
                    )
                  }
                </div>
                <div className="form-group">
                  <label>
                    Information Text
                    {/* <sup>*</sup> */}
                  </label>
                  <textarea
                    type="text"
                    rows={4}
                    className="form-control"
                    placeholder="Information Text"
                    onChange={handleChange}
                    value={appData.description}
                    name="description"
                    maxLength={20}
                    {...commonInputs}
                  ></textarea>
                </div>

                <Grid className="form-group" container columnSpacing={ 3 }>
                  <Grid item md={ 6 }>
                    <div className="form-group">
                      <label>
                        Application URL<sup>*</sup>
                      </label>
                      <div className="input-with-icon">
                        <LinkIcon />
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Add Application URL"
                          onChange={ handleChange }
                          value={ decodeURIComponent( appData.appUrl ) }
                          name="appUrl"
                          { ...commonInputs }
                        />
                      </div>

                      { urlInputIsInvalid && (
                        <p className="error-text">Please enter a valid URL</p>
                      ) }
                    </div>
                  </Grid>
                  <Grid item md={ 6 }>
                    <div className="form-group">
                      <label>Mobile App URL</label>
                      <div className="input-with-icon">
                        <LinkIcon />
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Add Mobile App URL"
                          onChange={ handleChange }
                          value={ decodeURIComponent( appData.mobileAppUrl ) }
                          name="mobileAppUrl"
                          { ...commonInputs }
                        />
                      </div>

                    </div>
                  </Grid>
                </Grid>



                <div className="form-group ful-w">
                  <label>Add tags</label>
                  <div className="tag-input-container">
                    {appData.tagList.map((tag, index) => (
                      <div className="tag-item" key={index}>
                        <span className="text-show">{tag}</span>
                        <span
                          onClick={() => removeTag(index)}
                          className="close-btn"
                        >
                          <img src={cross} alt="close"></img>
                        </span>
                      </div>
                    ))}
                    <input
                      type="text"
                      className="tags-input"
                      placeholder="Type a Tag Name and Hit Enter..."
                      onKeyDown={handleKeyDown}
                      name="tags"
                      {...commonInputs}
                    />
                  </div>
                </div>


                <div className="form-group ">
                  <label>Upload logo</label>

                  <div className="file-upload">
                    <span className="file-name">
                      {photoLoading ? (
                        <Loader />
                      ) : appData.imagebase64String || appData.localImage ? (
                        <img
                          src={
                            appData.localImage
                              ? URL.createObjectURL(appData.localImage)
                              : `data:${appData.imagebase64String.startsWith('PHN')? IMAGE_FILE_TYPES.SVG : IMAGE_FILE_TYPES.JPEG };base64,${appData.imagebase64String}`
                          }
                          alt="icon"
                          className="up-icon-alt"
                        />
                      ) : (
                        "Formats: .jpg , .png, .svg"
                      )}{" "}
                      {(appData.imagebase64String || appData.localImage) && (
                        <img
                          src={remove}
                          alt="close"
                          className="remove-icon"
                          onClick={() => removeimageHandler()}
                        />
                      )}
                    </span>
                    <span className="upload">
                      <ContentCopyOutlinedIcon />
                      Browse from computer
                      <input
                        type="file"
                        className="file"
                        onChange={(e) => imageChange(e)}
                        name="logo"
                        accept={`${IMAGE_FILE_TYPES.PNG}, ${IMAGE_FILE_TYPES.JPG}, ${IMAGE_FILE_TYPES.JPEG}, ${IMAGE_FILE_TYPES.SVG}`}
                        {...commonInputs}
                      />
                    </span>
                  </div>
                  {(appData.imagebase64String || appData.localImage) &&
                  !logoValidation.logoIsValid ? (
                    logoValidation.errorMsg.map(e=>
                      <p className="error-text"> {e} </p>)
                  ) : (
                    <p className="e-black  error-text">Image needs to be less than {MAX_LOGO_SIZE_BYTES/1000}kb</p>
                  )}
                </div>
              </form>
              {submitted && ( (errorMsg === 200) ? (<Alert
                sx={ {
                borderRadius: '8px',
                mb: 2,
                mt: 3,
                padding: '1rem',
                gap: '12px',
                border: '1px solid #6CE9A6',
                background: '#F6FEF9',

                '& .MuiAlert-icon': {
                  padding: 0,
                  margin: 0,
                  color: '#027A48',

                  '& svg': {
                    fontSize: '20px'
                  }
                },

                '& .MuiAlert-message': {
                  padding: 0,

                  '& .MuiTypography-root': {
                    marginTop: '0.25rem',
                    fontFamily: 'Manrope',
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#027A48',
                    lineHeight: '142.857%',
                  },

                  '& .MuiAlertTitle-root': {
                    fontFamily: "Manrope SemiBold",
                    margin: 0,
                    color: '#027A48',
                    fontWeight: 600,
                  }
                }
              }} icon={<CheckCircleOutlineOutlinedIcon />} variant="outlined" severity="success">
                <AlertTitle>{appData.appName} updated successfully.</AlertTitle>
                <Button
                  disableRipple
                  data-bs-dismiss="modal"
                  onClick={openAssignUserModal}
                  sx={{
                    textTransform: 'none',
                    gap: '0.5rem',
                    padding: 0,
                    marginTop: '12px',
                    fontSize: '14px !important',
                    fontFamily: "Manrope SemiBold",
                    fontWeight: 600,
                    color: '#039855',
                    lineHeight: '142.857%',
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  }}>
                    Assign Users to this App
                    <ArrowForwardOutlinedIcon sx={{fontSize: '20px', color: '#027A48'}} />
                </Button>
              </Alert> ) : (
              <Alert
                sx={{
                  borderRadius: '8px',
                  mb: 2,
                  mt: 3,
                  padding: '1rem',
                  gap: '12px',
                  border: '1px solid #FDA29B',
                  background: '#FFFBFA',

                  '& .MuiAlert-icon': {
                    padding: 0,
                    margin: 0,
                    color: '#B42318',

                    '& svg': {
                      fontSize: '20px'
                    }
                  },

                  '& .MuiAlert-message': {
                    padding: 0,

                    '& .MuiTypography-root': {
                      marginTop: '0.25rem',
                      fontFamily: 'Manrope',
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#B42318',
                      lineHeight: '142.857%',
                    },

                    '& .MuiAlertTitle-root': {
                      margin: 0,
                      fontFamily: "Manrope SemiBold",
                      color: '#B42318',
                      fontWeight: 600,
                    }
                  }
                }} icon={<ErrorOutlineOutlinedIcon />} variant="outlined" severity="error">
                <AlertTitle>{appData.appName} {displayErrorMsg}.</AlertTitle>
                {/* <Typography>Please add letters.</Typography> */}
              </Alert> ))
            }
            </div>
            <div className="modal-footer">
              { errorMsg === 200 && submitted ?
                <button
                  className="btn btn-genmeb ms-3 submit--btn"
                  data-bs-dismiss="modal"
                  onClick={modalClosed}
                >
                  Done
                </button> : (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={modalClosed}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-genmeb ms-3" onClick={submitHandler}
                  disabled={(appData.localImage && !logoValidation.logoIsValid) || nameAlreadyExists || (displayInputIsInvalid && !display)}>
                    Update
                  </button>
                </>
                )
              }

            </div>
          </div>
        </div>
    </Modal>
  );
};

export default UpdateApp;
