import React, { useEffect, useState } from "react";
import Modal from "../UI/Modal";
import SearchIcon from "../../assets/images/search.svg";
import up from "../../assets/images/new-images/up.png";
import axios from "axios";
import sucess from "../../assets/images/new-images/sucess.png";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllUsers } from "../../actions/actions-users";
import { fetchAllUserGroups } from "../../actions/action-master";
import {
  fetchAppsByUserId,
  fetchAllUserApps,
} from "../../actions/actions-users";
import { Loader } from "rsuite";
import { Alert, AlertTitle, Avatar, Box, Button, Typography } from "@mui/material";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { appsData } from "../../reducers/reducer-apps";
import { UserIcon, CloseIcon, ContentIcon, GroupIcon, ArrowRight, CheckIcon } from './icons'
import { CustomAlert } from "../UI/Alert";
import { CustomAvatar } from "../UI/Avatar";
const { REACT_APP_BACKEND_API_APP } = process.env;

const Assign = (props) => {
  const initialState = {
    assignApp: [],
    group: [],
    userArr: [],
  };
  const [ openMenu, setOpenMenu ] = useState( '' );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllUsers(true));
    dispatch(fetchAllUserGroups());
    dispatch(fetchAllUserApps());
  }, []);
  const allUsers = useSelector((state) => state?.usersList?.allUsers);
  const allApps = useSelector((state) => state?.usersList?.allApps);
  const allUserApps = useSelector((state) => state?.usersList?.allUserApps);

  const allAppsLoading = useSelector(
    (state) => state?.usersList?.allAppsLoading
  );

  const allUsersLoading = useSelector(
    (state) => state?.usersList?.allUsersLoading
  );

  const allUserAppsLoading = useSelector(
    (state) => state?.usersList?.allUserAppsLoading
  );

  const [appData, setAppData] = useState(initialState);

  const { assignApp, group, userArr } = appData;

  const [userByGroupId, setUserByGroupId] = useState([]);
  const [searchUser, setSearchUser] = useState("");

  const groupsList = useSelector((state) => state.master.userGroups);
  const [searchUserGroup, setSearchUserGroup] = useState("");
  const loginData = useSelector((state) => state.usersList.dashData);
  const groupRules = useSelector((state) => state.groupsRule.groupRules);
  const [appListbyAd, setAppListbyAd] = useState([]);

  const [searchAppName, setSearchAppName] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [selectedApps, setSelectedApps] = useState([...props?.selectedApps]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userGroupList, setUserGroupList] = useState([]);

  useEffect(() => {
    allApps?.length > 0 &&
      setAppListbyAd(
        allApps
          ?.filter((i) => i.isActive === true)
          ?.map((item) => {
            return {
              ...item,
            };
          })
      );
  }, [allApps]);

  useEffect(() => {
    groupsList?.length > 0 &&
      setUserGroupList(
        groupsList?.map((item) => {
          return {
            ...item,
          };
        })
      );
  }, [groupsList]);

  useEffect(() => {
    var index = userGroupList.findIndex((x) => x.id === 0);
    index === -1
      ? userGroupList.splice(0, 0, { value: "Select All", id: 0, status: true })
      : console.log();
  });

  useEffect(() => {
    allUsers?.length > 0 &&
      setUserByGroupId(
        allUsers?.map((item) => {
          return {
            ...item,
          };
        })
      );
  }, [allUsers]);

  // useEffect(() => {
  //   let token = sessionStorage.getItem("accessToken2");
  //   const config = {
  //     headers: { Authorization: `Bearer ${token}` },
  //   };

  //   const userId = sessionStorage.getItem("userId");

  //   axios
  //     .get(
  //       `${REACT_APP_BACKEND_API_APP}/api/GetApplicationsListByLoginUserId?userId=${userId}&PageNumber=1&PageSize=50`,
  //       config
  //     )
  //     .then((response) => {
  //       if (response.data.statusCode === 200) {
  //         let appsList = response.data.responseData.data;
  //         appsList = appsList.map((item) => {
  //           return {
  //             date: new Date(item.createdAt),
  //             ...item,
  //             id: item.appId,
  //             value: item.appName,
  //           };
  //         });
  //         appsList = appsList.filter((a) => a.isActive === true);
  //         appsList.length > 0 &&
  //           appsList.splice(0, 0, { value: "Select All", appId: 0 });
  //         setAppListbyAd(appsList ? appsList : []);
  //       }
  //     });
  // }, [setAppData, submitted, props.statusSuccess, props.appsArr, groupRules]);

  // useEffect(() => {
  //   let groupList = userGroupList
  //     .filter((a) => appData.group.includes(a.id))
  //     .map((b) => b.id);
  //   let data = {
  //     groupIdList: groupList,
  //   };

  //   if (groupList.length > 0) {
  //     let token = sessionStorage.getItem("accessToken2");
  //     const config = {
  //       headers: { Authorization: `Bearer ${token}` },
  //     };
  //     axios
  //       .post(
  //         `${REACT_APP_BACKEND_API_APP}/api/GetAllUserListByGroupId`,
  //         data,
  //         config
  //       )
  //       .then(async (res) => {
  //         if (res.data.statusCode === 200) {
  //           let userData = res.data.responseData.map((a) => {
  //             return {
  //               userId: a.userId,
  //               userName: a.userName,
  //               email: a.email,
  //             };
  //           });
  //           userData.splice(0, 0, { userName: "Select All", userId: 0 });
  //           let test1 = [
  //             ...appListbyAd
  //               ?.filter((a) =>
  //                 selectedApps.some((o2) => a.appId === o2 && o2 !== 0)
  //               )
  //               .map((a) => a.userId),
  //           ];
  //           test1 = [...new Set(test1.flat())];
  //           test1 = userData
  //             ?.filter((a) => test1.some((o2) => a.userId === o2))
  //             .map((a) => a.userId);
  //           setSelectedUsers(test1?.length > 0 ? test1 : []);
  //           setAppData({
  //             ...appData,
  //             userArr: test1?.length > 0 ? test1 : [],
  //           });
  //           setUserByGroupId(userData ? userData : []);
  //         }
  //       });
  //   } else {
  //     setUserByGroupId([]);
  //   }
  // }, [appData.group, userGroupList, groupRules]);

  let appsSearchFilter =
    appListbyAd &&
    appListbyAd.filter((item) => {
      if (searchAppName === "") {
        return item;
      } else if (item.appName.toLowerCase().includes(searchAppName)) {
        return item;
      }
    });

  let userGroupSearchFilter =
    userGroupList &&
    userGroupList
      .filter((i) => i.status === true)
      .filter((item) => {
        if (searchUserGroup === "") {
          return item;
        } else if (item.value.toLowerCase().includes(searchUserGroup)) {
          return item;
        }
      });

  let userSearchFilter =
    userByGroupId &&
    userByGroupId?.filter((item) => {
      if (searchUser === "") {
        return item;
      } else if (item.userName.toLowerCase().includes(searchUser)) {
        return item;
      }
    } );

  const appChange = (e) => {
    var value = parseInt(e.target.value);
    if (value === 0 && e.target.checked)
      setSelectedApps(
        allApps.filter((item) => item.appId !== value).map((a) => a.appId)
      );
    else if (value === 0 && !e.target.checked) setSelectedApps([]);
    else if (e.target.checked && value !== 0)
      setSelectedApps([...selectedApps, value]);
    else if (!e.target.checked && value !== 0)
      setSelectedApps(selectedApps.filter((item) => item !== value));
  };

  const groupChange = (e) => {
    var value = parseInt(e.target.value);
    if (value === 0 && e.target.checked)
      setSelectedGroups(
        userGroupList
          ?.filter((i) => i.status === true)
          .filter((item) => item.id !== parseInt(value))
          .map((a) => a.id)
      );
    else if (value === 0 && !e.target.checked) setSelectedGroups([]);
    else if (e.target.checked && value !== 0)
      setSelectedGroups([...selectedGroups, value]);
    else if (!e.target.checked && value !== 0)
      setSelectedGroups(selectedGroups.filter((item) => item !== value));
  };

  const userChange = (e) => {
    var value = parseInt(e.target.value);
    if (value === 0 && e.target.checked) {
      setSelectedUsers(
        userByGroupId
          .filter((item) => item.userId !== parseInt(value))
          .map((a) => a.userId)
      );
    } else if (value === 0 && !e.target.checked) setSelectedUsers([]);
    else if (e.target.checked && value !== 0)
      setSelectedUsers([...selectedUsers, value]);
    else if (!e.target.checked && value !== 0)
      setSelectedUsers(selectedUsers.filter((item) => item !== value));
  };
  useEffect(() => {
    dispatch(fetchAppsByUserId(selectedUsers));
  }, [selectedUsers]);

  useEffect(() => {
    setSelectedApps(
      [...(appListbyAd
        ?.filter((obj) => {
          if (allUserApps?.find((ol) => ol.id === obj.appId)) {
            return { obj };
          }
        })
        .map((a) => a.appId)), ...props?.selectedApps]
    );

  }, [allUserApps, selectedUsers]);

  const appAdd = (e) => {
    setAppData({
      ...appData,
      assignApp: [...selectedApps],
    });
    setSearchAppName("");
    setOpenMenu("");
  };

  const groupAdd = (e) => {
    setAppData({
      ...appData,
      group: selectedGroups,
    });
    setSearchUserGroup( "" );
    setOpenMenu("");
  };

  const [enteredAssignApppTouched, setEnteredAssignApppTouched] =
    useState(false);
  const enteredAssignApppIsValid = appData.assignApp.length !== 0;
  const assignAppBlurHandler = () => {
    setEnteredAssignApppTouched(true);
  };

  const [enteredGroupTouched, setEnteredGroupTouched] = useState(false);
  const enteredGroupIsValid = appData.group.length !== 0;
  const groupInputBlurHandler = () => {
    setEnteredGroupTouched(true);
  };

  const [enteredUserTouched, setEnteredUserTouched] = useState(false);
  const enteredUserIsValid = appData.userArr.length !== 0;
  const userArrInputBlurHandler = () => {
    setEnteredUserTouched(true);
  };

  const userInputIsInvalid =
    (!enteredUserIsValid && enteredUserTouched) ||
    (enteredAssignApppTouched && !enteredUserIsValid) ||
    (enteredGroupTouched && !enteredUserIsValid);

  const assignAppInputValid =
    (!enteredAssignApppIsValid && enteredAssignApppTouched && selectedApps.length === 0) ||
    (enteredGroupTouched && !enteredAssignApppIsValid && selectedApps.length === 0);

  const groupInputValid = !enteredGroupIsValid && enteredGroupTouched;

  const [errorMsg, setErrorMsg] = useState(null);
  const [displayErrorMsg, setDisplayErrorMsg] = useState('');

  const submitHandler = (e) => {
    setEnteredAssignApppTouched(true);
    setEnteredGroupTouched(true);
    setEnteredUserTouched(true);
    if (appData.assignApp.length === 0 || appData.userArr.length === 0) {
      return;
    }

    e.preventDefault();

    let token = sessionStorage.getItem("accessToken2");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    };

    let data = {
      appIdList: appData.assignApp.filter((a) => a !== 0),
      createdBy: loginData?.userId,
      userIdList: appData.userArr.filter((a) => a !== 0),
      groupIdList: appData.group.filter((a) => a !== 0),
    };
    axios
      .post(
        `${REACT_APP_BACKEND_API_APP}/api/AssignApplicationtoUsers`,
        data,
        config
      )
      .then(async (res) => {
        setErrorMsg(res.data.statusCode);
        setSubmitted(true);
        if (res.data.statusCode === 200) {
        } else {
          setDisplayErrorMsg(res.data.responseData)
        }
      });


  };

  const modalClose = () => {
    setAppData(initialState);
    setEnteredAssignApppTouched(false);
    setEnteredGroupTouched(false);
    setEnteredUserTouched(false);
    setSelectedApps([]);
    setSelectedGroups([]);
    setSelectedUsers([]);
    setSubmitted(false);
    props?.setShowAssign(false)
    setErrorMsg(null);
    props?.setSelectedAppsProps([])
  }

  React.useEffect(() => {
    setAppData({
      ...appData,
      assignApp: [...selectedApps, ...props?.selectedApps]
    })
  }, [props?.selectedApps, appData.assignApp.length === 0]);

  const getAppNames = (assigned, apps) => {
    const filteredApps = apps
      ?.filter((a) =>
        assigned.some(
          (o2) => a.appId === parseInt(o2)
        )
      )
      ?.map((a) => a.appName)
      return filteredApps.length > 5 ? `${filteredApps.length} apps` : filteredApps.slice(0, -1).join(', ') + ' and ' + filteredApps[filteredApps.length - 1]
  }

  const selectedUsersChips = userByGroupId
    .filter((a) => appData.userArr.some((o2) => a.userId === parseInt(o2)))
    .map((a) => a.userName.split(",")[0])
    .filter((a) => a !== "Select All");

  const selectedAppsChips =  allApps?.filter((a) =>
    appData.assignApp.some(
      (o2) => a.appId === parseInt(o2)
    )
  )
  ?.map((a) => ({ appId: a.appId, appName: a.appName }))
  ?.filter((a) => a !== "Select All")

  const selectedGroupChips = userGroupList
   .filter((a) =>
     appData.group.some((o2) => a.id === parseInt(o2))
   )
   .map((a) => a.value)
   .filter((a) => a !== "Select All")



   const getList = (data, selectedOptions, list, setOptions, id, onOptionChange, name, key, icon = true) => {
     if ( !data ) return;
    const firstElement = data?.slice(0, 1);
    const restElements = data?.slice( 1 );

    if (
      selectedOptions?.length > 0 &&
      selectedOptions.length === list.length - 1 &&
      selectedOptions.includes(0)
    )
      setOptions(
        selectedOptions.filter((a) => parseInt(a) !== 0)
      );
    if (name === 'group') {
      if (
        selectedOptions?.length > 0 &&
        selectedOptions.length === list?.filter((i) => i.status === true).length - 1 &&
        !selectedOptions.includes(0)
      )
        setOptions([...selectedOptions, 0]);
    } else {
      if (
        selectedOptions?.length > 0 &&
        selectedOptions.length === list.length - 1 &&
        !selectedOptions.includes(0)
      )
        setOptions([...selectedOptions, 0]);
    }



    return (
      <>
        <div className="s-all-wrapper">
          {firstElement?.map((item, index) => {
            return (
              <label key={ index } className={`s-all ${selectedOptions?.filter((a) => a === item[id])
                .length === 1
                ? 'active'
                : ''}`}>
                  <input
                    type="checkbox"
                    checked={
                      selectedOptions?.filter((a) => a === item[id])
                        .length === 1
                        ? true
                        : false
                    }
                    value={item[id]}
                    onChange={onOptionChange}
                    name={name}
                    className="form-check-input"
                  />
                  {item[key].split(",")[0]}
                  {selectedOptions?.filter((a) => a === item[id])
                      .length === 1
                      ? <CheckIcon />
                      : null
                  }
              </label>
            )
          })}
        </div>
        <div className="all-check-wrapper">
          {restElements?.map((item, index) => {
            return (
              <label key={index + 1} className={`all-check ${selectedOptions?.filter((a) => a === item[id])
                .length === 1
                ? 'active'
                : ''}`}>
                  <input
                    type="checkbox"
                    checked={
                      selectedOptions?.filter((a) => a === item[id])
                        .length === 1
                        ? true
                        : false
                    }
                    value={item[id]}
                    onChange={onOptionChange}
                    name={name}
                    className="form-check-input"
                  />
                    <Box display='flex' alignItems='center' gap={1}>
                      {icon && (
                        <CustomAvatar sx={ { width: '1.5rem', height: '1.5rem', } } title={ item[ key ].split( "," )[ 0 ]?.split( '' )?.[ 0 ]?.toUpperCase() } />
                      )}
                      {item[key].split(",")[0]}
                    </Box>

                  {
                      selectedOptions?.filter((a) => a === item[id])
                        .length === 1
                        ? <CheckIcon />
                        : null
                    }
              </label>
            )
          })}
        </div>
      </>
    )
  }

  return (
    <>
      <Modal
        modalId={"assign"}
        modalLabel={"exampleModalLabel"}
        className={"addUserModal secondary-modal"}
        showModal={props?.show}
      >
          <div className="modal-dialog modal-dialog-centered one-col modal-l">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Assign / Unassign Users to Apps
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={modalClose}
                ></button>
              </div>
              <div className="modal-body">
                <form className="user-lable">
                  <div className="custom-chip-input">
                    <label>Select Users<sup>*</sup></label>
                    <div
                      data-bs-target="#exampleModalToggle7"
                      data-bs-toggle="modal"
                      className={`input-wrapper ${openMenu.includes('SelectUsers') ? 'active' : ''}`}
                      onClick={() => setOpenMenu('SelectUsers')}
                    >
                      <UserIcon />
                      <div className="wrap">
                        {selectedUsersChips?.map((userName, index) => (
                          <div className="custom-chip" key={index}>
                            <CustomAvatar title={userName?.split( '' )?.[ 0 ]} />
                            {userName}
                          </div>
                        ))}
                        <p>Select Users</p>
                      </div>
                    </div>
                    {userInputIsInvalid && (
                      <p className="error-text">Please select a User</p>
                    )}
                  </div>

                  <div className="custom-chip-input">
                    <label>Select Apps<sup>*</sup></label>
                    <div
                      data-bs-target="#exampleModalToggle8"
                      data-bs-toggle="modal"
                      className={`input-wrapper ${openMenu.includes('SelectApps') ? 'active' : ''}`}
                      onClick={() => setOpenMenu('SelectApps')}

                    >
                      <ContentIcon />
                      <div className="wrap">
                        {selectedAppsChips?.map((userName, index) => (
                          <div className="custom-chip" key={index} style={{
                            background:
                              parseInt(props?.selectedApps[0]) === parseInt(userName?.appId)
                                ? '#e6f6f4'
                                : 'initial'

                          }}>
                            <CustomAvatar title={userName.appName?.split( '' )?.[ 0 ]} />
                            {userName?.appName}
                          </div>
                        ))}
                        <p>Select Apps</p>
                      </div>
                    </div>
                    {assignAppInputValid && (
                      <p className="error-text">Please select App</p>
                    )}
                  </div>

                  <div className="custom-chip-input">
                    <label>Select Groups</label>
                    <div
                      data-bs-target="#exampleModalToggle6"
                      data-bs-toggle="modal"
                      className={`input-wrapper ${openMenu.includes('SelectGroups') ? 'active' : ''}`}
                      onClick={() => setOpenMenu('SelectGroups')}
                    >
                      <GroupIcon />
                      <div className="wrap">
                        {selectedGroupChips?.map((userName, index) => (
                          <div className="custom-chip" key={index}>
                            {userName}
                          </div>
                        ))}
                        <p>Select Groups</p>
                      </div>
                    </div>
                </div>

                { submitted && ( errorMsg === 200 ? (
                  <CustomAlert
                    title={ `${ appData.userArr.length } ${ appData.userArr.length > 1 ? 'users' : 'user' } ${appData.group.length > 0 ? `and ${appData.group.length} ${appData.group.length > 1 ? 'groups' : 'group'} have` : 'has'} been assigned to ${ getAppNames( appData.assignApp, allApps ) }.` }
                  />
                ) : (
                    <CustomAlert
                      severity='error'
                      title={displayErrorMsg}
                    />
                  ))
                }
                </form>

              </div>
              <div className="modal-footer">
                { errorMsg === 200 && submitted ?
                  <button
                    className="btn btn-genmeb ms-3 submit--btn"
                    data-bs-dismiss="modal"
                    onClick={modalClose}
                  >
                    Done
                  </button> : (
                  <>
                  <button
                    type="button"
                    className="btn btn-secondary cancel--btn"
                    data-bs-dismiss="modal"
                    onClick={modalClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={submitHandler}
                    className="btn btn-genmeb ms-3 submit--btn"
                  >
                    Assign/Unassign
                  </button>
                  </>
                  )
                }

              </div>
            </div>
          </div>
        {/* )  */}
      </Modal>

      <Modal
        modalId={"exampleModalToggle8"}
        modalLabel={"exampleModalToggleLabel8"}
      >
        <div
          className="modal-dialog modal-dialog-centered me-widht"
          onBlur={assignAppBlurHandler}
        >
          <div className="modal-content app-sub1">
            <div className="modal-body p-0">
              {allAppsLoading ? (
                <Loader />
              ) : (
                <>
                  <div className="relative w-100">
                    <img src={SearchIcon} alt="icon" className="pop-search" />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchAppName}
                      onChange={(evnt) =>
                        setSearchAppName(evnt.target.value.toLowerCase())
                      }
                    />
                    {/* <img
                      src={up}
                      alt="icon"
                      className="up-icon"
                      data-bs-target="#exampleModalToggle"
                      data-bs-dismiss="modal"
                      data-bs-toggle="modal"
                    /> */}
                  </div>

                  {appListbyAd && appListbyAd?.length === 0 ? (
                    <p className="events-error">No apps available </p>
                  ) : appsSearchFilter.length === 0 ? (
                    <p className="events-error">No Match Found </p>
                  ) : getList(appsSearchFilter, selectedApps, appListbyAd, setSelectedApps, 'appId', appChange, 'assignApp', 'appName')}
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  setSelectedApps(appData.assignApp);
                  setSearchAppName("");
                  setOpenMenu("");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-genmeb"
                data-bs-target="#exampleModalToggle"
                data-bs-dismiss="modal"
                onClick={appAdd}
                disabled={submitted && errorMsg === 200}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        modalId={"exampleModalToggle6"}
        modalLabel={"exampleModalToggleLabel6"}
      >
        <div
          className="modal-dialog modal-dialog-centered me-widht"
          onBlur={groupInputBlurHandler}
        >
          <div className="modal-content app-sub2">
            <div className="modal-body p-0">
              <div className="relative w-100">
                <img src={SearchIcon} alt="icon" className="pop-search" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={searchUserGroup}
                  onChange={(evnt) =>
                    setSearchUserGroup(evnt.target.value.toLowerCase())
                  }
                />
                {/* <img
                  src={up}
                  alt="icon"
                  className="up-icon"
                  data-bs-target="#exampleModalToggle"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                /> */}
              </div>
              {userGroupList && userGroupList?.length === 0 ? (
                <p className="events-error">No Groups available </p>
              ) : userGroupSearchFilter.length === 0 ? (
                <p className="events-error">No Match Found </p>
              ) : getList(userGroupSearchFilter, selectedGroups, userGroupList, setSelectedGroups, 'id', groupChange, "group", 'value', false )
              }
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  setSelectedGroups(appData.group);
                  setSearchUserGroup( "" );
                  setOpenMenu("");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-target="#exampleModalToggle"
                data-bs-dismiss="modal"
                onClick={groupAdd}
                disabled={submitted && errorMsg === 200}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        modalId={"exampleModalToggle7"}
        modalLabel={"exampleModalToggleLabel7"}
      >
        <div
          className="modal-dialog menu-modal modal-dialog-centered me-widht"
          onBlur={userArrInputBlurHandler}
        >
          <div className="modal-content app-sub3">
            <div className="modal-body p-0">
              {allUsersLoading ? (
                <Loader />
              ) : (
                <>
                  <div className="relative w-100">
                    <img src={SearchIcon} alt="icon" className="pop-search" />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchUser}
                      onChange={(evnt) =>
                        setSearchUser(evnt.target.value.toLowerCase())
                      }
                    />
                    {/* <img
                      src={up}
                      alt="icon"
                      className="up-icon"
                      data-bs-target="#exampleModalToggle"
                      data-bs-dismiss="modal"
                      data-bs-toggle="modal"
                    /> */}
                  </div>
                  {userByGroupId && userByGroupId?.length === 0 ? (
                    <p className="events-error">No User(s) Found </p>
                  ) : userSearchFilter.length === 0 ? (
                    <p className="events-error">No Match Found </p>
                  ) : getList(userSearchFilter, selectedUsers, userByGroupId, setSelectedUsers, 'userId', userChange,"userArr", 'userName' )
                  }
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  setSelectedUsers(appData.userArr);
                  setSearchUser( "" );
                  setOpenMenu("")
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-target="#exampleModalToggle"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  setAppData({
                    ...appData,
                    userArr: selectedUsers,
                    assignApp: [...selectedApps, ...props?.selectedApps],
                  });
                  setSearchUserGroup("");
                  setSearchUser( "" );
                  setOpenMenu("")
                }}
                disabled={allUserAppsLoading || (submitted && errorMsg === 200)}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Assign;
