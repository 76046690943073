import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/action-master";
import Circle from "../../assets/images/new-images/add.png";
import deleteIcon from "../../assets/images/new-images/delete.png";
import edit from "../../assets/images/new-images/edit.png";
import sortIcon from "../../assets/images/new-images/sortIcon.svg";
import AddUserGroup from "./AddUserGroup";
import DeleteUserGroup from "./DeleteUserGroup";
import ActivateUserGroup from "./ActivateUserGroup";
import { createUserGroup } from "../../actions/action-master";
import moment from "moment";

const UserGroup = ({ fetchAllUserGroups, userGroups = [], ...props }) => {
  const init = () => {
    fetchAllUserGroups();
  };

  useEffect(() => {
    init();
  }, []);

  const data = [];

  const [group, setGroups] = useState(data);

  const createGroup = (newItem) => {
    setGroups([...group, newItem]);
  };

  const [deleteId, setDeleteId] = useState(null);

  const removeType = (id) => {
    setDeleteId(id);
  };

  const [editmode, setEditMode] = useState(false);
  const [filterGroupIndex, setFilterGroupIndex] = useState("");
  const [filterGroup, setFilterGroup] = useState({});
  const editType = (id) => {
    setEditMode(true);
    setFilterGroupIndex(userGroups?.findIndex((item, index) => item.id === id));
    setFilterGroup(userGroups?.find((item, index) => item.id === id));
  };
  const [showAll, setShowAll] = useState(false);
  const [inactiveItem, setInactiveItem] = useState(null);
  const [activeStatus, setActiveStatus] = useState("Active");
  const [groupId, setGroupId] = useState(false);
  const inActiveTest = (item, status, id) => {
    setInactiveItem(item);
    setActiveStatus(status);
    setGroupId(id);
  };

  return (
    <>
      <AddUserGroup
        userGroups={userGroups}
        setGroups={setGroups}
        editmode={editmode}
        setEditMode={setEditMode}
        filterGroupIndex={filterGroupIndex}
        filterGroup={filterGroup}
        setFilterGroup={setFilterGroup}
        activeStatus={activeStatus}
      />
      <ActivateUserGroup
        modalId={"activateGroupModalToggle12"}
        modalLabel={"activateGroupModalToggleLabel12"}
        inactiveItem={inactiveItem}
        activeStatus={activeStatus}
        groupId={groupId}
      />
      <DeleteUserGroup deleteId={deleteId} />
      <div
        className="tab-pane fade show active"
        id="nav-profile"
        role="tabpanel"
        aria-labelledby="nav-profile-tab"
      >
        <div className="setting-header">
          <p className="app-heding">User Group</p>
          <div className="applist-right-headbar">
            <div className="d-flex">
              <p className="text-w">Hide Inactive</p>
              <div className="togl-btn">
                <label className="switch me-3 ms-3">
                  <input
                    type="checkbox"
                    name="hide-inactive-groups-switch"
                    checked={showAll}
                    onChange={() => setShowAll(!showAll)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <button
              className="btn btn-genmeb  min-w72 open-modal--btn"
              data-bs-toggle="modal"
              data-bs-target="#groupName"
            >
              <img src={Circle} alt="icon" className="me-2" />
              Add User Group
            </button>
          </div>
        </div>

        <div className="table-responsive notificationTable ">
          <table className="table table-hover">
            <thead className="table-head">
              <tr>
                <th>Action</th>
                <th>
                  #
                  {/* <span className="ms-2">
                    <img src={sortIcon} alt="icon" />
                  </span> */}
                </th>
                <th>
                  User Group
                  {/* <span className="ms-2">
                    <img src={sortIcon} alt="icon" />
                  </span> */}
                </th>
                <th>Status</th>
                <th>
                  Date Added{" "}
                  {/* <span className="ms-2">
                    <img src={sortIcon} alt="icon" />
                  </span> */}
                </th>
                <th>Last Modified Date</th>
                <th>Last Modified By</th>
              </tr>
            </thead>
            <tbody>
              {userGroups && userGroups?.length === 0
                ? "No Notification Types available"
                : userGroups
                    ?.filter((i) => (!showAll ? i : i.status === true))
                    ?.map((item, index) => (
                      <tr key={item.id}>
                        <td>
                          <span className="d-flex">
                            {item.status === true && (
                              <img
                                src={edit}
                                alt="icon"
                                className="me-3"
                                data-bs-toggle="modal"
                                data-bs-target="#groupName"
                                onClick={() => {
                                  editType(item.id);
                                }}
                              />
                            )}
                            <img
                              src={deleteIcon}
                              alt="icon"
                              onClick={() => removeType(item.id)}
                              data-bs-target="#deleteGroupsModal"
                              data-bs-toggle="modal"
                            />
                          </span>
                        </td>
                        <td>{index + 1}</td>
                        <td>{item.value}</td>
                        <td>
                          <div className="togl-btn">
                            <span
                              className={`w33 ${
                                item.status === true ? "green" : "red"
                              }`}
                            >
                              {item.status ? "Active" : "Inactive"}
                            </span>
                            <label
                              className="switch me-3 ms-3"
                              data-bs-toggle="modal"
                              data-bs-target="#activateGroupModalToggle12"
                            >
                              <input
                                type="checkbox"
                                checked={item.status === true ? true : false}
                                onChange={() => {
                                  console.log("");
                                }}
                                onClick={() => {
                                  inActiveTest(item, item.status, item.id);
                                }}
                              />

                              <span className="slider round"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          {moment(item.createdAt).local().format("MM/DD/YYYY")}
                        </td>
                        <td>
                          {item.lastModifiedAt ? moment(item.lastModifiedAt).local().format("MM/DD/YYYY") : "N/A"}
                        </td>
                        <td>
                          {item.lastModifiedBy ? item.lastModifiedBy : "N/A"}
                        </td>
                      </tr>
                    ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ master }) => ({
  userGroups: master.userGroups,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllUserGroups: () => dispatch(actions.fetchAllUserGroups()),
  createUserGroup: (payload) => dispatch(createUserGroup(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserGroup);
