import * as React from 'react';
import SortingIcon from "../../assets/images/sorting.svg"
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { BULK_USER_VALIDATION_ERRORS, USER_ROLES } from '../../constants';
import {joinArrayByComma, renderStringsFromArray} from "../../middlewares/utils";

function createData ( name, email, group, role, error )
{
  return {
    name,
    email,
    group,
    role,
    error,
  };
}

function descendingComparator ( a, b, orderBy )
{
  if ( b[ orderBy ] < a[ orderBy ] )
  {
    return -1;
  }
  if ( b[ orderBy ] > a[ orderBy ] )
  {
    return 1;
  }
  return 0;
}

function getComparator ( order, orderBy )
{
  return order === 'desc'
    ? ( a, b ) => descendingComparator( a, b, orderBy )
    : ( a, b ) => -descendingComparator( a, b, orderBy );
}


function stableSort ( array, comparator )
{
  const stabilizedThis = array.map( ( el, index ) => [ el, index ] );
  stabilizedThis.sort( ( a, b ) =>
  {
    const order = comparator( a[ 0 ], b[ 0 ] );
    if ( order !== 0 )
    {
      return order;
    }
    return a[ 1 ] - b[ 1 ];
  } );
  return stabilizedThis.map( ( el ) => el[ 0 ] );
}

const headCells = [
  {
    id: 'name',
    label: 'Full name',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'groups',
    label: 'Group(s)',
  },
  {
    id: 'role',
    label: 'Role',
  },
  {
    id: 'issuesFound',
    label: 'Issues found',
  },
];

function EnhancedTableHead ( props )
{
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = ( property ) => ( event ) =>
  {
    onRequestSort( event, property );
  };

  return (
    <TableHead>
      <TableRow>
        { headCells.map( ( headCell ) => (
          <TableCell
            sx={ {
              color: '#626D8A',
              padding: '12px 11px !important',
              fontFamily: 'Manrope',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '133.333%',
              borderColor: '#EAEBEB',
            }}
            key={ headCell.id }
            align={ headCell.numeric ? 'right' : 'left' }
            padding={ headCell.disablePadding ? 'none' : 'normal' }
            sortDirection={ orderBy === headCell.id ? order : false }
          >
            <TableSortLabel
              IconComponent={() => <img src={SortingIcon} />}
              active={ orderBy === headCell.id }
              direction={ orderBy === headCell.id ? order : 'asc' }
              onClick={ createSortHandler( headCell.id ) }
              sx={ {
                gap: '0.25rem',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              { headCell.label }
              { orderBy === headCell.id ? (
                <Box component="span" sx={ visuallyHidden }>
                  { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                </Box>
              ) : null }
            </TableSortLabel>
          </TableCell>
        ) ) }
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf( [ 'asc', 'desc' ] ).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


export default function UploadErrorTable (props)
{
  const [ order, setOrder ] = React.useState( 'asc' );
  const [ orderBy, setOrderBy ] = React.useState( 'email' );
  const [ selected, setSelected ] = React.useState( [] );
  const [ page, setPage ] = React.useState( 0 );
  const [ rowsPerPage, setRowsPerPage ] = React.useState( 5 );

  const handleRequestSort = ( event, property ) =>
  {
    const isAsc = orderBy === property && order === 'asc';
    setOrder( isAsc ? 'desc' : 'asc' );
    setOrderBy( property );
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max( 0, ( 1 + page ) * rowsPerPage - props.users.length ) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort( props.users, getComparator( order, orderBy ) ).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [ order, orderBy, page, rowsPerPage ],
  );

  return (
    <Table
      sx={{minWidth: 1}}
        aria-labelledby="tableTitle"
      >
        <EnhancedTableHead
          numSelected={ selected.length }
          order={ order }
          orderBy={ orderBy }
          onRequestSort={ handleRequestSort }
          rowCount={ props.users.length }
        />
        <TableBody>
          { visibleRows.map( ( row, index ) =>
          {

            return row.validationErrors.length > 0 && (
              <TableRow
                hover
                key={ row.name }
                sx={{
                  '& .MuiTableCell-root': {
                    color: '#161616 !important',
                    padding: '12px 11px !important',
                    fontFamily: '"Manrope"',
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '133.333%',
                    borderLeft: '1px solid',
                    borderColor: '#EAEBEB',

                    '&:last-of-type': {
                      borderRight: '1px solid',
                      borderColor: '#EAEBEB',
                      color: '#B42318 !important'
                    }
                  }
                }}
              >
                <TableCell>
                  { row.userName }
                </TableCell>
                <TableCell>{ row.email }</TableCell>
                <TableCell>{ joinArrayByComma(row.groups) }</TableCell>
                <TableCell>{ USER_ROLES[row.userRoleId - 1] }</TableCell>
                <TableCell>{ renderStringsFromArray(BULK_USER_VALIDATION_ERRORS, row.validationErrors) }</TableCell>
              </TableRow>
            );
          } ) }
          { emptyRows > 0 && (
            <TableRow
              style={ {
                height: 53 * emptyRows,
              } }
            >
              <TableCell colSpan={ 6 } />
            </TableRow>
          ) }
        </TableBody>
      </Table>
  );
}