import React from "react";
import Modal from "../UI/Modal";
import { connect } from "react-redux";
import sucess from "../../assets/images/new-images/sucess.png";
import dltIcon from "../../assets/images/new-images/dlt.png";
import { useState } from "react";
import { deleteGroupRule } from "../../actions/action-grouprule";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import failure from "../../assets/images/new-images/error.png";

const DeleteRule = ({
  deleteId,
  setGroupRules,
  groupsRules,
  deleteGroupRule,
  message,
  loading,
  ...props
}) => {
  const [ruleDelete, setRuleDelete] = useState(true);
  const rulesDeleteHandler = () => {
    setRuleDelete(!ruleDelete);
  };

  const deleteType = (id) => {
    deleteGroupRule(id);
    setRuleDelete(!ruleDelete);
  };

  return (
    <Modal
      modalId={"deleteGroupRulesModal"}
      modalLabel={"deleteGroupsModalLabel"}
      className={"dlt-modal"}
    >
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content">
          <div className="modal-body text-center pt-0 type-loader">
            {ruleDelete ? (
              <>
                <img
                  src={ruleDelete ? dltIcon : sucess}
                  alt="icon"
                  className="m-3"
                />

                {ruleDelete && (
                  <p className="dlt-hed">
                    Are you sure you want to delete this rule?
                  </p>
                )}

                <p className="dlt-body">
                  {ruleDelete
                    ? "The rule will then be removed from the database and any users associated with that rule will no longer be able to access the applications that were assigned in that rule."
                    : "Group Rule Deleted Successfully"}
                </p>
              </>
            ) : loading ? (
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  top: "30%",
                  width: "100%",
                  height: "300PX",
                  justifyContent: "center",
                  alignItems: "start",
                  // background: "#000",
                  zIndex: 99,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <img
                  src={message && (message === 404 ? failure : sucess)}
                  alt="icon"
                  className="m-3"
                />

                <p
                  className={`${
                    message && (message === 404 ? "red-text" : "green-text")
                  }`}
                >
                  {message && (message === 404 ? "Failure" : "Success")}
                </p>

                <p className="dlt-body">
                  {message &&
                    (message === 404
                      ? "You cannot delete/deactivate the group rule until you reassign the user(s) associated with this group to another group"
                      : "User Group Deleted Successfully")}
                </p>
              </>
            )}
          </div>
          <div className="modal-footer">
            {ruleDelete && (
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            )}

            {ruleDelete ? (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                onClick={() => deleteType(deleteId)}
              >
                Delete
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-dismiss="modal"
                onClick={rulesDeleteHandler}
              >
                Ok
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ groupsRule }) => ({
  message: groupsRule.deleteResponse,
  loading: groupsRule.deleteResponseLoading,
});

const mapDispatchToProps = (dispatch) => ({
  deleteGroupRule: (payload) => dispatch(deleteGroupRule(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteRule);
