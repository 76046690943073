import React, { useRef, useState, useEffect } from "react";
import SearchIcon from "../../../assets/images/search.svg";
import noteRemove from "../../../assets/images/new-images/note-remove.svg";
import demoImg from "../../../assets/images/new-images/t3.png";
import demouser from "../../../assets/images/new-images/e2.png";
import emailUs from "../../../assets/images/new-images/email-us.svg";
import locationIcon from "../../../assets/images/new-images/location-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import RulePagination from "../../UI/RulePagination";
import {
  getPaginationData,
  fetchSearch,
} from "../../../actions/action-grouprule";
import {
  fetchUsersPopUpInfo,
  fetchUsersProfiePhoto,
} from "../../../actions/actions-users";
import { Loader } from "rsuite";
import moment from "moment";
import { fetchImages } from "../../../actions/action-master";
import n1 from "../../../assets/images/new-images/placeholder.png";
import {getMimeTypeFromBase64, renderImageDataUrl} from "../../../middlewares/utils";

const RulePreview = ({ userGroups, userApps, test }) => {
  const dispatch = useDispatch();
  const userPreview = useSelector((state) => state?.groupsRule?.userPreview);
  const userPreviewLoading = useSelector(
    (state) => state?.groupsRule?.userPreviewLoading
  );
  const paginationOptions = useSelector(
    (state) => state?.groupsRule?.paginationOptions
  );
  const totalRecords = useSelector((state) => state?.groupsRule?.totalRecords);
  const conditionsData = useSelector(
    (state) => state?.groupsRule?.conditionsData
  );
  const recordsPerPage = useSelector(
    (state) => state?.groupsRule?.recordsPerPage
  );
  const userPreviewResponse = useSelector(
    (state) => state?.groupsRule?.userPreviewResponse
  );

  let pageNo = useRef();

  const modifyRuleList = useSelector(
    (state) => state?.groupsRule?.modifyRuleList
  );
  // useEffect(() => {
  //   !test?.map((i) => i.conditionOn).includes("") &&
  //     !test?.map((i) => i.conditionOperator).includes("") &&
  //     dispatch(fetchSearch(""));
  // }, [test]);

  pageNo.current = paginationOptions.pageNo;
  const setPaginationOptions = (values) => {
    dispatch(getPaginationData(values));
  };

  const [timer, setTimer] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const searchHandler = (e) => {
    setSearchInput(e.target.value);
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      dispatch(fetchSearch(e.target.value));
    }, 1000);

    setTimer(newTimer);
  };
  const usersInfo = useSelector((state) => state?.usersList?.usersInfo);
  const infoLoading = useSelector((state) => state?.usersList?.infoLoading);
  const photoInfo = useSelector((state) => state?.usersList?.photoInfo);
  const photoLoading = useSelector((state) => state?.usersList?.photoLoading);

  const appPhotoInfo = useSelector((state) => state?.master?.photoInfo);
  const appPhotoLoading = useSelector((state) => state?.master?.photoLoading);

  useEffect(() => {
    let empIds = userPreview?.map((e) => e.employeeId);
    userPreview?.length > 0 && dispatch(fetchUsersPopUpInfo(empIds));
    userPreview?.length > 0 && dispatch(fetchUsersProfiePhoto(empIds));
  }, [userPreview]);

  return (
    <>
      <div className="preview">
        <div className="preview-header">
          <h5 className="main-title">Preview</h5>
          <div className="relative">
            <img src={SearchIcon} alt="icon" />
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={searchInput}
              onChange={searchHandler}
            />
          </div>
        </div>
        {userPreviewLoading ? (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: "50%",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "start",
              left: "0",
              // background: "#000",
              zIndex: 99,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <div className="table-responsive preview-table">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Full name</th>
                    <th scope="col">Department</th>
                    <th scope="col">Apps assigned</th>
                    <th scope="col">Date added </th>
                    <th scope="col">Group</th>
                    <th scope="col">Title</th>
                  </tr>
                </thead>
                <tbody>
                  {userPreview && userPreview?.length > 0 ? (
                    userPreview?.map((u, index) => (
                      <tr key={u.userId}>
                        <td>{paginationOptions.pageNo * 10 - 9 + index}</td>
                        <td className="mainRelative">
                          <div className="d-flex align-items-center min-h-48">
                            {/* <img
                                  src={u1}
                                  alt="icon"
                                  className="me-2 customIcon"
                                /> */}
                            {photoLoading ? (
                              <Loader className="loader-w" />
                            ) : photoInfo?.find(
                                (b) => u.employeeId === b.employeeId
                              )?.profilePhoto ? (
                              <img
                                className="user-pic  me-2"
                                src={renderImageDataUrl(photoInfo?.find(
                                    (b) => u.employeeId === b.employeeId
                                )?.profilePhoto)}
                                alt="icon"
                              />
                            ) : (
                              <div className="up-img me-2">
                                <span>
                                  {u.userName
                                    .match(/(^\S\S?|\b\S)?/g)
                                    .join("")
                                    .match(/(^\S|\S$)?/g)
                                    .join("")
                                    .toUpperCase()}
                                </span>
                              </div>
                            )}
                            <span className="pre-username">{u.userName}</span>
                          </div>

                          <div className="apphover w-300">
                            {infoLoading ? (
                              <Loader />
                            ) : (
                              <>
                                <div className="userHeader">
                                  {photoInfo?.find(
                                    (b) => u.employeeId === b.employeeId
                                  )?.profilePhoto ? (
                                    <img
                                      className="user-pic usershowimg"
                                      src={renderImageDataUrl(photoInfo?.find(
                                          (b) => u.employeeId === b.employeeId
                                      )?.profilePhoto)}
                                      alt="icon"
                                    />
                                  ) : (
                                    <div className="up-img usershowimg">
                                      <span>
                                        {usersInfo
                                          ?.find(
                                            (b) => u.employeeId === b.employeeId
                                          )
                                          ?.userName.match(/(^\S\S?|\b\S)?/g)
                                          .join("")
                                          .match(/(^\S|\S$)?/g)
                                          .join("")
                                          .toUpperCase()}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="userbodybox">
                                  <p className="user-n font-9">
                                    {
                                      usersInfo?.find(
                                        (b) => u.employeeId === b.employeeId
                                      )?.userName
                                    }
                                  </p>
                                  <p className="user-d">
                                    <b>Role: </b>
                                    {
                                      usersInfo?.find(
                                        (b) => u.employeeId === b.employeeId
                                      )?.userRole
                                    }
                                  </p>
                                  <p className="user-d">
                                    <b>Groups: </b>
                                    {u?.groups?.length > 0
                                      ? usersInfo
                                          ?.find(
                                            (b) => u.employeeId === b.employeeId
                                          )
                                          ?.userGroups?.map((a) => a.value)
                                          .join(", ")
                                      : "N/A"}
                                  </p>
                                  <p className="user-d">
                                    <b>Business Title: </b>
                                    {usersInfo?.find(
                                      (b) => u.employeeId === b.employeeId
                                    )?.jobTitle !== null
                                      ? usersInfo?.find(
                                          (b) => u.employeeId === b.employeeId
                                        )?.jobTitle
                                      : "N/A"}
                                  </p>

                                  <p className="user-d">
                                    <b>Organization: </b>
                                    {usersInfo?.find(
                                      (b) => u.employeeId === b.employeeId
                                    )?.companyName !== null
                                      ? usersInfo?.find(
                                          (b) => u.employeeId === b.employeeId
                                        )?.companyName
                                      : "N/A"}
                                  </p>

                                  <p className="user-d">
                                    <b>Reporting Manager: </b>
                                    {usersInfo?.find(
                                      (b) => u.employeeId === b.employeeId
                                    )?.manager !== null
                                      ? usersInfo?.find(
                                          (b) => u.employeeId === b.employeeId
                                        )?.manager
                                      : "N/A"}
                                  </p>
                                </div>
                                <div className="bdr-top">
                                  <span className="useremail-bar pb-0">
                                    <img
                                      src={locationIcon}
                                      alt="user-pic"
                                      className="locationIcon"
                                    />
                                    <span className="ms-2">
                                      {usersInfo?.find(
                                        (b) => u.employeeId === b.employeeId
                                      )?.location !== null
                                        ? usersInfo?.find(
                                            (b) => u.employeeId === b.employeeId
                                          )?.location
                                        : "N/A"}
                                    </span>
                                  </span>

                                  <span className="useremail-bar mb-2">
                                    <img
                                      src={emailUs}
                                      alt="user-pic"
                                      className="emailicon"
                                    />
                                    <span className="ms-2">
                                      {" "}
                                      {
                                        usersInfo?.find(
                                          (b) => u.employeeId === b.employeeId
                                        )?.email
                                      }
                                    </span>
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                        </td>
                        <td>
                          {u.deptName && u.deptName !== null
                            ? u.deptName
                            : "None"}
                        </td>

                        <td>
                          {userApps
                            ?.filter((a) => a.isActive === true)
                            .filter((i) => i.ischecked === true)
                            ?.filter(
                              (a) =>
                                !u.applications
                                  ?.map((g) => g.id)
                                  .includes(a.appId)
                            )
                            .map((item) => item.appId)?.length > 0 && (
                            <strong>
                              {userApps
                                ?.filter((a) => a.isActive === true)
                                .filter((i) => i.ischecked === true)
                                ?.filter(
                                  (a) =>
                                    !u.applications
                                      ?.map((g) => g.id)
                                      .includes(a.appId)
                                )
                                .map((item) => item.appName)
                                .join(", ")}
                             { u.applications?.length > 0 ? ", " : ""} 
                            </strong>
                          )}
                          {userApps
                            ?.filter((a) => a.isActive === true)
                            .filter((i) => i.ischecked === true)
                            ?.filter(
                              (a) =>
                                !u.applications
                                  ?.map((g) => g.id)
                                  .includes(a.appId)
                            )
                            .map((item) => item.appId)?.length === 0
                            ? u.applications?.length > 0
                              ? u.applications
                                  ?.map((g) => g.applicationName)
                                  .join(", ")
                              : "None"
                            : u.applications
                                ?.map((g) => g.applicationName)
                                .join(", ")}
                        </td>
                        <td>
                          {" "}
                          {moment.utc(u.createdAt).local().format("MM/DD/YYYY")}
                        </td>
                        <td>
                          {!test?.map((i) => i.conditionOn).includes(5) ? (
                            <>
                              {userGroups
                                ?.filter((a) => a.status === true)
                                .filter((i) => i.ischecked === true)
                                ?.filter(
                                  (a) =>
                                    !u.groups?.map((g) => g.id).includes(a.id)
                                )
                                .map((item) => item.value)?.length > 0 && (
                                <strong>
                                  {userGroups
                                    ?.filter((a) => a.status === true)
                                    .filter((i) => i.ischecked === true)
                                    ?.filter(
                                      (a) =>
                                        !u.groups
                                          ?.map((g) => g.id)
                                          .includes(a.id)
                                    )
                                    .map((item) => item.value)
                                    .join(", ")}
                                  {", "}
                                </strong>
                              )}
                              {u.groups?.length > 0
                                ? u.groups?.map((g) => g.value).join(", ")
                                : "None"}
                            </>
                          ) : u.groups?.length > 0 ? (
                            u.groups?.map((g) => g.value).join(", ")
                          ) : (
                            "None"
                          )}
                        </td>
                        <td>
                          {u.jobTitle && u.jobTitle !== null
                            ? u.jobTitle
                            : "N/A"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="nohover">
                      <td colSpan="7">
                        <div className="noData">
                          <img src={noteRemove} alt="icon" />
                          <p>
                            {userPreviewResponse
                              ? searchInput
                                ? "No user found"
                                : "No results have been found, please try with a different condition."
                              : "No records found  "}
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {userPreview && userPreview?.length > 0 && (
              <div className="pagination-section">
                <RulePagination
                  totalRecords={totalRecords}
                  options={paginationOptions}
                  pageNo={pageNo}
                  onChange={(e) => setPaginationOptions(e)}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RulePreview;
