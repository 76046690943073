import React, { useMemo, useEffect } from "react";

const Pagination = ({ totalRecords, onChange, options, pageNo }) => {
  const noOfPages = Math.ceil(totalRecords / options.displayLimit);

  const paginationChange = (newOptions) => {
    pageNo.current = newOptions.pageNo;
    onChange({
      ...options,
      ...newOptions,
    });
  };

  useEffect(() => {
    if (totalRecords > 0 && pageNo.current > noOfPages) {
      paginationChange({ pageNo: noOfPages });
    }
  }, [noOfPages, totalRecords]);

  const renderPaginationPages = () => {
    const nodes = [];
    let start;
    let end;
    const pageCutOff = 50;
    const ceiling = Math.ceil(pageCutOff / 2);
    const floor = Math.floor(pageCutOff / 2);
    if (noOfPages < pageCutOff) {
      start = 1;
      end = noOfPages;
    } else if (options.pageNo >= 1 && options.pageNo <= ceiling) {
      start = 1;
      end = pageCutOff;
    } else if (options.pageNo + floor >= noOfPages) {
      start = noOfPages - pageCutOff;
      end = noOfPages;
    } else {
      start = options.pageNo - ceiling;
      end = options.pageNo + floor;
    }
    for (let i = start; i <= end; i += 1) {
      if (options.pageNo === i) {
        nodes.push(
          <li
            key={i}
            className={`page-item ${
              options.pageNo === pageNo.current ? "active" : ""
            }`}
          >
            <span className="page-link nex-pev ms-2">{i}</span>
          </li>
        );
      } else {
        nodes.push(
          <li
            className="page-item"
            onClick={() =>
              options.pageNo === i ? null : paginationChange({ pageNo: i })
            }
            key={i}
          >
            <span className="page-link nex-pev ms-2">{i}</span>
          </li>
        );
      }
    }
    return nodes;
  };

  return (
    <nav aria-label="Page navigation example">
      <p>
        {`Showing ${totalRecords > 0 ? options.pageNo * 10 - 9 : 0} - ${
          options.pageNo * options.displayLimit > totalRecords
            ? totalRecords
            : options.pageNo * options.displayLimit
        } of ${totalRecords}`}{" "}
        Entries
      </p>
      <ul className="pagination">
        <li
          className="page-item"
          onClick={() => {
            if (options.pageNo && options.pageNo !== 1) {
              paginationChange({ pageNo: options.pageNo - 1 });
            }
          }}
        >
          <a className="page-link nex-pev me-2" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        {renderPaginationPages()}
        <li
          className="page-item"
          onClick={() => {
            if (
              options.pageNo &&
              noOfPages &&
              options.pageNo !== noOfPages &&
              options.pageNo <= noOfPages
            ) {
              paginationChange({ pageNo: options.pageNo + 1 });
            }
          }}
        >
          <a className="page-link nex-pev ms-2" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
