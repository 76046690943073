import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { connect, useSelector } from "react-redux";
import { fetchDashboardApps } from "../../actions/actions-users";
import "../../assets/css/new-dashboard.css";
import dragIcon from "../../assets/images/new-images/move.svg";
import arowIcon from "../../assets/images/new-images/next-arow.svg";
import lock from "../../assets/images/new-images/lock.svg";
import n1 from "../../assets/images/new-images/placeholder.png";
import SearchIcon from "../../assets/images/search.svg";
import axios from "axios";
import SortableItem from "./SortableItem";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  PointerSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import Item from "./Item";
import { openInNewTab } from "./utils";

import useMediaQuery from "../Calendar/useMediaQuery";
import {renderImageDataUrl} from "../../middlewares/utils";
const { REACT_APP_BACKEND_API_APP } = process.env;

function QuickLinks({
  fetchDashboardApps,
  dashAppsLoading,
  dashApps = [],
  ...props
}) {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const loginData = useSelector((state) => state.usersList.dashData);
  const [appData, setAppData] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));


  useEffect(() => {
    loginData && Object.keys(loginData).length > 0 && fetchDashboardApps();
  }, [loginData]);

  const sharepointNotifications = useSelector(state =>  state.sharepointReducer.notifications)

  const loadNotificationsCount = (app, sharepointNotifications) => {

    const folders = sharepointNotifications?.filter(
        notification => notification.folder && app.appId === notification.appId && notification.children.length > 0);

    let notifications = [];

    if(folders.length === 0) return notifications;

    const expirationDateLimit = new Date();
    expirationDateLimit.setDate(expirationDateLimit.getDate() - 90);

    folders.forEach(f => {

      f.children.forEach(c => {
        const folder = {...c, ...app}

        const appDate = new Date(app.createdAt);
        const childDate = new Date(c.createdDateTime);

        if(appDate.getTime() < childDate.getTime() && childDate.getTime() > expirationDateLimit) {
          notifications.push(folder)
        }
      })

    });

    let lsNotifications = localStorage.getItem('notifications');

    if(lsNotifications) {
      let lsParsed = JSON.parse(lsNotifications);
      notifications = notifications.filter(n => !lsParsed.includes(n.id));
    }

    return notifications;
  };

  useEffect(() => {
    dashApps &&
      setAppData(
        dashApps
          ?.map((item) => {

            //const notifications = sharepointNotifications.filter(n => n.appId === item.appId);
            let notifications = loadNotificationsCount(item, sharepointNotifications);
            return {
              ...item,
              notifications: notifications
            };
          })
          .sort((a, b) => a.priorityIndex - b.priorityIndex)
      );
  }, [sharepointNotifications, dashApps]);

  const [toggle, setToggle] =  useState(
    JSON.parse(localStorage.getItem('isMenuExpanded')) || false
  );

  useEffect(() => {
    // Update the localStorage when the state changes
    localStorage.setItem('isMenuExpanded', JSON.stringify(toggle));
  }, [toggle]);

  const toggleHandler = () => {
    setToggle(!toggle);
  };

  const [searchInput, setSearchInput] = useState("");

  const searchHandler = (e) => {
    setSearchInput(e.target.value);
  };

  let filter = appData.filter((val) => {
    if (searchInput === "") {
      return val;
    } else if (
      val.appName.toLowerCase().includes(searchInput.toLowerCase()) ||
      val?.displayName.toLowerCase().includes(searchInput.toLowerCase()) ||
      val?.tagList.includes(searchInput.toLowerCase())
    ) {
      return val;
    }
  });

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    const oldId = over ? over.id : "";
    if (active.id !== over.id) {
      setAppData((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const nextState = arrayMove(items, oldIndex, newIndex);
        let token = sessionStorage.getItem("accessToken2");
        const config = {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        };

        let data = {
          appIndex: nextState
            ?.map((a, index) => {
              return {
                applicationId: a.appId,
                index: index,
                appName: a.appName,
              };
            })
            .sort((a, b) => a.index - b.index),

          userId: parseInt(sessionStorage.getItem("userId")),
        };

        axios
          .post(
            `${REACT_APP_BACKEND_API_APP}/api/UpdateApplicationIndexByAppId`,
            data,
            config
          )
          .then(async (res) => {
            if (res.data.statusCode === 200) {
              fetchDashboardApps();
            }
          });
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  };

  const handleDragCancel = (event) => {
    setActiveId(null);
  };

  let fullView = (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={filter} strategy={rectSortingStrategy}>
        <ul
          className={`app-drow deviceWidht forWeb ${
            toggle ? "" : "toggleView snaps-inline"
          }`}
        >
          {" "}
          {filter?.length === 0 && searchInput === ""
            ? "No apps Assigned"
            : filter.length === 0
            ? "No Matching Apps Found"
            : filter?.map((value, index) => (
                <SortableItem
                  key={index}
                  id={value}
                  item={value}
                  index={index}
                  searchInput={searchInput}
                />
              ))}
        </ul>
      </SortableContext>

      <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
        {activeId ? <Item item={activeId} isDragging /> : null}
      </DragOverlay>
    </DndContext>
  );

  let fullViewMobile = (
    <ul
      className={`app-drow deviceWidht forIpadMobile ${
        toggle ? "" : "toggleView snaps-inline"
      }`}
    >
      {filter.length === 0 && searchInput === ""
        ? "No apps Assigned"
        : filter.length === 0
        ? "No Matching Apps Found"
        : filter.map((item, index) => (
            <li
              className="app-drow-list"
              style={{ background: item.appColour }}
              key={index}
              onClick={() =>
                openInNewTab(
                  decodeURIComponent(item.appUrl),
                  decodeURIComponent(item.mobileAppUrl)
                )
              }
            >
              <span className="uploadAppLogo">
                <img
                  className="app-logo"
                  src={
                    item.imagebase64String !== "" &&
                    item.imagebase64String !== null
                      ? renderImageDataUrl(item.imagebase64String)
                      : n1
                  }
                  alt={item.alt}
                />
              </span>
              <p className="app-name">{item.displayName}</p>
              <span
                style={{ fontSize: "10px", display: "block" }}
                className="information"
              >
                {item.description}
              </span>
              <img className="arowIcon" src={arowIcon} alt="arowIcon" />
            </li>
          ))}
    </ul>
  );

  return (
    <div className="app-bar-box">
      <div className="heading-bar">
        <span className="h-bar"></span>
        <p>My Apps</p>
        <div className="relative app-search">
          <img src={SearchIcon} alt="icon" />
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            onChange={searchHandler}
          />
        </div>
      </div>

      {isDesktop ? fullView : fullViewMobile}

      <div className="text-right">
        <p className="v-all forWeb" onClick={toggleHandler}>
          {props.onAdd === false
            ? filter?.length > 7
              ? !toggle
                ? "Expand"
                : "Collapse"
              : ""
            : filter.length > 5
            ? !toggle
              ? "Expand"
              : "Collapse"
            : ""}
        </p>
        <p className="v-all forIpadMobile" onClick={toggleHandler}>
          {props.onAdd === false
            ? filter?.length > 2
              ? !toggle
                ? "Expand"
                : "Collapse"
              : ""
            : filter.length > 5
            ? !toggle
              ? "Expand"
              : "Collapse"
            : ""}
        </p>
      </div>
    </div>
  );
}

const mapStateToProps = ({ master, usersList, alerts }) => ({
  dashApps: usersList.dashApps,
  dashAppsLoading: usersList.dashAppsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDashboardApps: () => dispatch(fetchDashboardApps()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickLinks);
