import { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import "./App.css";
import "./assets/css/admin-console.css";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import MyApp from "./pages/MyApp";
import ErrorPage from "./pages/ErrorPage";
import Event from "./pages/Event";
import Support from "./pages/Support";
import Alerts from "./pages/Alerts";
import { useSelector } from "react-redux";
import CalendarView from "./pages/CalendarView";
import Assignment from "./pages/Assignment";
import Groups from "./pages/Groups";
import Login from "./pages/Login";
import AdminCenter from "./pages/AdminCenter";
import axios from "axios";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme/index";
import {fetchToken} from "./utils/auth";

function App({ pca }) {
  return (
    <ThemeProvider theme={ theme }>
      <CssBaseline />
      <ClientSideNavigation pca={pca}>
        <MsalProvider instance={pca}>
          <Pages />
        </MsalProvider>
        </ClientSideNavigation>
    </ThemeProvider>
  );
}

function ClientSideNavigation({ pca, children }) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
}

function Pages() {
  const loginData = useSelector((state) => state.usersList.dashData);
  const { instance, inProgress } = useMsal();
  sessionStorage.setItem("userRoleId", loginData.userRoleId);
  sessionStorage.setItem("userId", loginData.userId);
  const [tokenAcquired, setTokenAcquired] = useState(false);

  function loadBearerToken() {
    fetchToken(instance).then((response) =>{
      sessionStorage.setItem("accessToken2", response.accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${response.accessToken}`;
      setRefreshTimeout(response.expiresOn);
      setTokenAcquired(true);
    })
  }

  const [timer, setTimer] = useState(null);
  useEffect(() => {
    loadBearerToken();
  }, [inProgress, instance]);

  const setRefreshTimeout = (expiresAt) => {
    clearTimeout(timer);

    const oneMin = 60 * 1000;
    var refreshDeadline = Math.max(
      5 * oneMin,
      expiresAt - Date.now() - 5 * oneMin
    );

    const newTimer = setTimeout(() => {
      loadBearerToken();
    }, refreshDeadline);

    setTimer(newTimer);
  };

  let empId = sessionStorage.getItem("userEmpId");

  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" replace />} />
      <Route
        path="/"
        element={<Dashboard empId={empId} tokenAcquired={tokenAcquired} />}
      />
      <Route path="/error-page" element={<ErrorPage />} />
      {tokenAcquired && (
        <>
          {loginData.userRoleName === "Admin" && (
            <Route path="/my-apps" element={<MyApp />} />
          )}
          {loginData.userRoleName === "Admin" && (
            <Route path="/login-as" element={<Login />} />
          )}
          {loginData.userRoleName === "Admin" && (
            <Route path="/users" element={<Users />} />
          )}
          {loginData.userRoleName === "Admin" && (
            <Route path="/notifications" element={<Alerts />} />
          )}
          {loginData.userRoleName === "Team Lead" && (
            <Route path="/notifications" element={<Alerts />} />
          )}
          {loginData.userRoleName === "Admin" && (
            <Route path="/events" element={<Event />} />
          )}
          {loginData.userRoleName === "Team Lead" && (
            <Route path="/events" element={<Event />} />
          )}
          {loginData.userRoleName !== undefined && (
            <Route path="/calendar" element={<CalendarView empId={empId} />} />
          )}
          {loginData.userRoleName === "Admin" && (
            <Route path="/groups" element={<Groups />} />
          )}
          {loginData.userRoleName !== undefined && (
            <Route path="/support" element={<Support />} />
          )}
          {loginData.userRoleName === "Admin" && (
            <Route path="/Assignment" element={<Assignment />} />
          )}
          {loginData.userRoleName === "Admin" && (
            <Route path="/AdminCenter" element={<AdminCenter />} />
          )}
        </>
      )}
    </Routes>
  );
}

export default App;
