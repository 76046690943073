import React, { useState } from "react";
import Modal from "../UI/Modal";
import sucess from "../../assets/images/new-images/sucess.png";
import dltIcon from "../../assets/images/new-images/dlt.png";
import { connect } from "react-redux";
import * as actions from "../../actions/action-event";

const DeleteEvents = ({
  fetchEvent,
  deleteEvent,
  eventsDelete,
  deleteModal,
  setCalendarDeleteId,
  ...props
}) => {
  const { allEvents, setAllEvents, deleteId } = props;

  const eventDeleteHandler = () => {
    deleteModal(!eventsDelete);
    setCalendarDeleteId(true);
  };

  return (
    <Modal
      modalId={"deleteEvent"}
      modalLabel={"eventModal2"}
      className={"dlt-modal"}
    >
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content">
          <div className="modal-body text-center pt-0">
            <img
              src={eventsDelete ? dltIcon : sucess}
              alt="icon"
              className="m-3"
            />

            {eventsDelete && <p className="dlt-hed">Are you sure ?</p>}

            <p className="dlt-body">
              {eventsDelete
                ? "Do you want to delete this record? Once deleted, this action cannot be undone"
                : "Event Deleted Successfully"}
            </p>
          </div>
          <div className="modal-footer">
            {eventsDelete && (
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            )}

            {eventsDelete ? (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                onClick={() => deleteEvent(deleteId)}
              >
                Delete
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-dismiss="modal"
                onClick={eventDeleteHandler}
              >
                Ok
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ event }) => ({
  eventsDelete: event.openDeleteModal,
});

const mapDispatchToProps = (dispatch) => ({
  deleteEvent: (id) => dispatch(actions.deleteEvent(id)),
  deleteModal: (val) => dispatch(actions.deleteModal(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteEvents);