import React from "react";
import { ErrorIcon } from "../App/icons";

export const TextField = (props) => {
  const {
    label,
    required,
    value,
    type = 'text',
    onChange,
    placeholder,
    className,
    onBlur,
    errorMessage,
    error,
    maxLength,
    name,
    disabled
  } = props;
  return (
    <div className="custom-text-field">
      { label && <label>{label}{required && <sup>*</sup>}</label>}
      <div className={`wrap ${error ? 'error' : ''}`}>
        <input
          type={ type }
          placeholder={ placeholder }
          className={ className }
          onChange={ onChange }
          value={ value }
          onBlur={ onBlur }
          name={ name }
          maxLength={ maxLength }
          disabled={ disabled }
        />
        {error && <ErrorIcon />}
      </div>

      { error && (
        <div className="input-error-message">{ errorMessage }</div>
      )}
    </div>
  )
}