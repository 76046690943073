import React from "react";
import arrow from "../../assets/images/new-images/right-arrow.svg";

const Groups_navigation = () => {
  return (
    <nav className="nav-setting">
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        
       
        <button
          className="nav-link active"
          data-bs-toggle="tab"
          data-bs-target="#nav-profile"
          type="button"
          role="tab"
          aria-controls="nav-profile"
          aria-selected="false"
        >
          <span> User Group</span>
          <img src={arrow} alt="logo" />
        </button>
        <div className="divider"></div>
      </div>
    </nav>
  );
};

export default Groups_navigation;
