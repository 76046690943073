import React, { useState, useRef } from "react";
import Modal from "../UI/Modal";
import cross from "../../assets/images/close.svg";
import sucess from "../../assets/images/new-images/sucess.png";
import isURL from "validator/lib/isURL";
import axios from "axios";
import remove from "../../assets/images/new-images/remove.png";
import error from "../../assets/images/new-images/error.png";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllUserApps } from "../../actions/actions-users";
import {IMAGE_FILE_TYPES, MAX_LOGO_SIZE_BYTES} from "../../constants";
import { customStyles } from "../Users/styles";
import { Grid } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { ErrorOutlineIcon } from "../Icons";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {normaliseString} from "../../middlewares/utils";
const { REACT_APP_BACKEND_API_APP } = process.env;

const Onboard = (props) => {
  const dispatch = useDispatch();
  const initialState = {
    appName: "",
    displayName: "",
    subtitle: "",
    url: "",
    mobileAppUrl: "",
    logo: "",
    tags: [],
  };
  const [appData, setAppData] = useState(initialState);
  const [selectedAppId, setSelectedAppId] = useState(null);

  const { appName, displayName, subtitle, url, logo, tags, mobileAppUrl } =
    appData;

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setAppData({ ...appData, [name]: value });
    setErrorMsg(null)
    setSubmitted(false)
  };

  const [base64Img, setBase64Img] = useState([]);
  const [imgDelete, setImgDelete] = useState(false);

  const handleImageUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setAppData({
        ...appData,
        logo: e.target.files[0],
      });
      setImgDelete(true);
    }
  };
  function handleKeyDown(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setAppData({ ...appData, tags: [...tags, value] });

    e.target.value = "";
  }
  function removeTag(index) {
    setAppData({ ...appData, tags: tags.filter((el, i) => i !== index) });
  }
  const removeimageHandler = () => {
    setAppData({ ...appData, logo: "" });

    setImgDelete(false);
    setBase64Img([]);
  };
  const defaultState = {
    appName: false,
    displayName: false,
    subtitle: false,
    url: false,
    mobileAppUrl: false,
    logo: false,
  };
  const [touched, setTouched] = useState(defaultState);

  const handleFocus = (e) => {
    let { name } = e.target;
    setTouched({ ...touched, [name]: true });
  };

  const [display, setDisplay] = useState(false);

  const enteredNameIsValid = appName.trim() !== "";
  const enteredDisplayIsValid = display
    ? appName.trim() !== ""
    : displayName.trim() !== "";

  const enteredUrlIsValid = url.trim() !== "" && isURL(url);
  // const enteredLogoIsValid = logo !== "";
  const logoSizeIsValid = logo.size < MAX_LOGO_SIZE_BYTES;
  const logoFormatIsValid = logo ? (logo.type === IMAGE_FILE_TYPES.JPEG || logo.type === IMAGE_FILE_TYPES.JPG || logo.type === IMAGE_FILE_TYPES.PNG || logo.type === IMAGE_FILE_TYPES.SVG) : true

  const logoValidation = {
    logoIsValid: logoSizeIsValid && logoFormatIsValid,
    errorMsg:[!logoSizeIsValid && `Please upload a image with size less than ${MAX_LOGO_SIZE_BYTES/1000}kb`,
    !logoFormatIsValid && 'Please upload a image with a valid format: .jpg, .png and .svg']
  }

  const nameInputIsInvalid =
    (!enteredNameIsValid && touched.appName) ||
    (touched.displayName && !enteredNameIsValid) ||
    (touched.subtitle && !enteredNameIsValid) ||
    (touched.url && !enteredNameIsValid) ||
    (touched.logo && !enteredNameIsValid) ||
    (touched.tags && !enteredNameIsValid);

  const displayInputIsInvalid =
    (!enteredDisplayIsValid && touched.displayName) ||
    (touched.subtitle && !enteredDisplayIsValid) ||
    (touched.url && !enteredDisplayIsValid) ||
    (touched.logo && !enteredDisplayIsValid) ||
    (touched.tags && !enteredDisplayIsValid);

  const urlInputIsInvalid =
    (!enteredUrlIsValid && touched.url) ||
    (touched.logo && !enteredUrlIsValid) ||
    (touched.tags && !enteredUrlIsValid);

  // const logoInputIsInvalid =
  //   (touched.logo && !enteredLogoIsValid) ||
  //   (touched.tags && !enteredLogoIsValid);

  const [errorMsg, setErrorMsg] = useState(null);
  const [displayErrorMsg, setDisplayErrorMsg] = useState('');
  const buttonRef = useRef(null);

  const formIsValid =
    enteredDisplayIsValid &&
    enteredNameIsValid &&
    enteredUrlIsValid &&
    logo &&
    logoSizeIsValid &&
    logoFormatIsValid;

  const submitHandler = (e) => {
    e.preventDefault();
    buttonRef.current.disabled = formIsValid ? true : false;
    setTouched({
      appName: true,
      displayName: true,
      subtitle: true,
      url: true,
      mobileAppUrl: true,
      logo: true,
      tags: true,
    });

    if (
      !enteredDisplayIsValid ||
      !enteredNameIsValid ||
      !enteredUrlIsValid ||
      (logo && (!logoSizeIsValid || !logoFormatIsValid))
    ) {
      return;
    }

    const formData = new FormData();

    formData.append("appName", appName.trim());
    formData.append(
      "displayName",
      display === true ? appName.trim() : displayName.trim()
    );
    formData.append("description", subtitle.trim());
    formData.append("imagebase64String", "");
    formData.append("imageName", appName);
    formData.append("appUrl", encodeURIComponent(url.trim()));
    formData.append(
      "mobileAppUrl",
      mobileAppUrl
        ? encodeURIComponent(mobileAppUrl.trim())
        : encodeURIComponent(url.trim())
    );
    formData.append("priorityIndex", 0);
    formData.append("createdBy", 0);
    formData.append("isActive", true);
    formData.append("isApproved", true);
    formData.append("userId", []);
    formData.append("tagList", tags ? tags : [""]);
    formData.append("image", logo);

    let token = sessionStorage.getItem("accessToken2");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    };

    axios
      .post(
        `${REACT_APP_BACKEND_API_APP}/api/CreateNewApplication`,
        formData,
        config
      )
      .then(async (res) => {
        setErrorMsg(res.data.statusCode);
        setSubmitted(true);
        if (res.data.statusCode === 200) {
          props.setOnboardSuccess(true);
          setSelectedAppId(res.data.responseData.appId);
        } else {
          setDisplayErrorMsg(res.data.displayMessage)
        }
        dispatch(fetchAllUserApps());
      });
  };

  let commonInputs = {
    onBlur: handleFocus,
  };
  const appsData = useSelector((state) => state.appsData.apps);
  const [nameAlreadyExists, setNameAlreadyExists] = useState(false);

  const modalClosed = () => {
    setAppData(initialState);
    setTouched(defaultState);
    setDisplay(false);
    setImgDelete(false);
    setBase64Img([]);
    setErrorMsg(null);
    setSubmitted(false);
    setSelectedAppId(null);
    setNameAlreadyExists(false);
    props?.setOpenAppModal(false);
    props?.setShowAssign(false);
  };

  React.useEffect(() => {

    const normalisedAppName = normaliseString(appName);
    //only set the error message when we have some value in the appname and there is an exact match
    setNameAlreadyExists(normalisedAppName !== ''
        && appsData.appsList?.some(app => normaliseString(app.appName) === normalisedAppName));
  }, [appName, appsData]);

  const openEditAppModal = () => {
    modalClosed();
    // open the edit modal with a delay
    delayedFunction()
  }

  const [timeoutId, setTimeoutId] = useState(null);

  const delayedFunction = () => {
    // Clear any existing timeout
    clearTimeout(timeoutId);

    const newTimeoutId = setTimeout(() => {
      props?.editClick(appsData.appsList[0])
    }, 100);

    // Update the state with the new timeoutId
    setTimeoutId(newTimeoutId);
  };

  // Cleanup function to clear the timeout when the component unmounts
  const cleanupTimeout = () => {
    clearTimeout(timeoutId);
  };

  React.useEffect(() => {
    return cleanupTimeout;
  }, [timeoutId]);


  const openAssignUserModal = () => {
    props?.setSelectedApps([selectedAppId])
    modalClosed();
    props?.setShowAssign(true);
  }

  return (
    <Modal
      modalId={"Onboard"}
      modalLabel={ "exampleModalLabel" }
      sx={customStyles}
      showModal={props?.openAppModal}
    >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Add App
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={modalClosed}
              ></button>
            </div>

            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label className="d-flex justify-content-between">
                    <span>
                      App Name<sup>*</sup>
                    </span>
                  </label>
                  <div className="input-with-icon">
                    <input
                      type="text"
                      className={nameInputIsInvalid ? 'form-control error' : 'form-control'}
                      placeholder="App Name"
                      onChange={handleChange}
                      name="appName"
                      value={appName}
                      onKeyUp={(e) => e.target.value.trim().length > 1 && props?.searchApp(e.target.value.toLowerCase().trim())}
                      {...commonInputs}
                    />
                    { nameInputIsInvalid && (
                      <ErrorOutlineIcon />
                    )}
                  </div>
                  {nameInputIsInvalid && (
                    <p className="error-text">Please enter an App Name</p>
                  )}
                  {
                    nameAlreadyExists > 0 && (
                      <p className="error-text">
                        An app with the same name already exists.
                        <Button
                          data-bs-dismiss="modal"
                          onClick={openEditAppModal}
                          disableRipple
                          sx={ {
                            color: '#D92D20',
                            fontWeight: 600,
                            marginLeft: '0.375rem',
                            padding: 0,
                            gap: '0.5rem',
                            height: 'auto',
                            textTransform: 'none',
                            fontSize: '0.875rem',
                            lineHeight: 1,
                            fontFamily: '"Manrope", Roboto, "Helvetica Neue", Arial, sans-serif !important',

                            '&:hover': {
                              background: 'transparent'
                            }
                        } }>Edit existing app <ArrowForwardOutlinedIcon sx={{color: '#D92D20',fontSize: '0.875rem'}} /></Button>
                      </p>
                    )
                  }
                </div>
                <div className="form-group">
                  <div className="d-flex justify-content-between">
                    <label>Display Name<sup>*</sup></label>
                    <label className="form-check-label">
                      <input
                        className="form-check-input me-2"
                        name="same-as-app-name"
                        type="checkbox"
                        checked={!display ? false : true}
                        onChange={() => {
                          setDisplay(!display);
                        }}
                      />

                      Same as App Name
                    </label>
                  </div>
                  <div className="input-with-icon">
                    <input
                      type="text"
                      className={displayInputIsInvalid ? 'form-control error' : 'form-control'}
                      placeholder="Display Name"
                      onChange={handleChange}
                      value={display ? appName : displayName}
                      name="displayName"
                      {...commonInputs}
                    />
                    { displayInputIsInvalid && (
                       <ErrorOutlineIcon />
                    )}
                  </div>
                  {displayInputIsInvalid && !display && (
                    <p className="error-text">Please enter a Display Name</p>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Information Text
                    {/* <sup>*</sup> */}
                  </label>
                  <textarea
                    type="text"
                    rows={4}
                    className="form-control"
                    placeholder="Information Text"
                    onChange={handleChange}
                    value={subtitle}
                    name="subtitle"
                    {...commonInputs}
                    maxLength={20}
                  ></textarea>
                </div>

                <Grid className="form-group" container columnSpacing={3}>
                  <Grid item md={ 6 }>
                    <div className="form-group">
                      <label>
                        Application URL<sup>*</sup>
                      </label>
                      <div className="input-with-icon">
                        <LinkIcon />
                        <input
                          type="text"
                          className={urlInputIsInvalid ? 'form-control error' : 'form-control'}
                          placeholder="Add Application URL"
                          onChange={handleChange}
                          value={url}
                          name="url"
                          {...commonInputs}
                        />
                      </div>
                      {urlInputIsInvalid && (
                        <p className="error-text">Please enter a valid URL</p>
                      )}
                    </div>
                  </Grid>
                  <Grid item md={ 6 }>
                    <div className="form-group">
                      <label>Mobile App URL</label>
                      <div className="input-with-icon">
                        <LinkIcon />
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Add Mobile App URL"
                          onChange={handleChange}
                          value={mobileAppUrl}
                          name="mobileAppUrl"
                          {...commonInputs}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>



                <div className="form-group ful-w">
                  <label>Add tags</label>
                  <div className="tag-input-container">
                    {tags.map((tag, index) => (
                      <div className="tag-item" key={index}>
                        <span className="text-show">{tag}</span>
                        <span
                          onClick={() => removeTag(index)}
                          className="close-btn"
                        >
                          <img src={cross} alt="close"></img>
                        </span>
                      </div>
                    ))}
                    <input
                      type="text"
                      className="tags-input"
                      placeholder="Type a Tag Name and Hit Enter..."
                      onKeyDown={handleKeyDown}
                      name="tags"
                      {...commonInputs}
                    />
                  </div>
                </div>

                <div className="form-group ">
                  <label>Upload logo</label>

                  <div className="file-upload">
                    <span className="file-name">
                      {logo ? (
                        <img
                          src={logo ? URL.createObjectURL(logo) : ""}
                          alt="icon"
                          className="up-icon-alt"
                        />
                      ) : (
                        "Formats: .jpg , .png, .svg"
                      )}
                      {logo && (
                        <img
                          src={remove}
                          alt="close"
                          className="remove-icon"
                          onClick={() => removeimageHandler()}
                        />
                      )}
                    </span>

                    <span className="upload">
                      <ContentCopyOutlinedIcon />
                      Browse from computer
                      <input
                        type="file"
                        className="file"
                        onChange={handleImageUpload}
                        name="logo"
                        accept={`${IMAGE_FILE_TYPES.JPEG}, ${IMAGE_FILE_TYPES.JPG}, ${IMAGE_FILE_TYPES.PNG},${IMAGE_FILE_TYPES.SVG}`}
                        {...commonInputs}
                      />
                    </span>

                  </div>

                  {logo && !logoValidation.logoIsValid ? (
                    logoValidation.errorMsg.map(e=>
                      <p className="error-text"> {e} </p>)
                  ) : (
                    <p className="error-text e-black">Image needs to be less than {MAX_LOGO_SIZE_BYTES/1000}kb</p>
                  )}
                  {/* {logoInputIsInvalid && (
                    <p className="error-text">Please upload a logo</p>
                  )}  */}

                  {/* {logo && !logo.name.match(/\.(jpg|jpeg|png|gif)$/) && (
                    <p className="error-text">
                      Please upload a image in .jpg or.png format
                    </p>
                  )} */}
                </div>
              </form>
              {submitted && (errorMsg === 200 ? (<Alert
                sx={ {
                borderRadius: '8px',
                mb: 2,
                mt: 3,
                padding: '1rem',
                gap: '12px',
                border: '1px solid #6CE9A6',
                background: '#F6FEF9',

                '& .MuiAlert-icon': {
                  padding: 0,
                  margin: 0,
                  color: '#027A48',

                  '& svg': {
                    fontSize: '20px'
                  }
                },

                '& .MuiAlert-message': {
                  padding: 0,

                  '& .MuiTypography-root': {
                    marginTop: '0.25rem',
                    fontFamily: 'Manrope',
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#027A48',
                    lineHeight: '142.857%',
                  },

                  '& .MuiAlertTitle-root': {
                    fontFamily: "Manrope SemiBold",
                    margin: 0,
                    color: '#027A48',
                    fontWeight: 600,
                  }
                }
              }} icon={<CheckCircleOutlineOutlinedIcon />} variant="outlined" severity="success">
                <AlertTitle>{appName} successfully added.</AlertTitle>
                <Button
                  disableRipple
                  data-bs-dismiss="modal"
                  onClick={openAssignUserModal}
                  sx={{
                    textTransform: 'none',
                    gap: '0.5rem',
                    padding: 0,
                    marginTop: '12px',
                    fontSize: '14px !important',
                    fontFamily: "Manrope SemiBold",
                    fontWeight: 600,
                    color: '#039855',
                    lineHeight: '142.857%',
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  }}>
                    Assign Users to this App
                    <ArrowForwardOutlinedIcon sx={{fontSize: '20px', color: '#027A48'}} />
                </Button>
              </Alert> ) : (
              <Alert
                sx={{
                  borderRadius: '8px',
                  mb: 2,
                  mt: 3,
                  padding: '1rem',
                  gap: '12px',
                  border: '1px solid #FDA29B',
                  background: '#FFFBFA',

                  '& .MuiAlert-icon': {
                    padding: 0,
                    margin: 0,
                    color: '#B42318',

                    '& svg': {
                      fontSize: '20px'
                    }
                  },

                  '& .MuiAlert-message': {
                    padding: 0,

                    '& .MuiTypography-root': {
                      marginTop: '0.25rem',
                      fontFamily: 'Manrope',
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#B42318',
                      lineHeight: '142.857%',
                    },

                    '& .MuiAlertTitle-root': {
                      margin: 0,
                      fontFamily: "Manrope SemiBold",
                      color: '#B42318',
                      fontWeight: 600,
                    }
                  }
                }} icon={<ErrorOutlineOutlinedIcon />} variant="outlined" severity="error">
                <AlertTitle>{appName} {displayErrorMsg}.</AlertTitle>
                {/* <Typography>Please add letters.</Typography> */}
              </Alert> ))
            }

            </div>
            <div className="modal-footer">

              { errorMsg === 200 && submitted ?
                <button
                  className="btn btn-genmeb ms-3 submit--btn"
                  ref={buttonRef}
                  data-bs-dismiss="modal"
                  onClick={modalClosed}
                >
                  Done
                </button> : (
                <>
                 <button
                  type="button"
                  className="btn btn-secondary cancel--btn"
                  data-bs-dismiss="modal"
                  onClick={modalClosed}
                >
                  Cancel
                </button>

                <button
                  className="btn btn-genmeb ms-3 submit--btn"
                  ref={buttonRef}
                  disabled={(logo && !logoValidation.logoIsValid) || (nameInputIsInvalid || nameAlreadyExists || displayInputIsInvalid || !url || urlInputIsInvalid )}
                  onClick={submitHandler}
                >
                  Submit
                </button>
                </>
                )
              }
            </div>
          </div>
        </div>
    </Modal>
  );
};

export default Onboard;
