import React, { useState, useEffect } from "react";
import "../../assets/css/new-dashboard.css";
import calendar from "../../assets/images/new-images/calender-ico.svg";
import image from "../../assets/images/new-images/image.png";
import p1 from "../../assets/images/new-images/bDot.png";
import p2 from "../../assets/images/new-images/loacation.png";
import p3 from "../../assets/images/new-images/time.png";
import path from "../../assets/images/new-images/Path.png";
import newIcon from "../../assets/images/new-images/new.svg";
import p5 from "../../assets/images/new-images/evt5.svg";
import Calendar from "react-calendar";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  fetchDashboardPopupDates,
  fetchDashboardPopUpEvents,
  dashDatePopUpEventsFilter,
} from "../../actions/action-event";
import { updateEventReadStatus } from "../../actions/action-master";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import { connect } from "react-redux";
const { REACT_APP_BACKEND_API_APP } = process.env;

const CalenderViewAll = ({
  fetchDashboardPopUpEvents,
  fetchDashboardPopupDates,
  updateEventReadStatus,
  dashPopEventsLoading,
  dashDatePopUpEventsFilter,
  ...props
}) => {
  const dashPopupDates = useSelector((state) => state.event.dashPopupDates);
  const dashPopEvents = useSelector((state) => state.event.dashPopEvents);
  useEffect(() => {
    props.activatePopUp && fetchDashboardPopupDates();
    // props.activatePopUp && fetchDashboardPopUpEvents()
  }, [props.activatePopUp]);
  const [allEvents, setAllEvents] = useState([]);
  const [allDates, setAllDates] = useState([]);
  useEffect(() => {
    dashPopEvents &&
      setAllEvents(
        dashPopEvents?.map((a) => {
          return {
            id: a.eventId,
            start: moment.utc(a.eventStartDateTime).local().format(),
            end: moment.utc(a.eventEndDateTime).local().format(),
            ...a,
          };
        })
      );
  }, [dashPopEvents]);

  useEffect(() => {
    dashPopupDates &&
      dashPopupDates?.length > 0 &&
      setAllDates(dashPopupDates?.map((item) => item));
  }, [dashPopupDates]);

  const [value, onChange] = useState(new Date());

  const [calendarResults, setCalendarResults] = useState(false);
  const [calendarToggle, setCalendarToggle] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    let date = {};
    date.startDate = startDate;
    date.endDate = endDate;
    props.activatePopUp && dashDatePopUpEventsFilter(date);
  }, [startDate, endDate, props.activatePopUp]);

  const calenderToggleHandler = () => {
    setCalendarToggle(!calendarToggle);
    setCalendarResults(false);
    setStartDate("");
    setEndDate("");
  };

  const [status, setStatus] = useState("All");
  const statusHandler = (e) => {
    setStatus(e.target.value);
  };

  const [filteredEvents, setFilteredevents] = useState([]);

  let curr = new Date();
  let first = curr.getDate();
  let last = first + 7;

  let firstday = new Date();
  let lastday = new Date(curr.setDate(last));

  let currMonth = curr.getMonth() + 1;
  let currYear = curr.getFullYear();

  const changeReadHandler = (id, value) => {
    updateEventReadStatus(id, value);
  };

  const filterHandler = () => {
    switch (status) {
      case "Today":
        setStartDate(moment(new Date()).format("YYYY-MM-DD"));
        setEndDate(moment(new Date()).format("YYYY-MM-DD"));
        setFilteredevents(
          allEvents.filter(
            (event) =>
              moment
                .utc(event.eventStartDateTime)
                .local()
                .format("YYYY-MM-DD") ===
              moment(new Date()).format("YYYY-MM-DD")
          )
        );
        break;
      case "Week":
        setStartDate(
          moment(new Date()).startOf("isoWeek").format("YYYY-MM-DD")
        );
        setEndDate(moment(new Date()).endOf("isoWeek").format("YYYY-MM-DD"));
        setFilteredevents(
          allEvents.filter((event) => {
            let date = moment(event.eventStartDateTime).format("YYYY-MM-DD");
            if (
              date >=
                moment(new Date()).startOf("isoWeek").format("YYYY-MM-DD") &&
              date <= moment(new Date()).endOf("isoWeek").format("YYYY-MM-DD")
            ) {
              return event;
            }
          })
        );
        break;
      case "Month":
        setStartDate(
          moment(new Date()).add(1, "M").startOf("month").format("YYYY-MM-DD")
        );
        setEndDate(
          moment(new Date()).add(1, "M").endOf("month").format("YYYY-MM-DD")
        );
        setFilteredevents(
          allEvents.filter((event) => {
            let date = moment(event.eventStartDateTime).format("YYYY-MM-DD");
            if (
              date >=
                moment(new Date())
                  .add(1, "M")
                  .startOf("month")
                  .format("YYYY-MM-DD") &&
              date <=
                moment(new Date())
                  .add(1, "M")
                  .endOf("month")
                  .format("YYYY-MM-DD")
            ) {
              return event;
            }
          })
        );
        break;
      case "All":
        setStartDate("");
        setEndDate("");

        setFilteredevents(
          allEvents.filter(
            (event) =>
              new Date(moment.utc(event.start).local()).getTime() >
              new Date().getTime()
          )
        );
        break;

      default:
        setFilteredevents(allEvents);
    }
  };

  useEffect(() => {
    if (!calendarToggle) filterHandler();
  }, [allEvents, status, calendarToggle]);

  const groupByDates = (group, key) =>
    group?.reduce((groups, game) => {
      const date = game?.start?.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(game);
      return groups;
    }, {});

  const renderCalendarEvents = (data) => {
    let eventDates = data.filter(
      (event) =>
        moment.utc(event.start).local().format("MM-DD-YYYY") ===
        moment(value).format("MM-DD-YYYY")
    );

    let newDates = [...new Set(eventDates)];

    return (
      <>
        <p className="cal-dtl mt-3 1">
          {moment(value).format("dddd, MMMM Do, YYYY")}
        </p>
        {eventDates && eventDates.length === 0 ? (
          <div className="event-drw">
            <img src={image} alt="icon" />
            <p className="grn-text">No events scheduled</p>
          </div>
        ) : (
          eventDates &&
          eventDates.map((event) => (
            <div className="event-key" key={event.id}>
              <div className="event-wiget">
                <div className="tym-show">
                  <p>{moment.utc(event.start).local().format("hh A")}</p>
                </div>
                <div
                  className={`${
                    new Date(moment.utc(event.start).local()).getTime() <
                    new Date().getTime()
                      ? "event-show-grey"
                      : "event-show"
                  } `}
                >
                  {event?.isRead === false ? (
                    <img src={newIcon} className="newIcon" alt="icon" />
                  ) : (
                    ""
                  )}

                  <div className="grd-bdr-y"></div>
                  <div>
                    <p className="main-text">{event.title}</p>

                    <p className="fade-text">
                      {moment.utc(event.start).local().format("hh:mm A") +
                        " - "}
                      {moment.utc(event.end).local().format("YYYY-MM-DD") >
                      moment.utc(event.start).local().format("YYYY-MM-DD")
                        ? moment
                            .utc(event.end)
                            .local()
                            .format("MMMM Do YYYY, h:mm A")
                        : moment.utc(event.end).local().format("h:mm A")}
                    </p>
                  </div>
                  <div className="dropdown eve-pop">
                    <button
                      type="button"
                      className="dropdown-toggle read-text"
                      data-bs-toggle="dropdown"
                      onClick={() =>
                        !event.isRead && changeReadHandler(event.id, true)
                      }
                    >
                      Read More
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <div className="event-popup">
                          <div className="p-top">
                            <img src={path} alt="icon" />
                          </div>

                          <span className="popup-head">
                            <img src={p1} alt="icon" />
                            <p>{event.title}</p>
                          </span>
                          {event.description !== "" && (
                            <span className="popup-body">
                              <img src={p5} alt="icon" />
                              <span>
                                <p className="p-text">Description</p>
                                <p className="p-sub-text">
                                  {event.description}
                                </p>
                              </span>
                            </span>
                          )}

                          <span className="popup-body">
                            <img src={p3} alt="icon" />
                            <span>
                              <p className="p-text">Scheduled</p>
                              <p className="p-sub-text">
                                {moment
                                  .utc(event.start)
                                  .local()
                                  .format("MMMM Do YYYY, h:mm A") + " - "}{" "}
                                {moment
                                  .utc(event.end)
                                  .local()
                                  .format("YYYY-MM-DD") >
                                moment
                                  .utc(event.start)
                                  .local()
                                  .format("YYYY-MM-DD")
                                  ? moment
                                      .utc(event.end)
                                      .local()
                                      .format("MMMM Do YYYY, h:mm A")
                                  : moment
                                      .utc(event.end)
                                      .local()
                                      .format("h:mm A")}
                              </p>
                            </span>
                          </span>
                          {event.location !== "" && (
                            <span className="popup-body">
                              <img src={p2} alt="icon" />
                              <span>
                                <p className="p-text">{event.location}</p>
                              </span>
                            </span>
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </>
    );
  };

  const renderDates = (data, typeStatus) => {
    let newData = data.sort((a, b) => new Date(a.start) - new Date(b.start));
    switch (typeStatus) {
      case "Today":
        // const changeDates = dates.map((i) => )
        const commonDates5 = groupByDates(newData, "start");

        return (
          <>
            {Object.entries(commonDates5) &&
            Object.entries(commonDates5).length === 0 ? (
              <div className="event-drw">
                <img src={image} alt="icon" />
                <p className="grn-text">No events scheduled</p>
              </div>
            ) : (
              Object.entries(commonDates5).map(([dateKeys, dateObjs]) => (
                <div key={dateKeys} className="event-key">
                  <p className="cal-dtl mt-3 2">
                    {moment(dateKeys).format("dddd, MMMM Do, YYYY")}
                  </p>
                  {dateObjs.map((event) => (
                    <div className="event-wiget" key={event.id}>
                      <div className="tym-show">
                        <p>{moment.utc(event.start).local().format("hh A")}</p>
                      </div>
                      <div className="event-show">
                        {event?.isRead === false ? (
                          <img src={newIcon} className="newIcon" alt="icon" />
                        ) : (
                          ""
                        )}
                        <div className="grd-bdr-y"></div>
                        <div>
                          <p className="main-text">{event.title}</p>

                          <p className="fade-text">
                            {moment.utc(event.start).local().format("hh:mm A") +
                              " - "}
                            {moment
                              .utc(event.end)
                              .local()
                              .format("YYYY-MM-DD") >
                            moment.utc(event.start).local().format("YYYY-MM-DD")
                              ? moment
                                  .utc(event.end)
                                  .local()
                                  .format("MMMM Do YYYY, h:mm A")
                              : moment.utc(event.end).local().format("h:mm A")}
                          </p>
                        </div>
                        <div className="dropdown eve-pop">
                          <button
                            type="button"
                            className="dropdown-toggle read-text"
                            data-bs-toggle="dropdown"
                            onClick={() =>
                              !event.isRead && changeReadHandler(event.id, true)
                            }
                          >
                            Read More
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <div className="event-popup">
                                <div className="p-top">
                                  <img src={path} alt="icon" />
                                </div>
                                <span className="popup-head">
                                  <img src={p1} alt="icon" />
                                  <p>{event.title}</p>
                                </span>
                                {event.description !== "" && (
                                  <span className="popup-body">
                                    <img src={p5} alt="icon" />
                                    <span>
                                      <p className="p-text">Description</p>
                                      <p className="p-sub-text">
                                        {event.description}
                                      </p>
                                    </span>
                                  </span>
                                )}
                                <span className="popup-body">
                                  <img src={p3} alt="icon" />
                                  <span>
                                    <p className="p-text">Scheduled</p>
                                    <p className="p-sub-text">
                                      {moment
                                        .utc(event.start)
                                        .local()
                                        .format("MMMM Do YYYY, h:mm A") +
                                        " - "}{" "}
                                      {moment
                                        .utc(event.end)
                                        .local()
                                        .format("YYYY-MM-DD") >
                                      moment
                                        .utc(event.start)
                                        .local()
                                        .format("YYYY-MM-DD")
                                        ? moment
                                            .utc(event.end)
                                            .local()
                                            .format("MMMM Do YYYY, h:mm A")
                                        : moment
                                            .utc(event.end)
                                            .local()
                                            .format("h:mm A")}
                                    </p>
                                  </span>
                                </span>
                                {event.location !== "" && (
                                  <span className="popup-body">
                                    <img src={p2} alt="icon" />
                                    <span>
                                      <p className="p-text">{event.location}</p>
                                    </span>
                                  </span>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            )}
          </>
        );
        break;
      case "All":
        // const changeDates = dates.map((i) => )
        const commonDates = groupByDates(newData, "start");

        return (
          <>
            {Object.entries(commonDates) &&
            Object.entries(commonDates).length === 0 ? (
              <div className="event-drw">
                <img src={image} alt="icon" />
                <p className="grn-text">No events scheduled</p>
              </div>
            ) : (
              Object.entries(commonDates).map(([dateKeys, dateObjs]) => (
                <div key={dateKeys} className="event-key">
                  <p
                    className="cal-dtl mt-3"
                    key={Math.floor(Math.random() * 100)}
                  >
                    {moment(dateKeys).format("dddd, MMMM Do, YYYY")}
                  </p>
                  {dateObjs.map((event) => (
                    <div className="event-wiget" key={event.id}>
                      <div className="tym-show">
                        <p>{moment.utc(event.start).local().format("hh A")}</p>
                      </div>
                      <div className="event-show">
                        {!event?.isRead ? (
                          <img src={newIcon} className="newIcon" alt="icon" />
                        ) : (
                          ""
                        )}
                        <div className="grd-bdr-y"></div>
                        <div>
                          <p className="main-text">{event.title}</p>

                          <p className="fade-text">
                            {moment.utc(event.start).local().format("hh:mm A") +
                              " - "}
                            {moment
                              .utc(event.end)
                              .local()
                              .format("YYYY-MM-DD") >
                            moment.utc(event.start).local().format("YYYY-MM-DD")
                              ? moment
                                  .utc(event.end)
                                  .local()
                                  .format("MMMM Do YYYY, h:mm A")
                              : moment.utc(event.end).local().format("h:mm A")}
                          </p>
                        </div>
                        <div className="dropdown eve-pop">
                          <button
                            type="button"
                            className="dropdown-toggle read-text"
                            data-bs-toggle="dropdown"
                            onClick={() =>
                              !event.isRead && changeReadHandler(event.id, true)
                            }
                          >
                            Read More
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <div className="event-popup">
                                <div className="p-top">
                                  <img src={path} alt="icon" />
                                </div>
                                <span className="popup-head">
                                  <img src={p1} alt="icon" />
                                  <p>{event.title}</p>
                                </span>
                                {event.description !== "" && (
                                  <span className="popup-body">
                                    <img src={p5} alt="icon" />
                                    <span>
                                      <p className="p-text">Description</p>
                                      <p className="p-sub-text">
                                        {event.description}
                                      </p>
                                    </span>
                                  </span>
                                )}
                                <span className="popup-body">
                                  <img src={p3} alt="icon" />
                                  <span>
                                    <p className="p-text">Scheduled</p>
                                    <p className="p-sub-text">
                                      {moment
                                        .utc(event.start)
                                        .local()
                                        .format("MMMM Do YYYY, h:mm A") +
                                        " - "}{" "}
                                      {moment
                                        .utc(event.end)
                                        .local()
                                        .format("YYYY-MM-DD") >
                                      moment
                                        .utc(event.start)
                                        .local()
                                        .format("YYYY-MM-DD")
                                        ? moment
                                            .utc(event.end)
                                            .local()
                                            .format("MMMM Do YYYY, h:mm A")
                                        : moment
                                            .utc(event.end)
                                            .local()
                                            .format("h:mm A")}
                                    </p>
                                  </span>
                                </span>
                                {event.location !== "" && (
                                  <span className="popup-body">
                                    <img src={p2} alt="icon" />
                                    <span>
                                      <p className="p-text">{event.location}</p>
                                    </span>
                                  </span>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            )}
          </>
        );
        break;
      case "Week":
        // const changeDates = dates.map((i) => )
        const commonDates2 = groupByDates(newData, "start");

        return (
          <>
            {Object.entries(commonDates2) &&
            Object.entries(commonDates2).length === 0 ? (
              <div className="event-drw">
                <img src={image} alt="icon" />
                <p className="grn-text">No events scheduled</p>
              </div>
            ) : (
              Object.entries(commonDates2).map(([dateKeys, dateObjs]) => (
                <div key={dateKeys} className="event-key">
                  <p className="cal-dtl mt-3">
                    {moment(dateKeys).format("dddd, MMMM Do, YYYY")}
                  </p>
                  {dateObjs.map((event) => (
                    <div className="event-wiget" key={event.id}>
                      <div className="tym-show">
                        <p>{moment.utc(event.start).local().format("hh A")}</p>
                      </div>
                      <div className="event-show">
                        {event?.isRead === false ? (
                          <img src={newIcon} className="newIcon" alt="icon" />
                        ) : (
                          ""
                        )}
                        <div className="grd-bdr-y"></div>
                        <div>
                          <p className="main-text">{event.title}</p>

                          <p className="fade-text">
                            {moment.utc(event.start).local().format("hh:mm A") +
                              " - "}
                            {moment
                              .utc(event.end)
                              .local()
                              .format("YYYY-MM-DD") >
                            moment.utc(event.start).local().format("YYYY-MM-DD")
                              ? moment
                                  .utc(event.end)
                                  .local()
                                  .format("MMMM Do YYYY, h:mm A")
                              : moment.utc(event.end).local().format("h:mm A")}
                          </p>
                        </div>
                        <div className="dropdown eve-pop">
                          <button
                            type="button"
                            className="dropdown-toggle read-text"
                            data-bs-toggle="dropdown"
                            onClick={() =>
                              !event.isRead && changeReadHandler(event.id, true)
                            }
                          >
                            Read More
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <div className="event-popup">
                                <div className="p-top">
                                  <img src={path} alt="icon" />
                                </div>
                                <span className="popup-head">
                                  <img src={p1} alt="icon" />
                                  <p>{event.title}</p>
                                </span>
                                {event.description !== "" && (
                                  <span className="popup-body">
                                    <img src={p5} alt="icon" />
                                    <span>
                                      <p className="p-text">Description</p>
                                      <p className="p-sub-text">
                                        {event.description}
                                      </p>
                                    </span>
                                  </span>
                                )}
                                <span className="popup-body">
                                  <img src={p3} alt="icon" />
                                  <span>
                                    <p className="p-text">Scheduled</p>
                                    <p className="p-sub-text">
                                      {moment
                                        .utc(event.start)
                                        .local()
                                        .format("MMMM Do YYYY, h:mm A") +
                                        " - "}{" "}
                                      {moment
                                        .utc(event.end)
                                        .local()
                                        .format("YYYY-MM-DD") >
                                      moment
                                        .utc(event.start)
                                        .local()
                                        .format("YYYY-MM-DD")
                                        ? moment
                                            .utc(event.end)
                                            .local()
                                            .format("MMMM Do YYYY, h:mm A")
                                        : moment
                                            .utc(event.end)
                                            .local()
                                            .format("h:mm A")}
                                    </p>
                                  </span>
                                </span>
                                {event.location !== "" && (
                                  <span className="popup-body">
                                    <img src={p2} alt="icon" />
                                    <span>
                                      <p className="p-text">{event.location}</p>
                                    </span>
                                  </span>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            )}
          </>
        );
        break;

      case "Month":
        // const changeDates = dates.map((i) => )
        const commonDates3 = groupByDates(newData, "start");

        return (
          <>
            {Object.entries(commonDates3) &&
            Object.entries(commonDates3).length === 0 ? (
              <div className="event-drw">
                <img src={image} alt="icon" />
                <p className="grn-text">No events scheduled</p>
              </div>
            ) : (
              Object.entries(commonDates3).map(([dateKeys, dateObjs]) => (
                <div key={dateKeys} className="event-key">
                  <p className="cal-dtl mt-3">
                    {moment(dateKeys).format("dddd, MMMM Do, YYYY")}
                  </p>
                  {dateObjs.map((event) => (
                    <div className="event-wiget" key={event.id}>
                      <div className="tym-show">
                        <p>{moment.utc(event.start).local().format("hh A")}</p>
                      </div>
                      <div className="event-show">
                        {event?.isRead === false ? (
                          <img src={newIcon} className="newIcon" alt="icon" />
                        ) : (
                          ""
                        )}
                        <div className="grd-bdr-y"></div>
                        <div>
                          <p className="main-text">{event.title}</p>

                          <p className="fade-text">
                            {moment.utc(event.start).local().format("hh:mm A") +
                              " - "}
                            {moment
                              .utc(event.end)
                              .local()
                              .format("YYYY-MM-DD") >
                            moment.utc(event.start).local().format("YYYY-MM-DD")
                              ? moment
                                  .utc(event.end)
                                  .local()
                                  .format("MMMM Do YYYY, h:mm A")
                              : moment.utc(event.end).local().format("h:mm A")}
                          </p>
                        </div>
                        <div className="dropdown eve-pop">
                          <button
                            type="button"
                            className="dropdown-toggle read-text"
                            data-bs-toggle="dropdown"
                            onClick={() =>
                              !event.isRead && changeReadHandler(event.id, true)
                            }
                          >
                            Read More
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <div className="event-popup">
                                <div className="p-top">
                                  <img src={path} alt="icon" />
                                </div>
                                <span className="popup-head">
                                  <img src={p1} alt="icon" />
                                  <p>{event.title}</p>
                                </span>
                                {event.description !== "" && (
                                  <span className="popup-body">
                                    <img src={p5} alt="icon" />
                                    <span>
                                      <p className="p-text">Description</p>
                                      <p className="p-sub-text">
                                        {event.description}
                                      </p>
                                    </span>
                                  </span>
                                )}
                                <span className="popup-body">
                                  <img src={p3} alt="icon" />
                                  <span>
                                    <p className="p-text">Scheduled</p>
                                    <p className="p-sub-text">
                                      {moment
                                        .utc(event.start)
                                        .local()
                                        .format("MMMM Do YYYY, h:mm A") +
                                        " - "}{" "}
                                      {moment
                                        .utc(event.end)
                                        .local()
                                        .format("YYYY-MM-DD") >
                                      moment
                                        .utc(event.start)
                                        .local()
                                        .format("YYYY-MM-DD")
                                        ? moment
                                            .utc(event.end)
                                            .local()
                                            .format("MMMM Do YYYY, h:mm A")
                                        : moment
                                            .utc(event.end)
                                            .local()
                                            .format("h:mm A")}
                                    </p>
                                  </span>
                                </span>
                                {event.location !== "" && (
                                  <span className="popup-body">
                                    <img src={p2} alt="icon" />
                                    <span>
                                      <p className="p-text">{event.location}</p>
                                    </span>
                                  </span>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            )}
          </>
        );
      default:
        setFilteredevents(allEvents);
    }
  };

  return (
    <div>
      <div
      // className="modal fade"
      // id="viewAllEvent"
      // tabIndex="-1"
      // aria-labelledby="viewAllEventLabel"
      // aria-hidden="true"
      >
        {/* <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content"> */}
        <div className="modal-header heading-bar-1 setheaderBox">
          <p className="modal-title" id="viewAllEventLabel">
            Upcoming Events
          </p>
          <img
            className={`calendar modal-cal ${
              calendarToggle ? "active-Cal" : ""
            }`}
            src={calendar}
            alt="calendar"
            onClick={calenderToggleHandler}
          />

          <div className="flex-space">
            {!calendarResults && (
              <div className="calender-head">
                <select
                  className="form-control w-110 nw"
                  onChange={statusHandler}
                  value={status}
                >
                  <option value="All">All</option>
                  <option value="Today">Today</option>
                  <option value="Week">This Week</option>
                  <option value="Month">Next Month</option>
                </select>
              </div>
            )}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => props.setActivatePopup(false)}
            ></button>
          </div>
        </div>
        <div
          className="modal-body notification-popupHeight"
          id="scrollableDiv"
          style={{ height: "100%", overflowY: "scroll" }}
        >
          {calendarToggle && (
            <div className="event-drw">
              <Calendar
                onChange={(e) => {
                  onChange(e);
                  setCalendarResults(true);
                  setStartDate(moment.utc(e).local().format("YYYY-MM-DD"));
                  setEndDate(moment.utc(e).local().format("YYYY-MM-DD"));
                }}
                value={value}
                tileClassName={({ date }) => {
                  let day = date.getDate();
                  let month = date.getMonth() + 1;

                  if (month < 10) {
                    month = "0" + month;
                  }
                  if (date.getDate() < 10) {
                    day = "0" + day;
                  }
                  const realDate = month + "-" + day + "-" + date.getFullYear();

                  if (
                    allDates.find(
                      (val) =>
                        moment.utc(val).local().format("MM-DD-YYYY") ===
                        realDate
                    )
                  ) {
                    return "highlt-big";
                  }
                }}
              />
            </div>
          )}

          {dashPopEventsLoading &&
          sessionStorage.getItem("dataLoad") === null ? (
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                top: "0%",
                width: "90%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 999,
              }}
            >
              <CircularProgress size="2rem" />
            </Box>
          ) : (
            <div>
              {!calendarResults && renderDates(filteredEvents, status)}
              {calendarResults && renderCalendarEvents(allEvents)}
            </div>
          )}
        </div>
        {/* </div>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = ({ master, event }) => ({
  dashPopEvents: event?.dashPopEvents,
  dashPopEventsLoading: event?.dashPopEventsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDashboardPopUpEvents: () => dispatch(fetchDashboardPopUpEvents()),
  fetchDashboardPopupDates: () => dispatch(fetchDashboardPopupDates()),
  updateEventReadStatus: (id, status) =>
    dispatch(updateEventReadStatus(id, status)),
  dashDatePopUpEventsFilter: (val) => dispatch(dashDatePopUpEventsFilter(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalenderViewAll);
