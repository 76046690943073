import React, { useRef, useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddAppsModal from "../Modals/AddAppsModal";
import CloseIcon from "@mui/icons-material/Close";

const AddApp = ({
  isShownApp,
  setIsShownApp,
  setIsShownGroup,
  userApps,
  setUserApps,
  appsSearchInput,
  setAppsSearchInput,
  selectAppChecked,
  setSelectAppChecked,
  selectedApps,
  setSelectedApps,
  test,
  userGroups,
  editmode,
  filterRule
}) => {
  const handleRemove = (e, id) => {
    e.preventDefault();
    setUserApps(
      userApps?.map((obj) => {
        if (id === obj.appId) {
          return {
            ...obj,
            ischecked: false,
          };
        } else {
          return { ...obj };
        }
      })
    );
    setSelectedApps(selectedApps.filter((i) => i.appId !== id));
    setSelectAppChecked(false);
  };

  const handleClickApp = (event) => {
    if (userApps.filter((i) => i.ischecked === true)?.length > 0) {
      setSelectedApps(userApps.filter((i) => i.ischecked === true));
    } else {
      setSelectedApps([]);
    }
    setAppsSearchInput("");
    setSelectAppChecked(false);
    setIsShownGroup(false);
    setIsShownApp((current) => !current);
  };

  const btnRefApp = useRef();
  useEffect(() => {
    const closeDropdown = (e) => {
      console.log(e);
      // setIsShownGroup(true);

      if (!btnRefApp.current.contains(e.target)) {
        setIsShownApp(false);
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <div className="c-select" ref={btnRefApp}>
      <div className="select-bar">
        {userApps?.filter((a) => a.ischecked === true)?.map((item) => item)
          ?.length > 0 ? (
          userApps &&
          userApps
            ?.filter((a) => a.isActive === true)
            ?.filter((a) => a.ischecked === true)
            ?.map((item) => (
              <span
                key={item.appId}
                className="placeholder placeholder_selected "
              >
                {item.appName}{" "}
                <button
                  className="remove-button"
                  onClick={(e) => handleRemove(e, item.appId)}
                >
                  <CloseIcon className="close-icon"></CloseIcon>
                </button>
              </span>
            ))
        ) : (
          <span className="placeholder">Assign App</span>
        )}

        <ArrowDropDownIcon className="down-icon" onClick={handleClickApp} />
      </div>
      <AddAppsModal
        userApps={userApps}
        setUserApps={setUserApps}
        appsSearchInput={appsSearchInput}
        setAppsSearchInput={setAppsSearchInput}
        selectAppChecked={selectAppChecked}
        setSelectAppChecked={setSelectAppChecked}
        selectedApps={selectedApps}
        setSelectedApps={setSelectedApps}
        isShownApp={isShownApp}
        handleClickApp={handleClickApp}
        editmode={editmode}
        filterRule={filterRule}
        // handleAppFocus={handleAppFocus}
        // appsInputIsInvalid={appsInputIsInvalid}
        // conflictEdit={conflictEdit}
        test={test}
        userGroups={userGroups}
      />
    </div>
  );
};

export default AddApp;
