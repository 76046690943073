export const updateState = (state, newState) => ({
    ...state,
    ...newState
})

export const getMimeTypeFromBase64 = (base64DataStream) => {
    let signatures = {
        "R0lGODdh": "image/gif",
        "iVBORw0KGgo": "image/png",
        "/9j/": "image/jpg",
        "PHN2Z": "image/svg+xml"
    };

    if (typeof base64DataStream !== 'string') {
        console.log(base64DataStream)
    }

    // Match the data stream against the known signatures
    for (const mimeType in signatures) {
        if (String(base64DataStream).startsWith(mimeType)) {
            return signatures[mimeType];
        }
    }

    //Fallabck to a default value.
    return "image/jpg"
};

export const renderImageDataUrl = (base64DataStream) => {
    return `data:${getMimeTypeFromBase64(base64DataStream)};base64,${base64DataStream}`;
};

/**
 * Takes in two arrays: a 'strArray' of strings and an 'idArray' of indices. It maps over the 'idArray'
 * to get the strings from 'strArray' at the specified indices. It then filters out any non-truthy values (like null,
 * undefined, empty strings, etc.) and finally joins the remaining strings with a comma and space.
 *
 * @param {string[]} strArray - Array of strings.
 * @param {number[]} idArray - Array of indices pointing to the desired strings in 'strArray'.
 * @returns {string} A single string composed of the valid strings from 'strArray' at the positions specified by 'idArray', separated by commas.
 */
export const renderStringsFromArray = (strArray, idArray) => {
    if (!idArray) return '';
    return idArray
        .map(id => strArray[id])
        .filter(Boolean)
        .join(', ');
}

export const joinArrayByComma = (arr) => {
    if (Array.isArray(arr)) {
        return arr.join(', ');
    }
    return arr;
}

export const normaliseString = (str) => str.replace(/\s+/g, '').toLowerCase();