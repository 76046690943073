import React, { useEffect } from "react";

const AddLocation = ({ locationsList }) => {
 
  return (
    <div className="mb-3 relative-box">
      <label className="form-label">Location</label>
      <input
        type="text"
        data-bs-target="#locationOpen"
        data-bs-toggle="modal"
        className="form-control arow"
        style={{ caretColor: "transparent" }}
        placeholder="Select Location"
        name="locations"
        value={
          locationsList.length > 0
            ? locationsList
                .filter((a) => a.checked === true)
                .map((item) => item.cityState)
                .join(", ")
            : ""
        }
        onChange={() => {
          console.log();
        }}
      />
    </div>
  );
};

export default AddLocation;
