import { createTheme } from "@mui/material/styles";
import vars from "./variable";

const { primaryFont } = vars;

let theme = createTheme();

theme = createTheme( {
  typography: {
    fontFamily: primaryFont,
    letterSpacing: 'normal'
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: `
        *, body {
          margin: 0;
          font-feature-settings: 'cv10' on, 'cv09' on, 'ss01' on;
          padding: 0;
          box-sizing: border-box;
          font-family: ${primaryFont}
        }

        .rs-btn-icon.rs-btn-xs > .rs-icon {
          font-size: 1rem;
        }

        .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after, .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::before {
          display: none;
        }

        .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .rs-calendar-header-title:hover,
        .rs-calendar-header-title:focus {
          backgroud: transparent !important;
        }

        .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content, .rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
          width: 2.5rem;
          border-radius: 50%;
          height: 2.5rem;
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          justify-content: center;
          font-weight: 500;
          line-height: 142.857%;
          color: #344054
        }

        .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
          background: #038177 !important;
          box-shadow: none;
          color: #fff !important;
        }

        .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content,
        .rs-btn-primary {
          background: #038177 !important;
        }

        .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
        .rs-calendar-table-cell-selected .rs-calendar-table-cell-content .rs-calendar-table-cell-day {
          color: #fff !important;
          background: #038177 !important;
        }

        .rs-calendar-table-cell-in-range::before, .rs-calendar-table-cell-selected::before {
          height: 2.5rem
        }

        .rs-picker-daterange-calendar-group {
          height: auto !important
        }

        .rs-picker-daterange-menu .rs-calendar {
          height: auto
        }

        .rs-calendar-table-cell-in-range::before {
          background: #F9FAFB
        }

        .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
          color: #fff !important;
        }

        .rs-calendar-table-cell.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content {
          color: #626D8A;
          font-weight: 400;
        }


        .rs-calendar-table-header-cell-content,
        .rs-btn-sm:not(.rs-btn-primary) {
          color: #344054 !important;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 142.857%;
          padding: 0.625rem 0.5rem
        }

        .rs-btn-xs,
        .rs-btn-icon.rs-btn-xs {
          font-size: 1rem;
          color: #344054;
          font-weight: 600;
          line-height: 150%;
          padding: 0;
        }

        .rs-picker-daterange-menu .rs-calendar {
          background: transparent;
          border-radius: 0
        }

        .rs-stack {
          justify-content: space-between;
          flex: 1;
        }

        .rs-picker-default .rs-picker-toggle {
          box-shadow: none !important;
          border-color: #D0D5DD !important
        }



        .rs-picker-default .rs-picker-toggle.rs-picker-toggle-active {
          box-shadow: 0rem 0rem 0rem 0.25rem #CEF8EB, 0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.05) !important;
          border-color: #33EBC3 !important
        }

        .rs-picker-toggle .rs-picker-toggle-placeholder,
        .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
          color: #626D8A !important;
          font-weight: 400 !important;
          line-height: 1 !important;
          font-size: 1rem !important;
        }

        .rs-picker-default .rs-picker-toggle.rs-btn {
          padding: 0.5rem 0.875rem;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 2.5rem;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 600,
          lineHeight: '142.857%',
          padding: '0 1rem',
          textTransform: 'none',
          height: '2.5rem',
          borderRadius: '0.5rem',
          boxShadow: '0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.05)',
          '&:hover': {
            boxShadow: '0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.05)'
          }
        },

        outlined: {
          color: '#344054',
        },

        outlinedPrimary: {
          borderColor: '#D0D5DD',
          background: '#FFF'

        },

        containedPrimary: {
          background: '#038177',

          '&:hover': {
            background: '#038177',
          }
        }
      }
    },

    MuiFormGroup: {
      styleOverrides: {
        root: {
          gap: '0.5rem'
        }
      }
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
          lineHeight: 1,

          '& .MuiTypography-root': {
            color: '#667085',
            marginLeft: '0.5rem',
            userSelect: 'none',
            display: 'flex',
            alignItems: 'center',
            gap: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 400
          }
        },
      }
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 500,
          lineHeight: 'normal',
          marginBottom: '1rem',
          color: '#344054',
          '&.Mui-focused': {
            color: '#344054',
          }
        }
      }
    },

    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiTypography-h3': {
            fontSize: '1.25rem',
            fontWeight: 600,
            lineHeight: '150%',
            fontFamily: '"Manrope SemiBold"',
            color: '#101828'
          },

          '& .MuiTypography-body2': {
            marginTop: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '142.857%'
          },
        },
        paper: {
          width: '25rem',
          borderLeft: '0.0625rem solid #EAECF0',
          background: '#FCFCFD',
          boxShadow: '0rem 0.5rem 0.5rem -0.25rem rgba(16, 24, 40, 0.03), 0rem 1.25rem 1.5rem -0.25rem rgba(16, 24, 40, 0.08)'
        }
      }
    },

    MuiModal: {
      styleOverrides: {
        backdrop: {
          background: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  }
} );

export default theme;