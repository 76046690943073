import React from "react";
import "../assets/css/new-dashboard.css";
import Navbar from "./Navbar";
export const DashboardLayout = (props) => {
  const { children, setEmpId, empId } = props;

  return (
    <>
      {/* <DashboardNavbar empId={empId}></DashboardNavbar> */}
      <Navbar ></Navbar>
      <section className="main-bg"> {children}</section>
    </>
  );
};
