import queryString from "query-string";
import Service from "../lib/Service";
import { masterActions } from "./constants";
import {
  fetchAlertByLoginId,
  fetchDashboardAlerts,
  fetchDashboardPopupAlerts,
} from "./action-notifications";
import {
  fetchDashboardEvents,
  fetchDashboardPopUpEvents,
} from "./action-event";

export const fetchAllUserGroups = () => async (dispatch) => {
  const userRoleId = sessionStorage.getItem("userRoleId");

  try {
    dispatch({
      type: masterActions.FETCH_ALL_USER_GROUPS_START,
    });

    const data = await Service.GET({
      name: "GetAllUserGroups",
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: masterActions.FETCH_ALL_USER_GROUPS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: masterActions.FETCH_ALL_USER_GROUPS_FAILED,
      payload: error.message,
    });
  }
};

export const createUserGroup = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: masterActions.CREATE_USER_GROUPS_START,
    });

    const data = await Service.POST({
      name: "AddGroup",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: masterActions.CREATE_USER_GROUPS_SUCCESS,
      payload: data,
    });

    dispatch(fetchAllUserGroups());
  } catch (error) {
    dispatch({
      type: masterActions.CREATE_USER_GROUPS_FAILED,
      payload: error.message,
    });
  }
};

export const updateUserGroup = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: masterActions.UPDATE_USER_GROUPS_START,
    });

    const data = await Service.POST({
      name: "UpdateGroup",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: masterActions.UPDATE_USER_GROUPS_SUCCESS,
      payload: data,
    });
    dispatch(fetchAllUserGroups());
  } catch (error) {
    dispatch({
      type: masterActions.UPDATE_USER_GROUPS_FAILED,
      payload: error.message,
    });
  }
};

export const deleteUserGroup = (id) => async (dispatch) => {
  try {
    dispatch({
      type: masterActions.DELETE_USER_GROUPS_START,
    });

    const data = await Service.POST({
      name: "DeleteGroup",
      queryString: `groupId=${id}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });

    dispatch({
      type: masterActions.DELETE_USER_GROUPS_SUCCESS,
      payload: data,
    });
    dispatch(fetchAllUserGroups());
  } catch (error) {
    dispatch({
      type: masterActions.DELETE_USER_GROUPS_FAILED,
      payload: error.message,
    });
  }
};

export const fetchAllUserRoles = () => async (dispatch) => {
  try {
    dispatch({
      type: masterActions.FETCH_ALL_USER_ROLES_START,
    });

    const data = await Service.GET({
      name: "GetAllUserRoles",
      queryString: "",
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: masterActions.FETCH_ALL_USER_ROLES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: masterActions.FETCH_ALL_USER_ROLES_FAILED,
      payload: error.message,
    });
  }
};

export const fetchNotificationTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: masterActions.FETCH_ALL_NOTIFICATION_TYPE_START,
    });

    const data = await Service.GET({
      name: "GetAllNotificationType",
      queryString: "",
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: masterActions.FETCH_ALL_NOTIFICATION_TYPE_SUCCESS,
      payload: data.responseData !== null ? data.responseData : [],
    });
  } catch (error) {
    dispatch({
      type: masterActions.FETCH_ALL_NOTIFICATION_TYPE_FAILED,
      payload: error.message,
    });
  }
};

export const createNotificationType = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: masterActions.CREATE_NOTIFICATION_TYPE_START,
    });

    const data = await Service.POST({
      name: "AddNotificationType",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: masterActions.CREATE_NOTIFICATION_TYPE_SUCCESS,
      payload: data,
    });

    dispatch(fetchNotificationTypes());
  } catch (error) {
    dispatch({
      type: masterActions.CREATE_NOTIFICATION_TYPE_FAILED,
      payload: error.message,
    });
  }
};

export const updateNotificationType = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: masterActions.UPDATE_NOTIFICATION_TYPE_START,
    });

    const data = await Service.POST({
      name: "UpdateNotificationType",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: masterActions.UPDATE_NOTIFICATION_TYPE_SUCCESS,
      payload: data,
    });
    dispatch(fetchNotificationTypes());
  } catch (error) {
    dispatch({
      type: masterActions.UPDATE_NOTIFICATION_TYPE_FAILED,
      payload: error.message,
    });
  }
};

export const deleteNotificationType = (id) => async (dispatch) => {
  try {
    dispatch({
      type: masterActions.DELETE_NOTIFICATION_TYPE_START,
    });

    const data = await Service.POST({
      name: "/DeleteNotificationType",
      queryString: `notificationTypeId=${id}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: masterActions.DELETE_NOTIFICATION_TYPE_SUCCESS,
      payload: data.responseData,
    });
    dispatch(fetchNotificationTypes());
    dispatch(fetchAlertByLoginId());
  } catch (error) {
    dispatch({
      type: masterActions.DELETE_NOTIFICATION_TYPE_FAILED,
      payload: error.message,
    });
  }
};

export const updateAlertReadStatus = (id, status) => async (dispatch) => {
  try {
    dispatch({
      type: masterActions.UPDATE_ALERT_READ_STATUS_START,
    });

    const data = await Service.POST({
      name: "UpdateAlertReadStatusByAlertId",
      queryString: `alertId=${id}&readStatus=${status}`,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: masterActions.UPDATE_ALERT_READ_STATUS_SUCCESS,
      payload: data,
    });
    dispatch(fetchDashboardAlerts());
    dispatch(fetchDashboardPopupAlerts());
  } catch (error) {
    dispatch({
      type: masterActions.UPDATE_ALERT_READ_STATUS_FAILED,
      payload: error.message,
    });
  }
};

export const updateEventReadStatus = (id, status) => async (dispatch) => {
  try {
    dispatch({
      type: masterActions.UPDATE_EVENT_READ_STATUS_START,
    });

    const data = await Service.POST({
      name: "UpdateEventStatusByEventId",
      queryString: `eventId=${id}&status=${status}`,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: masterActions.UPDATE_EVENT_READ_STATUS_SUCCESS,
      payload: data,
    });
    dispatch(fetchDashboardEvents());
    dispatch(fetchDashboardPopUpEvents());
  } catch (error) {
    dispatch({
      type: masterActions.UPDATE_EVENT_READ_STATUS_FAILED,
      payload: error.message,
    });
  }
};

export const fetchAllDepartments = () => async (dispatch) => {
  const userRoleId = sessionStorage.getItem("userRoleId");

  try {
    dispatch({
      type: masterActions.FETCH_ALL_DEPARTMENTS_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetDepartmentsByKeyword",
      queryString: `keyword=${""}`,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: masterActions.FETCH_ALL_DEPARTMENTS_SUCCESS,
      payload: {
        data: data.responseData !== null ? data.responseData : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: masterActions.FETCH_ALL_DEPARTMENTS_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const fetchAllLocations = () => async (dispatch) => {
  const userRoleId = sessionStorage.getItem("userRoleId");

  try {
    dispatch({
      type: masterActions.FETCH_ALL_LOCATIONS_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetLocationsByKeyword",
      queryString: `keyword=${""}`,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: masterActions.FETCH_ALL_LOCATIONS_SUCCESS,
      payload: {
        data: data.responseData !== null ? data.responseData : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: masterActions.FETCH_ALL_LOCATIONS_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const fetchImages = (value) => async (dispatch, getState) => {
  const payload = {
    imageName: value,
  };

  try {
    dispatch({
      type: masterActions.FETCH_IMAGES_START,
      loading: true,
    });

    const data = await Service.POST({
      name: "GetImages",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) {
        return res;
      }
    });
    dispatch({
      type: masterActions.FETCH_IMAGES_SUCCESS,
      payload: {
        photo: data.responseData !== null ? data.responseData : [],
        message: data.responseData === null ? "" : "",
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: masterActions.FETCH_IMAGES_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};
