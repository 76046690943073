import { combineReducers } from "redux";
import quickLinksReducer from "./quickLink";
import master from './reducer-master';
import alerts from './reducer-notifications';
import { usersList, users } from './reducer-users';
import loginReducer from './reducer-login'
import event from './reducer-event';
import groupsRule from "./reducer-grouprule";
import { appsData } from './reducer-apps'
import sharepointReducer from "./reducer-sharepoint";

const rootReducer = combineReducers({
    quickLinksReducer,
    master,
    alerts,
    usersList,
    loginReducer,
    event,
    users,
    appsData,
    groupsRule,
    sharepointReducer
});

export default rootReducer;
