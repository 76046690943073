import queryString from "query-string";
import Service from "../lib/Service";
import { notificationActions } from "./constants";
import moment from "moment";

export const setRecordLength =
  (data, records) => async (dispatch, getState) => {
    dispatch({
      type: notificationActions.SET_ALERTS_RECORDS_LENGTH,
      payload: {
        records: data.totalRecordCount ? data.totalRecordCount : 0,
      },
    });
  };

export const fetchAlertByLoginId = () => async (dispatch, getState) => {
  const offsetTime = encodeURIComponent(moment().local().format());
  const { paginationOptions, search, notificationStart, notificationEnd } =
    getState().alerts;
  const { pageNo, displayLimit } = paginationOptions;
  const searchKeyword = search ?  encodeURIComponent(search) : ""
  const userId = sessionStorage.getItem("userId");
  const userEmpId = sessionStorage.getItem("userEmpId") ? sessionStorage.getItem("userEmpId") : "";
  try {
    dispatch({
      type: notificationActions.FETCH_ALERTS_BY_LOGIN_ID_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetAlertsListByLoginUserId",
      queryString: `userId=${userId}&dateTimeOffset=${offsetTime}&PageNumber=${pageNo}&PageSize=${displayLimit}&fromDate=${
        notificationStart || ""
      }&toDate=${notificationEnd || ""}&keyword=${searchKeyword || ""}&empId=${userEmpId}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: notificationActions.FETCH_ALERTS_BY_LOGIN_ID_SUCCESS,
      payload: {
        payload: data.responseData !== null ? data.responseData : [],
        loading: false,
      },
    });
    dispatch(
      setRecordLength(
        data.responseData !== null ? data.responseData : [],
        data.totalRecordCount
      )
    );
  } catch (error) {
    dispatch({
      type: notificationActions.FETCH_ALERTS_BY_LOGIN_ID_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const createNewAlert = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: notificationActions.CREATE_NEW_ALERT_START,
    });

    const data = await Service.POST({
      name: "CreateNewAlert",
      payload: payload,
    }).then((res) => {
      return {
        type: res.isSuccess ? notificationActions.CREATE_NEW_ALERT_SUCCESS : notificationActions.CREATE_NEW_ALERT_FAILED,
        payload: res,
      };
    });
    dispatch(data)
    dispatch(fetchAlertByLoginId());

  } catch (error) {
    dispatch({
      type: notificationActions.CREATE_NEW_ALERT_FAILED,
      payload: error.message,
    });
  }
};

export const deleteModal = (openModal) => async (dispatch) => {
  dispatch({
    type: notificationActions.OPEN_DELETE_MODAL,
    payload: {
      openModal,
    },
  });
};

export const deleteAlert = (id) => async (dispatch) => {
  try {
    dispatch({
      type: notificationActions.DELETE_ALERT_START,
    });

    const data = await Service.POST({
      name: "DeleteAlertByAlertId",
      queryString: `alertId=${id}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: notificationActions.DELETE_ALERT_SUCCESS,
      payload: data.responseData,
    });
    dispatch(deleteModal(false));
    dispatch(fetchAlertByLoginId());
  } catch (error) {
    dispatch({
      type: notificationActions.DELETE_ALERT_FAILED,
      payload: error.message,
    });
  }
};

export const updateAlertDetails = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: notificationActions.UPDATE_ALERT_START,
    });

    const data = await Service.POST({
      name: "UpdateAlertDetails",
      payload: payload,
    }).then((res) => {
        return {
          type: res.isSuccess ? notificationActions.UPDATE_ALERT_SUCCESS : notificationActions.UPDATE_ALERT_FAILED,
          payload: res,
        };
    });
    dispatch(data)
    dispatch(fetchAlertByLoginId());
  } catch (error) {
    dispatch({
      type: notificationActions.UPDATE_ALERT_FAILED,
      payload: error.message,
    });
  }
};

export const getPaginationData = (data) => async (dispatch, getState) => {
  dispatch({
    type: notificationActions.FETCH_PAGINATION_DATA_SUCCESS,
    payload: {
      pageNo: data.pageNo,
      displayLimit: data.displayLimit,
    },
  });

  dispatch(fetchAlertByLoginId());
};

export const fetchSearch = (values) => async (dispatch) => {
  dispatch({
    type: notificationActions.FETCH_SEARCH_INPUT,
    payload: {
      search: values,
      paginationOptions: {
        pageNo: 1,
        displayLimit: 10,
      },
    },
  });
  dispatch(fetchAlertByLoginId());
};

export const dismissAlert = (val) => async (dispatch) => {
  const payload = {
    userId: sessionStorage.getItem("userId"),
    alertIdList: val,
  };
  try {
    dispatch({
      type: notificationActions.DISMISS_ALERT_START,
      payload: {
        dismissLoading: true,
        dismissResponse: "",
      },
    });

    const data = await Service.POST({
      name: "DismissAlerts",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) return res.responseData;
    });
    dispatch({
      type: notificationActions.DISMISS_ALERT_SUCCESS,
      payload: {
        dismissLoading: false,
        dismissResponse: data,
      },
    });
    dispatch(fetchDashboardAlerts());
    dispatch(fetchDashboardPopupAlerts())
  } catch (e) {
    dispatch({
      type: notificationActions.DISMISS_ALERT_FAILED,
      dismissError: e.message,
    });
  }
};

export const dragAndDrop = () => async (dispatch) => {
  const payload = {
    userId: sessionStorage.getItem("userId"),
  };
  try {
    dispatch({
      type: notificationActions.MOVE_APP_START,
      payload: {
        dragLoading: true,
      },
    });

    const data = await Service.POST({
      name: "UpdateApplicationIndexByAppId",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) return res.responseData;
    });
    dispatch({
      type: notificationActions.MOVE_APP_SUCCESS,
      payload: {
        dragLoading: false,
        newAppOrder: data,
      },
    });
  } catch (e) {
    dispatch({
      type: notificationActions.MOVE_APP_FAILED,
      dragLoading: false,
    });
  }
};

export const dateNotificatiionFilter = (date) => async (dispatch) => {
 
  dispatch({
    type: notificationActions.FETCH_NOTIFICATION_FITLER_DATES,
    payload: {
      dates: date,
      paginationOptions: {
        pageNo: 1,
        displayLimit: 10,
      },
    },
  });
  dispatch(fetchAlertByLoginId());
};

export const fetchDashboardAlerts = () => async (dispatch, getState) => {
  const offsetTime = encodeURIComponent(moment().local().format());
  const {dashNotificationStart, dashNotificationEnd } =
    getState().alerts;
  const userEmpId = sessionStorage.getItem("userEmpId") ? sessionStorage.getItem("userEmpId") : "";
  try {
    dispatch({
      type: notificationActions.FETCH_DASH_ALERTS_BY_LOGIN_ID_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetUserAlertSummaryByEmail",
      queryString: `dateTimeOffset=${offsetTime}&PageNumber=${1}&PageSize=${10}&notificationStartDate=${
        dashNotificationStart || ""
      }&notificationEndDate=${dashNotificationEnd || ""}&empId=${userEmpId}`,

     
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: notificationActions.FETCH_DASH_ALERTS_BY_LOGIN_ID_SUCCESS,
      payload: {
        payload: data.responseData !== null ? data.responseData.data : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: notificationActions.FETCH_DASH_ALERTS_BY_LOGIN_ID_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const fetchDashboardPopupAlerts = () => async (dispatch, getState) => {
  const offsetTime = encodeURIComponent(moment().local().format());
  const {dashNotificationStart, dashNotificationEnd } =
    getState().alerts;
  const userEmpId = sessionStorage.getItem("userEmpId") ? sessionStorage.getItem("userEmpId") : "";
  try {
    dispatch({
      type: notificationActions.FETCH_DASH_POPUP_ALERTS_BY_LOGIN_ID_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetUserAlertSummaryByEmail",
      queryString: `dateTimeOffset=${offsetTime}&PageNumber=${1}&PageSize=${10000}&notificationStartDate=${
        dashNotificationStart || ""
      }&notificationEndDate=${dashNotificationEnd || ""}&empId=${userEmpId}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: notificationActions.FETCH_DASH_POPUP_ALERTS_BY_LOGIN_ID_SUCCESS,
      payload: {
        payload: data.responseData !== null ? data.responseData.data : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: notificationActions.FETCH_DASH_POPUP_ALERTS_BY_LOGIN_ID_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};


export const dashDateNotificatiionFilter = (date) => async (dispatch) => {
 
  dispatch({
    type: notificationActions.FETCH_DASH_NOTIFICATION_FITLER_DATES,
    payload: {
      dates: date,
    },
  });
  dispatch(fetchDashboardAlerts());
  dispatch(fetchDashboardPopupAlerts())
};
