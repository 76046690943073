import React, { useEffect } from "react";
import SearchIcon from "../../../assets/images/search.svg";
import up from "../../../assets/images/new-images/up.png";
import { fetchUsersListByDepartment } from "../../../actions/action-grouprule";
import { connect } from "react-redux";
import Modal from "../../UI/Modal";
import { Loader } from "rsuite";

const AddUsersModal = ({
  fetchUsersListByDepartment,
  fetchedUsers = [],
  usersList,
  setUsersList,
  userSearchInput,
  setUserSearchInput,
  selectChecked,
  setSelectChecked,
  selectedUsers,
  setSelectedUsers,
  filterRule,
  editmode,
  message,
  adUsersLoading,
  selectedDepts,
  conflictEdit,
  ...props
}) => {
  const userSearchHandler = (e) => {
    setUserSearchInput(e.target.value);
  };

  let userSearchFilter =
    usersList &&
    usersList?.length &&
    usersList?.filter((item) => {
      if (userSearchInput === "") {
        return item;
      } else if (
        item.displayName.toLowerCase().includes(userSearchInput.toLowerCase())
      ) {
        return item;
      }
      return null;
    });
  const usersSelect = (e) => {
    setSelectChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedUsers(
        usersList.map((i) => {
          return {
            ...i,
            checked: e.target.checked,
          };
        })
      );
    } else if (e.target.checked === false) {
      setSelectedUsers([]);
    }
  };

  const usersHandler = (e, val) => {
    if (e.target.checked) {
      setSelectedUsers([...selectedUsers, val]);
    } else if (e.target.checked === false) {
      setSelectChecked(false);
      setSelectedUsers(selectedUsers.filter((i) => i.id !== val.id));
    }
  };

  const submitUsers = (values) => {
    setUsersList(
      usersList.map((obj) => {
        if (values.find((ol) => ol.id === obj.id)) {
          return {
            ...obj,
            checked: true,
          };
        } else {
          return { ...obj, checked: false };
        }
      })
    );

    setUserSearchInput("");
  };

  useEffect(() => {
    if (editmode) {
      let newVal = {};
      newVal =
        fetchedUsers?.length > 0 &&
        fetchedUsers?.map((item) => {
          if (
            [...new Set(filterRule.exceptUsers)]
              .map((i) => i.id)
              .includes(item.id)
          ) {
            return { ...item, checked: true };
          }
          return { ...item, checked: false };
        });
      if (newVal?.length > 0) {
        setUsersList(newVal);
      }

      let newArr = [];
      let test = fetchedUsers?.filter((item) =>
        filterRule?.exceptUsers.map((i) => i.id).includes(item.id)
      );
      for (let i = 0; i < test.length; i++) {
        if (selectedUsers?.indexOf(test[i]) === -1) {
          newArr?.push(test[i]);
        }
      }
      // setSelectedUsers(newArr)
      if (newArr?.length > 0) {
        setSelectedUsers(newArr);
      } else {
        fetchedUsers?.length > 0 && setSelectedUsers([]);
      }
      // if (newArr?.length > 0 ){ setSelectedUsers(newVal?.filter((i) => i.checked === true))}
      // else{setSelectedUsers([])};

      if (
        usersList?.filter((i) => i.checked === true).length ===
        fetchedUsers.length
      ) {
        setSelectChecked(true);
      }
    } else if (selectedUsers?.length > 0) {
      setUsersList(
        fetchedUsers?.map((obj) => {
          if (selectedUsers?.find((ol) => ol.id === obj.id)) {
            return {
              ...obj,
              checked: true,
            };
          } else {
            return { ...obj, checked: false };
          }
        })
      );
    } else {
      fetchedUsers?.length > 0 &&
        setUsersList(
          fetchedUsers?.length > 0 &&
            fetchedUsers?.map((item) => {
              return { ...item, checked: false };
            })
        );
    }
  }, [fetchedUsers, editmode, conflictEdit]);

  const cancelUsers = () => {
    if (
      usersList.length > 0 &&
      usersList.filter((i) => i.checked === true)?.length > 0
    ) {
      setSelectedUsers(usersList.filter((i) => i.checked === true));
    } else {
      setSelectedUsers([]);
    }
  };

  return (
    <Modal
      className="modal fade"
      modalId={"exceptUSerOpen"}
      modalLabel={"exampleexceptUSerOpen"}
    >
      <div className="modal-dialog modal-dialog-centered me-widht w-100">
        <div className="modal-content exceptUSer-side">
          <div className="modal-body p-0">
            <div>
              <div className="relative w-100">
                {usersList?.length > 0 && (
                  <>
                    <img
                      src={SearchIcon}
                      alt="icon"
                      className="pop-search top"
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      onChange={userSearchHandler}
                      value={userSearchInput}
                    />
                  </>
                )}
                <img
                  data-bs-dismiss="modal"
                  src={up}
                  alt="icon"
                  className="up-icon"
                />
              </div>

              {selectedDepts?.length === 0 ? (
                <div className="all-check height-full">No User(s) Found</div>
              ) : adUsersLoading ? (
                <Loader />
              ) : (
                <div className="scroll-height">
                  {usersList?.length > 0 && !userSearchInput && (
                    <div className="s-all">
                      <p>Select All</p>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={selectedUsers?.length === usersList?.length}
                        onChange={(e) => usersSelect(e)}
                      />
                    </div>
                  )}
                  {/* <div className="all-check">{message}</div> */}
                  {usersList?.length === 0 && (
                    <div className="all-check">No User(s) Found</div>
                  )}
                  {usersList?.length > 0 && userSearchFilter.length === 0 ? (
                    <div className="all-check">No Match Found</div>
                  ) : (
                    userSearchFilter?.length > 0 &&
                    userSearchFilter?.map((user, index) => (
                      <div key={index} className="all-check">
                        <p>
                          {user.displayName}
                          <span>{user.email}</span>
                        </p>
                        <input
                          type="checkbox"
                          name="exceptusers"
                          className="form-check-input"
                          value={user?.id}
                          checked={selectedUsers
                            ?.map((ir) => ir.id)
                            ?.includes(user?.id)}
                          onChange={(e) => usersHandler(e, user)}
                        />
                      </div>
                    ))
                  )}
                </div>
              )}
              <>
                <div className="drop-btn-set">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={(e) => {
                      cancelUsers();
                      setUserSearchInput("");
                      setSelectChecked(false);
                    }}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => submitUsers(selectedUsers)}
                    type="button"
                    className="btn btn-genmeb ms-3"
                    data-bs-dismiss="modal"
                  >
                    Save
                  </button>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
const mapStateToProps = ({ master, groupsRule }) => ({
  fetchedUsers: groupsRule?.usersList?.users,
  usersMessage: groupsRule.message,
  adUsersLoading: groupsRule?.adUsersLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersListByDepartment: (payload) =>
    dispatch(fetchUsersListByDepartment(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUsersModal);
