import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import cods from "../assets/images/new-images/cods.svg";
import login_logo from "../assets/images/new-images/login-logo.svg";
import "../../src/assets/css/login.css";
import { useDebounce } from "use-debounce";
import { Loader } from "rsuite";
import { useNavigate } from "react-router-dom";
const { REACT_APP_BACKEND_API_APP } = process.env;
const Login = ({ onLogin }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
  });

  const [suggestions, setSuggestions] = useState([]);

  const [active, setActive] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [value] = useDebounce(userEmail, 1000);
  const [empId, setEmpId] = useState("");

  const onChange = (e) => {
    setIsLoading(true);
    setActive(0);
    setIsShow(true);
    setUserEmail(e.currentTarget.value);

    let empId = suggestions?.filter(
      (item) => e.currentTarget.value === item.email
    );
    empId = empId[0]?.empId;

    setEmpId(empId);
  };
  const onClick = (e) => {
    setActive(0);
    setIsShow(false);
    let email = suggestions
      .filter((item) => e.currentTarget.innerText === item.userName)
      .map((item) => item.email)[0];

    setUserEmail(email);
    let empId = suggestions
      .filter((item) => e.currentTarget.innerText === item.userName)
      .map((item) => item.empId)[0];

    setEmpId(empId);
  };
  const [loading, setIsLoading] = useState(false);
  useEffect(() => {
    setUser({
      email: userEmail,
    });
  }, [userEmail]);

  useEffect(() => {
    sessionStorage.removeItem("dataLoad");
    sessionStorage.removeItem("photoLoad");

    if (value !== "") {
      let token = sessionStorage.getItem("accessToken2");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      setIsLoading(true);
      axios
        .get(
          `${REACT_APP_BACKEND_API_APP}/api/SearchListedUserbyUserName?keyword=${value}`,
          config
        )
        .then((response) => {
          setSuggestions(
            response.data.responseData ? response.data.responseData : []
          );
          setIsLoading(false);
        });
    }
  }, [value]);

  const onKeyDown = (e) => {
    if (isShow === true && e.key === "Enter") {
      e.preventDefault();

      setIsShow(false);

      let email = suggestions && suggestions[active]?.email;
      setUserEmail(email);

      let empId = suggestions && suggestions[active]?.empId;
      setEmpId(empId);
    } else {
      if (isShow === true && e.keyCode === 13) {
        e.preventDefault();

        let email = suggestions && suggestions[active].email;
        setUserEmail(email);
        let empId = suggestions && suggestions[active].empId;
        setEmpId(empId);
      } else if (isShow === true && e.keyCode === 38) {
        return active === 0 ? null : setActive(active - 1);
      } else if (isShow === true && e.keyCode === 40) {
        return active - 1 === suggestions.length ? null : setActive(active + 1);
      }
    }
  };

  const [enteredEmailTouched, setEnteredEmailTouched] = useState(false);
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const enteredEmailIsValid =
    userEmail &&
    userEmail !== "" &&
    userEmail !== undefined &&
    userEmail.match(isValidEmail) &&
    suggestions?.filter((item) => userEmail === item.email)?.length > 0 &&
    empId !== undefined &&
    empId !== "";

  const emailInputBlurHandler = () => {
    setEnteredEmailTouched(true);
  };
  const emailInputIsInvalid = !enteredEmailIsValid && enteredEmailTouched;

  const saveAddUser = (e) => {
    e.preventDefault();
    setEnteredEmailTouched(true);
    if (!enteredEmailIsValid) {
      return;
    }

    let data = empId;
    sessionStorage.setItem("userEmpId", data);
    // onLogin(data);

    setUser({
      email: "",
    });

    setUserEmail("");
    navigate("/");
    setEnteredEmailTouched(false);
  };

  return (
    <>
      <section className="login-body">
        <div className="login-left-part">
          <div>
            <img src={cods} alt="icon" className="mb-2" />
            <h4>
              Engineering a transformative <span>tomorrow</span>
            </h4>
          </div>
        </div>
        <div className="login-right-part">
          <div className="login-box">
            <img src={login_logo} alt="logo" className="mb-5" />
            <h5>Login as</h5>
            <p className="mb-3">Please enter email id or user name</p>
            <form>
              <div className=" form-group">
                {/* <label className="form-label">Email ID</label> */}
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter full name or email id"
                  name="email"
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  value={userEmail}
                  onBlur={emailInputBlurHandler}
                />
                {isShow && userEmail && suggestions && (
                  <ul className="autocomplete">
                    {loading ? (
                      <Loader />
                    ) : suggestions?.length > 0 ? (
                      suggestions
                        .filter((suggestion) => {
                          const searchTerm = userEmail.toLowerCase();
                          const fullName = suggestion.userName.toLowerCase();
                          const email = suggestion.email.toLowerCase();
                          if (searchTerm === "") {
                            return suggestion;
                          } else if (
                            fullName.includes(searchTerm) ||
                            email.includes(searchTerm)
                          ) {
                            return suggestion;
                          }
                        })
                        .map((item, index) => {
                          let className;
                          if (index === active) {
                            className = "active";
                          }
                          return (
                            <li
                              className={className}
                              key={index}
                              onClick={onClick}
                            >
                              {item.userName}
                            </li>
                          );
                        })
                    ) : (
                      <li className="error-text">Not Found</li>
                    )}
                  </ul>
                )}
                {emailInputIsInvalid && (
                  <p className="error-text">Please select a valid user</p>
                )}
              </div>
              <button
                type="submit"
                className="btn btn-genmab submit--btn"
                disabled={!enteredEmailIsValid}
                onClick={(e) => saveAddUser(e)}
              >
                SIGN IN
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
