import CheckIcon from "../../assets/images/check.svg";
import TickIcon from "../../assets/images/tick.svg";

const vars = {
  errorColor: '#F04438',
  inputTextColor: '#626D8A',
  labelColor: '#344054',
  whiteColor: '#FFF',
  checkedInputBorderColor: '#038177',
  checkedInputBgColor: '#EAFBF5',
  defaultInputBorderColor: '#D0D5DD',
  modalTitleColor: '#161616',
  checkBoxTextColor: '#475467',
  errorBorderColor: '#FDA29B',
  modalHeaderBorderColor: '#D9D9D9',
  inputShadow: '0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.05)',
  uploadButtonBorderColor: '#ced4da',
}

const { errorColor, inputTextColor, labelColor, whiteColor, checkedInputBorderColor: checkedBorderColor, checkedInputBgColor: checkedBgColor, defaultInputBorderColor, modalTitleColor, checkBoxTextColor, modalHeaderBorderColor, inputShadow, errorBorderColor, uploadButtonBorderColor } = vars;

export const customStyles = {
  '& .modal-footer': {
    height: '4.5rem',
    padding: '0 1rem',
    gap: '1rem',

    '& .btn': {
      height: '2.5rem',
      padding: '0 1rem',
      minWidth: '0.0625rem',
      borderRadius: '0.375rem',
      margin: 0
    }

  },
  '& .file-upload': {
    borderRadius: '0.5rem',

    '& .upload': {
      width: 'auto',
      background: 'transparent',
      color: labelColor,
      fontSize: '0.875rem',
      lineHeight: '142.857%',
      fontWeight: 400,
      zIndex: 999,
      cursor: 'pointer',
      padding: '0 1rem',
      gap: '0.5rem',
      borderLeft: `0.0625rem solid ${uploadButtonBorderColor}`,

      '& input': {
        cursor: 'pointer',
        width: '100%',
        height: '100%',
      },

      '& svg': {
        fontSize: '1.25rem',
        color: labelColor,
      }
    },
  },
  '& .tag-input-container': {
    borderRadius: '0.5rem',
  },
  '& .tag-item': {
      borderRadius: '0.375rem',
      border: `0.0625rem solid ${defaultInputBorderColor}`,
      background: whiteColor,
      display: 'inline-flex',
      alignItems: 'center',
      padding: '0.125rem 0.3125rem',
      fontSize: '0.875rem',
      color: labelColor,
      lineHeight: '142.857%',
      fontWeight: 500,
      '& span.close-btn': {
        marginLeft: '0.1875rem',
        padding: '0.2813rem',
        background: 'transparent',
        display: 'inline-flex',
        alignItems: 'center',

        '& img': {
          width: 'auto'
        }
      }
  },
  '& .error-text': {
    color: errorColor,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '142.857%',
    marginTop: '0.375rem',
    '&.e-black': {
      color: checkBoxTextColor,
    }
  },
  '& .form-group': {
    marginBottom: 0,
    '& + .form-group': {
      marginTop: '1.5rem'
    },
    '& .input-with-icon': {
      position: 'relative',

      '& svg': {
        fontSize: '1.25rem',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: '0.75rem',
        color: inputTextColor,

        '& + .form-control': {
          paddingLeft: '2.5rem',
        }
      },

      '& .form-control': {
        '& + svg': {
          left: 'auto',
          right: '0.75rem',
          color: errorColor
        }
      }
    },
    '& label': {
      display: 'block',
      color: labelColor,
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '142.857%',
      marginBottom: '0.375rem'
    },

    '& .note-text': {
      display: 'block',
      color: checkBoxTextColor,
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '142.857%',
    },

    '& .form-control': {
      minHeight: '2.5rem',
      padding: '0.5rem 0.75rem',
      borderRadius: '0.5rem',
      border: `0.0625rem solid ${defaultInputBorderColor}`,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '150%',
      color: inputTextColor,
      backgroundColor: whiteColor,
      boxShadow: inputShadow,

      '&::placeholder': {
        color: inputTextColor,
        fontWeight: 400,
      },

      '&.error': {
        borderColor: errorBorderColor
      }
    }
  },
  '& .modal-body': {
    px: '2rem'
  },
  '& .modal-header': {
    height: '3.625rem',
    borderBottomColor: modalHeaderBorderColor,
    borderTopLeftRadius: '0.375rem',
    borderTopRightRadius: '0.375rem',

    '& .btn-close': {
      margin: '0 0 0 auto',
      padding: '0.375rem',
    }
  },
  '& .form-check': {
    padding: 0,
    gap: '0.75rem',
    justifyContent: 'start',
    flex: 1,
    alignItems: 'center',

    '& span': {
      padding: 0,
      gap: '0.75rem',
      display: 'flex',
      justifyContent: 'start',
      flex: 1,
      alignItems: 'start',
    },
  },
  '& .form-check-input': {
    fontSize: '1.25rem',
    margin: 0,
    borderColor: defaultInputBorderColor,
    backgroundColor: whiteColor,
    '&:checked': {
      borderColor: checkedBorderColor,
      backgroundColor: checkedBgColor,
      '&[type=radio]': {
        backgroundImage: `url(${ CheckIcon })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '0.5rem',
        backgroundPosition: 'center',
      },
      '&[type=checkbox]': {
        backgroundImage: `url(${ TickIcon })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '0.75rem',
        backgroundPosition: 'center',
      }
    }
  },
  '& .wrp-check': {
    '& .all-check': {
      padding: 0,
      gap: '0.75rem',
      justifyContent: 'start',
      flex: 1,
      alignItems: 'center',



      '& p': {
        color: checkBoxTextColor,
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '150%'
      }
    }
  },
  '& .modal-title': {
    color: modalTitleColor,
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '150%',
    letterSpacing: '0.02rem',
  }
}