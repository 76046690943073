import { Box } from "@mui/material";
import React, { useEffect } from "react";


const Modal = (props) => {
  const { children, modalId, modalLabel , className, modalRef, sx = {}, showModal } = props;

  useEffect(() => {
    if (showModal) {
      // Create and display the dynamic modal backdrop
      const backdrop = document.querySelector('.modal-backdrop');
      if(!backdrop) {
        const backdrop = document.createElement('div');
        backdrop.className = 'modal-backdrop fade show';
        document.body.appendChild(backdrop);
      }
    } else {
      // Hide the dynamic modal backdrop
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        document.body.removeChild(backdrop);
      }
    }
  }, [showModal]);
  return (
    <Box
      ref={modalRef}
      className={`modal fade ${className} ${showModal ? ' show' : ''}`}
      id={modalId}
      aria-hidden="true"
      sx={{...sx,
      display: showModal ? 'block' : 'none' }}
      aria-labelledby={modalLabel}
      // tabIndex="-1"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      {children}
    </Box>

  );
};

export default Modal;
