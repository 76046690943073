import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/action-master";
import add from "../assets/images/new-images/add.png";
import DateRangePicker from "rsuite/DateRangePicker";
import {
  fetchAlertByLoginId,
  deleteModal,
  getPaginationData,
  fetchSearch,
} from "../actions/action-notifications";
import { DashboardLayout } from "../components/dashboard-layout";
import "../assets/css/myApp.css";
import "../assets/css/new-dashboard.css";
import brdCum from "../assets/images/new-images/brd-cum-icon.svg";
import SearchIcon from "../assets/images/search.svg";
import n1 from "../assets/images/new-images/n1.png";
import n3 from "../assets/images/new-images/n3.png";
import deleteIcon from "../assets/images/new-images/delete.png";
import edit from "../assets/images/new-images/edit.png";
import AddAlerts from "../components/Notifications/AddAlerts";
import DeleteAlerts from "../components/Notifications/DeleteAlerts";
import Pagination from "../components/UI/Pagination";
import { dateNotificatiionFilter } from "../actions/action-notifications";
import arrow from "../assets/images/new-images/right-arrow.svg";
import moment from "moment";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import Loader from "../components/UI/Loader";
import path from "../assets/images/new-images/Path.png";
import p5 from "../assets/images/new-images/evt5.svg";
import NotificationType from "../components/Notifications/NotificationType";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import {renderImageDataUrl} from "../middlewares/utils";
const { REACT_APP_BACKEND_API_APP } = process.env;

function Alerts({
  deleteModal,
  notifications = [],
  fetchAlertByLoginId,
  fetchAllUserGroups,
  fetchSearch,
  fetchNotificationTypes,
  notificationTypes = [],
  userGroups = [],
  totalRecords,
  loading,
  getPaginationData,
  paginationOptions,
  ...props
}) {
  const init = () => {
    // fetchAlertByLoginId();
    fetchSearch("");
    fetchAllUserGroups();
    fetchNotificationTypes();
  };
  let empId = sessionStorage.getItem("userEmpId");

  let pageNo = useRef();

  pageNo.current = paginationOptions.pageNo;
  const defaultState = [];

  const [alerts, setAlerts] = useState(defaultState);

  const setPaginationOptions = (values) => {
    getPaginationData(values);
  };

  useEffect(() => {
    init();
  }, []);

  // useEffect(() => {
  //   getPaginationData(paginationOptions);
  // }, []);

  const [deleteId, setDeleteId] = useState(null);
  const delTest = (id) => {
    deleteModal(true);
    setDeleteId(id);
  };
  const [editmode, setEditMode] = useState(false);
  const [filterAlertIndex, setfilterAlertIndex] = useState("");
  const [filterAlert, setFilterAlert] = useState({});
  const [timer, setTimer] = useState(null);

  const editAlert = (id) => {
    setEditMode(true);
    setfilterAlertIndex(
      notifications?.findIndex((alert, index) => alert.id === id)
    );
    setFilterAlert(notifications?.find((alert, index) => alert.id === id));
  };

  const [searchInput, setSearchInput] = useState("");

  const searchHandler = (e) => {
    setSearchInput(e.target.value);
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      fetchSearch(e.target.value);
    }, 1000);

    setTimer(newTimer);
  };

  // const ReadMore = ({ children }) => {
  //   const text = children;
  //   const [isReadMore, setIsReadMore] = useState(true);
  //   const toggleReadMore = () => {
  //     setIsReadMore(!isReadMore);
  //   };

  //   return (
  //     <p className="n-body">
  //       {isReadMore ? text.slice(0, 150) : text}
  //       <span onClick={toggleReadMore}>
  //         {text.length > 150
  //           ? isReadMore
  //             ? "... Read More"
  //             : " Show less"
  //           : ""}
  //       </span>
  //     </p>
  //   );
  // };
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [value, setValue] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dateFilterHandler = (val) => {
    setValue(val);

    let date = {};
    date.startDate = val && moment.utc(val[0]).local().format("YYYY-MM-DD");
    date.endDate = val && moment.utc(val[1]).local().format("YYYY-MM-DD");

    if (val) {
      setStartDate(date.startDate);
      setEndDate(date.endDate);
    } else {
      setStartDate("");
      setEndDate("");
    }

    props.dateNotificatiionFilter(date);
  };

  useEffect(() => {
    let date = {};
    date.startDate = "";
    date.endDate = "";
    props.dateNotificatiionFilter(date);
  }, []);


  return (
    <>
      <AuthenticatedTemplate>
        <div>
          <AddAlerts
            alerts={alerts}
            setAlerts={setAlerts}
            filterAlert={filterAlert}
            setFilterAlert={setFilterAlert}
            filterAlertIndex={filterAlertIndex}
            editmode={editmode}
            setEditMode={setEditMode}
            notifications={notifications}
            notificationTypes={notificationTypes}
            groupsList={userGroups}
          />
          <DeleteAlerts
            deleteId={deleteId}
            alerts={alerts}
            setAlerts={setAlerts}
            fetchAlertByLoginId={fetchAlertByLoginId}
            setDeleteSuccess={setDeleteSuccess}
          />

          <DashboardLayout>
            <section>
              <div className="breadcrumb">
                <img src={brdCum} alt="logo" />
                <p> |&nbsp;&nbsp; Notifications </p>
              </div>
              <div className="app-wrapper">
                <>
                  <div className="setting-box notification-setting-box">
                    <div className="application-name">
                      <div className="nav-setting">
                        <div className="nav nav-tabs" id="myTab" role="tablist">
                          <button
                            className="nav-link active"
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home"
                            type="button"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                          >
                            <span>Notification</span>
                            <img src={arrow} alt="logo" />
                          </button>

                          <div className="divider"></div>

                          <button
                            className="nav-link"
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile"
                            type="button"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                          >
                            <span> Notification Type</span>
                            <img src={arrow} alt="logo" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="application-detail">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div className="app-header eql-spc">
                            <p className="app-heding">Notifications</p>
                            <div className="app-searching">
                              <div className="relative w-100">
                                <img src={SearchIcon} alt="icon" />
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  value={searchInput}
                                  onChange={searchHandler}
                                />
                              </div>
                            </div>
                            <div className="wrp-notify-rightHead">
                              <div className="setDateRang notifyDateRange">
                                <DateRangePicker
                                  placeholder="Filter by Date"
                                  format="MM-dd-yyy"
                                  onChange={(ValueType) => {
                                    dateFilterHandler(ValueType);
                                  }}
                                  value={value}
                                  placement="bottomEnd"
                                  editable={false}
                                  size="md"
                                />
                              </div>
                              <div className="applist-right-headbar">
                                {empId &&
                                empId !== "" &&
                                empId !== undefined ? (
                                  <></>
                                ) : (
                                  <button
                                    className="btn btn-genmeb min-w72"
                                    data-bs-toggle="modal"
                                    data-bs-target="#Alertsend"
                                  >
                                    <img
                                      src={add}
                                      alt="icon"
                                      className="me-2"
                                    />{" "}
                                    Create Notification
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                          {loading ? (
                            <Box
                              sx={{
                                display: "flex",
                                position: "absolute",
                                top: "50%",
                                width: "100%",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "start",
                                left: "10%",
                                // background: "#000",
                                zIndex: 99,
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <>
                              {notifications && notifications?.length === 0 ? (
                                <div className="alert-bar-boxInner">
                                  No Notifications available
                                </div>
                              ) : notifications.length === 0 ? (
                                <div className="alert-bar-boxInner">
                                  {" "}
                                  No Match Found
                                </div>
                              ) : (
                                <div className="alert-bar-boxInner">
                                  {notifications?.map((a, index) => (
                                    <div
                                      className="alrt-item bg-w"
                                      style={{
                                        borderLeft: "6px solid",
                                        borderLeftColor:
                                          a.notificationTypeID === 0 ||
                                          notificationTypes?.find(
                                            (item) =>
                                              item.id === a.notificationTypeID
                                          )?.status === false
                                            ? "#06a894"
                                            : notificationTypes?.find(
                                                (item) =>
                                                  item.id ===
                                                  a.notificationTypeID
                                              )?.colour !== null
                                            ? notificationTypes?.find(
                                                (item) =>
                                                  item.id ===
                                                  a.notificationTypeID
                                              )?.colour
                                            : "#06a894",
                                      }}
                                      key={index}
                                    >
                                      <div
                                        className="n-img"
                                        style={{
                                          border: a.imageBase64String
                                            ? "none"
                                            : notificationTypes?.find(
                                                (item) =>
                                                  item.id ===
                                                  a.notificationTypeID
                                              )?.status === false ||
                                              a.notificationTypeID === 0
                                            ? "2px solid #06a894"
                                            : `2px solid ${
                                                notificationTypes?.find(
                                                  (item) =>
                                                    item.id ===
                                                    a.notificationTypeID
                                                )?.colour !== null
                                                  ? notificationTypes?.find(
                                                      (item) =>
                                                        item.id ===
                                                        a.notificationTypeID
                                                    )?.colour
                                                  : "#06a894"
                                              }
                  `,
                                        }}
                                      >
                                        {a.imageBase64String ? (
                                          <img
                                            src={renderImageDataUrl(a.imageBase64String)}
                                            alt="icon"
                                          />
                                        ) : (
                                          <div
                                            className="user-created-box"
                                            style={{
                                              color:
                                                notificationTypes?.find(
                                                  (item) =>
                                                    item.id ===
                                                    a.notificationTypeID
                                                )?.status === false ||
                                                a.notificationTypeID === 0
                                                  ? "#06a894"
                                                  : notificationTypes?.find(
                                                      (item) =>
                                                        item.id ===
                                                        a.notificationTypeID
                                                    )?.colour !== null
                                                  ? notificationTypes?.find(
                                                      (item) =>
                                                        item.id ===
                                                        a.notificationTypeID
                                                    )?.colour
                                                  : "#06a894",
                                            }}
                                          >
                                            <span>
                                              {notificationTypes?.find(
                                                (item) =>
                                                  item.id ===
                                                  a.notificationTypeID
                                              )?.status === false
                                                ? "INACT"
                                                : a.notificationTypeID === 0
                                                ? "DELE"
                                                : notificationTypes?.find(
                                                    (item) =>
                                                      item.id ===
                                                      a.notificationTypeID
                                                  )?.value}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                      <div>
                                        <p className="n-head">{a.title}</p>

                                        <div>
                                          {a.description !== null && (
                                            <p className="n-body">
                                              {a.description.slice(0, 150)}
                                            </p>
                                          )}
                                          <div className="dropdown inlinebox">
                                            <a
                                              className="dropdown-toggle n-body-read-btn"
                                              role="button"
                                              id="dropdownMenuLink"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              ReadMore
                                            </a>
                                            <ul className="dropdown-menu readMore-detail">
                                              <li>
                                                <div className="event-popup">
                                                  <div className="p-top">
                                                    <img
                                                      src={path}
                                                      alt="icon"
                                                    />
                                                  </div>
                                                  <span className="popup-head">
                                                    <span className="choose-color-blue"></span>
                                                    <p>{a.title}</p>
                                                  </span>
                                                  <span className="popup-body">
                                                    <img src={p5} alt="icon" />
                                                    <span>
                                                      <p className="p-text">
                                                        Notification Details
                                                      </p>
                                                      <p className="p-sub-text">
                                                        {a.description}
                                                      </p>
                                                    </span>
                                                  </span>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>

                                        <p className="n-footer">
                                          {moment
                                            .utc(a.createdAt)
                                            .local()
                                            .format("MMMM Do YYYY, h:mm a")}
                                        </p>
                                      </div>

                                      <div></div>
                                      <div></div>
                                      {empId &&
                                      empId !== "" &&
                                      empId !== undefined ? (
                                        <></>
                                      ) : (
                                        <div className="d-flex justify-content-end align-items-center icon-size">
                                          <img
                                            src={edit}
                                            alt="icon"
                                            className="me-3"
                                            data-bs-toggle="modal"
                                            data-bs-target="#Alertsend"
                                            onClick={() => {
                                              editAlert(a.id);
                                            }}
                                          />
                                          <img
                                            src={deleteIcon}
                                            alt="icon"
                                            data-bs-target="#deleteAlertsModal"
                                            data-bs-toggle="modal"
                                            onClick={() => {
                                              delTest(a.id);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              )}
                              <Pagination
                                totalRecords={totalRecords}
                                options={paginationOptions}
                                pageNo={pageNo}
                                onChange={(e) => setPaginationOptions(e)}
                              />
                            </>
                          )}
                        </div>

                        <div
                          className="tab-pane fade"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <NotificationType />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </section>
          </DashboardLayout>
        </div>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <center className="center-content">Conetnt is not accessabl</center>
      </UnauthenticatedTemplate>
    </>
  );
}

const mapStateToProps = ({ master, alerts }) => ({
  loading: alerts.loading,
  userGroups: master.userGroups,
  notifications: alerts.alerts,
  notificationTypes: master.notificationTypes,
  totalRecords: alerts.totalRecords,
  recordsPerPage: alerts.recordsPerPage,
  paginationOptions: alerts.paginationOptions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllUserGroups: () => dispatch(actions.fetchAllUserGroups()),
  fetchNotificationTypes: () => dispatch(actions.fetchNotificationTypes()),
  fetchAlertByLoginId: () => dispatch(fetchAlertByLoginId()),
  deleteModal: (val) => dispatch(deleteModal(val)),
  getPaginationData: (values) => dispatch(getPaginationData(values)),
  dateNotificatiionFilter: (val) => dispatch(dateNotificatiionFilter(val)),
  fetchSearch: (values) => dispatch(fetchSearch(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
